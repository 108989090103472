import { useState, useEffect, useCallback, useRef } from "react";
import { formatInputToCapitalization } from "../Date_Formate_Utilitiy/Index";
import { flushSync } from "react-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/actions/action-creator";
import { fieldConfig } from "../../pages/gatewayoftally/master/create/fieldNextPrev";

export const useInputActionLedger = ({ data, setData, validations, refs, check_dropdown, inputRefs }) => {
    const [submitConfirm, setSubmitConfirm] = useState('');
    function getNextMapping(key) {
        switch (key) {
            case "capital-account":
            case "reserves-surplus":
            case "current-assets":
            case "depositsasset":
            case "current-liabilities":
            case "direct-expenses":
            case "loansliability":
            case "investments":
            case "provisions":
            case "misc-expenses":
            case "secured-loans":
            case "unsecured-loans":
            case "suspense-ac":
            case "loans-advance-asset":
            case "direct-incomes":
            case "branchdivisions":
            case "sundry-creditors":
            case "sundry-debtors":
            case "indirect-expenses":
            case "indirect-incomes":
                return "maintain_balance_bill_by_bill";

            case "cashinhand":
            case "stockinhand":
            case "purchase-accounts":
            case "sales-accounts":
                return "opening_balance";

            case "fixed-assets":
                return "mailing_name";

            case "bank-od-ac":
            case "bank-account":
                return "effective_date_for_reconciliation";
            default:
                return null; // Or any other default value you need
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (e.code === "Space") {
            e.preventDefault();
            const cursorPosition = e.target.selectionStart;
            if (cursorPosition === 1) {
                setData({ ...data, [name]: "" });
                return;
            }
        }
        if (e.altKey && e.key === 'Enter') {
            e.preventDefault();
            if (name === 'address') {
                const nextInputRef = refs[name]?.curr?.current;
                if (nextInputRef) {
                    nextInputRef.value = nextInputRef.value + "\r\n";
                }
            }
            return
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField = null;
            // if (name === 'under_id') {
            //     nextField = getNextMapping(data?.group_slug)
            // } else
            //     if (name === 'maintain_balance_bill_by_bill') {
            //         nextField = data?.maintain_balance_bill_by_bill === 'Yes' ? "default_credit_period" :
            //             ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug) ? "specify_credit_limit" :
            //                 ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses',].includes(data?.group_slug) ? "type_of_ledger" :
            //                     ['reserves-surplus', 'provisions', 'suspense-ac'].includes(data?.group_slug) ? "opening_balance" : "mailing_name"
            //     } else
            //         if (name === 'default_credit_period') {
            //             nextField = ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug) ? "check_for_credit_days_duriing_voucher_entry" :
            //                 ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses',].includes(data?.group_slug) ? "type_of_ledger" :
            //                     ['reserves-surplus', 'provisions', 'suspense-ac'].includes(data?.group_slug) ? "opening_balance" : "mailing_name"
            //         } else
            //             if (name === 'check_for_credit_days_duriing_voucher_entry') {
            //                 nextField = ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug) ? "specify_credit_limit" : "check_for_credit_days_duriing_voucher_entry"
            //             } else
            //                 if (name === 'specify_credit_limit') {
            //                     nextField = 'mailing_name'
            //                 } else
            //                     if (name === 'type_of_ledger') {
            //                         nextField = value === 'Not Applicable' ? "opening_balance" : "rounding_method"
            //                     }else
            //                     if (name === 'effective_date_for_reconciliation') {
            //                         nextField = data?.group_slug === 'bank-od-ac' ? 'set_od_limit' : "ac_holders_name"
            //                     }
            //                     else {
            //                         // Default sequential navigation
            //                         const fields = Object.keys(inputRefs);
            //                         const currentIndex = fields.indexOf(name);
            //                         nextField = fields[currentIndex + 1] || null;
            //                     }

            const field = fieldConfig[name];
            if (field) {
                if (field.next) {
                    nextField = typeof field.next === "function" ? field.next(data) : field.next;
                }
            }

            if (!nextField) {
                const fields = Object.keys(inputRefs);
                const currentIndex = fields.indexOf(name);
                nextField = fields[currentIndex + 1] || null;
            }

            if (nextField && inputRefs[nextField]?.current) {
                inputRefs[nextField].current.focus();
                inputRefs[nextField].current.setSelectionRange(0, inputRefs[nextField].current.value.length);
            } else {
                setSubmitConfirm('Accept')
                inputRefs[name]?.current?.blur();
            }
        }
        // Apply field-specific validations
        const validation = validations[name];
        if (validation) {
            const isValid = validation(value);
            if (isValid || value === "") {
                const updatedValue = (name === 'company_name' || name === 'mailing_name' || name === 'address' || name === 'city' || name === 'name' || name === 'ledger_name' || name === 'alise_name' || name === 'symbol' || name === 'formal_name')
                    ? formatInputToCapitalization(value)
                    : (name === 'no_of_decimal_place' || name === 'no_of_decimal_place_for_amount')
                        ? value.slice(-1)
                        : value;
                setData((prevData) => {
                    const updatedData = { ...prevData, [name]: updatedValue };
                    return updatedData;
                })


            }
        } else {
            flushSync(setData({ ...data, [name]: value }))

        }
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        const focusAction = refs[name]?.onFocus;
        if (focusAction) focusAction();
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const blurAction = refs[name]?.onBlur;
        if (blurAction) blurAction(value, setData);
    };

    const handleKeyDown = (e) => {
        const { name, value } = e.target;
        if (e.key === "Enter") {
            e.preventDefault();

            return;
        }
        if (e.shiftKey && e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            const prevInputRef = refs[name]?.prev;
            if (prevInputRef && prevInputRef.current) {
                prevInputRef.current.focus();
                prevInputRef.current.setSelectionRange(0, prevInputRef?.current?.value?.length);
            }
            return;
        }
    }



    return {
        data,
        setData,
        handleChange,
        handleFocus,
        handleBlur,
        handleKeyDown,
        submitConfirm,
        setSubmitConfirm
    };
};
