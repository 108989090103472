import { godownCreate, godownList, openModal, closeModal } from '../../../../../redux/actions/action-creator';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import Form from '../../../../../components/Form/Form';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { useInputAction } from '../../../../../components/Custom_Hooks/useInputAction';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';
import useData from '../../../../../components/Custom_Hooks/useData';


const GoDown = ({ title, closeModal1 }) => {

  const dispatch = useDispatch();


  const { godown_data = null } = useSelector(state => state.common);
  const modal_state = useSelector(state => state.common.modal_state);
  const { userData = null } = useSelector(state => state.auth);

  const initialState = {
    name: '',
    alias_name: '',
    under: 'Primary',
    under_id: 'Primary',
    company_id: userData?.id,
  }
  const { data, setData } = useData(initialState);
  const [nameInput, setNameInput] = useState(null)

  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(godownList(formData))
  }, [])
  const inputRefs = {
    name: useRef(null),
    alias_name: useRef(null),
    under_id: useRef(null),
  };
  const first_ref = inputRefs?.name

  function getCurrentInputRef(name) {
    return inputRefs[name] || null;
  }
  // const fieldConfig = [
  //   { name: "name", next: "alias_name" },
  //   { name: "alias_name", next: "under_id", prev: "name" },
  //   { name: "under_id", prev: "alias_name" },
  // ];


  const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  const validations = {
    name: (value) => value.trim() !== "",
    alias_name: (value) => value.trim() !== "",
    under_id: (value) => godown_data.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
  };

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    // const nextField = fieldConfig.find(f => f.name === name)?.next;
    // const prevField = fieldConfig.find(f => f.name === name)?.prev;
    acc[name] = {
      // next: nextField ? inputRefs[nextField] : null,
      // prev: prevField ? inputRefs[prevField] : null,
      // curr: getCurrentInputRef(name),
      onFocus: () => {
        const ref = getCurrentInputRef(name);
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if (name === 'under_id') {
          dispatch(openModal(name))
        }
      },
      onBlur: (value) => {
        if (name === 'name') {
          if (!value.trim()) {
            getCurrentInputRef(name)?.current?.focus();
          }
        }
        if (name === 'under_id') {
        }
        dispatch(closeModal(name))
      },
    };
    return acc;
  }, {});
  const { handleChange, handleFocus, handleBlur, handleKeyDown, setSubmitConfirm, submitConfirm, } = useInputAction({
    data,
    setData,
    validations,
    refs,
    check_dropdown: modal_state?.under_id,
    inputRefs
  });

  const validateForm = () => {
    const requiredFields = [
      { field: 'name', message: 'Required Name Field' },
      { field: 'under_id', message: 'Required Under Field' },
    ]

    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        getCurrentInputRef(field)?.current?.focus();
        return false;
      }
    }
    return true;
  };

  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setData(initialState)
  };

  const { handleSubmit, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(godownCreate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref,
    closeModal,
    setSubmitConfirm,
    submitConfirm
  });

  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };

  useClickOutside(nameInput, handleClickOutside, handleClickInside);
  
  const inputFields = [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      isRequired: true,
    },
    {
      label: '(alise)',
      name: 'alias_name',
      type: 'text',
      isRequired: false,
    },
    {
      label: 'Under',
      name: 'under_id',
      type: 'text',
      isRequired: true,
    },
  ];
  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title={title || 'Godown Creation'}
      loading={loading}
      col='col-5'
      Footer={MasterCreationFooter}
    >
      <div className='row p-0 ps-2 m-0 mb-1'>
        {inputFields?.map(({ label, name, type, value, ref, isRequired }, index) => (
          <InputField
            key={index}
            lable={label}
            type={type}
            name={name}
            value={data[name]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={inputRefs[name]}
          />
        ))}
        {
          modal_state?.under_id && (
            <DropdownLayout_1
              title='List of Godown'
              state={modal_state?.under_id}
              data={data}
              setData={setData}
              input_value={data?.under_id}
              input_key='under_id'
              input_id='under'
              rawData={godown_data}
              ref={inputRefs?.under_id}
              setSubmitConfirm={setSubmitConfirm}
            />)
        }
      </div>
    </Form>
  )
}

export default React.memo(GoDown)

