import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useKeyboardNavigation = (keyMap) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleKeyDown = (event) => {
    const key = event.key.toLowerCase();
    const route = keyMap[key];
    if (route && !['/ledgerbook', '/current_date', '/period','/create-master-group','/create-master-ledger','/create-master-invmas-stockgroup','/create-master-invmas-stockcategory','/create-master-invmas-stockcompany','/create-master-invmas-stockitem','/create-master-invmas-unit','/create-master-invmas-godown'].includes(location.pathname)) {
      navigate(route);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [location.pathname, navigate, keyMap]);
};

export default useKeyboardNavigation;
