import React from 'react'

function NavFooterLayout({children}) {
    return (
        <div className='row border_2_2px_top' style={{height:19.5}} >
            <div className='col-12 d-flex justify-content-between table_header_font'style={{height:19.5}}>
                {children}
            </div>
        </div>
    )
}

export default NavFooterLayout
