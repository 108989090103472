import React, { forwardRef, useRef } from 'react'
import { Link } from 'react-router-dom';
import { useDropdownFilter } from '../Custom_Hooks/useDropdownFilter';
import { useDropdownKeyboardNavigation } from '../Custom_Hooks/useDropdownKeyboardNavigation';
import { useDropdownScroll } from '../Custom_Hooks/useDropdownScroll';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from '../../redux/actions/action-creator';


const DropdownYesNo = forwardRef(({ state, input_value, input_key, rawData, data, setData, inputRefs, setSubmitConfirm, style, index }, ref) => {
    const dropdownRef = useRef(null)
    const dispatch = useDispatch();
    const { filteredData } = useDropdownFilter({
        dataList: rawData,
        searchInput: input_value,
        inputRef: ref,
    });
    // console.log("this is state dropdown yes no :---",state)
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: filteredData,
        state,
        onItemSelect: (item) => {
            if (input_key === 'per') {
                setData((prevData) => {
                    const updatedData = { ...prevData };
                    updatedData.data_item = updatedData.data_item.map((item_data, i) =>
                        i === index ? {
                            ...item_data,
                            [input_key]: item?.name,
                        } : item_data
                    );

                    return updatedData;
                });
                dispatch(closeModal(`${input_key}${index}`))
            }else{
                if (input_key === 'provide_behaviour_opt') {
                    if (item?.name === 'Yes') {
                        dispatch(openModal('behavior'))
                    }
                }
                setData({ ...data, [input_key]: item?.name })
    
                if (input_key === 'treat_rejection_scrap') {
                    setTimeout(() => {
                        dispatch(closeModal('behavior'))
                    }, 150);
                }
                dispatch(closeModal(input_key))
            }
        },
        input_value
    });

    useDropdownScroll({ dropdownRef, selectedItemIndex });

    return (
        <div className={`dropdown_yes_no_list`} style={{ display: state ? 'block' : 'none', ...style }} >
            <div className='col dropdown_header'>
                Yes / No
            </div>
            <div id='data'>
                {filteredData?.length > 0 ? (
                    filteredData?.map((key, number) => {
                        const name = key?.name;
                        const index = name?.toLowerCase()?.indexOf(input_value?.toLowerCase());
                        return (
                            <Link
                                className={`list font-weight-bold ${number === selectedItemIndex ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={() => {
                                    setSelectedItemIndex(number)
                                    if (number === selectedItemIndex) {
                                        setData({ ...data, [input_key]: name })

                                        let nextField = null;
                                        if (input_key === "weight") {
                                            nextField = name === "Available" ? "per_unit" : "set_standard_rate";
                                        } else if (input_key === "provide_behaviour_opt") {
                                            nextField = data?.provide_behaviour_opt === "Yes" ? "ignore_diff_physical" : "quantity";
                                        } else {
                                            // Default sequential navigation
                                            const fields = Object.keys(inputRefs);
                                            const currentIndex = fields.indexOf(input_key);
                                            nextField = fields[currentIndex + 1] || null;
                                        }

                                        if (nextField && inputRefs[nextField]?.current) {
                                            inputRefs[nextField].current.focus();
                                            inputRefs[nextField].current.setSelectionRange(0, inputRefs[nextField].current.value.length);
                                        } else {
                                            setSubmitConfirm('Accept')
                                            inputRefs[input_key]?.current?.blur();
                                        }
                                    }
                                }}
                            >
                                {index !== -1 ? (
                                    < >
                                        {name.substring(0, index)}
                                        <span style={{ color: 'red' }}>{name.substring(index, index + input_value?.length)}</span>
                                        {name.substring(index + input_value?.length)}
                                    </>
                                ) : (
                                    name
                                )}
                            </Link>
                        );
                    })
                ) : (
                    <p style={{ color: 'red' }}>No matching items found</p>
                )}
            </div>
        </div>
    )
})

export default React.memo(DropdownYesNo)
