export const getNextMapping = (groupSlug) => {
    switch (groupSlug) {
        case "capital-account":
        case "reserves-surplus":
        case "current-assets":
        case "depositsasset":
        case "current-liabilities":
        case "direct-expenses":
        case "loansliability":
        case "investments":
        case "provisions":
        case "misc-expenses":
        case "secured-loans":
        case "unsecured-loans":
        case "suspense-ac":
        case "loans-advance-asset":
        case "direct-incomes":
        case "branchdivisions":
        case "sundry-creditors":
        case "sundry-debtors":
        case "indirect-expenses":
        case "indirect-incomes":
            return "maintain_balance_bill_by_bill";

        case "cashinhand":
        case "stockinhand":
        case "purchase-accounts":
        case "sales-accounts":
            return "opening_balance";

        case "fixed-assets":
            return "mailing_name";

        case "bank-od-ac":
        case "bank-account":
            return "effective_date_for_reconciliation";

        default:
            return null;
    }
};

export const fieldConfig = {
    ledger_name: { name: "ledger_name", next: "alise_name" },
    alise_name: { name: "alise_name", next: "under_id", prev: "ledger_name" },
    under_id: {
        name: "under_id",
        next: (data) => getNextMapping(data?.group_slug),
        prev: "alise_name",
    },
    maintain_balance_bill_by_bill: {
        name: "maintain_balance_bill_by_bill",
        next: (data) => {
            if (data?.maintain_balance_bill_by_bill === 'Yes') return "default_credit_period";
            if (["branchdivisions", "sundry-creditors", "sundry-debtors"].includes(data?.group_slug)) return "specify_credit_limit";
            if (["direct-expenses", "direct-incomes", "indirect-expenses", "indirect-incomes", "misc-expenses"].includes(data?.group_slug)) return "type_of_ledger";
            if (["reserves-surplus", "provisions", "suspense-ac"].includes(data?.group_slug)) return "opening_balance";
            return "mailing_name";
        },
        prev: "under_id",
    },
    default_credit_period: {
        name: "default_credit_period",
        next: (data) => {
            if (["branchdivisions", "sundry-creditors", "sundry-debtors"].includes(data?.group_slug)) return "check_for_credit_days_duriing_voucher_entry";
            if (["direct-expenses", "direct-incomes", "indirect-expenses", "indirect-incomes", "misc-expenses"].includes(data?.group_slug)) return "type_of_ledger";
            if (["reserves-surplus", "provisions", "suspense-ac"].includes(data?.group_slug)) return "opening_balance";
            return "mailing_name";
        },
        prev: "maintain_balance_bill_by_bill",
    },
    check_for_credit_days_duriing_voucher_entry: {
        name: "check_for_credit_days_duriing_voucher_entry",
        next: (data) => ["branchdivisions", "sundry-creditors", "sundry-debtors"].includes(data?.group_slug) ? "specify_credit_limit" : null,
        prev: "default_credit_period",
    },
    specify_credit_limit: {
        name: "specify_credit_limit",
        next: "mailing_name",
        prev: "check_for_credit_days_duriing_voucher_entry",
    },
    type_of_ledger: {
        name: "type_of_ledger",
        next: (data) => data?.type_of_ledger === "Not Applicable" ? "opening_balance" : "rounding_method",
        prev: "maintain_balance_bill_by_bill",
    },
    rounding_method: {
        name: "rounding_method",
        next: "rounding_limit",
        prev: "type_of_ledger",
    },
    rounding_limit: {
        name: "rounding_limit",
        next: "opening_balance",
        prev: "rounding_method",
    },
    effective_date_for_reconciliation: {
        name: "effective_date_for_reconciliation",
        next: (data) => data?.group_slug === "bank-od-ac" ? "set_od_limit" : "ac_holders_name",
        prev: "under_id",
    },
    set_od_limit: {
        name: "set_od_limit",
        next: "ac_holders_name",
        prev: "effective_date_for_reconciliation",
    },
    ac_holders_name: {
        name: "ac_holders_name",
        next: "ac_no",
        prev: "set_od_limit",
    },
    ac_no: {
        name: "ac_no",
        next: "ifs_code",
        prev: "ac_holders_name",
    },
    ifs_code: {
        name: "ifs_code",
        next: "swift_code",
        prev: "ac_no",
    },
    swift_code: {
        name: "swift_code",
        next: "bank_name",
        prev: "ifs_code",
    },
    bank_name: {
        name: "bank_name",
        next: "branch",
        prev: "swift_code",
    },
    branch: {
        name: "branch",
        next: "set_alter_range_for_checque_books",
        prev: "bank_name",
    },
    set_alter_range_for_checque_books: {
        name: "set_alter_range_for_checque_books",
        next: "enable_check_printing",
        prev: "branch",
    },
    enable_check_printing: {
        name: "enable_check_printing",
        next: (data) => data?.enable_check_printing === "Yes" ? "set_alter_cheque_printing_configuration" : "use_as_notional_bank_for_post_dated_vouchers",
        prev: "set_alter_range_for_checque_books",
    },
    set_alter_cheque_printing_configuration: {
        name: "set_alter_cheque_printing_configuration",
        next: "use_as_notional_bank_for_post_dated_vouchers",
        prev: "enable_check_printing",
    },
    use_as_notional_bank_for_post_dated_vouchers: {
        name: "use_as_notional_bank_for_post_dated_vouchers",
        next: "mailing_name",
        prev: "set_alter_cheque_printing_configuration",
    },
    mailing_name: {
        name: "mailing_name",
        next: "address",
        prev: "use_as_national_bank_for_post_dated_vouchers",
    },
    address: {
        name: "address",
        next: "city",
        prev: "mailing_name",
    },
    city: {
        name: "city",
        next: "state",
        prev: "address",
    },
    state: {
        name: "state",
        next: "country",
        prev: "city",
    },
    country: {
        name: "country",
        next: "pincode",
        prev: "state",
    },
    pincode: {
        name: "pincode",
        next: "email",
        prev: "country",
    },
    email: {
        name: "email",
        next: 'mobile_no',
        prev: "pincode",
    },
    mobile_no: {
        name: "mobile_no",
        next: "provide_contact_details",
        prev: "email",
    },
    provide_contact_details: { name: "provide_contact_details", next: "provide_bank_details", prev: "mobile_no" },
    provide_bank_details: { name: "provide_bank_details", next: "aadhaar", prev: "provide_contact_details" },
    aadhaar: { name: "aadhaar", next: "transport_detail", prev: "provide_bank_details" },
    transport_detail: { name: "transport_detail", next: "pan_it_no", prev: "aadhaar" },
    pan_it_no: { name: "pan_it_no", next: "opening_balance", prev: "transport_detail" },

    registration_type: { name: "registration_type", next: "", prev: "used_for_calculation" },
    assessee_of_other_territory: { name: "assessee_of_other_territory", next: "", prev: "used_for_calculation" },
    gst_in_uin: { name: "gst_in_uin", next: "", prev: "used_for_calculation" },
    use_ledger_as_common_party: { name: "use_ledger_as_common_party", next: "", prev: "used_for_calculation" },
    set_alter_additional_gst_details: { name: "set_alter_additional_gst_details", next: "", prev: "used_for_calculation" },
    ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: { name: "ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation", prev: "used_for_calculation" },

    opening_balance: {
        name: "opening_balance",
        next: (data) => (data?.opening_balance ? "type" : null),
        prev: (data) => {
            if (['capital-account', 'current-assets', 'fixed-assets', 'depositsasset', 'current-liabilities',
                'bank-account', 'bank-od-ac', 'branchdivisions', 'secured-loans', 'unsecured-loans',
                'sundry-creditors', 'sundry-debtors', 'investments', 'loans-advance-asset', 'loansliability'].includes(data?.group_slug)) {
                return "pan_it_no";
            } else if (['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses'].includes(data?.group_slug)) {
                return data?.type_of_ledger === 'Not Applicable' ? "type_of_ledger" : "rounding_limit";
            }
            return ""; 
        },
    },

    type: { name: "type", prev: "opening_balance" }
};
