import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LINK_PATH } from '../../../../constant';
import { useDropdownKeyboardNavigation } from '../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../../../components/NavigationList/NavigationList';
import useKeyboardNavigation from '../../../../components/Custom_Hooks/useKeyboardNavigation';

const AccountBook =()=> {
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const keyMap = {
        'c': '',
        'l': '/ledgerbook',
        'g': '',
        'v': '',
        't': '',
        'y': '',
        'r': '',
        's': '',
        'p': '',
        'j': '',
        'd': '',
        'e': '',
        'u': '',
    };
    
    useKeyboardNavigation(keyMap);
   
    const data = [
        { to: '/createmasters', text: 'Cash/Bank Book(s)' },
        { to: '/ledgerbook', text: 'Ledger' },
        { to: '/chartofaccountmasters', text: 'Group Summary' },
        { to: '/chartofaccountmasters', text: 'Group Vouchers' },
        { to: '/voucherstransaction', text: 'Contra Register' },
        { to: '/daybooktransaction', text: 'Payment Register' },
        { to: '/daybooktransaction', text: 'Receipt Register' },
        { to: '/daybooktransaction', text: 'Sales Register' },
        { to: '/daybooktransaction', text: 'Purchase Register' },
        { to: '/daybooktransaction', text: 'Journal Register' },
        { to: '/daybooktransaction', text: 'Debit Note Register' },
        { to: '/daybooktransaction', text: 'Credit Note Register' },
        { to: '/daybooktransaction', text: 'Voucher Clarification' }
    ];
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: data,
        state: true,
        onItemSelect: (item) => {
            if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
                return;
            }
            navigate(item.to)
        },

    });
    return (
        <NavigationList
            data={data}
            selectedItemIndex={selectedItemIndex}
            setSelectedItemIndex={setSelectedItemIndex}
            title='Account Books'
            first_heading='SUMMARY'
            secound_heading='REGISTERS'
        />
    )
}

export default React.memo(AccountBook);
