import { useState, useRef, useEffect } from 'react';

function useData(initialState) {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const setImmediateState = (newState) => {
    setState((prevState) => {
      const updatedState = typeof newState === 'function' ? newState(prevState) : newState;
      stateRef.current = updatedState;
      return updatedState;
    });
  };

  return { data: state, setData: setImmediateState, stateRef };
}

export default useData;
