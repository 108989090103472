import React from 'react';
import './header.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../../redux/actions/action-creator';
import { House, Bell, Person } from 'react-bootstrap-icons'
import { ToastContainer, toast } from 'react-toastify';

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    function myFunction() {
        document.getElementById("myDropdown").classList.toggle("show");
        document.getElementById("myDropdown1").classList.remove('show');
        document.getElementById("myDropdown2").classList.remove('show');
        // document.getElementById("myDropdown3").classList.remove('show');
        document.getElementById("myDropdown4").classList.remove('show');
        document.getElementById("myDropdown5").classList.remove('show');
    }
    function myFunction1() {
        document.getElementById("myDropdown1").classList.toggle("show");
        document.getElementById("myDropdown").classList.remove("show");
        document.getElementById("myDropdown2").classList.remove('show');
        // document.getElementById("myDropdown3").classList.remove('show');
        document.getElementById("myDropdown4").classList.remove('show');
        document.getElementById("myDropdown5").classList.remove('show');
    }
    function myFunction2() {
        document.getElementById("myDropdown2").classList.toggle("show");
        document.getElementById("myDropdown").classList.remove("show");
        document.getElementById("myDropdown1").classList.remove('show');
        // document.getElementById("myDropdown3").classList.remove('show');
        document.getElementById("myDropdown4").classList.remove('show');
        document.getElementById("myDropdown5").classList.remove('show');
    }
    // function myFunction3() {
    //     document.getElementById("myDropdown3").classList.toggle("show");
    //     document.getElementById("myDropdown").classList.remove("show");
    //     document.getElementById("myDropdown1").classList.remove('show');
    //     document.getElementById("myDropdown2").classList.remove('show');
    //     document.getElementById("myDropdown4").classList.remove('show');
    //     document.getElementById("myDropdown5").classList.remove('show');
    // }
    function myFunction4() {
        document.getElementById("myDropdown4").classList.toggle("show");
        document.getElementById("myDropdown").classList.remove("show");
        document.getElementById("myDropdown1").classList.remove('show');
        document.getElementById("myDropdown2").classList.remove('show');
        // document.getElementById("myDropdown3").classList.remove('show');
        document.getElementById("myDropdown5").classList.remove('show');
    }
    function myFunction5() {
        document.getElementById("myDropdown5").classList.toggle("show");
        document.getElementById("myDropdown").classList.remove("show");
        document.getElementById("myDropdown1").classList.remove('show');
        document.getElementById("myDropdown2").classList.remove('show');
        // document.getElementById("myDropdown3").classList.remove('show');
        document.getElementById("myDropdown4").classList.remove('show');
    }
    // Close the dropdown if the user clicks outside of it
    window.onclick = function (event) {
        if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    }
    const { userData = null } = useSelector(state => state.auth);
    const userLogout = () => {
        if (userData?.id) {
            dispatch(logOut()).then((response) => {
                localStorage.removeItem('authToken');
                localStorage.removeItem('reduxState');
                toast.success(response);
                requestAnimationFrame(() => { navigate('/') }, 1000);
            });
        }
    }
    return (
        <nav className=' container-fluid'>
            <ToastContainer />
            <div className='row border_2_1px_bottom '>
                <div className='col-12 branch-and-menu'>
                    {/* <div className='row branch'>
                        <div className='col-2'>
                            {location.pathname == '/' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Gateway of Tally </p> : null}
                            {location.pathname == '/create-master-group' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Group Creation </p> : null}
                            {location.pathname == '/create-master-ledger' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Ledger Creation </p> : null}
                            {location.pathname == '/create-master-vouchertype' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Voucher type Creation </p> : null}
                            {location.pathname == '/create-master-invmas-stockgroup' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Stock Group Creation </p> : null}
                            {location.pathname == '/create-master-invmas-stockcategory' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Stock Category Creation </p> : null}
                            {location.pathname == '/create-master-invmas-stockcompany' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Stock Company Creation </p> : null}
                            {location.pathname == '/create-master-invmas-stockitem' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Stock Item Creation </p> : null}
                            {location.pathname == '/create-master-invmas-unit' ? <p className='text-center  text-Capitilize text-white font-weight-bold'>Unit Creation </p> : null}
                            {location.pathname == '/alter_accounting_groupupdate' ? <p className='text-center  text-Capitilize text-white font-weight-bold'> Group Alteration </p> : null}
                            {location.pathname == '/alter_accounting_ledgerupdate/:id/:group_id_ledger' ? <p className='text-center  text-Capitilize text-white font-weight-bold'> Ledger Alteration </p> : null}
                            {location.pathname == '/alter_inventory_stockgroupupdate/:id' ? <p className='text-center  text-Capitilize text-white font-weight-bold'> Stock Group Alteration </p> : null}
                            {location.pathname == '/alter_inventory_stockcategoryupdate/:id' ? <p className='text-center  text-Capitilize text-white font-weight-bold'> Stock Category Alteration </p> : null}
                            {location.pathname == '/alter_inventory_stockcompanyupdate/:id' ? <p className='text-center  text-Capitilize text-white font-weight-bold'> Stock Company Alteration </p> : null}
                            {location.pathname == '/alter_inventory_unitupdate/:id' ? <p className='text-center  text-Capitilize text-white font-weight-bold'> Unit Alteration </p> : null}
                        </div>
                        <div className='col justify-content-center'>
                            <div className='text-center text_color '>{userData ? userData?.company_name : ""}</div>
                        </div>
                    </div> */}
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-sm p-0 ps-1 menu'>
                            <button
                                onClick={() => { navigate('/') }}
                                className="dropbtn">
                                <House
                                    style={{ cursor: 'pointer' }} />
                                <span> Home</span>
                            </button>
                        </div>
                        <div className='col-sm p-0 menu position-relative'>
                            <button
                                onClick={() => { return (myFunction()) }}
                                className="dropbtn">
                                K: Company
                            </button>
                            <div
                                id="myDropdown"
                                className="dropdown-content">
                                <Link to='/company-alteration'>Alter</Link>
                                <p className='m-0 p-0 ps-1 text-start font_size'> USER MANAGEMENT</p>
                                <Link to='/changeuser'>Change UseR</Link>
                                <Link to='/changepassword'>Change Password</Link>
                                <Link to='/userpassword'>user and Passwords</Link>
                                <Link to='/userrole'>User RoLes</Link>
                                <Link to='/passwordpolicy'>PassWord Policy</Link>
                                <p className='m-0 p-0 ps-1 text-start font_size'>CONFIGURE</p>
                                <Link to='/feature'>Features  F11</Link>
                                <Link to='/configure'>Configure</Link>
                                <Link to='/seting'>Setting</Link>
                            </div>
                        </div>
                        <div className='col-sm p-0 menu position-relative'>
                            <button
                                onClick={() => { return (myFunction1()) }}
                                className="dropbtn">
                                B: Branch
                            </button>
                            <div
                                id="myDropdown1"
                                className="dropdown-content">
                                <p className='m-0 p-0 ps-1 text-start font_size'>BRANCH MANAGEMENT</p>
                                <Link to='/branch_create'> Branch Create</Link>
                                <Link to='/branch_alter'> Branch Alter</Link>
                                <Link to='/branch_list'>Branch List</Link>
                            </div>
                        </div>
                        <div className='col-sm p-0 menu position-relative'>
                            <button
                                onClick={() => { return (myFunction2()) }}
                                className="dropbtn">
                                Z: Exchange
                            </button>
                            <div
                                id="myDropdown2"
                                className="dropdown-content">
                                <p className='m-0 p-0 ps-1 text-start font_size'> E-INVOICE</p>
                                <Link to='/sendforeinvoicing'>Send for e-Invoicing</Link>
                                <p className='m-0 p-0 ps-1 text-start font_size'>E-WAY BILL</p>
                                <Link to='/sendforewaybill'>Send for e-Way Bill</Link>
                            </div>
                        </div>
                        <div className='col-sm p-0 menu position-relative'>
                            <button

                                onClick={() => { return (myFunction4()) }}
                                className="dropbtn">
                                M: E-Mail
                            </button>
                            <div
                                id="myDropdown4"
                                className="dropdown-content">
                                <p className='m-0 p-0 ps-1 text-start font_size'> REPORTS</p>
                                <Link to='/current'>Current</Link>
                                <Link to='/other1'>Other</Link>
                                <Link to='/configuration1'>Configuration</Link>
                            </div>
                        </div>
                        <div className='col-sm p-0 menu position-relative'>
                            <button className="dropbtn">
                                <Bell />
                                <span> Notification</span>
                            </button>
                        </div>

                        <div className='col-sm p-0  menu position-relative'>
                            <button
                                onClick={() => { return (myFunction5()) }}
                                className="dropbtn">
                                <Person /> {userData ? userData.user_name : ""}
                            </button>
                            <div
                                id="myDropdown5"
                                className="dropdown-content">
                                <p className='m-0 p-0 ps-1 text-start font_size'> Settings</p>
                                <Link onClick={() => { return (userLogout()) }}>Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;