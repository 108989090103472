// import React, { useEffect, useState, useRef } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import '../accounting_master/group.css'
// import { stockItemList, stockItemDelete } from '../../../../../redux/actions/action-creator';
// import { Link, useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import Modal from 'react-modal';
// import MasterListFooter from '../../../../../components/Footer/Master_Footer/MasterListFooter';
// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// };

// const StockItem = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [selectedIndex, setSelectedIndex] = useState(0);
//   const [all_data, setall_data] = useState([]);
//   const [click, setClick] = useState(0)
//   const { userData = null } = useSelector(state => state.auth);
//   const boxRef = useRef(null);
//   // Fetch data -----------------------------------
//   useEffect(() => {
//     const formData = new FormData();
//     formData?.append('company_id', userData?.id)
//     dispatch(stockItemList(formData))?.then((response) => {
//       if (response?.status) {
//         setall_data(response?.data)
//         setLoading(false)
//       } else {
//         console.log("response failed", response)
//       }
//     }).catch((error) => {
//       console.log(error)
//     })
//   }, [isDeleteModalOpen])
//   // Keyboard Functionality ------------------------
//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (!isDeleteModalOpen) {
//         if (event?.key === 'ArrowDown') {
//           setSelectedIndex((prevIndex) => Math?.min(prevIndex + 1, all_data?.length - 1));
//         } else if (event?.key === 'ArrowUp') {
//           setSelectedIndex((prevIndex) => Math?.max(prevIndex - 1, 0));
//         } else if (event?.key === 'Enter') {
//           const selectedLink = all_data[selectedIndex];
//           if (selectedLink) {
//             navigate(`../alter-inventory-stockitem/${all_data[selectedIndex]?.id}`)
//           }
//         } else if (event?.ctrlKey && event?.key === 'd') {
//           event?.preventDefault();
//           setIsDeleteModalOpen(true);
//         }
//       }
//     };
//     window.addEventListener('keydown', handleKeyDown);
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [selectedIndex, all_data]);
//   // Scroll Functionality ------------------------
//   useEffect(() => {
//     if (boxRef?.current) {
//       const selectedElement = boxRef?.current?.querySelector('.selected');

//       if (selectedElement) {
//         const boxHeight = boxRef?.current?.offsetHeight;
//         const elementBottom = selectedElement?.offsetTop + selectedElement?.offsetHeight;

//         if (elementBottom > boxHeight) {
//           boxRef.current.scrollTop = elementBottom - boxHeight;
//         }
//       }
//     }
//   }, [selectedIndex]);
//   //---------------------------------------------------------------------------
//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       if (isDeleteModalOpen) {
//         if (e?.key === 'a' && e?.ctrlKey) {
//           e.preventDefault();
//           yPressToDelete();
//         }
//         if (e?.key === 'y' || e?.key === 'Y') {
//           e.preventDefault();
//           yPressToDelete();
//         }
//         if (e?.key === 'n' || e?.key === 'N') {
//           e?.preventDefault();
//           setIsDeleteModalOpen(false)
//         }
//       }
//     }
//     window?.addEventListener('keydown', handleKeyDown);
//     return () => {
//       window?.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [isDeleteModalOpen])

//   const yPressToDelete = () => {
//     setLoading(true);
//     const formData = new FormData();
//     formData?.append('id', all_data[selectedIndex]?.id);
//     dispatch(stockItemDelete(formData))
//       .then((response) => {
//         if (response?.status) {
//           toast?.success(response?.message || 'group delete successfully!', {
//             position: toast?.POSITION.TOP_CENTER
//           })
//           setIsDeleteModalOpen(false);
//           setLoading(false);
//         } else {
//           toast.warn(response?.message || 'Fail !', {
//             position: toast?.POSITION.TOP_CENTER
//           });
//           setIsDeleteModalOpen(false);
//           setLoading(false);
//         }
//       })
//       .catch((error) => {
//         toast.error(error?.responseMessage || ' Failed!', {
//           position: toast?.POSITION.TOP_CENTER
//         });
//         setIsDeleteModalOpen(false);
//         setLoading(false);
//       });
//   };
//   return (
//     <div className='section' >

//       <div className='detail border'>
//         <Modal
//           isOpen={isDeleteModalOpen}
//           style={customStyles}
//         >
//           <div className='container'>
//             <div className='row'>
//               <div className='col-12'>
//                 <p className='modal_message'>
//                   Accept ?
//                 </p>
//               </div>
//               <div className='col-5'>
//                 <Link className="modal_link" onClick={yPressToDelete} >Yes</Link>
//               </div>
//               <div className='col-2'>or</div>
//               <div className='col-5'>
//                 <Link className='modal_link' onClick={() => { return (setIsDeleteModalOpen(false)) }}>No</Link>
//               </div>
//             </div>
//           </div>
//         </Modal>
//         <form>
//           <div className='container-fluid p-0'>
//             <div className='row' id='ledger-form'>
//               <div className='row m-0' id='ledger-chart-of-head'>
//                 <div className='col'><p className='font-weight-bold p-2' style={{ textAlign: 'left' }}>List Of Stock Item</p></div>
//                 <div className='col'><p className='font-weight-bold p-2' style={{ textAlign: 'right' }}>For 1-Apr-22</p></div>
//               </div>
//               {
//                 loading ?
//                   <div className='row' ref={boxRef} style={{ overflowY: 'auto', marginTop: '2.80%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', maxHeight: '70vh' }}>
//                     <div className="spinner-border text-success" role="status">
//                       <span className="sr-only"></span>
//                     </div>
//                   </div> :
//                   <div className='row' ref={boxRef} style={{ overflowY: 'auto', marginTop: '2.80%', maxHeight: '70vh' }}>
//                     <div className='col' style={{ paddingRight: 0 }}>
//                       {
//                         all_data?.map((item, index) => {
//                           return (
//                             <div key={index} className={index === selectedIndex ? 'selected' : ''} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', textAlign: 'left' }}>
//                               <Link onClick={() => setSelectedIndex(index)} className={'group_list_chart_of_account'} style={{ width: '100%' }}>{item?.name}</Link>
//                             </div>
//                           )
//                         })
//                       }
//                     </div>
//                   </div>
//               }
//               <div className='row m-0 border-top' id='ledger-chart-of-footer'>
//                 <div className='col'>
//                   <p className='font-weight-bold p-2' style={{ textAlign: 'left' }}>{all_data?.length} Stock Item(s) and Stock Item</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <MasterListFooter />
//         </form>
//       </div>
//       <div className='help'></div>
//     </div >
//   )
// }
// export default StockItem;


import { openModal, closeModal, stockItemList, stockItemDelete } from '../../../../../redux/actions/action-creator';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../accounting_master/group.css'
import { Link, } from 'react-router-dom';
import MasterListFooter from '../../../../../components/Footer/Master_Footer/MasterListFooter';
import { useDropdownKeyboardNavigation } from '../../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import { useDropdownScroll } from '../../../../../components/Custom_Hooks/useDropdownScroll';
import HomeLayout from '../../../../../components/home_page/HomeLayout';
import Form from '../../../../../components/Form/Form';
import NavFooterLayout from '../../../../../components/NavNameOfComponent/NavFooterLayout';
import DeletConfirm from '../../../../../components/Modal/DeletConfirm';
import { useDelet } from '../../../../../components/Custom_Hooks/useDelete';
import { prepareFormData } from '../../../../../components/Custom_Hooks/useFormHandler';
import StockItemCreate from '../../create/Inventory_masters/StockItem';
import StockItemAlter from '../../alter/Inventory_masters/StockItem';

const StockItem = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [all_data, setall_data] = useState([]);

  const { userData = null } = useSelector(state => state.auth);
  const modal_state = useSelector(state => state.common.modal_state);
  const dropdownRef = useRef(null);
  // fetch data ----------------------
  useEffect(() => {
    const formData = new FormData();
    formData?.append('company_id', userData?.id)
    dispatch(stockItemList(formData))?.then((response) => {
      if (response?.status) {
        setall_data(response?.data)
        setLoading(false)
      } else {
        console.log("response failed", response)
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [modal_state?.group_update, modal_state?.modal_1, modal_state?.modal_2])


  // keyboard Functionality ----------------------------------------
  const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
    items: all_data,
    state: modal_state?.group_update || modal_state?.modal_1 || modal_state?.modal_2 ? false : true,
    onItemSelect: (item) => {
      // navigate(`../alter_accounting_groupupdate/${item?.data?.id}`)
    },
    onItemControlEnterSelect: (item) => {
      if (!(modal_state?.modal_1 || modal_state?.modal_2)) {
        // navigate(`../alter_accounting_groupupdate/${item?.data?.id}`)
        dispatch(openModal('group_update'))
      }
    },
    onItemAltCSelect: (item) => {
      if (!(modal_state?.group_update || modal_state?.modal_2)) {
        dispatch(openModal('modal_1'))
      }
    },
    onItemAltDSelect: (item) => {
      if (!(modal_state?.group_update || modal_state?.modal_1)) {
        dispatch(openModal('modal_2'))
      }
    },
  });
  // Scroll Functionality -------------------------------------
  useDropdownScroll({ dropdownRef, selectedItemIndex });

  const [data, setData] = useState({
    id: all_data[selectedItemIndex]?.id,
    company_id: userData?.id
  })

  useEffect(() => {
    if (all_data[selectedItemIndex]) {
      setData({
        id: all_data[selectedItemIndex]?.id,
        company_id: userData?.id,
      });
    }
  }, [selectedItemIndex, all_data]);

  // Delete Functionality ------------------------------
  const { delteItem, loadingDelete } = useDelet({
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(stockItemDelete(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => delteItem(new Event('submit')),
      },
    ],
    closeModal: () => dispatch(closeModal('modal_2')),
    state: modal_state?.modal_2
  });
  return (
    <HomeLayout>
      {modal_state?.modal_1 && (
        <div style={{ zIndex: 10000 }}>
          <StockItemCreate
            title='Stock Item Creation (Secondary)'
            closeModal={() => dispatch(closeModal('modal_1'))}
          />
        </div>)}
      {
        // location.pathname === `/alter_accounting_groupupdate/${id}`
        modal_state?.group_update
        && (
          <div style={{ zIndex: 10000 }}>
            <StockItemAlter
              data_to_update={data}
              title='Stock Item Alteration'
              closeModal={() => dispatch(closeModal('group_update'))}
            />
          </div>
        )}
      <Form
        title='List of Stock Items'
        col='col-12'
        loading={loading}
        Footer={MasterListFooter}
      >
        {
          modal_state?.modal_2 ?
            <div style={{ zIndex: 10000 }}>
              <DeletConfirm
                handleSubmit={delteItem}
                loading={loadingDelete}
                closeModal={() => dispatch(closeModal('modal_2'))}
              />
            </div>
            : null
        }
        <div className='row p-0 mb-2' style={{ overflowY: 'hidden' }}>
          <div className='col m-0 p-0 ps-2' ref={dropdownRef} style={{ overflowY: 'auto', height: '81.3vh' }}>
            {
              all_data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={index === selectedItemIndex ? 'selected' : ''}
                    style={{
                      display: 'flex',
                      textAlign: 'left',
                      paddingLeft: 5,
                      // marginLeft: item.type === 'primary' ? 0 : item.type === 'secondary' ? 10 : item.type.startsWith('secondary') ? (item.type.length - 6) * 5 : 0
                    }}
                  >
                    <Link
                      onClick={() => setSelectedItemIndex(index)}
                      className={'group_list_chart_of_account'}
                      style={{ width: '100%' }}
                    >
                      {item.name}
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </div>
        <NavFooterLayout>
          <b className='ps-2'> {all_data.length} Stock Group(s)</b>
        </NavFooterLayout>

      </Form>
    </HomeLayout>
  )
}
export default StockItem;



