import { useEffect, useState } from 'react';

export const useDropdownKeyboardNavigation = ({
    items = [],
    state = false,
    onItemSelect,
    onItemControlEnterSelect,
    onItemAltCSelect,
    onItemAltDSelect,
    input_value = '',
}) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (state) {
                if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    setSelectedItemIndex((prev) => Math.max(prev - 1, 0));
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setSelectedItemIndex((prev) => Math.min(prev + 1, items.length - 1));
                } else if (e.key === 'Enter' && !e.ctrlKey) {
                    e.preventDefault();
                    if (items[selectedItemIndex] && onItemSelect) {
                        onItemSelect(items[selectedItemIndex]);
                    }
                } else if (e.ctrlKey && e.key === 'Enter') {
                    e.preventDefault();
                    if (items[selectedItemIndex] && onItemSelect) {
                        onItemControlEnterSelect(items[selectedItemIndex]);
                    }
                } else if (e.altKey && e.key === 'c') {
                    e.preventDefault();
                    if (items[selectedItemIndex] && onItemSelect) {
                        onItemAltCSelect(items[selectedItemIndex]);
                    } else {
                        onItemAltCSelect();
                    }
                } else if (e.altKey && e.key === 'd') {
                    e.preventDefault();
                    if (items[selectedItemIndex] && onItemSelect) {
                        onItemAltDSelect(items[selectedItemIndex]);
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [items, selectedItemIndex, state, onItemSelect, onItemControlEnterSelect, onItemAltCSelect, onItemAltDSelect, input_value]);

    useEffect(() => {
        if (selectedItemIndex >= items.length) {
            setSelectedItemIndex(0);
        }
    }, [items.length, selectedItemIndex]);

    useEffect(() => {
        if (state && input_value) {
            const index = items.findIndex((item) =>
                (item.name || item.ledger_name || item.symbol)?.toLowerCase() === input_value?.toLowerCase()
            );
            if (index !== -1) {
                setSelectedItemIndex(index);
            } else {
                setSelectedItemIndex(0);
            }
        }
    }, [state, input_value, items]);

    return { selectedItemIndex, setSelectedItemIndex };
};
