import React,{Suspense} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
// const Header = React.lazy(import('../components/header/Header'))
// const Section = React.lazy(import('../components/home_page/Section.js'))
import Header from '../components/header/Header';
import Section from '../components/home_page/Section.js';
// k-company menu ----------------------------------------------
// const ChangeUser = React.lazy(import('../pages/K_Company/ChangeUser'))
// const ChangePassword = React.lazy(import('../pages/K_Company/ChangePassword'))
// const UserAndPassword = React.lazy(import('../pages/K_Company/UserAndPassword'))
// const UserRoles = React.lazy(import('../pages/K_Company/UserRoles'))
// const PasswordPolicy = React.lazy(import('../pages/K_Company/PasswordPlolicy'))
// const Feature = React.lazy(import('../pages/K_Company/Features'))
// const ConfigureKcompany = React.lazy(import('../pages/K_Company/ConfigureKcompany'))
// const Setting = React.lazy(import('../pages/K_Company/Setting'))
import ChangeUser from '../pages/K_Company/ChangeUser';
import ChangePassword from '../pages/K_Company/ChangePassword';
import UserAndPassword from '../pages/K_Company/UserAndPassword';
import UserRoles from '../pages/K_Company/UserRoles';
import PasswordPolicy from '../pages/K_Company/PasswordPlolicy';
import Feature from '../pages/K_Company/Features';
import ConfigureKcompany from '../pages/K_Company/ConfigureKcompany';
import Setting from '../pages/K_Company/Setting';
// gateway tally menu ------------------------------------------------
// const CreateMasters = React.lazy(import('../pages/gatewayoftally/master/create/CreateMasters'))
// const ChartOfAccount = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/ChartOfAccount'))
// const Alters = React.lazy(import('../pages/gatewayoftally/master/alter/Alter'))
// const Vouchers = React.lazy(import('../pages/gatewayoftally/transaction/Vouchers'))
// const DayBook = React.lazy(import('../pages/gatewayoftally/transaction/DayBook'))
// const BalanceSheet = React.lazy(import('../pages/gatewayoftally/Reports/BalanceSheet'))
// const ProfitAndLossAC = React.lazy(import('../pages/gatewayoftally/Reports/StockSummary'))
// const StockSummary = React.lazy(import('../pages/K_Company/Setting'))
import CreateMasters from '../pages/gatewayoftally/master/create/CreateMasters';
import ChartOfAccount from '../pages/gatewayoftally/master/chartOfAccount/ChartOfAccount';
import Alters from '../pages/gatewayoftally/master/alter/Alter';
import Vouchers from '../pages/gatewayoftally/transaction/Vouchers';
import DayBook from '../pages/gatewayoftally/transaction/DayBook';
import BalanceSheet from '../pages/gatewayoftally/Reports/BalanceSheet';
import ProfitAndLossAC from '../pages/gatewayoftally/Reports/ProfitAndLossAC';
import StockSummary from '../pages/gatewayoftally/Reports/StockSummary';
// gateway tally of create menu ---------------------------------------------------------------------------
// const Group = React.lazy(import('../pages/gatewayoftally/master/create/accounting_masters/Group'))
// const Ledger = React.lazy(import('../pages/gatewayoftally/master/create/accounting_masters/Ledger'))
// const VouchersType = React.lazy(import('../pages/gatewayoftally/master/create/accounting_masters/VouchersType'))
// const Currency = React.lazy(import('../pages/gatewayoftally/master/create/accounting_masters/Currency'))
// const StockGroup = React.lazy(import('../pages/gatewayoftally/master/create/Inventory_masters/StockGroup'))
// const StockCategory = React.lazy(import('../pages/gatewayoftally/master/create/Inventory_masters/StockCatagory'))
// const StockItem = React.lazy(import('../pages/gatewayoftally/master/create/Inventory_masters/StockItem'))
// const Unit = React.lazy(import('../pages/gatewayoftally/master/create/Inventory_masters/Unit'))
// const GoDown = React.lazy(import('../pages/gatewayoftally/master/create/Inventory_masters/GoDown'))
// const GST_Details = React.lazy(import('../pages/gatewayoftally/master/create/Staturtory_details/GST_Details'))
// const PAN_Details = React.lazy(import('../pages/gatewayoftally/master/create/Staturtory_details/PAN_Details'))
import Group from '../pages/gatewayoftally/master/create/accounting_masters/Group';
import Ledger from '../pages/gatewayoftally/master/create/accounting_masters/Ledger';
import VouchersType from '../pages/gatewayoftally/master/create/accounting_masters/VouchersType';
import Currency from '../pages/gatewayoftally/master/create/accounting_masters/Currency';
import StockGroup from '../pages/gatewayoftally/master/create/Inventory_masters/StockGroup';
import StockCategory from '../pages/gatewayoftally/master/create/Inventory_masters/StockCatagory';
import StockItem from '../pages/gatewayoftally/master/create/Inventory_masters/StockItem';
import Unit from '../pages/gatewayoftally/master/create/Inventory_masters/Unit';
import GoDown from '../pages/gatewayoftally/master/create/Inventory_masters/GoDown';
import GST_Details from '../pages/gatewayoftally/master/create/Staturtory_details/GST_Details';
import PAN_Details from '../pages/gatewayoftally/master/create/Staturtory_details/PAN_Details';
// gateway tally of char of account menu -----------------------------------------------------------------
// const Group_Of_Accounting = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/accounting_master/Group'))
// const Ledger_of_chart_account_master = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/accounting_master/Ledger'))
// const Currencies = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/accounting_master/Currencies'))
// const VouchersType_of_chart_account_master = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/accounting_master/VouchersType'))
// const Budgets = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/accounting_master/Budgets'))
// const Scenarios = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/accounting_master/Scenarios'))
// const StockGroup_of_chart_account_master = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockGroup'))
// const StockCategory_of_chart_account_master = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockCategory'))
// const StockItem_of_chart_account_master = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockItem'))
// const Unit_of_chart_account_master = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/inventory_masters/Unit'))
// const GoDown_of_chart_account_master = React.lazy(import('../pages/gatewayoftally/master/chartOfAccount/inventory_masters/Godown'))
import Group_Of_Accounting from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Group';
import Ledger_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Ledger';
import Currencies from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Currencies';
import VouchersType_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/VouchersType';
import Budgets from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Budgets';
import Scenarios from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Scenarios';
import StockGroup_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockGroup';
import StockCategory_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockCategory';
import StockItem_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockItem';
import Unit_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/Unit';
import GoDown_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/Godown';
// gateway tally of alter menu list ----------------------------------------------------------------
import GroupAlter from '../pages/gatewayoftally/master/alter/accounting_masters/GroupAlter';
import Ledger_of_alter from '../pages/gatewayoftally/master/alter/accounting_masters/Ledger';
import VouchersType_of_alter from '../pages/gatewayoftally/master/alter/accounting_masters/VouchersType';
import Currency_of_alter from '../pages/gatewayoftally/master/alter/accounting_masters/Currency';
import StockGroup_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/StockGroup';
import StockCategory_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/StockCatagory';
import StockItem_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/StockItem';
import Unit_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/Unit';
import UnitList from '../pages/gatewayoftally/master/alter/Inventory_masters/UnitList';
import Godown_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/GoDown';
import GST_Details_of_alter from '../pages/gatewayoftally/master/alter/Staturtory_details/GST_Details';
import PAN_Details_of_alter from '../pages/gatewayoftally/master/alter/Staturtory_details/PAN_Details';
import CheckRegister from '../pages/gatewayoftally/utilities/banking/cheque/CheckRegister';
import Alter from '../pages/K_Company/Alter';
import { useSelector } from 'react-redux';
import ListOfLedger from '../pages/gatewayoftally/master/alter/accounting_masters/ListOfLedger';
import BranchCreate from '../pages/B_Branch/BranchCreate';
import BranchAlter from '../pages/B_Branch/BranchAlter';
import BranchList from '../pages/B_Branch/BranchList';
import StockCompany_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockCompany';
import StockCompany from '../pages/gatewayoftally/master/create/Inventory_masters/StockCompany';
import StockCompany_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/StockCompany';
import StockCompanyList from '../pages/gatewayoftally/master/alter/Inventory_masters/StockCompanyList';
import StockCategoryList from '../pages/gatewayoftally/master/alter/Inventory_masters/StockCategoryList';
import StockGroupList from '../pages/gatewayoftally/master/alter/Inventory_masters/StockGroupList';
import GroupList from '../pages/gatewayoftally/master/alter/accounting_masters/GroupList';
import { LINK_PATH } from '../constant';
import LedgerVoucher from '../pages/gatewayoftally/Reports/displaymore/account_book/ledger_book/LedgerVoucher.js';
import LoadingBar from 'react-top-loading-bar'
import VoucherUpdate from '../pages/gatewayoftally/transaction/Voucher_update.js';
import StockItemSummary from '../pages/gatewayoftally/Reports/stocksummry/StockItemSummary.js';
import StockItemVoucher from '../pages/gatewayoftally/Reports/stocksummry/StockItemVoucher.js';
import TrialBalance from '../pages/gatewayoftally/Reports/displaymore/TrialBalance.js';
import GroupSummary from '../pages/gatewayoftally/Reports/displaymore/TrialBalance/GroupSummary.js';
const AppRoute = ({setProgress}) => {
    const location = useLocation();
    const loginPath = location?.pathname || '';
    const newPath = loginPath?.split('/')
    const pathName = newPath[1] ? newPath[1] : '';
    const { userData = null } = useSelector(state => state.auth);
    return (
        <div className="App">
            {
                userData?.id ? <Header /> : null
            }
            <Routes>
                <Route path={`${LINK_PATH}/`} element={<Section />} />
                <Route path={`${LINK_PATH}/company-alteration`} element={<Alter />} />
                <Route path={`${LINK_PATH}/changeuser`} element={<ChangeUser />} />
                <Route path={`${LINK_PATH}/changepassword`} element={<ChangePassword />} />
                <Route path={`${LINK_PATH}/userpassword`} element={<UserAndPassword />} />
                <Route path={`${LINK_PATH}/userrole`} element={<UserRoles />} />
                <Route path={`${LINK_PATH}/passwordpolicy`} element={<PasswordPolicy />} />
                <Route path={`${LINK_PATH}/feature`} element={<Feature />} />
                <Route path={`${LINK_PATH}/configure`} element={<ConfigureKcompany />} />
                <Route path={`${LINK_PATH}/seting`} element={<Setting />} />
                <Route path={`${LINK_PATH}/createmasters`} element={<Section />} />
                <Route path={`${LINK_PATH}/chartofaccountmasters`} element={<Section />} />
                <Route path={`${LINK_PATH}/altermasters`} element={<Alters />} />
                <Route path={`${LINK_PATH}/voucherstransaction`} element={<Vouchers setProgress={setProgress}/>} />
                <Route path={`${LINK_PATH}/voucher-transaction/:id`} element={<VoucherUpdate  setProgress={setProgress}/>} />
                <Route path={`${LINK_PATH}/daybooktransaction`} element={<DayBook />} />
                <Route path={`${LINK_PATH}/bankingutilities`} element={<Section />} />
                <Route path={`${LINK_PATH}/balancesheetreports`} element={<BalanceSheet />} />
                <Route path={`${LINK_PATH}/profitandlossac`} element={<ProfitAndLossAC />} />
                <Route path={`${LINK_PATH}/stocksummary`} element={<StockSummary />} />
                <Route path={`${LINK_PATH}/displaymorereports`} element={<Section />} />
                <Route path={`${LINK_PATH}/accountbook`} element={<Section />} />
                <Route path={`${LINK_PATH}/statementofaccount`} element={<Section />} />
                <Route path={`${LINK_PATH}/inventorybook`} element={<Section />} />
                <Route path={`${LINK_PATH}/statementofinventory`} element={<Section />} />
                <Route path={`${LINK_PATH}/gstreport`} element={<Section />} />
                <Route path={`${LINK_PATH}/exceptionreports`} element={<Section />} />
                <Route path={`${LINK_PATH}/ledgerbook`} element={<Section />} />
                <Route path={`${LINK_PATH}/analysisverification`} element={<Section />} />
                <Route path={`${LINK_PATH}/current_date`} element={<Section />} />
                <Route path={`${LINK_PATH}/period`} element={<Section />} />
                <Route path={`${LINK_PATH}/ledgervoucher/:id`} element={<LedgerVoucher />} />
                <Route path={`${LINK_PATH}/create-master-group`} element={<Section />} />
                <Route path={`${LINK_PATH}/create-master-ledger`} element={<Section />} />
                {/* <Route path={`${LINK_PATH}/create-master-currency`} element={<Currency />} /> */}
                <Route path={`${LINK_PATH}/create-master-vouchertype`} element={<VouchersType />} />
                {/* <Route path={`${LINK_PATH}/create-master-define_number_list`} element={<DefineNumberSeries />} /> */}
                <Route path={`${LINK_PATH}/create-master-invmas-stockgroup`} element={<Section />} />
                <Route path={`${LINK_PATH}/create-master-invmas-stockcategory`} element={<Section />} />
                <Route path={`${LINK_PATH}/create-master-invmas-stockcompany`} element={<Section />} />
                <Route path={`${LINK_PATH}/create-master-invmas-stockitem`} element={<Section />} />
                <Route path={`${LINK_PATH}/create-master-invmas-unit`} element={<Section />} />
                <Route path={`${LINK_PATH}/create-master-invmas-godown`} element={<Section />} />
                <Route path={`${LINK_PATH}/create-master-sttdet-gstdetails`} element={<GST_Details />} />
                <Route path={`${LINK_PATH}/create-master-sttdet-pandetails`} element={<PAN_Details />} />
                <Route path={`${LINK_PATH}/chart-of-account-accounting-master-group`} element={<Group_Of_Accounting/>} />
                <Route path={`${LINK_PATH}/chart-of-account-accounting-master-ledger`} element={<Ledger_of_chart_account_master />} />
                <Route path={`${LINK_PATH}/chart-of-account-accounting-master-currencies`} element={<Currencies />} />
                <Route path={`${LINK_PATH}/chart-of-account-accounting-master-voucherstype`} element={<VouchersType_of_chart_account_master />} />
                <Route path={`${LINK_PATH}/chart-of-account-accounting-master-budgets`} element={<Budgets />} />
                <Route path={`${LINK_PATH}/chart-of-account-accounting-master-scenarios`} element={<Scenarios />} />
                <Route path={`${LINK_PATH}/chart-of-account-invmas-stockgroup`} element={<StockGroup_of_chart_account_master />} />
                <Route path={`${LINK_PATH}/chart-of-account-invmas-stockcategory`} element={<StockCategory_of_chart_account_master />} />
                <Route path={`${LINK_PATH}/chart-of-account-invmas-stockcompany`} element={<StockCompany_of_chart_account_master />} />
                <Route path={`${LINK_PATH}/chart-of-account-invmas-stockitem`} element={<StockItem_of_chart_account_master />} />
                <Route path={`${LINK_PATH}/chart-of-account-invmas-unit`} element={<Unit_of_chart_account_master />} />
                <Route path={`${LINK_PATH}/chart-of-account-invmas-godown`} element={<GoDown_of_chart_account_master />} />
                {/* <Route path={`${LINK_PATH}/alter-accounting-grouplist`} element={<GroupList />} /> */}
                {/* <Route path={`${LINK_PATH}/alter_accounting_ledgerlist`} element={<ListOfLedger />} /> */}
                <Route path={`${LINK_PATH}/alter_accounting_groupupdate/:id`} element={<Group_Of_Accounting />} />
                <Route path={`${LINK_PATH}/alter_accounting_ledgerupdate/:id`} element={<Ledger_of_alter />} />
                <Route path={`${LINK_PATH}/alter_accounting_vouchertype/:id`} element={<VouchersType_of_alter />} />
                <Route path={`${LINK_PATH}/alter-accounting-currency`} element={<Currency_of_alter />} />
                <Route path={`${LINK_PATH}/alter-inventory-stockgrouplist`} element={<StockGroupList />} />
                <Route path={`${LINK_PATH}/alter_inventory_stockgroupupdate/:id`} element={<StockGroup_of_alter />} />
                <Route path={`${LINK_PATH}/alter-inventory-stockcategorylist`} element={<StockCategoryList />} />
                <Route path={`${LINK_PATH}/alter_inventory_stockcategoryupdate/:id`} element={<StockCategory_of_alter />} />
                <Route path={`${LINK_PATH}/alter-inventory-stockcompanylist`} element={<StockCompanyList />} />
                <Route path={`${LINK_PATH}/alter_inventory_stockcompanyupdate/:id`} element={<StockCompany_of_alter />} />
                <Route path={`${LINK_PATH}/alter-inventory-stockitem/:id`} element={<StockItem_of_alter />} />
                <Route path={`${LINK_PATH}/alter-inventory-unitlist`} element={<UnitList />} />
                <Route path={`${LINK_PATH}/alter_inventory_unitupdate/:id`} element={<Unit_of_alter />} />

                <Route path={`${LINK_PATH}/alter-inventory-godown/:id`} element={<Godown_of_alter />} />
                <Route path={`${LINK_PATH}/alter-statutory-gstdetails`} element={<GST_Details_of_alter />} />
                <Route path={`${LINK_PATH}/alter-statutory-pan_details`} element={<PAN_Details_of_alter />} />
                <Route path={`${LINK_PATH}/alter_list_of_ledger`} element={<ListOfLedger />} />
                <Route path={`${LINK_PATH}/checkregister`} element={<CheckRegister />} />
                <Route path={`${LINK_PATH}/branch_create`} element={<BranchCreate />} />
                <Route path={`${LINK_PATH}/branch_alter`} element={<BranchAlter />} />
                <Route path={`${LINK_PATH}/branch_list`} element={<BranchList />} />
                <Route path={`${LINK_PATH}/stock_item_summary`} element={<StockItemSummary />} />
                <Route path={`${LINK_PATH}/stock_item_voucher/:id`} element={<StockItemVoucher />} />
                <Route path={`${LINK_PATH}/trial_balance`} element={<TrialBalance />} />
                <Route path={`${LINK_PATH}/group_summary/:id`} element={<GroupSummary />} />
            </Routes>
        </div>
    )
}

export default AppRoute









































































































// import React from 'react';
// import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
// import Header from '../components/header/Header';
// import Section from '../components/home_page/Section.js';
// // k-company menu ----------------------------------------------
// import ChangeUser from '../pages/K_Company/ChangeUser';
// import ChangePassword from '../pages/K_Company/ChangePassword';
// import UserAndPassword from '../pages/K_Company/UserAndPassword';
// import UserRoles from '../pages/K_Company/UserRoles';
// import PasswordPolicy from '../pages/K_Company/PasswordPlolicy';
// import Feature from '../pages/K_Company/Features';
// import ConfigureKcompany from '../pages/K_Company/ConfigureKcompany';
// import Setting from '../pages/K_Company/Setting';
// // gateway tally menu ------------------------------------------------
// import CreateMasters from '../pages/gatewayoftally/master/create/CreateMasters';
// import ChartOfAccount from '../pages/gatewayoftally/master/chartOfAccount/ChartOfAccount';
// import Alters from '../pages/gatewayoftally/master/alter/Alter';
// import Vouchers from '../pages/gatewayoftally/transaction/Vouchers';
// import DayBook from '../pages/gatewayoftally/transaction/DayBook';
// import Banking from '../pages/gatewayoftally/utilities/Banking';
// import BalanceSheet from '../pages/gatewayoftally/Reports/BalanceSheet';
// import ProfitAndLossAC from '../pages/gatewayoftally/Reports/ProfitAndLossAC';
// import StockSummary from '../pages/gatewayoftally/Reports/StockSummary';
// import DisplayMoreReports from '../pages/gatewayoftally/Reports/DisplayMoreReports';
// // gateway tally of create menu ---------------------------------------------------------------------------
// import Group from '../pages/gatewayoftally/master/create/accounting_masters/Group';
// import Ledger from '../pages/gatewayoftally/master/create/accounting_masters/Ledger';
// import VouchersType from '../pages/gatewayoftally/master/create/accounting_masters/VouchersType';
// import Currency from '../pages/gatewayoftally/master/create/accounting_masters/Currency';
// import StockGroup from '../pages/gatewayoftally/master/create/Inventory_masters/StockGroup';
// import StockCategory from '../pages/gatewayoftally/master/create/Inventory_masters/StockCatagory';
// import StockItem from '../pages/gatewayoftally/master/create/Inventory_masters/StockItem';
// import Unit from '../pages/gatewayoftally/master/create/Inventory_masters/Unit';
// import GoDown from '../pages/gatewayoftally/master/create/Inventory_masters/GoDown';
// import GST_Details from '../pages/gatewayoftally/master/create/Staturtory_details/GST_Details';
// import PAN_Details from '../pages/gatewayoftally/master/create/Staturtory_details/PAN_Details';
// // gateway tally of char of account menu -----------------------------------------------------------------
// import Group_Of_Accounting from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Group';
// import Ledger_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Ledger';
// import Currencies from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Currencies';
// import VouchersType_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/VouchersType';
// import Budgets from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Budgets';
// import Scenarios from '../pages/gatewayoftally/master/chartOfAccount/accounting_master/Scenarios';
// import StockGroup_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockGroup';
// import StockCategory_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockCategory';
// import StockItem_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockItem';
// import Unit_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/Unit';
// import GoDown_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/Godown';
// // gateway tally of alter menu list ----------------------------------------------------------------
// import Group_of_alter from '../pages/gatewayoftally/master/alter/accounting_masters/Group';
// import Ledger_of_alter from '../pages/gatewayoftally/master/alter/accounting_masters/Ledger';
// import VouchersType_of_alter from '../pages/gatewayoftally/master/alter/accounting_masters/VouchersType';
// import Currency_of_alter from '../pages/gatewayoftally/master/alter/accounting_masters/Currency';
// import StockGroup_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/StockGroup';
// import StockCategory_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/StockCatagory';
// import StockItem_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/StockItem';
// import Unit_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/Unit';
// import UnitList from '../pages/gatewayoftally/master/alter/Inventory_masters/UnitList';
// import Godown_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/GoDown';
// import GST_Details_of_alter from '../pages/gatewayoftally/master/alter/Staturtory_details/GST_Details';
// import PAN_Details_of_alter from '../pages/gatewayoftally/master/alter/Staturtory_details/PAN_Details';
// import CheckRegister from '../pages/gatewayoftally/utilities/banking/cheque/CheckRegister';
// import Alter from '../pages/K_Company/Alter';
// import { useSelector } from 'react-redux';
// import ListOfLedger from '../pages/gatewayoftally/master/alter/accounting_masters/ListOfLedger';
// import BranchCreate from '../pages/B_Branch/BranchCreate';
// import BranchAlter from '../pages/B_Branch/BranchAlter';
// import BranchList from '../pages/B_Branch/BranchList';
// import StockCompany_of_chart_account_master from '../pages/gatewayoftally/master/chartOfAccount/inventory_masters/StockCompany';
// import StockCompany from '../pages/gatewayoftally/master/create/Inventory_masters/StockCompany';
// import StockCompany_of_alter from '../pages/gatewayoftally/master/alter/Inventory_masters/StockCompany';
// import StockCompanyList from '../pages/gatewayoftally/master/alter/Inventory_masters/StockCompanyList';
// import StockCategoryList from '../pages/gatewayoftally/master/alter/Inventory_masters/StockCategoryList';
// import StockGroupList from '../pages/gatewayoftally/master/alter/Inventory_masters/StockGroupList';
// import GroupList from '../pages/gatewayoftally/master/alter/accounting_masters/GroupList';
// const AppRoute = () => {
//     const location = useLocation();
//     const loginPath = location?.pathname || '';
//     const newPath = loginPath?.split('/')
//     const pathName = newPath[1] ? newPath[1] : '';
//     const { userData = null } = useSelector(state => state.auth);
//     return (
//         <div className="App">
//             {
//                 userData?.id ? <Header /> : null
//             }
//             <Routes>
//                 <Route path='/' element={<Section />} />
//                 <Route path='/company-alteration' element={<Alter />} />
//                 <Route path='/changeuser' element={<ChangeUser />} />
//                 <Route path='/changepassword' element={<ChangePassword />} />
//                 <Route path='userpassword' element={<UserAndPassword />} />
//                 <Route path='/userrole' element={<UserRoles />} />
//                 <Route path='/passwordpolicy' element={<PasswordPolicy />} />
//                 <Route path='/feature' element={<Feature />} />
//                 <Route path='/configure' element={<ConfigureKcompany />} />
//                 <Route path='/seting' element={<Setting />} />
//                 <Route path='/createmasters' element={<CreateMasters />} />
//                 <Route path='/chartofaccountmasters' element={<ChartOfAccount />} />
//                 <Route path='/altermasters' element={<Alters />} />
//                 <Route path='/voucherstransaction' element={<Vouchers />} />
//                 <Route path='daybooktransaction' element={<DayBook />} />
//                 <Route path='bankingutilities' element={<Section />} />
//                 <Route path='/balancesheetreports' element={<BalanceSheet />} />
//                 <Route path='/profitandlossac' element={<ProfitAndLossAC />} />
//                 <Route path='/stocksummary' element={<StockSummary />} />
//                 <Route path='/home/displaymorereports' element={<Section />} />
//                 <Route path='/create-master-group' element={<Group />} />
//                 <Route path='/create-master-ledger' element={<Ledger />} />
//                 <Route path='/create-master-currency' element={<Currency />} />
//                 <Route path='/create-master-vouchertype' element={<VouchersType />} />
//                 <Route path='/create-master-invmas-stockgroup' element={<StockGroup />} />
//                 <Route path='/create-master-invmas-stockcategory' element={<StockCategory />} />
//                 <Route path='/create-master-invmas-stockcompany' element={<StockCompany />} />
//                 <Route path='/create-master-invmas-stockitem' element={<StockItem />} />
//                 <Route path='/create-master-invmas-unit' element={<Unit />} />
//                 <Route path='/create-master-invmas-godown' element={<GoDown />} />
//                 <Route path='/create-master-sttdet-gstdetails' element={<GST_Details />} />
//                 <Route path='/create-master-sttdet-pandetails' element={<PAN_Details />} />
//                 <Route path='/chart-of-account-accounting-master-group' element={<Group_Of_Accounting />} />
//                 <Route path='chart-of-account-accounting-master-ledger' element={<Ledger_of_chart_account_master />} />
//                 <Route path='chart-of-account-accounting-master-currencies' element={<Currencies />} />
//                 <Route path='chart-of-account-accounting-master-vouchertype' element={<VouchersType_of_chart_account_master />} />
//                 <Route path='chart-of-account-accounting-master-budgets' element={<Budgets />} />
//                 <Route path='chart-of-account-accounting-master-scenarios' element={<Scenarios />} />
//                 <Route path='chart-of-account-invmas-stockgroup' element={<StockGroup_of_chart_account_master />} />
//                 <Route path='chart-of-account-invmas-stockcategory' element={<StockCategory_of_chart_account_master />} />
//                 <Route path='chart-of-account-invmas-stockcompany' element={<StockCompany_of_chart_account_master />} />
//                 <Route path='chart-of-account-invmas-stockitem' element={<StockItem_of_chart_account_master />} />
//                 <Route path='/chart-of-account-invmas-unit' element={<Unit_of_chart_account_master />} />
//                 <Route path='chart-of-account-invmas-godown' element={<GoDown_of_chart_account_master />} />
//                 <Route path='alter-accounting-grouplist' element={<GroupList />} />
//                 <Route path='/alter_accounting_ledgerlist' element={<ListOfLedger />} />
//                 <Route path='/alter_accounting_groupupdate/:id' element={<Group_of_alter />} />
//                 <Route path='/alter_accounting_ledgerupdate/:id/:group_id_ledger' element={<Ledger_of_alter />} />
//                 <Route path='alter-accounting-vouchertype' element={<VouchersType_of_alter />} />
//                 <Route path='alter-accounting-currency' element={<Currency_of_alter />} />
//                 <Route path='alter-inventory-stockgrouplist' element={<StockGroupList />} />
//                 <Route path='alter_inventory_stockgroupupdate/:id' element={<StockGroup_of_alter />} />
//                 <Route path='alter-inventory-stockcategorylist' element={<StockCategoryList />} />
//                 <Route path='alter_inventory_stockcategoryupdate/:id' element={<StockCategory_of_alter />} />
//                 <Route path='alter-inventory-stockcompanylist' element={<StockCompanyList />} />
//                 <Route path='alter_inventory_stockcompanyupdate/:id' element={<StockCompany_of_alter />} />
//                 <Route path='alter-inventory-stockitem/:id' element={<StockItem_of_alter />} />
//                 <Route path='alter-inventory-unitlist' element={<UnitList />} />
//                 <Route path='alter_inventory_unitupdate/:id' element={<Unit_of_alter />} />
                
//                 <Route path='alter-inventory-godown' element={<Godown_of_alter />} />
//                 <Route path='alter-statutory-gstdetails' element={<GST_Details_of_alter />} />
//                 <Route path='alter-statutory-pan_details' element={<PAN_Details_of_alter />} />
//                 <Route path='alter_list_of_ledger' element={<ListOfLedger/>}/>
//                 <Route path='/checkregister' element={<CheckRegister />} />
//                 <Route path='/branch_create' element={<BranchCreate/>}/>
//                 <Route path='/branch_alter' element={<BranchAlter/>}/>
//                 <Route path='/branch_list' element={<BranchList/>}/>
//             </Routes>
//         </div>
//     )
// }

// export default AppRoute
