import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LINK_PATH } from '../../../constant';
import { useDropdownKeyboardNavigation } from '../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../../components/NavigationList/NavigationList';
import useKeyboardNavigation from '../../../components/Custom_Hooks/useKeyboardNavigation';

const DisplayMoreReports =()=> {

    const navigate = useNavigate();
    const location = useLocation();

    const keyMap = {
        't': '/trial_balance',
        'd': '/daybooktransaction',
        'c': '/chartofaccountmasters',
        'f': '/voucherstransaction',
        'a': '/accountbook',
        's': '/statementofaccount',
        'i': '/inventorybook',
        'e': '/statementofinventory',
        'o': '/gstreport',
        'x': '/exceptionreports',
        'v': '/analysisverification',
    };

    useKeyboardNavigation(keyMap);
    
    const data = [
        // Accounting
        { category: "Accounting", to: '/trial_balance', text: 'Trial Balance' },
        { category: "Accounting", to: '/daybooktransaction', text: 'Day Book' },
        { category: "Accounting", to: '/chartofaccountmasters', text: 'Cash Flow' },
        { category: "Accounting", to: '/chartofaccountmasters', text: 'Funds Flow' },
        { category: "Accounting", to: '/accountbook', text: 'Account Books' },
        { category: "Accounting", to: '/statementofaccount', text: 'Statements of Accounts' },
        // Inventory
        { category: "Inventory", to: '/inventorybook', text: 'Inventory Books' },
        { category: "Inventory", to: '/statementofinventory', text: 'Statements of Inventory' },
        // Statutory
        { category: "Statutory", to: '/gstreport', text: 'GST Reports' },
        // Exception
        { category: "Exception", to: `${LINK_PATH}/exceptionreports`, text: 'Exception Reports' },
        { category: "Exception", to: '/analysisverification', text: 'Analysis & Verification' }
    ];
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: data,
        state: true,
        onItemSelect: (item) => {
            if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
                return;
            }
            navigate(item.to)
        },

    });
    return (
        <NavigationList
            data={data}
            selectedItemIndex={selectedItemIndex}
            setSelectedItemIndex={setSelectedItemIndex}
            title='Display More Reports'
            first_heading='ACCOUNTING'
            secound_heading='INVENTORY'
            third_heading='EXCEPTION'
        />
    )
}

export default React.memo(DisplayMoreReports);
