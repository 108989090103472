import React, { forwardRef } from 'react'
import { Eye } from 'react-bootstrap-icons'
import DropdownYesNo from '../Dropdown/DropdownYesNo'
import { yes_no_array } from '../Dummi_Data'
import { useSelector } from 'react-redux'
import { dateFormateShow } from '../Date_Formate_Utilitiy/Index'

const InputField = forwardRef(({ type, lable, name, value, handleChange, handleFocus, handleBlur, isRequired, isDrop, show_drop, setDrop_add_show, data, setData, refs,isDisabled }, ref) => {
      const { userData = null } = useSelector(state => state.auth);
    return (
        <div className='row m-0 p-0' style={{height:'3.5vh'}}>
            <div className={`${name==='opening_balance'?`col-6`:`col-5`} m-0 p-0 text-start text-truncate`}>
                <label className={isDisabled?'text-muted':''} >{lable}{isRequired ? <span className='star_color'>*</span> : null} {name==='opening_balance'?`(${dateFormateShow(userData?.books_being_date)})`:null}</label>
            </div>
            <div className=' m-0 p-0' style={{ width: '2%' }}>
                <label className={isDisabled?'text-muted':''}> :</label>
            </div>
            <div className={`${name==='opening_balance'?`col-5`:`col-6`} p-0 m-0 text-start`} style={{ position: 'relative' }}>
                <input
                    style={{ fontSize: 13,fontWeight:'bold', width: '100%' }}
                    type={type}
                    autoComplete="off"
                    disabled={isDisabled}
                    className={isDisabled?'text-muted':''}
                    autoFocus={name === 'company_name' || name === 'name' || name === 'ledger_name'||name === 'symbol'}
                    name={name}
                    id={name}
                    value={value || ""}
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    ref={ref}
                />
                {
                    show_drop && (
                        <DropdownYesNo
                            state={show_drop}
                            setState={setDrop_add_show}
                            data={data}
                            setData={setData}
                            input_value={value}
                            input_key={name}
                            rawData={yes_no_array}
                            ref={ref}
                            refs={refs}
                            // next={next}
                        />)
                }
            </div>
        </div>
    )
})

export default React.memo(InputField)








// import  { useState, useEffect, useRef, useCallback } from 'react';
// import { useDispatch } from 'react-redux';
// import { Link, useNavigate, Outlet } from 'react-router-dom';
// import '../accounting_masters/ledger.css'
// import {
//   stockCategoryPrimaryList, stockGroupList,
//   stockItemCreate, unitsListing, stockCompanyList, godownList
// } from '../../../../../redux/actions/action-creator';
// import { toast } from 'react-toastify';
// import FormData from 'form-data';
// import '../../../../../App.css'
// import YesNoModal from '../../../../../components/model/YesNoModal';
// import ItemWithGodown from './ItemWithGodown';
// import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
// const is_gst_applicable_array =
//   [
//     { name: 'Applicable' },
//     { name: 'Not Applicable' },
//   ]

// const taxability_array =
//   [
//     { name: 'Exempt' },
//     { name: 'Nill Rated' },
//     { name: 'None-Gst' },
//     { name: 'Taxable' },
//   ]
// const hsn_detail_gst_rate_array =
//   [
//     { name: 'As per Company/Stock Group' },
//     { name: 'Specify Details Here' },
//     { name: 'Specify in Voucher' },
//   ]
// const type_of_supply_array =
//   [
//     { name: 'Capital Goods' },
//     { name: 'Goods' },
//     { name: 'Services' },
//   ]
// const costing_method_array =
//   [
//     { name: 'At Zero Cost' },
//     { name: 'Avg Cost' },
//     { name: 'FIFO' },
//     { name: 'FIFO Perpetual' },
//     { name: 'Last Purchase Cost' },
//     { name: 'LIFO Annual' },
//     { name: 'LIFO Perpetual' },
//     { name: 'Monthly Avg Cost' },
//     { name: 'STD Cost' },
//   ]
// const market_valuation_array =
//   [
//     { name: 'At Zero Price' },
//     { name: 'Avg Price' },
//     { name: 'Last Sale Price' },
//     { name: 'STD Price' },
//   ]
// const cess_valuation_array =
//   [
//     { name: 'Not Applicable' },
//     { name: 'Based on Quantity' },
//     { name: 'Based on Value' },
//     { name: 'Based on Qty & Value' },
//   ]

// const StockItem = React.memo(() => {
//   // get state data from redux ------------------------------------------------------------------------------------------
//   const { unit_data = null } = useSelector(state => state.common);
//   const { stock_category_under_data = null } = useSelector(state => state.common);
//   const { stock_group_data = null } = useSelector(state => state.common);
//   const { stock_company_data = null } = useSelector(state => state.common);
//   const { godown_data = null } = useSelector(state => state.common);
//   const { userData = null } = useSelector(state => state.auth);
//   // dropdown selection state ------------------------------------------------------------------------------------------
//   const [selectedItem, setSelectedItem] = useState(0);
//   const [backspaceCount, setBackspaceCount] = useState(0)
//   const [nameInput, setNameInput] = useState(null)
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [modal, setModal] = useState(false)
//   const [state, setState] = useState({
//     stock_name: '',
//     alise_name: '',
//     part_no: '',
//     description: '',
//     notes: '',
//     set_modify_default_ledger_for_invoice: 'No',
//     under: 'Primary',
//     under_id: 'Primary',
//     category: 'Not Applicable',
//     category_id: 'Not Applicable',
//     units: 'Not Applicable',
//     unit_id: 'Not Applicable',
//     alternate_unit: 'Not Applicable',
//     alternate_unit_id: 'Not Applicable',
//     first_unit_count: '',
//     secound_unit_count: '',
//     weight_in_kg: 'Not Available',
//     per_unit_kg: '',
//     per_alternate_unit_kg: '',
//     company_name_of_product: 'Not Applicable',
//     is_gst_applicable: 'Applicable',
//     hsn_sac_details: 'As per Company/Stock Group',
//     source_of_details: 'Not Applicable',
//     hsn_sac: '',
//     description_hsn_sac: '',
//     gst_rate_details: 'As per Company/Stock Group',
//     taxability_type: '',
//     igst_rate: '0',
//     cgst_rate: '0',
//     sgst_utst_rate: '0',
//     cess_valuation_type: 'Not Applicable',
//     cess_rate: '0',
//     applicable_for_reverse_charge: 'No',
//     eligible_for_tax_input_credit: 'No',
//     type_of_supply: 'Goods',
//     opening_balance: '',
//     default_unit: 'Not Applicable',
//     default_unit_id: 'Not Applicable',
//     default_unit_for_rate: '',
//     set_standard_rates: 'No',
//     costing_method: 'Avg. Cost',
//     market_valuation_method: 'Avg. Price',
//     provide_behaviour_options: 'No',
//     ignore_diffrence_due_to_physical_counting: 'No',
//     ignore_nagative_balance: 'No',
//     treat_all_sales_as_new_manufacture: 'No',
//     treat_all_purchased_as_comsumed: 'No',
//     treat_all_rejections_inward_as_scrap: 'No',
//     item_quantity: '',
//     item_rate: '',
//     item_per: '',
//     item_value: '',
//     item_quantity_in_alternate_qty: '',
//     item_quantity_in_alternate_unit: '',
//   });
//   // -------------------------------------------------------------------
//   const [show_godown_dropdown, setshow_godown_dropdown] = useState(false)
//   const [filteredDataGodown, setFilteredDataGodown] = useState(false)
//   const [currentIndex, setcurrentIndex] = useState(0)
//   const [total_trigger, settotal_trigger] = useState(false)

//   const [modal_data, setmodal_data] = useState([{
//     id: '1',
//     godown_name: '',
//     godown_name_id: '',
//     quantity: '',
//     combination_of_units: '',
//     rate: '',
//     per: '',
//     amount: '',
//     total_nos_box: '',
//     total_no_box_unit: '',
//   }])

//   const godown_name_input_ref = useRef([])
//   const quantity_input_ref = useRef([])
//   const rate_input_ref = useRef([])
//   const per_input_ref = useRef([])
//   const amount_input_ref = useRef([])
//   const isMouseDownInside = useRef(false);


//   const getNextInputRef = (name, value) => {
//     switch (name) {
//       case 'stock_name':
//         return alise_name_input_ref;
//       case 'alise_name':
//         return part_no_input_ref;
//       case 'part_no':
//         return description_input_ref;
//       case 'description':
//         return notes_input_ref;
//       case 'notes':
//         return set_modify_default_ledger_for_invoice_input_ref;
//       case 'set_modify_default_ledger_for_invoice':
//         return under_input_ref;
//       case 'under':
//         return category_input_ref;
//       case 'category':
//         return company_name_of_product_input_ref;
//       case 'company_name_of_product':
//         return is_gst_applicable_input_ref;
//       case 'provide_behaviour_options':
//         return state?.provide_behaviour_options === 'Yes' ? ignore_diffrence_due_to_physical_counting_input_ref : item_quantity_input_ref;
//       case 'ignore_diffrence_due_to_physical_counting':
//         return ignore_nagative_balance_input_ref;
//       case 'ignore_nagative_balance':
//         return treat_all_sales_as_new_manufacture_input_ref;
//       case 'treat_all_sales_as_new_manufacture':
//         return treat_all_purchased_as_comsumed_input_ref;
//       case 'treat_all_purchased_as_comsumed':
//         return treat_all_rejections_inward_as_scrap_input_ref;
//       case 'treat_all_rejections_inward_as_scrap':
//         return item_value_input_ref;
//       case 'is_gst_applicable':
//         return hsn_sac_details_input_ref;
//       case 'hsn_sac_details':
//         return (state?.hsn_sac_details === 'As per Company/Stock Group' || state?.hsn_sac_details === 'Specify in Voucher') ? gst_rate_details_input_ref : hsn_sac_input_ref;
//       case 'hsn_sac':
//         return description_hsn_sac_input_ref;
//       case 'description_hsn_sac':
//         return gst_rate_details_input_ref;
//       case 'gst_rate_details':
//         return (state?.gst_rate_details === 'As per Company/Stock Group' || state?.gst_rate_details === 'Specify in Voucher') ? type_of_supply_input_ref : taxability_type_input_ref;
//       case 'taxability_type':
//         return state?.taxability_type === 'Taxable' ? igst_rate_input_ref : type_of_supply_input_ref;
//       case 'igst_rate':
//         return cgst_rate_input_ref;
//       case 'cgst_rate':
//         return sgst_utst_rate_input_ref;
//       case 'sgst_utst_rate':
//         return cess_valuation_type_input_ref;
//       case 'cess_valuation_type':
//         return state?.cess_valuation_type === 'Not Applicable' ? applicable_for_reverse_charge_input_ref : cess_rate_input_ref;
//       case 'cess_rate':
//         return applicable_for_reverse_charge_input_ref;
//       case 'applicable_for_reverse_charge':
//         return eligible_for_tax_input_credit_input_ref;
//       case 'eligible_for_tax_input_credit':
//         return type_of_supply_input_ref;
//       case 'type_of_supply':
//         return units_input_ref;
//       case 'units':
//         return state?.units === 'Not Applicable' ? item_value_input_ref : alternate_unit_input_ref;
//       case 'alternate_unit':
//         return state?.alternate_unit === 'Not Applicable' ? default_unit_input_ref : first_unit_count_input_ref;
//       case 'first_unit_count':
//         return secound_unit_count_input_ref;
//       case 'secound_unit_count':
//         return default_unit_input_ref;
//       case 'default_unit':
//         return default_unit_for_rate_input_ref;
//       case 'default_unit_for_rate':
//         return weight_in_kg_input_ref;
//       case 'weight_in_kg':
//         return state?.weight_in_kg === 'Not Available' ? set_standard_rates_input_ref : per_unit_kg_input_ref;
//       case 'per_unit_kg':
//         return per_alternate_unit_kg_input_ref;
//       case 'per_alternate_unit_kg':
//         return set_standard_rates_input_ref;
//       case 'set_standard_rates':
//         return costing_method_input_ref;
//       case 'costing_method':
//         return market_valuation_method_input_ref;
//       case 'market_valuation_method':
//         return provide_behaviour_options_input_ref;
//       case 'opening_balance':
//       default:
//         return null;
//     }
//   };
//   const getCurrentInputRef = (name, value) => {
//     switch (name) {
//       case 'stock_name':
//         return stock_name_input_ref;
//       case 'alise_name':
//         return alise_name_input_ref;
//       case 'part_no':
//         return part_no_input_ref;
//       case 'description':
//         return description_input_ref;
//       case 'notes':
//         return notes_input_ref;
//       case 'set_modify_default_ledger_for_invoice':
//         return set_modify_default_ledger_for_invoice_input_ref;
//       case 'under':
//         return under_input_ref;
//       case 'category':
//         return category_input_ref;
//       case 'company_name_of_product':
//         return company_name_of_product_input_ref;
//       case 'provide_behaviour_options':
//         return provide_behaviour_options_input_ref;
//       case 'ignore_diffrence_due_to_physical_counting':
//         return ignore_diffrence_due_to_physical_counting_input_ref;
//       case 'ignore_nagative_balance':
//         return ignore_nagative_balance_input_ref;
//       case 'treat_all_sales_as_new_manufacture':
//         return treat_all_sales_as_new_manufacture_input_ref;
//       case 'treat_all_purchased_as_comsumed':
//         return treat_all_purchased_as_comsumed_input_ref;
//       case 'treat_all_rejections_inward_as_scrap':
//         return treat_all_rejections_inward_as_scrap_input_ref;
//       case 'is_gst_applicable':
//         return is_gst_applicable_input_ref;
//       case 'hsn_sac_details':
//         return hsn_sac_details_input_ref;
//       case 'hsn_sac':
//         return hsn_sac_input_ref;
//       case 'description_hsn_sac':
//         return description_hsn_sac_input_ref;
//       case 'gst_rate_details':
//         return gst_rate_details_input_ref;
//       case 'taxability_type':
//         return taxability_type_input_ref;
//       case 'igst_rate':
//         return igst_rate_input_ref;
//       case 'cgst_rate':
//         return cgst_rate_input_ref;
//       case 'sgst_utst_rate':
//         return sgst_utst_rate_input_ref;
//       case 'cess_valuation_type':
//         return cess_valuation_type_input_ref;
//       case 'cess_rate':
//         return cess_rate_input_ref;
//       case 'applicable_for_reverse_charge':
//         return applicable_for_reverse_charge_input_ref;
//       case 'eligible_for_tax_input_credit':
//         return eligible_for_tax_input_credit_input_ref;
//       case 'type_of_supply':
//         return type_of_supply_input_ref;
//       case 'units':
//         return units_input_ref;
//       case 'alternate_unit':
//         return alternate_unit_input_ref;
//       case 'first_unit_count':
//         return first_unit_count_input_ref;
//       case 'secound_unit_count':
//         return secound_unit_count_input_ref;
//       case 'default_unit':
//         return default_unit_input_ref;
//       case 'default_unit_for_rate':
//         return default_unit_for_rate_input_ref;
//       case 'weight_in_kg':
//         return weight_in_kg_input_ref;
//       case 'per_unit_kg':
//         return per_unit_kg_input_ref;
//       case 'per_alternate_unit_kg':
//         return per_alternate_unit_kg_input_ref;
//       case 'set_standard_rates':
//         return set_standard_rates_input_ref;
//       case 'costing_method':
//         return costing_method_input_ref;
//       case 'market_valuation_method':
//         return market_valuation_method_input_ref;
//       case 'item_quantity':
//         return item_quantity_input_ref;
//       case 'item_rate':
//         return item_rate_input_ref;
//       case 'item_per':
//         return item_per_input_ref;
//       case 'item_value':
//         return item_value_input_ref;
//       case 'opening_balance': return opening_balance_input_ref
//       default:
//         return null;
//     }
//   };

//   const getPrevInputRef = (name, state) => {
//     switch (name) {
//       case 'alise_name':
//         return stock_name_input_ref;
//       case 'part_no':
//         return alise_name_input_ref;
//       case 'description':
//         return part_no_input_ref;
//       case 'notes':
//         return description_input_ref;
//       case 'set_modify_default_ledger_for_invoice':
//         return notes_input_ref;
//       case 'under':
//         return set_modify_default_ledger_for_invoice_input_ref;
//       case 'category':
//         return under_input_ref;
//       case 'company_name_of_product':
//         return inputCategoryRef;
//       case 'provide_behaviour_options':
//         return market_valuation_method_input_ref;
//       case 'ignore_diffrence_due_to_physical_counting':
//         return provide_behaviour_options_input_ref;
//       case 'ignore_nagative_balance':
//         return ignore_diffrence_due_to_physical_counting_input_ref;
//       case 'treat_all_sales_as_new_manufacture':
//         return ignore_nagative_balance_input_ref;
//       case 'treat_all_purchased_as_comsumed':
//         return treat_all_sales_as_new_manufacture_input_ref;
//       case 'treat_all_rejections_inward_as_scrap':
//         return treat_all_purchased_as_comsumed_input_ref;
//       case 'is_gst_applicable':
//         return company_name_of_product_input_ref;
//       case 'hsn_sac_details':
//         return is_gst_applicable_input_ref;
//       case 'hsn_sac':
//         return hsn_sac_details_input_ref;
//       case 'description_hsn_sac':
//         return hsn_sac_input_ref;
//       case 'gst_rate_details':
//         return (hsn_sac_details === 'As per Company/Stock Group' || hsn_sac_details === 'Specify in Voucher')
//           ? hsn_sac_details_input_ref
//           : description_hsn_sac_input_ref;
//       case 'taxability_type':
//         return gst_rate_details_input_ref;
//       case 'igst_rate':
//         return taxability_type_input_ref;
//       case 'cgst_rate':
//         return igst_rate_input_ref;
//       case 'sgst_utst_rate':
//         return cgst_rate_input_ref;
//       case 'cess_valuation_type':
//         return sgst_utst_rate_input_ref;
//       case 'cess_rate':
//         return cess_valuation_type_input_ref;
//       case 'applicable_for_reverse_charge':
//         return cess_valuation_type === 'Not Applicable' ? cess_valuation_type_input_ref : cess_rate_input_ref;
//       case 'eligible_for_tax_input_credit':
//         return applicable_for_reverse_charge_input_ref;
//       case 'type_of_supply':
//         return (gst_rate_details === 'As per Company/Stock Group' || gst_rate_details === 'Specify in Voucher')
//           ? gst_rate_details_input_ref
//           : eligible_for_tax_input_credit_input_ref;
//       case 'units':
//         return type_of_supply_input_ref;
//       case 'alternate_unit':
//         return units_input_ref;
//       case 'first_unit_count':
//         return alternate_unit_input_ref;
//       case 'secound_unit_count':
//         return first_unit_count_input_ref;
//       case 'default_unit':
//         return state?.alternate_unit === 'Not Applicable'
//           ? alternate_unit_input_ref
//           : secound_unit_count_input_ref;
//       case 'default_unit_for_rate':
//         return default_unit_input_ref;
//       case 'weight_in_kg':
//         return default_unit_for_rate_input_ref;
//       case 'per_unit_kg':
//         return weight_in_kg_input_ref;
//       case 'per_alternate_unit_kg':
//         return per_unit_kg_input_ref;
//       case 'set_standard_rates':
//         return weight_in_kg === 'Not Available'
//           ? weight_in_kg_input_ref
//           : per_alternate_unit_kg_input_ref;
//       case 'costing_method':
//         return set_standard_rates_input_ref;
//       case 'market_valuation_method':
//         return costing_method_input_ref;
//       case 'opening_balance':
//         return state?.units === 'Not Applicable'
//           ? units_input_ref
//           : state?.provide_behaviour_options === 'Yes'
//             ? treat_all_rejections_inward_as_scrap_input_ref
//             : provide_behaviour_options_input_ref;
//       default:
//         return stock_name_input_ref; // Default fallback if none of the cases match
//     }
//   };






//   const handleItemFocus = () => {
//     setshow_godown_dropdown(true)
//   }
//   const handleItemBlur = () => {
//     if (modal_data[currentIndex]?.godown_name == '') {
//       godown_name_input_ref.current[currentIndex].focus();
//     } else {
//       setshow_godown_dropdown(false)
//     }
//   }
//   const handleChangeItemEntry = (index, key, value) => {
//     const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
//     switch (key) {
//       case 'godown_name':
//         const value_1 = godown_data?.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_1) {
//           setmodal_data((prevFormData) => {
//             const updatedFormData = [...prevFormData];
//             updatedFormData[index][key] = value;
//             return updatedFormData;
//           });
//         }
//         break;

//       case 'rate':
//         if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value.replace(/[,.]/g, '')) || value === '') {
//           setmodal_data((prevFormData) => {
//             const updatedFormData = [...prevFormData];
//             updatedFormData[index][key] = value;
//             return updatedFormData;
//           });
//         }
//         break;
//       case 'per':
//         const value_ = godown_data.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_) {
//           setmodal_data((prevFormData) => {
//             const updatedFormData = [...prevFormData];
//             updatedFormData[index][key] = value;
//             return updatedFormData;
//           });
//         }
//         break;
//       case 'amount':
//         if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value.replace(/[,.]/g, '')) || value === '') {
//           setmodal_data((prevFormData) => {
//             const updatedFormData = [...prevFormData];
//             updatedFormData[index][key] = value;
//             return updatedFormData;
//           });
//         }
//         break;
//       default:
//         setmodal_data((prevFormData) => {
//           const updatedFormData = [...prevFormData];
//           updatedFormData[index][key] = value;
//           return updatedFormData;
//         });
//         break;
//     }
//   }
//   // Triger function whenever user press enter key on input field ----------------------------------------------------------------------
//   const handleKeyUpItemEntry = (index, key, value, e) => {
//     if (e.key === 'Enter') {
//       e.preventDefault();
//       if (key === 'godown_name' && value !== '') {
//         if (state?.item_quantity !== '') {
//           setmodal_data((prevFormData) => {
//             const updatedFormData = [...prevFormData];
//             updatedFormData[index]['quantity'] = `${state?.item_quantity} ${state?.units}`;
//             return updatedFormData;
//           });
//           quantity_input_ref.current[index].focus();
//         } else {
//           amount_input_ref.current[index].focus();
//         }
//       }
//       if (key === 'quantity' && value !== '') {
//         const regex = /(\d+)(\D+)/g;
//         const splitText = [];
//         let match;
//         while ((match = regex.exec(value)) !== null) {
//           splitText.push({ number: match[1], text: match[2] });
//         }
//         if (value !== '') {
//           if (splitText[0]?.text && splitText[0]?.number) { } else {
//             const quotient = Math.floor(parseInt(value) / parseInt(state?.secound_unit_count));
//             const remainder = parseFloat(value) % parseFloat(state?.secound_unit_count);
//             let result_1 = `${quotient} ${state.alternate_unit}`
//             let result_2 = remainder > 0 ? `+ ${remainder}${state?.alternate_unit}` : ''
//             setmodal_data((prevValueLoop) => {
//               const updatedValueLoop = [...prevValueLoop];
//               updatedValueLoop[index]['quantity'] = `${value} ${state?.units}`
//               updatedValueLoop[index]['combination_of_units'] = `(${result_1} ${result_2})`
//               return updatedValueLoop;
//             });
//           }
//           rate_input_ref.current[index].focus();
//         }
//       }
//       if (key === 'rate') {
//         const regex = /(\d+)(\D+)/g;
//         const splitText = [];
//         let match;
//         while ((match = regex.exec(modal_data[index]?.quantity)) !== null) {
//           splitText.push({ number: match[1], text: match[2] });
//         }
//         if (value !== '') {
//           setmodal_data((prevValueLoop) => {
//             const updatedValueLoop = [...prevValueLoop];
//             updatedValueLoop[index]['amount'] = `${parseInt(value) * parseInt(splitText[0]?.number)}`
//             updatedValueLoop[index]['per'] = state?.units
//             return updatedValueLoop;
//           });
//         }
//         if (per_input_ref) {
//           per_input_ref.current[index].focus();
//         }
//       }
//       if (key === 'per') {
//         if (amount_input_ref) {
//           amount_input_ref.current[index].focus();
//         }
//       }
//       if (key === 'amount') {
//         const intValue = parseInt(value, 10);
//         if (Number.isInteger(intValue) && Math.sign(intValue) === 1) {
//           // settotal_trigger(true)
//           const isLastInputField = index === modal_data.length - 1;
//           if (isLastInputField) {
//             const quantities = modal_data.map(item => item.quantity);
//             const amount_array = modal_data.map(item => item.amount);
//             const numericValues = [];
//             const nonNumericValues = [];
//             quantities.forEach(quantity => {
//               const parts = quantity.split(' '); // Split by space assuming quantity format is 'number unit'
//               if (parts.length === 2 && !isNaN(parts[0])) {
//                 numericValues.push(parseFloat(parts[0])); // Convert number part to float
//               } else {
//                 nonNumericValues.push(quantity); // If not in expected format, add whole string to non-numeric
//               }
//             });
//             const totalNumeric = numericValues.reduce((acc, val) => acc + val, 0);
//             const totalamount = amount_array.reduce((acc, val) => parseFloat(acc) + parseFloat(val?.replace(/\,/g, '')), 0);
//             console.log("this is total amount :----", totalamount)
//             if ((totalNumeric == state.item_quantity && state?.item_quantity !== '') || totalamount == state?.item_value) {
//               setModal(false)
//               const rate_array = modal_data.map(item => item.rate);
//               const total_rate = rate_array.reduce((acc, val) => parseFloat(acc) + parseFloat(val?.replace(/\,/g, '')), 0);
//               const avg_rate = total_rate / rate_array?.length
//               setState({ ...state, item_value: totalamount, item_rate: avg_rate, item_per: state?.units })
//               setstock_submit('Accept')

//             } else {
//               setmodal_data((prevFormData) => [
//                 ...prevFormData,
//                 {
//                   id: `${prevFormData.length + 1}`,
//                   godown_name: '',
//                   godown_name_id: '',
//                   quantity: '',
//                   combination_of_units: '',
//                   rate: '',
//                   per: '',
//                   amount: '',
//                   total_nos_box: '',
//                   total_no_box_unit: '',
//                 },
//               ]);
//               const newIndex = index + 1;
//               setcurrentIndex(newIndex)
//               setTimeout(() => {
//                 godown_name_input_ref.current[newIndex].focus();
//                 setmodal_data((prevFormData) => {
//                   const updatedFormData = [...prevFormData];
//                   updatedFormData[index]['amount'] = parseFloat(value).toFixed(2).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
//                   return updatedFormData;
//                 });
//               }, 50);
//               settotal_trigger(true)
//             }
//           } else {
//             const newIndex = index + 1;
//             setcurrentIndex(newIndex)
//             setTimeout(() => {
//               setmodal_data((prevFormData) => {
//                 const updatedFormData = [...prevFormData];
//                 updatedFormData[index]['amount'] = parseFloat(value).toFixed(2).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
//                 return updatedFormData;
//               });
//             }, 50);
//             settotal_trigger(true)
//             const nextInput = godown_name_input_ref.current[index + 1];
//             if (nextInput) {
//               nextInput.focus();
//             }
//           }
//         }
//       }

//     }
//   }

//   useEffect(() => {
//     const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
//     const filteredSuggestions = godown_data?.filter((item, index) => {
//       const lowercaseLedgerName = cleanString(item?.name?.toLowerCase())
//       const lowercaseSearchInput = cleanString(modal_data[currentIndex]?.godown_name?.toLowerCase())
//       const matchesSearchInput = lowercaseLedgerName?.includes(modal_data[currentIndex]?.godown_name !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataGodown(modal_data[currentIndex]?.godown_name !== '' ? godown_name_input_ref?.current[currentIndex]?.selectionStart > 0 ? filteredSuggestions : godown_data : godown_data);
//   }, [modal_data[currentIndex]?.godown_name, godown_data])





//   // Search suggestion filter data state-------------------------------------------------------------------------------
//   const [filteredData, setFilteredData] = useState([]);
//   const [filteredDataUnit, setFilteredDataUnit] = useState([]);
//   const [filteredDataCategory, setFilteredDataCategory] = useState([]);
//   const [filteredDataComapny, setFilteredDataComapny] = useState([]);
//   const [filteredDataDefaultUnit, setFilteredDataDefaultUnit] = useState([]);
//   const [filteredDataAlternateUnit, setFilteredDataAlternateUnit] = useState([]);
//   const [filteredDataIsGstApplicable, setFilteredDataIsGstApplicable] = useState([])
//   const [filteredDataYesNo, setFilteredDataYesNo] = useState([])
//   const [filteredDataHsnDetailGstRate, setFilteredDataHsnDetailGstRate] = useState([])
//   const [filteredDataTaxability, setFilteredDataTaxability] = useState([])
//   const [filteredDataTypeOfSupply, setFilteredDataTypeOfSupply] = useState([])
//   const [filteredDataCessValuationType, setFilteredDataCessValuationType] = useState([])
//   const [filteredDataCostingMethod, setFilteredDataCostingMethod] = useState([])
//   const [filteredDataMarketValuation, setFilteredDataMarketValuation] = useState([])


//   // Name input field validation to  check value not empty'state -------------------------------------------------------
//   const [isStockItemNameEntered, setIsStockItemNameEntered] = useState(false);
//   const [hashTag, setHashTag] = useState(false);
//   // Create mutable object of all input to focus ---------------------------------------------------------------------------
//   const stock_name_input_ref = useRef(null);
//   const alise_name_input_ref = useRef(null);
//   const part_no_input_ref = useRef(null);
//   const description_input_ref = useRef(null);
//   const notes_input_ref = useRef(null);
//   const set_modify_default_ledger_for_invoice_input_ref = useRef(null);
//   const under_input_ref = useRef(null);
//   const category_input_ref = useRef(null);
//   const is_gst_applicable_input_ref = useRef(null);
//   const units_input_ref = useRef(null);
//   const alternate_unit_input_ref = useRef(null);
//   const first_unit_count_input_ref = useRef(null);
//   const secound_unit_count_input_ref = useRef(null);
//   const weight_in_kg_input_ref = useRef(null);
//   const per_unit_kg_input_ref = useRef(null);
//   const per_alternate_unit_kg_input_ref = useRef(null);
//   const company_name_of_product_input_ref = useRef(null);
//   const hsn_sac_details_input_ref = useRef(null);
//   const source_of_details_input_ref = useRef(null);
//   const hsn_sac_input_ref = useRef(null);
//   const description_hsn_sac_input_ref = useRef(null);
//   const gst_rate_details_input_ref = useRef(null);
//   const taxability_type_input_ref = useRef(null);
//   const igst_rate_input_ref = useRef(null);
//   const cgst_rate_input_ref = useRef(null);
//   const sgst_utst_rate_input_ref = useRef(null);
//   const cess_valuation_type_input_ref = useRef(null);
//   const cess_rate_input_ref = useRef(null);
//   const applicable_for_reverse_charge_input_ref = useRef(null);
//   const eligible_for_tax_input_credit_input_ref = useRef(null);
//   const type_of_supply_input_ref = useRef(null);
//   const opening_balance_input_ref = useRef(null);
//   const default_unit_input_ref = useRef(null);
//   const default_unit_for_rate_input_ref = useRef(null);
//   const set_standard_rates_input_ref = useRef(null);
//   const costing_method_input_ref = useRef(null);
//   const market_valuation_method_input_ref = useRef(null);
//   const provide_behaviour_options_input_ref = useRef(null);
//   const ignore_diffrence_due_to_physical_counting_input_ref = useRef(null);
//   const ignore_nagative_balance_input_ref = useRef(null);
//   const treat_all_sales_as_new_manufacture_input_ref = useRef(null);
//   const treat_all_purchased_as_comsumed_input_ref = useRef(null);
//   const treat_all_rejections_inward_as_scrap_input_ref = useRef(null);
//   const item_quantity_input_ref = useRef(null);
//   const item_rate_input_ref = useRef(null);
//   const item_per_input_ref = useRef(null);
//   const item_value_input_ref = useRef(null);
//   // Mutable object create to user click outside when we use it to dropdown --------------------------------------------------
//   const dropdownRef = useRef(null);
//   const dropdownCategory = useRef(null);
//   const dropdownUnits = useRef(null);
//   const dropdownAlternateUnit = useRef(null);
//   const dropdownDefaultUnit = useRef(null);
//   const inputCategoryRef = useRef(null);
//   // state for open dropdowns--------------------------------------------------------------------------------------------
//   const [showList, setShowList] = useState(false);
//   const [showListCategory, setShowListCategory] = useState(false);
//   const [showListCompany, setShowListCompany] = useState(false);
//   const [showListUnits, setShowLIstUnits] = useState(false);
//   const [showListAlternateUnit, setShowLIstAlternateUnit] = useState(false);
//   const [showListDefaultUnit, setShowListDefaultUnit] = useState(false);
//   const [stock_submit, setstock_submit] = useState('')

//   const [isGstAppShow, setIsGstAppShow] = useState(false)
//   const [hsnSacDetailShow, setHsnSacDetailShow] = useState(false)
//   const [gstRateDetailsShow, setGstRateDetailsShow] = useState(false)
//   const [taxabilityShow, setTaxabilityShow] = useState(false)
//   const [cessValueShow, setCessValueShow] = useState(false)
//   const [typeOfSupplyShow, setTypeOfSupplyShow] = useState(false)
//   const [costingMethodShow, setCostingMethodShow] = useState(false)
//   const [marketValuaShow, setMarketValuaShow] = useState(false)
//   const [applicableForResShow, setApplicableForResShow] = useState(false)
//   const [eligibleForTaxshow, setEligibleForTaxShow] = useState(false)
//   const [setModifyShow, setSetModifyShow] = useState(false)
//   const [weightInKgShow, setWeightInKgShow] = useState(false)
//   const [setStandardRateShow, setSetStandardRateShow] = useState(false)
//   const [provideBefShow, setProvideBefShow] = useState(false)
//   const [ignDiffShow, setIgnDiffShow] = useState(false)
//   const [ignNagtiveShow, setIgnNagtiveShow] = useState(false)
//   const [treatAllSalesShow, setTreatAllSalesShow] = useState(false)
//   const [treatAllPurchaseShow, setTreatAllPurchaseShow] = useState(false)
//   const [treatAllRejectShow, setTreatAllRejectShow] = useState(false)

//   const handleFocus = (e) => {
//     const { name, value } = e.target;
//     const ref = getCurrentInputRef(name);
//     if (ref) {
//       setNameInput(ref);
//     }
//     if (name === 'under') {
//       setShowList(true)
//       const index = stock_group_data.findIndex(item => item.name?.toLowerCase() == state?.under?.toLowerCase());
//       under_input_ref.current.setSelectionRange(0, e.target.value.length)
//       setSelectedItem(index)
//     }
//     if (name === 'category') {
//       const index = stock_category_under_data.findIndex(item => item.name?.toLowerCase() == state?.category?.toLowerCase());
//       setSelectedItem(index)
//       setShowListCategory(true)
//     }
//     if (name === 'units') {
//       const index = unit_data.findIndex(item => item?.symbol?.toLowerCase() == state?.units?.toLowerCase());
//       setSelectedItem(index)
//       setShowLIstUnits(true)
//     }
//     if (name === 'alternate_unit') {
//       const index = unit_data.findIndex(item => item.symbol?.toLowerCase() == state?.alternate_unit?.toLowerCase());
//       setSelectedItem(index)
//       setShowLIstAlternateUnit(true)
//     }
//     if (name === 'company_name_of_product') {
//       const index = stock_company_data.findIndex(item => item.name?.toLowerCase() == state?.company_name_of_product?.toLowerCase());
//       setSelectedItem(index)
//       setShowListCompany(true)
//     }
//     if (name === 'is_gst_applicable') {
//       setIsGstAppShow(true)
//     }
//     if (name === 'hsn_sac_details') {
//       setHsnSacDetailShow(true)
//     }
//     if (name === 'gst_rate_details') {
//       setGstRateDetailsShow(true)
//     }
//     if (name === 'taxability_type') {
//       setTaxabilityShow(true)
//     }
//     if (name === 'cess_valuation_type') {
//       setCessValueShow(true)
//     }
//     if (name === 'type_of_supply') {
//       setTypeOfSupplyShow(true)
//     }
//     if (name === 'default_unit') {
//       const index = unit_data.findIndex(item => item.symbol?.toLowerCase() == state?.default_unit?.toLowerCase());
//       setSelectedItem(index)
//       setShowListDefaultUnit(true)
//     }
//     if (name === 'costing_method') {
//       setCostingMethodShow(true)
//     }
//     if (name === 'market_valuation_method') {
//       setMarketValuaShow(true)
//     }

//   }

//   const handleBlur = (e) => {
//     const { name, value } = e.target;
//     if (name === 'stock_name' && value === '') {
//       stock_name_input_ref.current.focus();
//     }
//     if (name === 'under') {
//       setShowList(false)
//     }
//     if (name === 'category') {
//       setShowListCategory(false)
//     }
//     if (name === 'units') {
//       setShowLIstUnits(false)
//     }
//     if (name === 'alternate_unit') {
//       setShowLIstAlternateUnit(false)
//     }
//     if (name === 'company_name_of_product') {
//       setShowListCompany(false)
//     }
//     if (name === 'is_gst_applicable') {
//       setIsGstAppShow(false)
//     }
//     if (name === 'hsn_sac_details') {
//       setHsnSacDetailShow(false)
//     }
//     if (name === 'gst_rate_details') {
//       setGstRateDetailsShow(false)
//     }
//     if (name === 'taxability_type') {
//       setTaxabilityShow(false)
//     }
//     if (name === 'cess_valuation_type') {
//       setCessValueShow(false)
//     }
//     if (name === 'type_of_supply') {
//       setTypeOfSupplyShow(false)
//     }
//     if (name === 'default_unit') {
//       setShowListDefaultUnit(false)
//     }
//     if (name === 'costing_method') {
//       setCostingMethodShow(false)
//     }
//     if (name === 'market_valuation_method') {
//       setMarketValuaShow(false)
//     }
//     if (name === 'applicable_for_reverse_charge') {
//       setApplicableForResShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'eligible_for_tax_input_credit') {
//       setEligibleForTaxShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'set_standard_rates') {
//       setSetStandardRateShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'set_modify_default_ledger_for_invoice') {
//       setSetModifyShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'weight_in_kg') {
//       setWeightInKgShow(false)
//     }
//     if (name === 'provide_behaviour_options') {
//       setProvideBefShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'ignore_diffrence_due_to_physical_counting') {
//       setIgnDiffShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'ignore_nagative_balance') {
//       setIgnNagtiveShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'treat_all_sales_as_new_manufacture') {
//       setTreatAllSalesShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'treat_all_purchased_as_comsumed') {
//       setTreatAllPurchaseShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//     if (name === 'treat_all_rejections_inward_as_scrap') {
//       setTreatAllRejectShow(false)
//       const normalizedValue = value.toLowerCase().trim();
//       const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
//       const validPatternNo = /^(n|o|no)?$/;
//       if (normalizedValue === '') {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternNo.test(normalizedValue)) {
//         setState({ ...state, [name]: 'No' })
//       } else if (validPatternYes.test(normalizedValue)) {
//         setState({ ...state, [name]: 'Yes' })
//       }
//     }
//   }

//   // Triger function whenever the change input value ----------------------------------------------------------------------
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     const capitalizeFirstLetter = (str) => {
//       return str.toLowerCase().replace(/(^|\s)\S/g,
//         (firstLetter) => firstLetter.toUpperCase());
//     };
//     if (name === 'stock_name') {
//       setIsStockItemNameEntered(!!value.trim());
//     }
//     // space key functionality
//     if (e.code == 'Space') {
//       e.preventDefault();
//       const cursorPosition_start = e.target.selectionStart;
//       if (cursorPosition_start == 1) {
//         setState({ ...state, [name]: '' });
//         return;
//       }
//     }

//     // Enter key functionality
//     if (e.key === 'Enter' && state?.stock_name !== '') {
//       e.preventDefault();
//       const nextInputRef = getNextInputRef(name, value)
//       if (name === 'igst_rate') {
//         const cleanedValue = value.replace(/^0+/, '');
//         const igstRate = parseInt(cleanedValue, 10) || 0;
//         setState((prevState) => ({
//           ...prevState,
//           igst_rate: cleanedValue.toString(),
//           cgst_rate: igstRate / 2,
//           sgst_utst_rate: igstRate / 2,
//         }));
//       }
//       if (name == 'item_quantity') {
//         if (value !== '' && godown_data.length > 1) {
//           setModal(true)
//         } else {
//           item_rate_input_ref.current.focus();
//         }
//         // setstock_submit('Accept')
//       }
//       if (name == 'item_rate') {
//         if (value !== '' && state.item_quantity !== '') {
//           const get_total_value = parseFloat(state.item_quantity) * parseFloat(value);
//           setState({ ...state, item_value: get_total_value, item_per: state?.units })
//           item_per_input_ref.current.focus();
//         } else {
//           item_per_input_ref.current.focus();
//         }
//       }
//       if (name == 'item_per') {
//         item_value_input_ref.current.focus();
//       }
//       if (name == 'item_value') {
//         if (value !== '' && godown_data.length > 1) {
//           setModal(true)
//         } else {
//           item_value_input_ref.current.blur();
//           setstock_submit('Accept')
//         }
//       }
//       if (nextInputRef) {
//         nextInputRef.current.focus();
//         if (nextInputRef.current) {
//           nextInputRef.current.setSelectionRange(0, 0);
//         }
//       }
//       return;
//     }
//     // Back space key functionality
//     if (e.key === 'Backspace') {
//       e.preventDefault();
//       const nextInputRef = getPrevInputRef(name)
//       const cursorPosition = e.target.selectionStart;
//       if (cursorPosition === 0) {
//         if (backspaceCount === 1) {
//           setBackspaceCount(0);
//           if (nextInputRef && nextInputRef.current) {
//             nextInputRef.current.focus();
//             nextInputRef.current.setSelectionRange(0, 0);
//           }
//         } else {
//           if (cursorPosition === 0 && value) {
//             if (nextInputRef && nextInputRef.current) {
//               nextInputRef.current.focus();
//               nextInputRef.current.setSelectionRange(0, 0);
//             }
//           } else {
//             setBackspaceCount(backspaceCount + 1);
//           }
//         }
//       }
//       return;
//     }

//     const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
//     switch (name) {
//       case 'under':
//         const value_1 = stock_group_data.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_1) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'category':
//         const value_2 = stock_category_under_data.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_2) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'company_name_of_product':
//         const value_3 = stock_company_data.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_3) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'units':
//         const value_4 = unit_data.some(item => cleanString(item.symbol).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_4) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'alternate_unit':
//         const value_5 = unit_data.some(item => cleanString(item.symbol).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_5) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'default_unit':
//         const value_6 = unit_data.some(item => cleanString(item.symbol).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_6) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'is_gst_applicable':
//         const value_7 = is_gst_applicable_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_7) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'hsn_sac_details':
//         const value_8 = hsn_detail_gst_rate_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_8) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'gst_rate_details':
//         const value_9 = hsn_detail_gst_rate_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_9) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'taxability_type':
//         const value_10 = taxability_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_10) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'cess_valuation_type':
//         const value_11 = is_gst_applicable_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_11) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'type_of_supply':
//         const value_12 = type_of_supply_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_12) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'costing_method':
//         const value_13 = costing_method_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_13) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'market_valuation_method':
//         const value_14 = market_valuation_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_14) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'applicable_for_reverse_charge':
//         const value_15 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_15) {
//           setState({ ...state, [name]: value });
//         } else {
//           setApplicableForResShow(true)
//         }
//         break;
//       case 'eligible_for_tax_input_credit':
//         const value_16 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_16) {
//           setState({ ...state, [name]: value });
//         } else {
//           setEligibleForTaxShow(true)
//         }
//         break;
//       case 'set_standard_rates':
//         const value_17 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_17) {
//           setState({ ...state, [name]: value });
//         } else {
//           setSetStandardRateShow(true)
//         }
//         break;
//       case 'set_modify_default_ledger_for_invoice':
//         const value_18 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_18) {
//           setState({ ...state, [name]: value });
//         } else {
//           setSetModifyShow(true)
//         }
//         break;
//       case 'weight_in_kg':
//         const value_19 = is_gst_applicable_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_19) {
//           setState({ ...state, [name]: value });
//         }
//         break;
//       case 'provide_behaviour_options':
//         const value_20 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_20) {
//           setState({ ...state, [name]: value });
//         } else {
//           setProvideBefShow(true)
//         }
//         break;
//       case 'ignore_diffrence_due_to_physical_counting':
//         const value_21 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_21) {
//           setState({ ...state, [name]: value });
//         } else {
//           setIgnDiffShow(true)
//         }
//         break;
//       case 'ignore_nagative_balance':
//         const value_22 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_22) {
//           setState({ ...state, [name]: value });
//         } else {
//           setIgnNagtiveShow(true)
//         }
//         break;
//       case 'treat_all_sales_as_new_manufacture':
//         const value_23 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_23) {
//           setState({ ...state, [name]: value });
//         } else {
//           setTreatAllSalesShow(true)
//         }
//         break;
//       case 'treat_all_purchased_as_comsumed':
//         const value_24 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_24) {
//           setState({ ...state, [name]: value });
//         } else {
//           setTreatAllPurchaseShow(true)
//         }
//         break;
//       case 'treat_all_rejections_inward_as_scrap':
//         const value_25 = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
//         if (value_25) {
//           setState({ ...state, [name]: value });
//         } else {
//           setTreatAllRejectShow(true)
//         }
//         break;
//       default:
//         // Capitalize the first letter of each word for certain input fields
//         const updatedValue = name === 'stock_name' || name === 'alise_name' || name === 'part_no' ?
//           capitalizeFirstLetter(value) : value;
//         setState({ ...state, [name]: updatedValue })
//         break;
//     }

//   }

//   useEffect(() => {
//     const formData = new FormData();
//     formData.append('company_id', userData?.id)
//     dispatch(unitsListing(formData))
//     dispatch(godownList(formData))
//     dispatch(stockGroupList(formData))
//     dispatch(stockCategoryPrimaryList(formData))
//     dispatch(stockCompanyList(formData))
//   }, [])


//   // Destructuring object from state-------------------------------------------------------------------------------
//   const { stock_name, alise_name, part_no, description, notes, set_modify_default_ledger_for_invoice, under,
//     category, units, alternate_unit, first_unit_count, secound_unit_count, weight_in_kg, per_unit_kg,
//     per_alternate_unit_kg, company_name_of_product,
//     is_gst_applicable, hsn_sac_details, source_of_details, hsn_sac, description_hsn_sac, gst_rate_details,
//     taxability_type, igst_rate, cgst_rate, sgst_utst_rate, cess_valuation_type, cess_rate, applicable_for_reverse_charge,
//     eligible_for_tax_input_credit, type_of_supply, opening_balance,
//     default_unit, default_unit_for_rate, set_standard_rates, costing_method, market_valuation_method,
//     provide_behaviour_options, ignore_diffrence_due_to_physical_counting, ignore_nagative_balance,
//     treat_all_sales_as_new_manufacture, treat_all_purchased_as_comsumed, treat_all_rejections_inward_as_scrap,
//     item_quantity, item_rate, item_per, item_value,
//   } = state;

//   //triger when the change value of search input and get filter data ---------------------------------------------------
//   useEffect(() => {
//     if (stock_group_data[0]?.name === 'Primary') { } else {
//       stock_group_data?.unshift({ id: 'Primary', name: 'Primary' });
//     }
//     const filteredSuggestions = stock_group_data?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = state?.under?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.under !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredData(state.under !== '' ? under_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : stock_group_data : stock_group_data);
//     if (filteredSuggestions?.length > 0 && under_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.under, stock_group_data, under_input_ref?.current?.selectionStart])


//   useEffect(() => {
//     if (stock_category_under_data[0]?.name === 'Not Applicable') { }
//     else {
//       stock_category_under_data?.unshift({ id: 'Not Applicable', name: 'Not Applicable' });
//     }
//     const filteredSuggestions = stock_category_under_data?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = state?.category?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.category !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataCategory(state.category !== '' ? inputCategoryRef?.current?.selectionStart > 0 ? filteredSuggestions : stock_category_under_data : stock_category_under_data);
//     if (filteredSuggestions?.length > 0 && category_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.category, stock_category_under_data, inputCategoryRef?.current?.selectionStart])


//   useEffect(() => {
//     if (stock_company_data[0]?.name === 'Not Applicable') { }
//     else {
//       stock_company_data?.unshift({ id: 'Not Applicable', name: 'Not Applicable' });
//     }
//     const filteredSuggestions = stock_company_data?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = state?.company_name_of_product?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.category !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataComapny(state.company_name_of_product !== '' ? company_name_of_product_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : stock_company_data : stock_company_data);
//     if (filteredSuggestions?.length > 0 && company_name_of_product_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.company_name_of_product, stock_company_data, company_name_of_product_input_ref?.current?.selectionStart])


//   useEffect(() => {
//     if (unit_data[0]?.symbol === 'Not Applicable') { }
//     else {
//       unit_data?.unshift({ id: 'Not Applicable', symbol: 'Not Applicable' });
//     }
//     const filteredSuggestions = unit_data?.filter((item, index) => {
//       const lowercaseLedgerName = item?.symbol?.toLowerCase();
//       const lowercaseSearchInput = (showListUnits ? state?.units : showListAlternateUnit ? state?.alternate_unit : state?.default_unit)?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.units !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataUnit(-(showListUnits ? state?.units : showListAlternateUnit ? state?.alternate_unit : state?.default_unit) !== '' ? (showListUnits ? units_input_ref : showListAlternateUnit ? alternate_unit_input_ref : default_unit_input_ref)?.current?.selectionStart > 0 ? filteredSuggestions : unit_data : unit_data);
//     if (filteredSuggestions?.length > 0 && type_of_supply_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.units, state?.alternate_unit, state?.default_unit, unit_data, (showListUnits ? units_input_ref : showListAlternateUnit ? alternate_unit_input_ref : default_unit_input_ref)?.current?.selectionStart])



//   useEffect(() => {
//     const filteredSuggestions = yes_no_array?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = (setModifyShow ? state?.set_modify_default_ledger_for_invoice
//         : applicableForResShow ? state?.applicable_for_reverse_charge
//           : eligibleForTaxshow ? state?.eligible_for_tax_input_credit
//             : setStandardRateShow ? state?.set_standard_rates
//               : provideBefShow ? state?.provide_behaviour_options
//                 : ignDiffShow ? state?.ignore_diffrence_due_to_physical_counting
//                   : ignNagtiveShow ? state?.ignore_nagative_balance
//                     : treatAllSalesShow ? state?.treat_all_sales_as_new_manufacture
//                       : treatAllPurchaseShow ? state?.treat_all_purchased_as_comsumed
//                         : state?.treat_all_rejections_inward_as_scrap)?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes((setModifyShow ? state?.set_modify_default_ledger_for_invoice
//         : applicableForResShow ? state?.applicable_for_reverse_charge
//           : eligibleForTaxshow ? state?.eligible_for_tax_input_credit
//             : setStandardRateShow ? state?.set_standard_rates
//               : provideBefShow ? state?.provide_behaviour_options
//                 : ignDiffShow ? state?.ignore_diffrence_due_to_physical_counting
//                   : ignNagtiveShow ? state?.ignore_nagative_balance
//                     : treatAllSalesShow ? state?.treat_all_sales_as_new_manufacture
//                       : treatAllPurchaseShow ? state?.treat_all_purchased_as_comsumed
//                         : state?.treat_all_rejections_inward_as_scrap) !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataYesNo((setModifyShow ? state?.set_modify_default_ledger_for_invoice
//       : applicableForResShow ? state?.applicable_for_reverse_charge
//         : eligibleForTaxshow ? state?.eligible_for_tax_input_credit
//           : setStandardRateShow ? state?.set_standard_rates
//             : provideBefShow ? state?.provide_behaviour_options
//               : ignDiffShow ? state?.ignore_diffrence_due_to_physical_counting
//                 : ignNagtiveShow ? state?.ignore_nagative_balance
//                   : treatAllSalesShow ? state?.treat_all_sales_as_new_manufacture
//                     : treatAllPurchaseShow ? state?.treat_all_purchased_as_comsumed
//                       : state?.treat_all_rejections_inward_as_scrap) !== '' ? (setModifyShow ? set_modify_default_ledger_for_invoice_input_ref
//                         : applicableForResShow ? applicable_for_reverse_charge_input_ref
//                           : eligibleForTaxshow ? eligible_for_tax_input_credit_input_ref
//                             : setStandardRateShow ? set_standard_rates_input_ref
//                               : provideBefShow ? provide_behaviour_options_input_ref
//                                 : ignDiffShow ? ignore_diffrence_due_to_physical_counting_input_ref
//                                   : ignNagtiveShow ? ignore_nagative_balance_input_ref
//                                     : treatAllSalesShow ? treat_all_sales_as_new_manufacture_input_ref
//                                       : treatAllPurchaseShow ? treat_all_purchased_as_comsumed_input_ref
//                                         : treat_all_rejections_inward_as_scrap_input_ref)?.current?.selectionStart > 0 ? filteredSuggestions : yes_no_array : yes_no_array);
//     if (filteredSuggestions?.length > 0 && (setModifyShow ? set_modify_default_ledger_for_invoice_input_ref
//       : applicableForResShow ? applicable_for_reverse_charge_input_ref
//         : eligibleForTaxshow ? eligible_for_tax_input_credit_input_ref
//           : setStandardRateShow ? set_standard_rates_input_ref
//             : provideBefShow ? provide_behaviour_options_input_ref
//               : ignDiffShow ? ignore_diffrence_due_to_physical_counting_input_ref
//                 : ignNagtiveShow ? ignore_nagative_balance_input_ref
//                   : treatAllSalesShow ? treat_all_sales_as_new_manufacture_input_ref
//                     : treatAllPurchaseShow ? treat_all_purchased_as_comsumed_input_ref
//                       : treat_all_rejections_inward_as_scrap_input_ref)?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state?.set_modify_default_ledger_for_invoice
//     , state?.applicable_for_reverse_charge
//     , state?.eligible_for_tax_input_credit
//     , state?.set_standard_rates
//     , state?.provide_behaviour_options
//     , state?.ignore_diffrence_due_to_physical_counting
//     , state?.ignore_nagative_balance
//     , state?.treat_all_sales_as_new_manufacture
//     , state?.treat_all_purchased_as_comsumed
//     , state?.treat_all_rejections_inward_as_scrap,
//     yes_no_array,
//   (setModifyShow ? set_modify_default_ledger_for_invoice_input_ref
//     : applicableForResShow ? applicable_for_reverse_charge_input_ref
//       : eligibleForTaxshow ? eligible_for_tax_input_credit_input_ref
//         : setStandardRateShow ? set_standard_rates_input_ref
//           : provideBefShow ? provide_behaviour_options_input_ref
//             : ignDiffShow ? ignore_diffrence_due_to_physical_counting_input_ref
//               : ignNagtiveShow ? ignore_nagative_balance_input_ref
//                 : treatAllSalesShow ? treat_all_sales_as_new_manufacture_input_ref
//                   : treatAllPurchaseShow ? treat_all_purchased_as_comsumed_input_ref
//                     : treat_all_rejections_inward_as_scrap_input_ref)?.current?.selectionStart])



//   useEffect(() => {
//     const filteredSuggestions = hsn_detail_gst_rate_array?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = (hsnSacDetailShow ? state?.hsn_sac_details : gst_rate_details)?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes((hsnSacDetailShow ? state?.hsn_sac_details : gst_rate_details) !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataHsnDetailGstRate(((hsnSacDetailShow ? state?.hsn_sac_details : gst_rate_details)) !== '' ? ((hsnSacDetailShow ? hsn_sac_details_input_ref : gst_rate_details_input_ref))?.current?.selectionStart > 0 ? filteredSuggestions : hsn_detail_gst_rate_array : hsn_detail_gst_rate_array);
//     if (filteredSuggestions?.length > 0 && (hsnSacDetailShow ? hsn_sac_details_input_ref : gst_rate_details_input_ref)?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.hsn_sac_details, gst_rate_details, hsn_detail_gst_rate_array, ((hsnSacDetailShow ? hsn_sac_details_input_ref : gst_rate_details_input_ref))?.current?.selectionStart])



//   useEffect(() => {
//     const filteredSuggestions = is_gst_applicable_array?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = state?.is_gst_applicable?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.is_gst_applicable !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataIsGstApplicable(state.is_gst_applicable !== '' ? is_gst_applicable_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : is_gst_applicable_array : is_gst_applicable_array);
//     if (filteredSuggestions?.length > 0 && is_gst_applicable_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.is_gst_applicable, is_gst_applicable_array, is_gst_applicable_input_ref?.current?.selectionStart])


//   useEffect(() => {
//     const filteredSuggestions = taxability_array?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = state?.taxability_type?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.taxability_type !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataTaxability(state.taxability_type !== '' ? taxability_type_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : taxability_array : taxability_array);
//     if (filteredSuggestions?.length > 0 && taxability_type_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.taxability_type, taxability_array, taxability_type_input_ref?.current?.selectionStart])

//   useEffect(() => {
//     const filteredSuggestions = type_of_supply_array?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = state?.type_of_supply?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.type_of_supply !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataTypeOfSupply(state.type_of_supply !== '' ? type_of_supply_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : type_of_supply_array : type_of_supply_array);
//     if (filteredSuggestions?.length > 0 && type_of_supply_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.type_of_supply, type_of_supply_array, type_of_supply_input_ref?.current?.selectionStart])


//   useEffect(() => {
//     const filteredSuggestions = costing_method_array?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = state?.costing_method?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.costing_method !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataCostingMethod(state.costing_method !== '' ? costing_method_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : costing_method_array : costing_method_array);
//     if (filteredSuggestions?.length > 0 && costing_method_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.costing_method, costing_method_array, costing_method_input_ref?.current?.selectionStart])


//   useEffect(() => {
//     const filteredSuggestions = market_valuation_array?.filter((item, index) => {
//       const lowercaseLedgerName = item?.name?.toLowerCase();
//       const lowercaseSearchInput = state?.market_valuation_method?.toLowerCase();
//       const matchesSearchInput = lowercaseLedgerName?.includes(state.market_valuation_method !== '' ? lowercaseSearchInput : '');
//       return matchesSearchInput;
//     })
//     setFilteredDataTypeOfSupply(state.market_valuation_method !== '' ? market_valuation_method_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : market_valuation_array : market_valuation_array);
//     if (filteredSuggestions?.length > 0 && market_valuation_method_input_ref?.current?.selectionStart > 0) {
//       setSelectedItem(0);
//     }
//   }, [state.market_valuation_method, market_valuation_array, market_valuation_method_input_ref?.current?.selectionStart])


//   // Keyboad functionality in dropdown select and enter -------------------------------------------------------------

//   const handleKeyDown = useCallback((e) => {
//     if (
//       showList ||
//       showListCategory ||
//       showListCompany ||
//       showListUnits ||
//       showListAlternateUnit ||
//       showListDefaultUnit ||
//       show_godown_dropdown ||
//       setModifyShow ||
//       isGstAppShow ||
//       hsnSacDetailShow ||
//       gstRateDetailsShow ||
//       taxabilityShow ||
//       cessValueShow ||
//       typeOfSupplyShow ||
//       applicableForResShow ||
//       eligibleForTaxshow ||
//       setStandardRateShow ||
//       provideBefShow ||
//       costingMethodShow ||
//       marketValuaShow ||
//       ignDiffShow ||
//       ignNagtiveShow ||
//       treatAllSalesShow ||
//       treatAllPurchaseShow ||
//       treatAllRejectShow

//     ) {
//       if (e.key === 'ArrowUp') {
//         e.preventDefault();
//         setSelectedItem(prevState => Math.max(prevState - 1, 0));
//       } else if (e.key === 'ArrowDown') {
//         e.preventDefault();
//         setSelectedItem(prevState => Math.min(prevState + 1,
//           (showList ? filteredData.length :
//             showListCategory ? filteredDataCategory.length :
//               showListCompany ? filteredDataComapny.length :
//                 showListUnits || showListAlternateUnit || showListDefaultUnit ? filteredDataUnit.length :
//                   show_godown_dropdown ? filteredDataGodown.length :
//                     isGstAppShow ? filteredDataIsGstApplicable.length :
//                       hsnSacDetailShow || gstRateDetailsShow ? filteredDataHsnDetailGstRate.length :
//                         taxabilityShow ? filteredDataTaxability.length :
//                           cessValueShow ? filteredDataCessValuationType.length :
//                             typeOfSupplyShow ? filteredDataTypeOfSupply.length :
//                               costingMethodShow ? filteredDataCostingMethod.length :
//                                 marketValuaShow ? filteredDataMarketValuation.length :
//                                   filteredDataYesNo.length)
//           - 1));
//       } else if (e.key === 'Enter') {
//         e.preventDefault();
//         if (showList) {
//           const selectedLink = filteredData[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               under: selectedLink.name,
//               under_id: selectedLink.id,
//               hsn_sac: selectedLink?.name === 'Primary' ? '' : selectedLink.HSN_SAC,
//               description_hsn_sac: selectedLink?.name === 'Primary' ? 'Not Applicable' : selectedLink.description,
//               taxability_type: selectedLink?.name === 'Primary' ? '' : selectedLink.taxability_type,
//               igst_rate: selectedLink?.name === 'Primary' ? '0' : selectedLink.IGST_rate,
//               cgst_rate: selectedLink?.name === 'Primary' ? '0' : selectedLink.CGST_rate,
//               sgst_utst_rate: selectedLink?.name === 'Primary' ? '0' : selectedLink.SGST_UTGST_rate,
//               source_of_details: selectedLink?.name === 'Primary' ? 'Not Applicable' : selectedLink.name
//             })
//           }
//         }
//         if (showListCategory) {
//           const selectedLink = filteredDataCategory[selectedItem || 0]
//           if (selectedLink) {
//             setState({
//               ...state,
//               category: selectedLink.name,
//               category_id: selectedLink.id
//             })
//           }
//         }
//         if (showListCompany) {
//           const selectedLink = filteredDataComapny[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               company_name_of_product: selectedLink.name
//               // category_id: selectedLink.id
//             })
//           }
//         }
//         if (showListUnits) {
//           const selectedLink = filteredDataUnit[selectedItem || 0]
//           if (selectedLink) {
//             setState({
//               ...state,
//               units: selectedLink.symbol,
//               unit_id: selectedLink.id,
//             })
//           }
//         }
//         if (showListAlternateUnit) {
//           const selectedLink = filteredDataUnit[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               alternate_unit: selectedLink.symbol,
//               alternate_unit_id: selectedLink.id,
//             })
//           }
//         }
//         if (showListDefaultUnit) {
//           const selectedLink = filteredDataUnit[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               default_unit: selectedLink.symbol,
//               default_unit_id: selectedLink.id,
//             })
//           }
//         }
//         if (show_godown_dropdown) {
//           const selectedLink = filteredDataGodown[selectedItem]
//           if (selectedLink) {
//             setmodal_data((prevValueLoop) => {
//               const updatedValueLoop = [...prevValueLoop];
//               updatedValueLoop[currentIndex]['godown_name'] = selectedLink.name;
//               updatedValueLoop[currentIndex]['godown_name_id'] = selectedLink.id;
//               return updatedValueLoop;
//             });
//           }
//         }
//         if (setModifyShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               set_modify_default_ledger_for_invoice: selectedLink.name,
//             })
//           }
//         }
//         if (isGstAppShow) {
//           const selectedLink = filteredDataIsGstApplicable[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               is_gst_applicable: selectedLink.name,
//             })
//           }
//         }
//         if (hsnSacDetailShow) {
//           const selectedLink = filteredDataHsnDetailGstRate[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               hsn_sac_details: selectedLink.name,
//             })
//           }
//         }
//         if (gstRateDetailsShow) {
//           const selectedLink = filteredDataHsnDetailGstRate[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               gst_rate_details: selectedLink.name,
//             })
//           }
//         }
//         if (taxabilityShow) {
//           const selectedLink = filteredDataTaxability[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               taxability_type: selectedLink.name,
//             })
//           }
//         }
//         if (cessValueShow) {
//           const selectedLink = filteredDataCessValuationType[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               cess_valuation_type: selectedLink.name,
//             })
//           }
//         }
//         if (typeOfSupplyShow) {
//           const selectedLink = filteredDataTypeOfSupply[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               type_of_supply: selectedLink.name,
//             })
//           }
//         }
//         if (applicableForResShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               applicable_for_reverse_charge: selectedLink.name,
//             })
//           }
//         }
//         if (eligibleForTaxshow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               eligible_for_tax_input_credit: selectedLink.name,
//             })
//           }
//         }
//         if (setStandardRateShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               set_standard_rates: selectedLink.name,
//             })
//           }
//         }
//         if (provideBefShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               provide_behaviour_options: selectedLink.name,
//             })
//           }
//         }
//         if (costingMethodShow) {
//           const selectedLink = filteredDataCostingMethod[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               costing_method: selectedLink.name,
//             })
//           }
//         }
//         if (marketValuaShow) {
//           const selectedLink = filteredDataMarketValuation[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               market_valuation_method: selectedLink.name,
//             })
//           }
//         }
//         if (ignDiffShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               ignore_diffrence_due_to_physical_counting: selectedLink.name,
//             })
//           }
//         }
//         if (ignNagtiveShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               ignore_nagative_balance: selectedLink.name,
//             })
//           }
//         }
//         if (treatAllSalesShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               treat_all_sales_as_new_manufacture: selectedLink.name,
//             })
//           }
//         }
//         if (treatAllPurchaseShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               treat_all_purchased_as_comsumed: selectedLink.name,
//             })
//           }
//         }
//         if (treatAllRejectShow) {
//           const selectedLink = filteredDataYesNo[selectedItem]
//           if (selectedLink) {
//             setState({
//               ...state,
//               treat_all_rejections_inward_as_scrap: selectedLink.name,
//             })
//           }
//         }
//       }
//     }
//   }, [
//     selectedItem,
//     filteredData,
//     filteredDataCategory,
//     filteredDataComapny,
//     filteredDataGodown,
//     filteredDataUnit,
//     filteredDataIsGstApplicable,
//     filteredDataHsnDetailGstRate,
//     filteredDataTaxability,
//     filteredDataCessValuationType,
//     filteredDataTypeOfSupply,
//     filteredDataYesNo,
//     showList,
//     showListCategory,
//     showListCompany,
//     showListUnits,
//     showListAlternateUnit,
//     showListDefaultUnit,
//     show_godown_dropdown,
//     setModifyShow,
//     isGstAppShow,
//     hsnSacDetailShow,
//     gstRateDetailsShow,
//     taxabilityShow,
//     cessValueShow,
//     typeOfSupplyShow,
//     applicableForResShow,
//     eligibleForTaxshow,
//     setStandardRateShow,
//     provideBefShow,
//     costingMethodShow,
//     marketValuaShow,
//     ignDiffShow,
//     ignNagtiveShow,
//     treatAllSalesShow,
//     treatAllPurchaseShow,
//     treatAllRejectShow

//   ])

//   useEffect(() => {
//     window.addEventListener('keydown', handleKeyDown);
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [handleKeyDown]);
//   //---------------------------------------------------------------------------
//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       if (stock_submit === 'Accept') {
//         if (e.key === 'y' || e.key === 'Y') {
//           e.preventDefault();
//           controlPlusASubmit();
//         }
//       }
//       if (stock_submit === 'Accept') {
//         if (e.key === 'n' || e.key === 'N') {
//           e.preventDefault();
//           setstock_submit('')
//           stock_name_input_ref.current.focus();
//         }
//       }
//     }
//     window.addEventListener('keydown', handleKeyDown);
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [state?.stock_name, stock_submit])


//   const handleClickOutside = (event) => {
//     let clickedElement = event.target;
//     let isInputField = false;
//     while (clickedElement) {
//       if ((clickedElement.tagName &&
//         (clickedElement.tagName.toLowerCase() === 'input' ||
//           clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
//         isInputField = true;
//         break;
//       }
//       clickedElement = clickedElement.parentNode;
//     }

//     if (!isInputField) {
//       if (nameInput && nameInput.current) {
//         event.preventDefault();
//         console.log("this is name input :---", nameInput)
//         nameInput.current.focus();
//       }
//     }
//   };
//   useEffect(() => {
//     document.addEventListener('click', handleClickOutside);
//     return () => {
//       document.removeEventListener('click', handleClickOutside);
//     };
//   }, [nameInput])


//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       let clickedElement = event.target;
//       let isInputField = false;
//       while (clickedElement) {
//         if ((clickedElement.tagName &&
//           (clickedElement.tagName.toLowerCase() === 'input' ||
//             clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
//           isInputField = true;
//           break;
//         }
//         clickedElement = clickedElement.parentNode;
//       }
//       if (!isInputField) {
//         if (nameInput && nameInput.current) {
//           event.preventDefault();
//           nameInput.current.focus();
//         }
//       }
//     };

//     const handleMouseDown = () => {
//       isMouseDownInside.current = true;
//     };

//     const handleMouseUp = () => {
//       isMouseDownInside.current = false;
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     document.addEventListener('mousedown', handleMouseDown);
//     document.addEventListener('mouseup', handleMouseUp);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//       document.removeEventListener('mousedown', handleMouseDown);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [nameInput]);

//   // submit form by ctr + a -------------------------------------------------
//   const controlPlusASubmit = async () => {
//     const newData = modal_data.map(obj => ({
//       ...obj,
//       value: obj.amount,
//       location: obj.godown_name_id,
//     }));
//     if (validateForm()) {
//       try {
//         let data = new FormData();
//         data.append('company_id', userData?.id)
//         data.append('name', state.stock_name);
//         data.append('alias_name', state.alise_name);
//         data.append('under', state.under_id);
//         data.append('part_no', state.part_no);
//         data.append('item_desc', state.description);
//         data.append('notes', state.notes);
//         data.append('set_default_ledger', state.set_modify_default_ledger_for_invoice);
//         data.append('unit_id', state.unit_id);
//         data.append('alt_unit_id', state.alternate_unit_id);
//         data.append('stock_cat_id', state.category_id);
//         data.append('stock_company', state.company_name_of_product);
//         data.append('provide_behaviour_opt', state.provide_behaviour_options);
//         data.append('ignore_diff_physical', state.ignore_diffrence_due_to_physical_counting);
//         data.append('ignore_negative_balance', state.ignore_nagative_balance);
//         data.append('treat_sale_manufacture', state.treat_all_sales_as_new_manufacture);
//         data.append('treat_purchased_consumed', state.treat_all_purchased_as_comsumed);
//         data.append('treat_rejection_scrap', state.treat_all_rejections_inward_as_scrap);
//         data.append('set_standard_rate', state.set_standard_rates);
//         data.append('costing_method', state.costing_method);
//         data.append('market_valuation', state.market_valuation_method);
//         data.append('HSN_SAC_details', state.hsn_sac_details);
//         data.append('source_details', state.source_of_details);
//         data.append('HSN_SAC', state.hsn_sac);
//         data.append('description', state.description_hsn_sac);
//         data.append('GST_rate_details', state.gst_rate_details);
//         data.append('source_of_details', state.source_of_details);
//         data.append('taxability_type', state.taxability_type);
//         data.append('IGST_rate', state.igst_rate);
//         data.append('CGST_rate', state.cgst_rate);
//         data.append('SGST_UTGST_rate', state.sgst_utst_rate);
//         data.append('cess_validation', state.cess_valuation_type);
//         data.append('cess_rate', state.cess_rate);
//         data.append('application_of_reverse', state.applicable_for_reverse_charge);
//         data.append('eligible_tax_credit', state.eligible_for_tax_input_credit);
//         data.append('GST_applicable', state.is_gst_applicable);
//         data.append('rate_of_Duty', '');
//         data.append('default_unit', state.default_unit);
//         data.append('default_unit_rate', state.default_unit_for_rate);
//         data.append('weight', state.weight_in_kg);
//         data.append('per_unit', state.per_unit_kg);
//         data.append('per_alt_unit', state.per_alternate_unit_kg);
//         data.append('where_unit', state.first_unit_count);
//         data.append('where_alternate_unit', state.secound_unit_count);
//         data.append('opening_balance', state.item_value);
//         data.append('type_of_supply', state.type_of_supply);
//         data.append('data_item', JSON?.stringify(newData));
//         data.append('quantity', state.item_quantity);
//         data.append('rate', state.item_rate);
//         data.append('per', state.item_per);
//         data.append('value', state.item_value);
//         data.append('total_nos_box', state.item_quantity_in_alternate_qty);
//         data.append('total_no_box_unit', state.item_quantity_in_alternate_unit);
//         data.append('opening_date', userData?.books_being_date);
//         const response = await dispatch(stockItemCreate(data));
//         if (response.status) {
//           toast.success(response?.message || 'stock item  created!', {
//             position: toast.POSITION.TOP_CENTER
//           });
//           resetForm();
//         } else {
//           toast.warn(response?.message || 'Fail!', {
//             position: toast.POSITION.TOP_CENTER,
//           });
//           setstock_submit('')
//           stock_name_input_ref.current.focus();
//         }
//       } catch (error) {
//         console.log(error);
//         toast.error(error?.message || 'Failed!', {
//           position: toast.POSITION.TOP_CENTER,
//         });
//         setstock_submit('')
//         stock_name_input_ref.current.focus();
//       }
//     } else {
//       toast.error('Please fill all the fields.', {
//         position: toast.POSITION.TOP_CENTER
//       });
//       setstock_submit('')
//       stock_name_input_ref.current.focus();
//     }
//   };
//   // Triggered when a form is submitted ----------------------------------------------------------------------------------
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (hashTag) {
//       if (validateForm()) {
//         let data = new FormData();
//         data.append('company_id', userData?.id)
//         data.append('name', state.stock_name);
//         data.append('alias_name', state.alise_name);
//         data.append('under', state.under_id);
//         data.append('part_no', state.part_no);
//         data.append('item_desc', state.description);
//         data.append('notes', state.notes);
//         data.append('set_default_ledger', state.set_modify_default_ledger_for_invoice);
//         data.append('unit_id', state.unit_id);
//         data.append('alt_unit_id', state.alternate_unit_id);
//         data.append('stock_cat_id', state.category_id);
//         data.append('stock_company', state.company_name_of_product);
//         data.append('provide_behaviour_opt', state.provide_behaviour_options);
//         data.append('ignore_diff_physical', state.ignore_diffrence_due_to_physical_counting);
//         data.append('ignore_negative_balance', state.ignore_nagative_balance);
//         data.append('treat_sale_manufacture', state.treat_all_sales_as_new_manufacture);
//         data.append('treat_purchased_consumed', state.treat_all_purchased_as_comsumed);
//         data.append('treat_rejection_scrap', state.treat_all_rejections_inward_as_scrap);
//         data.append('set_standard_rate', state.set_standard_rates);
//         data.append('costing_method', state.costing_method);
//         data.append('market_valuation', state.market_valuation_method);
//         data.append('HSN_SAC_details', state.hsn_sac_details);
//         data.append('source_details', state.source_of_details);
//         data.append('HSN_SAC', state.hsn_sac);
//         data.append('description', state.description_hsn_sac);
//         data.append('GST_rate_details', state.gst_rate_details);
//         data.append('source_of_details', state.source_of_details);
//         data.append('taxability_type', state.taxability_type);
//         data.append('IGST_rate', state.igst_rate);
//         data.append('CGST_rate', state.cgst_rate);
//         data.append('SGST_UTGST_rate', state.sgst_utst_rate);
//         data.append('cess_validation', state.cess_valuation_type);
//         data.append('cess_rate', state.cess_rate);
//         data.append('application_of_reverse', state.applicable_for_reverse_charge);
//         data.append('eligible_tax_credit', state.eligible_for_tax_input_credit);
//         data.append('GST_applicable', state.is_gst_applicable);
//         data.append('rate_of_Duty', '');
//         data.append('default_unit', state.default_unit);
//         data.append('default_unit_rate', state.default_unit_for_rate);
//         data.append('weight', state.weight_in_kg);
//         data.append('per_unit', state.per_unit_kg);
//         data.append('per_alt_unit', state.per_alternate_unit_kg);
//         data.append('where_unit', state.first_unit_count);
//         data.append('where_alternate_unit', state.secound_unit_count);
//         data.append('opening_balance', state.opening_balance);
//         data.append('type_of_supply', state.type_of_supply);
//         data.append('data_item', []);
//         data.append('quantity', state.item_quantity);
//         data.append('rate', state.item_rate);
//         data.append('per', state.item_per);
//         data.append('value', state.item_value);
//         data.append('total_nos_box', state.item_quantity_in_alternate_qty);
//         data.append('total_no_box_unit', state.item_quantity_in_alternate_unit);
//         data.append('opening_date', userData?.books_being_date);
//         dispatch(stockItemCreate(data)).then((response) => {
//           if (response.status) {
//             toast.success(response?.message || 'group created!', {
//               position: toast.POSITION.TOP_CENTER
//             });
//             resetForm()
//             setHashTag(false)
//             setstock_submit('')
//             stock_name_input_ref.current.focus();
//             setIsStockItemNameEntered(false)
//           }
//           else {
//             toast.warn(response?.message || 'Fail !', {
//               position: toast.POSITION.TOP_CENTER
//             });
//             setHashTag(false)
//             setstock_submit('')
//             stock_name_input_ref.current.focus();
//             setIsStockItemNameEntered(false)
//           }
//         }).catch(error => {
//           console.log(error)
//           toast.error(error?.message || ' Failed!', {
//             position: toast.POSITION.TOP_CENTER
//           });
//           setHashTag(false)
//           setstock_submit('')
//           stock_name_input_ref.current.focus();
//           setIsStockItemNameEntered(false)
//         })
//       } else {
//         toast.error('Please fill all the fields.', {
//           position: toast.POSITION.TOP_CENTER
//         });
//         setstock_submit('')
//         setHashTag(false)
//         stock_name_input_ref.current.focus();
//       }
//     }
//   }
//   // Function to validate form fields---------------------------------------
//   const validateForm = () => {
//     const condition1 = state?.units === 'Not Applicable' && state.units !== '';
//     const condition2 = state.alternate_unit !== 'Not Applicable' && state.alternate_unit !== '';
//     if (condition1) {

//     } else if (condition2) {
//       return (
//         userData?.id !== '' &&
//         state?.stock_name !== '' &&
//         state?.set_modify_default_ledger_for_invoice !== '' &&
//         state?.under !== '' &&
//         state?.under_id !== '' &&
//         state?.category !== '' &&
//         state?.category_id !== '' &&
//         state?.alternate_unit_id !== '' &&
//         state?.first_unit_count !== '' &&
//         state?.secound_unit_count !== '' &&
//         state?.set_standard_rates !== '' &&
//         state?.costing_method !== '' &&
//         state?.market_valuation_method !== '' &&
//         state?.provide_behaviour_options !== '' &&
//         state?.ignore_diffrence_due_to_physical_counting !== '' &&
//         state?.ignore_nagative_balance !== '' &&
//         state?.treat_all_sales_as_new_manufacture !== '' &&
//         state?.treat_all_purchased_as_comsumed !== '' &&
//         state?.treat_all_rejections_inward_as_scrap !== '' &&
//         state?.company_name_of_product !== '' &&
//         state?.is_gst_applicable !== '' &&
//         state?.hsn_sac_details !== '' &&
//         state?.gst_rate_details !== '' &&
//         state?.cess_valuation_type !== '' &&
//         state?.cess_rate !== '' &&
//         state?.applicable_for_reverse_charge !== '' &&
//         state?.eligible_for_tax_input_credit !== '' &&
//         state?.type_of_supply !== ''
//       )
//     } else {
//       return (
//         userData?.id !== '' &&
//         state?.stock_name !== '' &&
//         state?.set_modify_default_ledger_for_invoice !== '' &&
//         state?.under !== '' &&
//         state?.under_id !== '' &&
//         state?.category !== '' &&
//         state?.category_id !== '' &&
//         state?.company_name_of_product !== '' &&
//         state?.is_gst_applicable !== '' &&
//         state?.hsn_sac_details !== '' &&
//         state?.gst_rate_details !== '' &&
//         state?.cess_valuation_type !== '' &&
//         state?.cess_rate !== '' &&
//         state?.applicable_for_reverse_charge !== '' &&
//         state?.eligible_for_tax_input_credit !== '' &&
//         state?.type_of_supply !== ''
//       )
//     }
//   };
//   // Function to reset form fields-----------------------------------------
//   const resetForm = () => {
//     setState(prevState => ({
//       // ...prevState,
//       stock_name: '',
//       alise_name: '',
//       part_no: '',
//       description: '',
//       notes: '',
//       set_modify_default_ledger_for_invoice: 'No',
//       under: 'Primary',
//       under_id: 'Primary',
//       category: 'Not Applicable',
//       category_id: 'Not Applicable',
//       units: 'Not Applicable',
//       unit_id: 'Not Applicable',
//       alternate_unit: 'Not Applicable',
//       alternate_unit_id: 'Not Applicable',
//       first_unit_count: '',
//       secound_unit_count: '',
//       weight_in_kg: 'Not Available',
//       per_unit_kg: '',
//       per_alternate_unit_kg: '',
//       company_name_of_product: 'Not Applicable',
//       is_gst_applicable: 'Applicable',
//       hsn_sac_details: 'As per Company/Stock Group',
//       source_of_details: 'Not Applicable',
//       hsn_sac: '',
//       description_hsn_sac: '',
//       gst_rate_details: 'As per Company/Stock Group',
//       taxability_type: '',
//       igst_rate: '0',
//       cgst_rate: '0',
//       sgst_utst_rate: '0',
//       cess_valuation_type: 'Not Applicable',
//       cess_rate: '0',
//       applicable_for_reverse_charge: 'No',
//       eligible_for_tax_input_credit: 'No',
//       type_of_supply: 'Goods',
//       opening_balance: '',
//       default_unit: 'Not Applicable',
//       default_unit_id: '',
//       default_unit_for_rate: '',
//       set_standard_rates: 'No',
//       costing_method: 'Avg. Cost',
//       market_valuation_method: 'Avg. Price',
//       provide_behaviour_options: 'No',
//       ignore_diffrence_due_to_physical_counting: 'No',
//       ignore_nagative_balance: 'No',
//       treat_all_sales_as_new_manufacture: 'No',
//       treat_all_purchased_as_comsumed: 'No',
//       treat_all_rejections_inward_as_scrap: 'No',
//       item_quantity: '',
//       item_rate: '',
//       item_per: '',
//       item_value: '',
//       item_quantity_in_alternate_qty: '',
//       item_quantity_in_alternate_unit: '',
//     }));
//     setmodal_data([{
//       id: '1',
//       godown_name: '',
//       godown_name_id: '',
//       quantity: '',
//       combination_of_units: '',
//       rate: '',
//       per: '',
//       amount: '',
//       total_nos_box: '',
//       total_no_box_unit: '',
//     }])

//     setstock_submit('');
//     setTimeout(() => {
//       stock_name_input_ref.current.focus();
//     }, 50);
//     setIsStockItemNameEntered(false);
//   };

//   return (
//     <div className='section'>
//       <div className='detail'>
//         {modal ?
//           <ItemWithGodown
//             state={state}
//             setModal={setModal}
//             show_godown_dropdown={show_godown_dropdown}
//             setshow_godown_dropdown={setshow_godown_dropdown}
//             filteredDataGodown={filteredDataGodown}
//             modal_data={modal_data}
//             setmodal_data={setmodal_data}
//             selectedItem={selectedItem}
//             setSelectedItem={setSelectedItem}
//             godown_name_input_ref={godown_name_input_ref}
//             quantity_input_ref={quantity_input_ref}
//             rate_input_ref={rate_input_ref}
//             per_input_ref={per_input_ref}
//             amount_input_ref={amount_input_ref}
//             handleItemFocus={handleItemFocus}
//             handleItemBlur={handleItemBlur}
//             handleChangeItemEntry={handleChangeItemEntry}
//             handleKeyUpItemEntry={handleKeyUpItemEntry}
//           /> : null}
//         {
//           stock_submit === 'Accept' ?
//             <YesNoModal>
//               <div className='container'>
//                 <div className='row'>
//                   <div className='col-12'>
//                     <p className='modal_message'>
//                       {stock_submit}
//                     </p>
//                   </div>
//                   <div className='col-5'>
//                     <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
//                   </div>
//                   <div className='col-2'>or</div>
//                   <div className='col-5'>
//                     <Link className='modal_link'>No</Link>
//                   </div>
//                 </div>
//               </div>
//             </YesNoModal> : null
//         }
//     </div >
//   )
// })

// export default StockItem

