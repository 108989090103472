import React, { useState, useCallback, useEffect, useMemo } from 'react'
import TableLayoutTrialBalance from '../../../../../components/Table/TableLayoutTrialBalance'
import NavLayout from '../../../../../components/NavNameOfComponent/NavLayout'
import TrialBalanceHelp from '../../../../../components/help/TrialBalance/TrialBalanceHelp'
import TrialBalanceFooter from '../../../../../components/Footer/TrialBalanceFooter'
import { groupSummary } from '../../../../../redux/actions/action-creator'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { currencyFormate, dateFormateSend, dateFormateShow } from '../../../../../components/Date_Formate_Utilitiy/Index'
function GroupSummary() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { godown_data = nullyui } = useSelector(state => state.common);
    const { userData = null } = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState(0)
    const [selectedItems, setSelectedItems] = useState([]);
    const [show, setShow] = useState(false)
    const [showGroup, setShowGroup] = useState(false)
    const [showLedgerWise, setShowLedgerWise] = useState(false)
    const [showMonthly, setShowMonthly] = useState(false)
    const [showBasis, setShowBasis] = useState(false)
    const [showChange, setShowChange] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [showSave, setShowSave] = useState(false)
    const [showApply, setShowApply] = useState(false)
    const [data, setData] = useState([])
    const { company_session } = useSelector(state => state.common);
    const initialDate = useMemo(() => ({
        send_from_date: dateFormateSend(company_session?.start_financial_year),
        send_to_date: dateFormateSend(company_session?.current_date),
    }), [company_session]);

    const [date, setDate] = useState(initialDate);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('fromDate', dateFormateSend(date.send_from_date));
                formData.append('toDate', dateFormateSend(date.send_to_date));
                formData.append('company_id', userData.id);
                formData.append('groupID', id);
                const response = await dispatch(groupSummary(formData));
                if (response && response.status) {
                    setData(response.data);
                } else {
                    setData(response.data);
                }
            } catch (error) {
                setData([]);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [date])
    const handleKeyDown = useCallback((e) => {
        // if (show || godownWise || stockGroup || basic || exceptionReports || saveView || valuation) {

        // } else {
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedItem(prevState => Math.min(prevState + 1, data?.data?.ledger_info?.length - 1));
            }
        if (e.key === 'Enter') {
            e.preventDefault();
            const selectedList = data?.data?.ledger_info[selectedItem]
            if (selectedList) {
                navigate(`../ledgervoucher/${selectedList?.id}`);
            }
        }
        // }
    }, [selectedItem, data])
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown])
    let total_closing;
    return (
        <div className='section'>
            <div className='detail'>
                <div className='container-fluid'>
                    <NavLayout>
                        Group Summary {`: ${data?.data?.group_name}`} <span>{`${dateFormateShow(date?.send_from_date) === 'NaN' ? null : dateFormateShow(date?.send_from_date)} To ${dateFormateShow(date?.send_to_date) === 'NaN' ? null : dateFormateShow(date?.send_to_date)}`}</span>
                    </NavLayout>
                </div>
                <TableLayoutTrialBalance>
                    {
                        data?.data?.ledger_info?.map((item, index) => {
                            total_closing = parseFloat(item?.opening_amount) + parseFloat(item?.DR_amt) - (item?.CR_amt<0?-parseFloat(item?.CR_amt): parseFloat(item?.CR_amt))
                            return (
                                <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                                    <div className={`row ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                                        <div className={`col-5 border-end border-start border-dark text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                                            {item?.ledger_name}
                                        </div>
                                        <div className='col-7 m-0 p-0'>
                                            <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                                                <div className='col-3 border-end border-dark m-0 p-0 table_header_font'>
                                                    {item?.opening_amount === '0' ? null : currencyFormate(Math.abs(item?.opening_amount))} {item?.opening_type}
                                                </div>
                                                <div className='col-6 m-0 p-0'>
                                                    <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                                                        <div className='col-4 text-end p-0 m-0 table_header_font' >
                                                            {item?.DR_amt === '0' ? null : currencyFormate(item?.DR_amt)}
                                                        </div>
                                                        <div className='col-2 text-end p-0 m-0  table_header_font' >
                                                            {/* 100% */}
                                                        </div>

                                                        <div className='col-4 text-end p-0 pe-1 m-0 table_header_font' >
                                                            {item?.CR_amt === 0 ? null : currencyFormate(Math.abs(item?.CR_amt))}
                                                        </div>
                                                        <div className='col-2 text-end p-0 pe-1 m-0 table_header_font' >
                                                            {/* 100% */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-3 border-end border-dark m-0 p-0 table_header_font'>
                                                    {currencyFormate(Math.abs(total_closing))} {total_closing === 0 ? null : total_closing > 0 ? 'Dr' : 'Cr'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </TableLayoutTrialBalance>
                <TrialBalanceFooter />
            </div>
            <div className='help' style={{ position: 'relative' }}>
                <TrialBalanceHelp
                    setShow={setShow}
                    setShowGroup={setShowGroup}
                    setShowLedgerWise={setShowLedgerWise}
                    setShowMonthly={setShowMonthly}
                    setShowBasis={setShowBasis}
                    setShowChange={setShowChange}
                    setShowReport={setShowReport}
                    setShowSave={setShowSave}
                    setShowApply={setShowApply}
                />
            </div>
        </div>
    )
}

export default GroupSummary
