import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LINK_PATH } from '../../../../constant';
import { useDropdownKeyboardNavigation } from '../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../../../components/NavigationList/NavigationList';
import useKeyboardNavigation from '../../../../components/Custom_Hooks/useKeyboardNavigation';

const StatementofAccount = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const keyMap = {
        'o': '',
        't': '',
        's': '',
        'q': '',
    };

    useKeyboardNavigation(keyMap);
    const data = [
        { to: '', text: 'OutStandings' },
        { to: '', text: 'Tally Audit' },
        { to: '', text: 'Statistics' },
        { to: '', text: 'Quit' },
    ];
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: data,
        state: true,
        onItemSelect: (item) => {
            if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
                return;
            }
            navigate(item.to)
        },

    });
    return (
        <NavigationList
            data={data}
            selectedItemIndex={selectedItemIndex}
            setSelectedItemIndex={setSelectedItemIndex}
            title='Statements of Accounts'
            first_heading=''
        />
    )
}

export default React.memo(StatementofAccount);
