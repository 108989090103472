import { stockGroupPrimaryList, stockGroupDelete,openModal,closeModal } from '../../../../../redux/actions/action-creator';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../accounting_master/group.css'
import { Link, } from 'react-router-dom';
import MasterListFooter from '../../../../../components/Footer/Master_Footer/MasterListFooter';
import { useDropdownKeyboardNavigation } from '../../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import { useDropdownScroll } from '../../../../../components/Custom_Hooks/useDropdownScroll';
import HomeLayout from '../../../../../components/home_page/HomeLayout';
import Form from '../../../../../components/Form/Form';
import NavFooterLayout from '../../../../../components/NavNameOfComponent/NavFooterLayout';
import DeletConfirm from '../../../../../components/Modal/DeletConfirm';
import { useDelet } from '../../../../../components/Custom_Hooks/useDelete';
import { prepareFormData } from '../../../../../components/Custom_Hooks/useFormHandler';
import StockGroupCreate from '../../create/Inventory_masters/StockGroup';
import StockGroupAlter from '../../alter/Inventory_masters/StockGroup';

const StockGroup = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [all_data, setall_data] = useState([]);

    const { userData = null } = useSelector(state => state.auth);
    const modal_state = useSelector(state => state.common.modal_state);
    const dropdownRef = useRef(null);
    // fetch data ----------------------
    useEffect(() => {
        const fetchData = async () => {
            try {
                const formdata = new FormData();
                formdata.append('company_id', userData?.id);
                const response = await dispatch(stockGroupPrimaryList(formdata));
                const array_list = [];

                const processSubGroups = async (subGroups, type) => {
                    if (subGroups && subGroups.length > 0) {
                        for (const data of subGroups) {
                            array_list.push({ type, data });
                            await processSubGroups(data.sub_stock_group, type + 1);
                        }
                    }
                };

                if (response.data && response.data.length > 0) {
                    for (const data of response.data) {
                        array_list.push({ type: 'primary', data });
                        await processSubGroups(data.sub_stock_group, 'secondary');
                    }
                }

                setall_data(array_list);
            } catch (error) {
                console.error('Error fetching group data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [modal_state?.group_update, modal_state?.modal_1, modal_state?.modal_2])
    
    
    // keyboard Functionality ----------------------------------------
     const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
         items: all_data,
         state: modal_state?.group_update || modal_state?.modal_1 || modal_state?.modal_2 ? false : true,
         onItemSelect: (item) => {
             // navigate(`../alter_accounting_groupupdate/${item?.data?.id}`)
         },
         onItemControlEnterSelect: (item) => {
             if (!(modal_state?.modal_1 || modal_state?.modal_2)) {
                 // navigate(`../alter_accounting_groupupdate/${item?.data?.id}`)
                 dispatch(openModal('group_update'))
             }
         },
         onItemAltCSelect: (item) => {
             if (!(modal_state?.group_update || modal_state?.modal_2)) {
                 dispatch(openModal('modal_1'))
             }
         },
         onItemAltDSelect: (item) => {
             if (!(modal_state?.group_update || modal_state?.modal_1)) {
                 dispatch(openModal('modal_2'))
             }
         },
     });
     // Scroll Functionality -------------------------------------
     useDropdownScroll({ dropdownRef, selectedItemIndex });
 
     const [data, setData] = useState({
         id: all_data[selectedItemIndex]?.data?.id,
         company_id: userData?.id
     })
 
     useEffect(() => {
         if (all_data[selectedItemIndex]) {
             setData({
                 id: all_data[selectedItemIndex]?.data?.id,
                 company_id: userData?.id,
             });
         }
     }, [selectedItemIndex, all_data]);
 
     // Delete Functionality ------------------------------
     const { delteItem, loadingDelete } = useDelet({
         prepareFormData: () => prepareFormData(data),
         submitForm: (data) => dispatch(stockGroupDelete(data)),
         shortcuts: [
             {
                 key: 'a',
                 ctrlKey: true,
                 action: () => delteItem(new Event('submit')),
             },
         ],
         closeModal: () => dispatch(closeModal('modal_2')),
         state: modal_state?.modal_2
     });
    return (
        <HomeLayout>
        {modal_state?.modal_1 && (
            <div style={{ zIndex: 10000 }}>
                <StockGroupCreate
                    title='Stock Group Creation (Secondary)'
                    closeModal={() => dispatch(closeModal('modal_1'))}
                />
            </div>)}
        {
        // location.pathname === `/alter_accounting_groupupdate/${id}`
        modal_state?.group_update
         && (
            <div style={{ zIndex: 10000 }}>
                <StockGroupAlter
                    data_to_update={data}
                    title='Stock Group Alteration'
                    closeModal={() => dispatch(closeModal('group_update'))}
                />
            </div>
        )}
        <Form
            title='List of Stock Groups'
            col='col-12'
            loading={loading}
            Footer={MasterListFooter}
        >
            {
                modal_state?.modal_2 ?
                    <div style={{ zIndex: 10000 }}>
                        <DeletConfirm
                            handleSubmit={delteItem}
                            loading={loadingDelete}
                            closeModal={() => dispatch(closeModal('modal_2'))}
                        />
                    </div>
                    : null
            }
            <div className='row p-0 mb-2' style={{ overflowY: 'hidden' }}>
                <div className='col m-0 p-0 ps-2' ref={dropdownRef} style={{ overflowY: 'auto', height: '81.3vh' }}>
                    {
                        all_data.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={index === selectedItemIndex ? 'selected' : ''}
                                    style={{
                                        display: 'flex',
                                        textAlign: 'left',
                                        paddingLeft: 5,
                                        marginLeft: item.type === 'primary' ? 0 : item.type === 'secondary' ? 10 : item.type.startsWith('secondary') ? (item.type.length - 6) * 5 : 0
                                    }}
                                >
                                    <Link
                                        onClick={() => setSelectedItemIndex(index)}
                                        className={'group_list_chart_of_account'}
                                        style={{ width: '100%' }}
                                    >
                                        {item.data.name}
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <NavFooterLayout>
                <b className='ps-2'> {all_data.length} Stock Group(s)</b>
            </NavFooterLayout>

        </Form>
    </HomeLayout>
    )
}
export default StockGroup;

