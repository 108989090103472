import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LINK_PATH } from '../../../../constant';
import { useDropdownKeyboardNavigation } from '../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../../../components/NavigationList/NavigationList';
import useKeyboardNavigation from '../../../../components/Custom_Hooks/useKeyboardNavigation';

const InventoryBook = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const keyMap = {
        'i': '',
        'g': '',
        's': '',
        'c': '',
        't': '',
        'p': '',
    };
    useKeyboardNavigation(keyMap);
    const data = [
        { to: '', text: 'Stock Item' },
        { to: '', text: 'Godowns' },
        { to: '', text: 'Stock Group Summary' },
        { to: '', text: 'Stock Category Summary' },
        { to: '', text: 'Sales Order Book' },
        { to: '', text: 'Purchase Order Book' },
        { to: '', text: 'Delivery Note Register' },
        { to: '', text: 'Receipt Note Register' },
        { to: '', text: 'Stock Transfer Journal Register' },
        { to: '', text: 'Physical Stock Register' },
        { to: '', text: 'Quit' }
    ];
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: data,
        state: true,
        onItemSelect: (item) => {
            if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
                return;
            }
            navigate(item.to)
        },

    });

    return (
        <NavigationList
            data={data}
            selectedItemIndex={selectedItemIndex}
            setSelectedItemIndex={setSelectedItemIndex}
            title='Inventory Books'
            first_heading='SUMMARY'
            secound_heading='REGISTERS'
        />
    )
}

export default React.memo(InventoryBook);
