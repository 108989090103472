import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { signUp } from '../../redux/actions/action-creator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { dateFormateShow, formatInputToCapitalization, getDateInFormate, statesList } from '../Date_Formate_Utilitiy/Index';
import useClickOutside from '../Custom_Hooks/useClickOutsSide';
import { useFormHandler, prepareFormData } from '../Custom_Hooks/useFormHandler'
import DropdownYesNo from '../Dropdown/DropdownYesNo';
import DropdownLayout_1 from '../Dropdown/DropdownLayout_1';
import { useInputAction } from '../Custom_Hooks/useInputAction';
import InputField from '../Input/InputField';
import TextArea from '../Input/TextArea';
import CreateConfirm from '../Modal/CreateConfirm';
import RegistrationFooter from '../Footer/RegistrationFooter';
import { yes_no_array } from '../Dummi_Data';
import Indicator from '../Indicator/Indicator';

const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();
const financialYearStartMonth = 3;
const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
const financialYearEndYear = financialYearStartYear + 1;
const date_1 = new Date(`${financialYearStartYear}-${financialYearStartMonth + 1}-01`)
const date_2 = new Date(`${financialYearEndYear}-${financialYearStartMonth}-31`)
const financialYear = `${date_1.getDate()}-${date_1.toLocaleString('default', { month: 'short' })}-${date_1.getFullYear()} To ${date_2.getDate()}-${date_2.toLocaleString('default', { month: 'short' })}-${date_2.getFullYear()}`;
const current_date_redux = `${currentDate.toLocaleString('en-US', { weekday: 'long' })},${currentDate.getDate()}-${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`
let missingFields = [];
const initialState = {
    company_name: '',
    mailing_name: '',
    address: '',
    city: '',
    state: 'Madhya Pradesh',
    country: 'India',
    pincode: '',
    mobile: '',
    telephone: '',
    fax: '',
    email: '',
    website: '',
    financial_year_begning_form: dateFormateShow(date_1),
    books_being_date: dateFormateShow(date_1),
    user_name: '',
    password: "",
    confirm_password: "",
    enable_tally_audit_feature: '',
    maintain_multiple_branches_for_this_company: '',
    base_currancy_symbol: 'Rs',
    formal_name: 'INR',
    suffex_symbol_amount: 'Yes',
    add_space_between_amount_symbol: 'Yes',
    no_of_decimal_place: '2',
    word_reprenting_after_amount: 'Paise',
    no_of_decimal_place_for_amount: '2'
}


const RegistrationPage = (props) => {

    const dispatch = useDispatch();
    const [nameInput, setNameInput] = useState(null)
    const [showStateList, setShowStateList] = useState(false);
    const [drop_add_show, setDrop_add_show] = useState(false)
    const [data, setData] = useState(initialState);

    const inputRefs = {
        company_name: useRef(null),
        mailing_name: useRef(null),
        address: useRef(null),
        city: useRef(null),
        state: useRef(null),
        country: useRef(null),
        pincode: useRef(null),
        mobile: useRef(null),
        telephone: useRef(null),
        fax: useRef(null),
        email: useRef(null),
        website: useRef(null),
        financial_year_begning_form: useRef(null),
        books_being_date: useRef(null),
        user_name: useRef(null),
        password: useRef(null),
        confirm_password: useRef(null),
        // enable_tally_audit_feature: useRef(null),
        base_currancy_symbol: useRef(null),
        formal_name: useRef(null),
        suffex_symbol_amount: useRef(null),
        add_space_between_amount_symbol: useRef(null),
        no_of_decimal_place: useRef(null),
        word_reprenting_after_amount: useRef(null),
        no_of_decimal_place_for_amount: useRef(null),
        registration: useRef(null),
    };
    const first_ref = inputRefs?.company_name

    function getCurrentInputRef(name) {
        return inputRefs[name] || null;
    }

    const fieldConfig = [
        { name: "company_name", next: "mailing_name" },
        { name: "mailing_name", next: "address", prev: "company_name" },
        { name: "address", next: "city", prev: "mailing_name" },
        { name: "city", next: "state", prev: "address" },
        { name: "state", next: "country", prev: "city" },
        { name: "country", next: "pincode", prev: "state" },
        { name: "pincode", next: "mobile", prev: "country" },
        { name: "mobile", next: "telephone", prev: "pincode" },
        { name: "telephone", next: "fax", prev: "mobile" },
        { name: "fax", next: "email", prev: "telephone" },
        { name: "email", next: "website", prev: "fax" },
        { name: "website", next: "financial_year_begning_form", prev: "email" },
        { name: "financial_year_begning_form", next: "books_being_date", prev: "website" },
        { name: "books_being_date", next: "user_name", prev: "financial_year_begning_form" },
        { name: "user_name", next: "password", prev: "books_being_date" },
        { name: "password", next: "confirm_password", prev: "user_name" },
        { name: "confirm_password", next: "base_currancy_symbol", prev: "password" },
        // { name: "enable_tally_audit_feature", next: "base_currancy_symbol", prev: "confirm_password" },
        { name: "base_currancy_symbol", next: "formal_name", prev: "confirm_password" },
        { name: "formal_name", next: "suffex_symbol_amount", prev: "base_currancy_symbol" },
        { name: "suffex_symbol_amount", next: "add_space_between_amount_symbol", prev: "formal_name" },
        { name: "add_space_between_amount_symbol", next: "no_of_decimal_place", prev: "suffex_symbol_amount" },
        { name: "no_of_decimal_place", next: "word_reprenting_after_amount", prev: "add_space_between_amount_symbol" },
        { name: "word_reprenting_after_amount", next: "no_of_decimal_place_for_amount", prev: "no_of_decimal_place" },
        { name: "no_of_decimal_place_for_amount", prev: "word_reprenting_after_amount" },
    ];


    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    const validations = {
        company_name: (value) => value.trim() !== "",
        mailing_name: (value) => value.trim() !== "",
        address: (value) => value.trim() !== "",
        city: (value) => value.trim() !== "",
        state: (value) => statesList.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
        pincode: (value) => (/^\d{1,6}$/).test(value),
        mobile: (value) => (/^\d{1,10}$/).test(value),
        telephone: (value) => (/^(\d+)[-]?(\d{3})?[.]?(\d*)$/).test(value),
        fax: (value) => (/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value),
        add_space_between_amount_symbol: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
        no_of_decimal_place: (value) => (/^\d+$/).test(value),
        no_of_decimal_place_for_amount: (value) => (/^\d+$/).test(value),

    };

    const refs = Object.keys(inputRefs).reduce((acc, name) => {
        const nextField = fieldConfig.find(f => f.name === name)?.next;
        const prevField = fieldConfig.find(f => f.name === name)?.prev;
        acc[name] = {
            next: nextField ? inputRefs[nextField] : null,
            prev: prevField ? inputRefs[prevField] : null,
            curr: getCurrentInputRef(name),
            onFocus: () => {
                const ref = getCurrentInputRef(name);
                if (ref) {
                    setNameInput(ref)
                    ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
                };
                if (name === 'state') {
                    setShowStateList(true)
                    console.log('focus')
                }
                if (name === 'add_space_between_amount_symbol') {
                    setDrop_add_show(true)
                }
            },
            onBlur: (value) => {
                if (name === 'company_name') {
                    if (!value.trim()) {
                        getCurrentInputRef(name)?.current?.focus();
                    }
                }
                // if (name === 'company_name' || name === 'state' || name === 'financial_year_begning_form' || name === 'books_being_date' || name === 'user_name' || name === 'password' || name === 'confirm_password') {
                //     if (!value.trim()) {
                //         getCurrentInputRef(name)?.current?.focus();
                //     }
                // }

                // if (name === 'confirm_password') {
                //     if (data?.password !== value) {
                //         getCurrentInputRef(name)?.current?.focus();
                //     }
                // }

                if (name === 'state') {
                    setShowStateList(false)
                }
                // if (name === 'email') {
                //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                //     const isValidEmailFormat = emailRegex.test(value);
                //     if (isValidEmailFormat) {
                //         setData({ ...data, ['email']: value });
                //     } else {
                //         getCurrentInputRef(name)?.current?.focus();
                //     }
                // }
                if (name === 'financial_year_begning_form') {
                    const fyb = getDateInFormate(value)
                    if (fyb) {
                        setData({ ...data, ['financial_year_begning_form']: fyb });
                    } else {
                        getCurrentInputRef(name)?.current?.focus();
                    }
                }
                if (name === 'books_being_date') {
                    const fyb = getDateInFormate(value)
                    if (fyb) {
                        const date_1 = new Date(data.financial_year_begning_form)
                        const date_2 = new Date(fyb)
                        if (date_2 >= date_1) {
                            setData({ ...data, ['books_being_date']: fyb });
                        } else {
                            getCurrentInputRef(name)?.current?.focus();
                        }
                    } else {
                        getCurrentInputRef(name)?.current?.focus();
                    }
                }
                // if (name === 'mobile' && value) {
                //     if (!/^\d{10}$/.test(value)) {
                //         getCurrentInputRef(name)?.current?.focus();
                //     }
                // }
                if (name === 'add_space_between_amount_symbol') {
                    setDrop_add_show(false)
                }
                // if (name === 'website') {
                //     if (value) {
                //         const emailRegex = /^(ftp|http[s]?):\/\/[^ "]+$/;
                //         const isValidEmailFormat = emailRegex.test(value);
                //         if (isValidEmailFormat) {
                //             setData({ ...data, ['website']: value });
                //         } else {
                //             getCurrentInputRef(name)?.current?.focus();
                //         }
                //     }
                // }

            },
        };
        return acc;
    }, {});


    const validateForm = () => {
        const requiredFields = [
            { field: 'company_name', message: 'Required Company Name' },
            { field: 'mailing_name', message: 'Required Mailing Name' },
            { field: 'financial_year_begning_form', message: 'Required Financial Year' },
            { field: 'books_being_date', message: 'Required Book Being Date' },
            { field: 'user_name', message: 'Required User Name' },
            { field: 'password', message: 'Required Password' },
            { field: 'confirm_password', message: 'Required Confirm Password' },
            { field: 'email', message: 'Required Email' },
            { field: 'base_currancy_symbol', message: 'Required Base Currency Symbol' },
            { field: 'formal_name', message: 'Required Formal Name' },
            { field: 'suffex_symbol_amount', message: 'Required Suffex Symbol Amount' },
            { field: 'add_space_between_amount_symbol', message: 'Required Add Space Between Amount Symbol' },
            { field: 'no_of_decimal_place', message: 'Required No of Decimal Place' },
            { field: 'word_reprenting_after_amount', message: 'Required Word Reprenting After Amount' },
            { field: 'no_of_decimal_place_for_amount', message: 'Required No of Decimal Place For Amount' }
        ];

        for (let { field, message } of requiredFields) {
            if (!data?.[field]) {
                toast.error(message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setSubmitConfirm('');
                getCurrentInputRef(field)?.current?.focus();
                return false;
            }
        }

        // Specific field validation logic for some fields
        if (data?.books_being_date && data?.financial_year_begning_form) {
            const date_1 = new Date(data.financial_year_begning_form);
            const date_2 = new Date(data.books_being_date);
            if (!(date_2 >= date_1)) {
                toast.error('Book Date Should be equal to fy or Greater', {
                    position: toast.POSITION.TOP_CENTER,
                });
                setSubmitConfirm('');
                getCurrentInputRef('books_being_date')?.current?.focus();
                return false;
            }
        }

        if (data?.password !== data?.confirm_password) {
            toast.error('Confirm Password Not Match', {
                position: toast.POSITION.TOP_CENTER,
            });
            setSubmitConfirm('');
            getCurrentInputRef('confirm_password')?.current?.focus();
            return false;
        }

        if (data?.email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmailFormat = emailRegex.test(data?.email);
            if (!isValidEmailFormat) {
                toast.error('Email Invalid', {
                    position: toast.POSITION.TOP_CENTER,
                });
                setSubmitConfirm('');
                getCurrentInputRef('email')?.current?.focus();
                return false;
            }
        }

        return true;
    };

    // Function to reset form fields-----------------------------------------
    const resetForm = () => {
        setData(initialState)
        setSubmitConfirm('')
    };

    const { handleSubmit, setSubmitConfirm, submitConfirm, loading, handleFocusReset } = useFormHandler({
        validateForm,
        prepareFormData: () => prepareFormData(data),
        submitForm: (data) => dispatch(signUp(data)),
        shortcuts: [
            {
                key: 'a',
                ctrlKey: true,
                action: () => handleSubmit(new Event('submit')),
            },
        ],
        resetForm,
        first_ref

    });
    const check_dropdown = showStateList || drop_add_show
    const { handleChange, handleFocus, handleBlur, handleKeyDown } = useInputAction({
        data,
        setData,
        validations,
        refs,
        setSubmitConfirm,
        check_dropdown,
    });

    const inputFields = [
        { label: 'Company Name', name: 'company_name', type: 'text', value: data?.company_name, ref: inputRefs?.company_name, isRequired: true },
        { label: 'Mailing Name', name: 'mailing_name', type: 'text', value: data?.mailing_name, ref: inputRefs?.mailing_name, isRequired: true },
        { label: 'Address', name: 'address', type: 'text', value: data?.address, ref: inputRefs?.address, isRequired: false },
        { label: 'Financial Year Beginning From', name: 'financial_year_begning_form', type: 'text', value: data?.financial_year_begning_form, ref: inputRefs?.financial_year_begning_form, isRequired: true },
        { label: 'Books Beginning From', name: 'books_being_date', type: 'text', value: data?.books_being_date, ref: inputRefs?.books_being_date, isRequired: true },
        { label: 'Username (Administrator)', name: 'user_name', type: 'text', value: data?.user_name, ref: inputRefs?.user_name, isRequired: true },
        { label: 'Password', name: 'password', type: 'password', value: data?.password, ref: inputRefs?.password, isRequired: true },
        { label: 'Confirm Password', name: 'confirm_password', type: 'password', value: data?.confirm_password, ref: inputRefs?.confirm_password, isRequired: true },
        // { label: 'Enable TallyAudit Features', name: 'enable_tally_audit_feature', type: 'text', value: data?.enable_tally_audit_feature, ref: inputRefs?.enable_tally_audit_feature },
        { label: 'City', name: 'city', type: 'text', value: data?.city, ref: inputRefs?.city, isRequired: false },
        { label: 'State', name: 'state', type: 'text', value: data?.state, ref: inputRefs?.state, isRequired: false },
        { label: 'Country', name: 'country', type: 'text', value: data?.country, ref: inputRefs?.country, isRequired: false },
        { label: 'Pincode', name: 'pincode', type: 'text', value: data?.pincode, ref: inputRefs?.pincode, isRequired: false },
        { label: 'Mobile', name: 'mobile', type: 'text', value: data?.mobile, ref: inputRefs?.mobile, isRequired: false },
        { label: 'Telephone', name: 'telephone', type: 'text', value: data?.telephone, ref: inputRefs?.telephone, isRequired: false },
        { label: 'Fax', name: 'fax', type: 'text', value: data?.fax, ref: inputRefs?.fax, isRequired: false },
        { label: 'E-Mail', name: 'email', type: 'text', value: data?.email, ref: inputRefs?.email, isRequired: true },
        { label: 'Website', name: 'website', type: 'text', value: data?.website, ref: inputRefs?.website, isRequired: false },
        { label: 'Base Currency symbol', name: 'base_currancy_symbol', type: 'text', value: data?.base_currancy_symbol, ref: inputRefs?.base_currancy_symbol, isRequired: false },
        { label: 'Formal name', name: 'formal_name', type: 'text', value: data?.formal_name, ref: inputRefs?.formal_name, isRequired: false },
        { label: 'Suffix symbol to amount', name: 'suffex_symbol_amount', type: 'text', value: data?.suffex_symbol_amount, ref: inputRefs?.suffex_symbol_amount, isRequired: false },
        {
            label: 'Add space between amount and symbol', name: 'add_space_between_amount_symbol', type: 'text', value: data?.add_space_between_amount_symbol, ref: inputRefs?.add_space_between_amount_symbol, isRequired: false, isDrop: true,
            state: drop_add_show,
            setState: setDrop_add_show
            // state: drop['add_space_between_amount_symbol'],
            // setState: (val) => setDrop((prevDrop) => ({
            //     ...prevDrop,
            //     ['add_space_between_amount_symbol']: val,
            // }))
        },
        { label: 'Number of decimal places', name: 'no_of_decimal_place', type: 'text', value: data?.no_of_decimal_place, ref: inputRefs?.no_of_decimal_place, isRequired: false },
        { label: 'Word representing amount after decimal', name: 'word_reprenting_after_amount', type: 'text', value: data?.word_reprenting_after_amount, ref: inputRefs?.word_reprenting_after_amount, isRequired: false },
        { label: 'Number of decimal places for amount in words', name: 'no_of_decimal_place_for_amount', type: 'text', value: data?.no_of_decimal_place_for_amount, ref: inputRefs?.no_of_decimal_place_for_amount, isRequired: false },
    ];



    const handleClickOutside = (event) => {
        if (nameInput.current) {
            event.preventDefault();
            nameInput.current.focus();
        }
    };

    const handleClickInside = (event) => {
        let clickedElement = event.target;
        if (clickedElement?.tagName &&
            (clickedElement?.tagName.toLowerCase() === 'input' ||
                clickedElement?.tagName.toLowerCase() === 'textarea')) {
            clickedElement.focus();
        }
    };

    useClickOutside(nameInput, handleClickOutside, handleClickInside);

    return (
        <div className='container-fluid'>
            {
                submitConfirm === 'Accept' ?
                    <CreateConfirm
                        message={submitConfirm}
                        handleSubmit={handleSubmit}
                        handleFocusReset={handleFocusReset}
                    /> : null
            }
            <ToastContainer />
            {
                loading ? (
                    <Indicator />
                ) : (
                    <div className='row' style={{ pointerEvents: submitConfirm === 'Accept' ? 'none' : 'auto' }}>
                        <div className='col-12'>
                            <div className='row table_date_header'>
                                <div className='col m-0 p-0  table_header_font'>
                                    <b>Company Creation </b>
                                </div>
                            </div>
                            <form className='m-0 p-0' method='POST' autoComplete='off' target="_self" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                                <div className='row position-relative'>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col-sm-6 ps-1'>
                                                {inputFields.slice(0, 2).map(({ label, name, type, value, ref, isRequired }) => (
                                                    <InputField
                                                        lable={label}
                                                        type={type}
                                                        name={name}
                                                        value={value}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        handleFocus={handleFocus}
                                                        isRequired={isRequired}
                                                        ref={ref}
                                                    />
                                                ))}
                                                {inputFields.slice(2, 3).map(({ label, name, type, value, ref, isRequired }) => (
                                                    <TextArea
                                                        lable={label}
                                                        type={type}
                                                        name={name}
                                                        value={value}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        handleFocus={handleFocus}
                                                        isRequired={isRequired}
                                                        ref={ref}
                                                    />
                                                ))}
                                            </div>
                                            <div className='col-sm-6 ps-1'>
                                                {inputFields.slice(3, 8).map(({ label, name, type, value, ref, isRequired }) => (
                                                    <InputField
                                                        lable={label}
                                                        type={type}
                                                        name={name}
                                                        value={value}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        handleFocus={handleFocus}
                                                        isRequired={isRequired}
                                                        ref={ref}
                                                    />
                                                ))}
                                            </div>
                                            <div className='col-sm-6 ps-1'>
                                                {inputFields.slice(8, 13).map(({ label, name, type, value, ref, isRequired }) => (
                                                    <InputField
                                                        lable={label}
                                                        type={type}
                                                        name={name}
                                                        value={value}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        handleFocus={handleFocus}
                                                        isRequired={isRequired}
                                                        ref={ref}
                                                    />
                                                ))}
                                                {
                                                    showStateList ?
                                                        <DropdownLayout_1
                                                            title='List of State'
                                                            state={showStateList}
                                                            setState={setShowStateList}
                                                            data={data}
                                                            setData={setData}
                                                            input_value={data?.state}
                                                            input_key='state'
                                                            rawData={statesList}
                                                            ref={inputRefs.state}
                                                            refs={refs}
                                                        /> : null}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6 ps-1 pb-1'>
                                                {inputFields.slice(13, 17).map(({ label, name, type, value, ref, isRequired }) => (
                                                    <InputField
                                                        lable={label}
                                                        type={type}
                                                        name={name}
                                                        value={value}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        handleFocus={handleFocus}
                                                        isRequired={isRequired}
                                                        ref={ref}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <div className='row border-top'>
                                            <div className='col-sm-6 ps-1 pt-1'>
                                                {inputFields.slice(17, 20).map(({ label, name, type, value, ref, isRequired }) => (
                                                    <InputField
                                                        lable={label}
                                                        type={type}
                                                        name={name}
                                                        value={value}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        handleFocus={handleFocus}
                                                        isRequired={isRequired}
                                                        ref={ref}
                                                    />
                                                ))}
                                                {inputFields.slice(20, 21).map(({ label, name, type, value, ref, isRequired, isDrop, state, setState }) => (
                                                    <InputField
                                                        lable={label}
                                                        type={type}
                                                        name={name}
                                                        value={value}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        handleFocus={handleFocus}
                                                        isRequired={isRequired}
                                                        ref={ref}
                                                        show_drop={state}
                                                        setDrop_add_show={setState}
                                                        data={data}
                                                        setData={setData}
                                                        refs={refs}
                                                        isDrop={isDrop}
                                                    />
                                                ))}



                                            </div>
                                            <div className='col-sm-6 ps-1 pt-1'>
                                                {inputFields.slice(21).map(({ label, name, type, value, ref, isRequired }) => (
                                                    <InputField
                                                        lable={label}
                                                        type={type}
                                                        name={name}
                                                        value={value}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        handleFocus={handleFocus}
                                                        isRequired={isRequired}
                                                        ref={ref}
                                                    />
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div >
                                <RegistrationFooter />
                            </form>
                        </div>
                    </div>
                )}
        </div >
    )
}
export default RegistrationPage