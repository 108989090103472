import React from 'react';
import { Link } from 'react-router-dom';
import Indicator from '../Indicator/Indicator';

const DeletConfirm = ({ handleSubmit, closeModal, loading }) => {
    return (
        <div className='form'>
            {
                loading ?
                    <Indicator /> :
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            width: '15%',
                            height: '150px',
                            borderWidth: 1,
                            borderColor: 'black',
                            borderStyle: 'solid',
                            background: '#fff',
                            zIndex: 1000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <div className='container'>
                            <div className='row ps-3'>
                                <div className='col-12 mb-5 pe-4'>
                                    Delete ?
                                </div>
                                <div className='col-3 offset-1'>
                                    <Link style={{ textDecoration: 'none', color: '#000' }} className="modal_link" onClick={() => { return (handleSubmit(new Event('submit'))) }} ><span style={{ color: 'red' }}>Y</span>es</Link>
                                </div>
                                <div className='col-2'>or</div>
                                <div className='col-3'>
                                    <Link style={{ textDecoration: 'none', color: '#000' }} className='modal_link' onClick={() => { return (closeModal()) }}><span style={{ color: 'red' }}>N</span>o</Link>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default React.memo(DeletConfirm)
