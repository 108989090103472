import React, { useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { LINK_PATH } from '../../../../constant';
import { useDropdownKeyboardNavigation } from '../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../../../components/NavigationList/NavigationList';
import useKeyboardNavigation from '../../../../components/Custom_Hooks/useKeyboardNavigation';

const ExceptionReports =()=> {
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const data = [
        { to: '', text: 'Ledgers Without E-mail IDs' },
        { to: '', text: 'Optional Vouchers' },
        { to: '', text: 'Cancelled Vouchers' },
        { to: '', text: 'Post-Dated Vouchers' },
        { to: ``, text: 'Negative Ledgers' },
        { to: '', text: 'Negative Stock' },
        { to: '', text: 'Overdue Receivables' },
        { to: '', text: 'Overdue Payables' },
        { to: '', text: 'Quit' }
    ];
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: data,
        state: true,
        onItemSelect: (item) => {
            if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
                return;
            }
            navigate(item.to)
        },

    });
    return (
        <NavigationList
            data={data}
            selectedItemIndex={selectedItemIndex}
            setSelectedItemIndex={setSelectedItemIndex}
            title='Exception Reports'
            first_heading='MASTERS'
            secound_heading='REGISTERS'
            third_heading='REPORTS'
        />
    )
}

export default React.memo(ExceptionReports);
