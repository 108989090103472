// SIGN_IN
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
// SIGN_UP
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
// FOR GET COMPANY LIST 
export const GET_Company_LIST_REQUEST = 'GET_Company_LIST_REQUEST';
export const GET_Company_LIST_SUCCESS = 'GET_Company_LIST_SUCCESS';
export const GET_Comapny_LIST_FAILURE = 'GET_Comapny_LIST_FAILURE';
// FOR GET COMPANY LIST 
export const GET_Company_Alter_REQUEST = 'GET_Company_Alter_REQUEST';
export const GET_Company_Alter_SUCCESS = 'GET_Company_Alter_SUCCESS';
export const GET_Comapny_Alter_FAILURE = 'GET_Comapny_Alter_FAILURE';
// Logout
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
// GROUP ===============================================================
export const GROUP_LIST_UNDER_REQUEST = 'GROUP_LIST_UNDER_REQUEST';
export const GROUP_LIST_UNDER_SUCCESS = 'GROUP_LIST_UNDER_SUCCESS';
export const GROUP_LIST_UNDER_FAILURE = 'GROUP_LIST_UNDER_FAILURE';
// GROUP LIST 
export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_FAILURE = 'GROUP_LIST_FAILURE';
// GROUP CREATE 
export const GROUP_CREATE_REQUEST = 'GROUP_CREATE_REQUEST';
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS';
export const GROUP_CREATE_FAILURE = 'GROUP_CREATE_FAILURE';
// GROUP UPDATE
export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_FAILURE = 'GROUP_UPDATE_FAILURE';
// GROUP DELETE 
export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_FAILURE = 'GROUP_DELETE_FAILURE';
// GROUP SINGLE DATA 
export const GROUP_SINGLE_REQUEST = 'GROUP_SINGLE_REQUEST';
export const GROUP_SINGLE_SUCCESS = 'GROUP_SINGLE_SUCCESS';
export const GROUP_SINGLE_FAILURE = 'GROUP_SINGLE_FAILURE';
// LEDGER TYPE FOR CREATE 
export const LEDGER_CREATE_REQUEST = 'LEDGER_CREATE_REQUEST';
export const LEDGER_CREATE_SUCCESS = 'LEDGER_CREATE_SUCCESS';
export const LEDGER_CREATE_FAILURE = 'LEDGER_CREATE_FAILURE';
// LEDGER TYPE FOR CREATE 
export const LEDGER_SINGLE_DATA_REQUEST = 'LEDGER_SINGLE_DATA_REQUEST';
export const LEDGER_SINGLE_DATA_SUCCESS = 'LEDGER_SINGLE_DATA_SUCCESS';
export const LEDGER_SINGLE_DATA_FAILURE = 'LEDGER_SINGLE_DATA_FAILURE';
// LEDGER TYPE FOR CREATE 
export const LEDGER_UPDATE_REQUEST = 'LEDGER_UPDATE_REQUEST';
export const LEDGER_UPDATE_SUCCESS = 'LEDGER_UPDATE_SUCCESS';
export const LEDGER_UPDATE_FAILURE = 'LEDGER_UPDATE_FAILURE';
// DELETE LEDGER
export const LEDGER_DELETE_REQUEST = 'LEDGER_DELETE_REQUEST';
export const LEDGER_DELETE_SUCCESS = 'LEDGER_DELETE_SUCCESS';
export const LEDGER_DELETE_FAILURE = 'LEDGER_DELETE_FAILURE';
// ledger common list 
export const LEDGER_COMMON_LIST_REQUEST = 'LEDGER_COMMON_LIST_REQUEST';
export const LEDGER_COMMON_LIST_SUCCESS = 'LEDGER_COMMON_LIST_SUCCESS';
export const LEDGER_COMMON_LIST_FAILURE = 'LEDGER_COMMON_LIST_FAILURE';
// ledger common list 
export const LEDGER_LIST_REQUEST = 'LEDGER_LIST_REQUEST';
export const LEDGER_LIST_SUCCESS = 'LEDGER_LIST_SUCCESS';
export const LEDGER_LIST_FAILURE = 'LEDGER_LIST_FAILURE';
// unit create 
export const UNIT_CREATE_REQUEST = 'UNIT_CREATE_REQUEST';
export const UNIT_CREATE_SUCCESS = 'UNIT_CREATE_SUCCESS';
export const UNIT_CREATE_FAILURE = 'UNIT_CREATE_FAILURE';
// unit listing
export const UNIT_LIST_REQUEST = 'UNIT_LIST_REQUEST';
export const UNIT_LIST_SUCCESS = 'UNIT_LIST_SUCCESS';
export const UNIT_LIST_FAILURE = 'UNIT_LIST_FAILURE';
// unit listing
export const UNIT_UPDATE_REQUEST = 'UNIT_UPDATE_REQUEST';
export const UNIT_UPDATE_SUCCESS = 'UNIT_UPDATE_SUCCESS';
export const UNIT_UPDATE_FAILURE = 'UNIT_UPDATE_FAILURE';
// unit listing
export const UNIT_DELETE_REQUEST = 'UNIT_DELETE_REQUEST';
export const UNIT_DELETE_SUCCESS = 'UNIT_DELETE_SUCCESS';
export const UNIT_DELETE_FAILURE = 'UNIT_DELETE_FAILURE';
// unit single data fetch
export const UNIT_SINGLE_DATA_REQUEST = 'UNIT_SINGLE_DATA_REQUEST';
export const UNIT_SINGLE_DATA_SUCCESS = 'UNIT_SINGLE_DATA_SUCCESS';
export const UNIT_SINGLE_DATA_FAILURE = 'UNIT_SINGLE_DATA_FAILURE';
// uqc code listing 
export const UQC_CODE_LIST_REQUEST = 'UQC_CODE_LIST_REQUEST';
export const UQC_CODE_LIST_SUCCESS = 'UQC_CODE_LIST_SUCCESS';
export const UQC_CODE_LIST_FAILURE = 'UQC_CODE_LIST_FAILURE';
// uqc code create
export const UQC_CODE_CREATE_REQUEST = 'UQC_CODE_CREATE_REQUEST';
export const UQC_CODE_CREATE_SUCCESS = 'UQC_CODE_CREATE_SUCCESS';
export const UQC_CODE_CREATE_FAILURE = 'UQC_CODE_CREATE_FAILURE';
// uqc code update
export const UQC_CODE_UPDATE_REQUEST = 'UQC_CODE_UPDATE_REQUEST';
export const UQC_CODE_UPDATE_SUCCESS = 'UQC_CODE_UPDATE_SUCCESS';
export const UQC_CODE_UPDATE_FAILURE = 'UQC_CODE_UPDATE_FAILURE';
// uqc code delete
export const UQC_CODE_DELETE_REQUEST = 'UQC_CODE_DELETE_REQUEST';
export const UQC_CODE_DELETE_SUCCESS = 'UQC_CODE_DELETE_SUCCESS';
export const UQC_CODE_DELETE_FAILURE = 'UQC_CODE_DELETE_FAILURE';
// uqc code single data fatch
export const UQC_CODE_SINGLE_DATA_REQUEST = 'UQC_CODE_SINGLE_DATA_REQUEST';
export const UQC_CODE_SINGLE_DATA_SUCCESS = 'UQC_CODE_SINGLE_DATA_SUCCESS';
export const UQC_CODE_SINGLE_DATA_FAILURE = 'UQC_CODE_SINGLE_DATA_FAILURE';
// stock category create  
export const STOCK_CATEGORY_CREATE_REQUEST = 'STOCK_CATEGORY_CREATE_REQUEST';
export const STOCK_CATEGORY_CREATE_SUCCESS = 'STOCK_CATEGORY_CREATE_SUCCESS';
export const STOCK_CATEGORY_CREATE_FAILURE = 'STOCK_CATEGORY_CREATE_FAILURE';
// stock category create  
export const STOCK_CATEGORY_NAME_VALIDATE_REQUEST = 'STOCK_CATEGORY_NAME_VALIDATE_REQUEST';
export const STOCK_CATEGORY_NAME_VALIDATE_SUCCESS = 'STOCK_CATEGORY_NAME_VALIDATE_SUCCESS';
export const STOCK_CATEGORY_NAME_VALIDATE_FAILURE = 'STOCK_CATEGORY_NAME_VALIDATE_FAILURE';
//  stock category update 
export const STOCK_CATEGORY_UPDATE_REQUEST = 'STOCK_CATEGORY_UPDATE_REQUEST';
export const STOCK_CATEGORY_UPDATE_SUCCESS = 'STOCK_CATEGORY_UPDATE_SUCCESS';
export const STOCK_CATEGORY_UPDATE_FAILURE = 'STOCK_CATEGORY_UPDATE_FAILURE';
//  stock category listing 
export const STOCK_CATEGORY_LIST_REQUEST = 'STOCK_CATEGORY_LIST_REQUEST';
export const STOCK_CATEGORY_LIST_SUCCESS = 'STOCK_CATEGORY_LIST_SUCCESS';
export const STOCK_CATEGORY_LIST_FAILURE = 'STOCK_CATEGORY_LIST_FAILURE';
//  stock category listing 
export const STOCK_CATEGORY_UNDER_LIST_REQUEST = 'STOCK_CATEGORY_UNDER_LIST_REQUEST';
export const STOCK_CATEGORY_UNDER_LIST_SUCCESS = 'STOCK_CATEGORY_UNDER_LIST_SUCCESS';
export const STOCK_CATEGORY_UNDER_LIST_FAILURE = 'STOCK_CATEGORY_UNDER_LIST_FAILURE';
//  stock category delete 
export const STOCK_CATEGORY_DELETE_REQUEST = 'STOCK_CATEGORY_DELETE_REQUEST';
export const STOCK_CATEGORY_DELETE_SUCCESS = 'STOCK_CATEGORY_DELETE_SUCCESS';
export const STOCK_CATEGORY_DELETE_FAILURE = 'STOCK_CATEGORY_DELETE_FAILURE';
//  stock category single data  
export const STOCK_CATEGORY_SINGLE_REQUEST = 'STOCK_CATEGORY_SINGLE_REQUEST';
export const STOCK_CATEGORY_SINGLE_SUCCESS = 'STOCK_CATEGORY_SINGLE_SUCCESS';
export const STOCK_CATEGORY_SINGLE_FAILURE = 'STOCK_CATEGORY_SINGLE_FAILURE';
//  stock group create  
export const STOCK_GROUP_CREATE_REQUEST = 'STOCK_GROUP_CREATE_REQUEST';
export const STOCK_GROUP_CREATE_SUCCESS = 'STOCK_GROUP_CREATE_SUCCESS';
export const STOCK_GROUP_CREATE_FAILURE = 'STOCK_GROUP_CREATE_FAILURE';
//  stock group update  
export const STOCK_GROUP_UPDATE_REQUEST = 'STOCK_GROUP_UPDATE_REQUEST';
export const STOCK_GROUP_UPDATE_SUCCESS = 'STOCK_GROUP_UPDATE_SUCCESS';
export const STOCK_GROUP_UPDATE_FAILURE = 'STOCK_GROUP_UPDATE_FAILURE';
//  stock group list  
export const STOCK_GROUP_LIST_REQUEST = 'STOCK_GROUP_LIST_REQUEST';
export const STOCK_GROUP_LIST_SUCCESS = 'STOCK_GROUP_LIST_SUCCESS';
export const STOCK_GROUP_LIST_FAILURE = 'STOCK_GROUP_LIST_FAILURE';
//  stock group list  
export const STOCK_GROUP_UNDER_LIST_REQUEST = 'STOCK_GROUP_UNDER_LIST_REQUEST';
export const STOCK_GROUP_UNDER_LIST_SUCCESS = 'STOCK_GROUP_UNDER_LIST_SUCCESS';
export const STOCK_GROUP_UNDER_LIST_FAILURE = 'STOCK_GROUP_UNDER_LIST_FAILURE';

//  stock group delete  
export const STOCK_GROUP_DELETE_REQUEST = 'STOCK_GROUP_DELETE_REQUEST';
export const STOCK_GROUP_DELETE_SUCCESS = 'STOCK_GROUP_DELETE_SUCCESS';
export const STOCK_GROUP_DELETE_FAILURE = 'STOCK_GROUP_DELETE_FAILURE';
//  stock group single data   
export const STOCK_GROUP_SINGLE_DATA_REQUEST = 'STOCK_GROUP_SINGLE_DATA_REQUEST';
export const STOCK_GROUP_SINGLE_DATA_SUCCESS = 'STOCK_GROUP_SINGLE_DATA_SUCCESS';
export const STOCK_GROUP_SINGLE_DATA_FAILURE = 'STOCK_GROUP_SINGLE_DATA_FAILURE';
//  stock company create   
export const STOCK_COMPANY_CREATE_REQUEST = 'STOCK_COMPANY_CREATE_REQUEST';
export const STOCK_COMPANY_CREATE_SUCCESS = 'STOCK_COMPANY_CREATE_SUCCESS';
export const STOCK_COMPANY_CREATE_FAILURE = 'STOCK_COMPANY_CREATE_FAILURE';
//  stock company create   
export const STOCK_COMPANY_UPDATE_REQUEST = 'STOCK_COMPANY_UPDATE_REQUEST';
export const STOCK_COMPANY_UPDATE_SUCCESS = 'STOCK_COMPANY_UPDATE_SUCCESS';
export const STOCK_COMPANY_UPDATE_FAILURE = 'STOCK_COMPANY_UPDATE_FAILURE';
//  stock company create   
export const STOCK_COMPANY_LIST_REQUEST = 'STOCK_COMPANY_LIST_REQUEST';
export const STOCK_COMPANY_LIST_SUCCESS = 'STOCK_COMPANY_LIST_SUCCESS';
export const STOCK_COMPANY_LIST_FAILURE = 'STOCK_COMPANY_LIST_FAILURE';
//  stock company create   
export const STOCK_COMPANY_DELETE_REQUEST = 'STOCK_COMPANY_DELETE_REQUEST';
export const STOCK_COMPANY_DELETE_SUCCESS = 'STOCK_COMPANY_DELETE_SUCCESS';
export const STOCK_COMPANY_DELETE_FAILURE = 'STOCK_COMPANY_DELETE_FAILURE';
//  stock company create   
export const STOCK_COMPANY_SINGLE_DATA_REQUEST = 'STOCK_COMPANY_SINGLE_DATA_REQUEST';
export const STOCK_COMPANY_SINGLE_DATA_SUCCESS = 'STOCK_COMPANY_SINGLE_DATA_SUCCESS';
export const STOCK_COMPANY_SINGLE_DATA_FAILURE = 'STOCK_COMPANY_SINGLE_DATA_FAILURE';
//  stock item create   
export const STOCK_ITEM_CREATE_REQUEST = 'STOCK_ITEM_CREATE_REQUEST';
export const STOCK_ITEM_CREATE_SUCCESS = 'STOCK_ITEM_CREATE_SUCCESS';
export const STOCK_ITEM_CREATE_FAILURE = 'STOCK_ITEM_CREATE_FAILURE';
//  stock item   update 
export const STOCK_ITEM_UPDATE_REQUEST = 'STOCK_ITEM_UPDATE_REQUEST';
export const STOCK_ITEM_UPDATE_SUCCESS = 'STOCK_ITEM_UPDATE_SUCCESS';
export const STOCK_ITEM_UPDATE_FAILURE = 'STOCK_ITEM_UPDATE_FAILURE';
//  stock item list 
export const STOCK_ITEM_LIST_REQUEST = 'STOCK_ITEM_LIST_REQUEST';
export const STOCK_ITEM_LIST_SUCCESS = 'STOCK_ITEM_LIST_SUCCESS';
export const STOCK_ITEM_LIST_FAILURE = 'STOCK_ITEM_LIST_FAILURE';
//  stock item list 
export const STOCK_ITEM_LIST_TO_DROPDOWN_REQUEST = 'STOCK_ITEM_LIST_TO_DROPDOWN_REQUEST';
export const STOCK_ITEM_LIST_TO_DROPDOWN_SUCCESS = 'STOCK_ITEM_LIST_TO_DROPDOWN_SUCCESS';
export const STOCK_ITEM_LIST_TO_DROPDOWN_FAILURE = 'STOCK_ITEM_LIST_TO_DROPDOWN_FAILURE';
//  stock item delete 
export const STOCK_ITEM_DELETE_REQUEST = 'STOCK_ITEM_DELETE_REQUEST';
export const STOCK_ITEM_DELETE_SUCCESS = 'STOCK_ITEM_DELETE_SUCCESS';
export const STOCK_ITEM_DELETE_FAILURE = 'STOCK_ITEM_DELETE_FAILURE';
//  stock item single data 
export const STOCK_ITEM_SINGLE_DATA_REQUEST = 'STOCK_ITEM_SINGLE_DATA_REQUEST';
export const STOCK_ITEM_SINGLE_DATA_SUCCESS = 'STOCK_ITEM_SINGLE_DATA_SUCCESS';
export const STOCK_ITEM_SINGLE_DATA_FAILURE = 'STOCK_ITEM_SINGLE_DATA_FAILURE';
// voucher type create ----------
export const VOUCHER_TYPE_CREATE_REQUEST = "VOUCHER_TYPE_CREATE_REQUEST"
export const VOUCHER_TYPE_CREATE_SUCCESS = "VOUCHER_TYPE_CREATE_SUCCESS"
export const VOUCHER_TYPE_CREATE_FAILURE = "VOUCHER_TYPE_CREATE_FAILURE"
// voucher type create ----------
export const VOUCHER_TYPE_UPDATE_REQUEST = "VOUCHER_TYPE_UPDATE_REQUEST"
export const VOUCHER_TYPE_UPDATE_SUCCESS = "VOUCHER_TYPE_UPDATE_SUCCESS"
export const VOUCHER_TYPE_UPDATE_FAILURE = "VOUCHER_TYPE_UPDATE_FAILURE"
// voucher type create ----------
export const VOUCHER_TYPE_LIST_REQUEST = "VOUCHER_TYPE_LIST_REQUEST"
export const VOUCHER_TYPE_LIST_SUCCESS = "VOUCHER_TYPE_LIST_SUCCESS"
export const VOUCHER_TYPE_LIST_FAILURE = "VOUCHER_TYPE_LIST_FAILURE"
// voucher type default list --------------------------
export const VOUCHER_TYPE_DEFAULT_LIST_REQUEST = 'VOUCHER_TYPE_DEFAULT_LIST_REQUEST'
export const VOUCHER_TYPE_DEFAULT_LIST_SUCCSESS = 'VOUCHER_TYPE_DEFAULT_LIST_SUCCSESS'
export const VOUCHER_TYPE_DEFAULT_LIST_FAILURE = 'VOUCHER_TYPE_DEFAULT_LIST_FAILURE'
// voucher type create ----------
export const VOUCHER_TYPE_DELETE_REQUEST = "VOUCHER_TYPE_DELETE_REQUEST"
export const VOUCHER_TYPE_DELETE_SUCCESS = "VOUCHER_TYPE_DELETE_SUCCESS"
export const VOUCHER_TYPE_DELETE_FAILURE = "VOUCHER_TYPE_DELETE_FAILURE"
// voucher type create ----------
export const VOUCHER_TYPE_SINGLE_DATA_REQUEST = "VOUCHER_TYPE_SINGLE_DATA_REQUEST"
export const VOUCHER_TYPE_SINGLE_DATA_SUCCESS = "VOUCHER_TYPE_SINGLE_DATA_SUCCESS"
export const VOUCHER_TYPE_SINGLE_DATA_FAILURE = "VOUCHER_TYPE_SINGLE_DATA_FAILURE"
// voucher type name check --------
export const VOUCHER_TYPE_NAME_CHECK_REQUEST = 'VOUCHER_TYPE_NAME_CHECK_REQUEST'
export const VOUCHER_TYPE_NAME_CHECK_SUCCESS = 'VOUCHER_TYPE_NAME_CHECK_SUCCESS'
export const VOUCHER_TYPE_NAME_CHECK_FAILURE = 'VOUCHER_TYPE_NAME_CHECK_FAILURE'
// voucher type number series create  --------
export const NUMBER_SERIES_CREATE_REQUEST = 'NUMBER_SERIES_CREATE_REQUEST'
export const NUMBER_SERIES_CREATE_SUCCESS = 'NUMBER_SERIES_CREATE_SUCCESS'
export const NUMBER_SERIES_CREATE_FAILURE = 'NUMBER_SERIES_CREATE_FAILURE'
// voucher type number series LIST  --------
export const NUMBER_SERIES_LIST_REQUEST = 'NUMBER_SERIES_LIST_REQUEST'
export const NUMBER_SERIES_LIST_SUCCESS = 'NUMBER_SERIES_LIST_SUCCESS'
export const NUMBER_SERIES_LIST_FAILURE = 'NUMBER_SERIES_LIST_FAILURE'
// voucher type number series LIST  --------
export const NUMBER_SERIES_SINGLE_LIST_REQUEST = 'NUMBER_SERIES_SINGLE_LIST_REQUEST'
export const NUMBER_SERIES_SINGLE_LIST_SUCCESS = 'NUMBER_SERIES_SINGLE_LIST_SUCCESS'
export const NUMBER_SERIES_SINGLE_LIST_FAILURE = 'NUMBER_SERIES_SINGLE_LIST_FAILURE'
// voucher type number series LIST  --------
export const NUMBER_SERIES_UPDATE_REQUEST = 'NUMBER_SERIES_UPDATE_REQUEST'
export const NUMBER_SERIES_UPDATE_SUCCESS = 'NUMBER_SERIES_UPDATE_SUCCESS'
export const NUMBER_SERIES_UPDATE_FAILURE = 'NUMBER_SERIES_UPDATE_FAILURE'
// godown create ------------------------------
export const GODOWN_CREATE_REQUEST = 'GODOWN_CREATE_REQUEST'
export const GODOWN_CREATE_SUCCESS = 'GODOWN_CREATE_SUCCESS'
export const GODOWN_CREATE_FAILURE = 'GODOWN_CREATE_FAILURE'
// godown create ------------------------------
export const GODOWN_UPDATE_REQUEST = 'GODOWN_UPDATE_REQUEST'
export const GODOWN_UPDATE_SUCCESS = 'GODOWN_UPDATE_SUCCESS'
export const GODOWN_UPDATE_FAILURE = 'GODOWN_UPDATE_FAILURE'
// godown create ------------------------------
export const GODOWN_LIST_REQUEST = 'GODOWN_LIST_REQUEST'
export const GODOWN_LIST_SUCCESS = 'GODOWN_LIST_SUCCESS'
export const GODOWN_LIST_FAILURE = 'GODOWN_LIST_FAILURE'
// godown create ------------------------------
export const GODOWN_DELETE_REQUEST = 'GODOWN_DELETE_REQUEST'
export const GODOWN_DELETE_SUCCESS = 'GODOWN_DELETE_SUCCESS'
export const GODOWN_DELETE_FAILURE = 'GODOWN_DELETE_FAILURE'
// godown create ------------------------------
export const GODOWN_SINGLE_DATA_REQUEST = 'GODOWN_SINGLE_DATA_REQUEST'
export const GODOWN_SINGLE_DATA_SUCCESS = 'GODOWN_SINGLE_DATA_SUCCESS'
export const GODOWN_SINGLE_DATA_FAILURE = 'GODOWN_SINGLE_DATA_FAILURE'
// godown primary listing ----------------------------
export const GODOWN_PRIMARY_LIST_REQUEST = 'GODOWN_PRIMARY_LIST_REQUEST'
export const GODOWN_PRIMARY_LIST_SUCCESS = 'GODOWN_PRIMARY_LIST_SUCCESS'
export const GODOWN_PRIMARY_LIST_FAILURE = 'GODOWN_PRIMARY_LIST_FAILURE'
// Listing CONTRA LEDGER --------
export const CONTRA_LEDGER_LIST_REQUEST = 'CONTRA_LEDGER_LIST_REQUEST'
export const CONTRA_LEDGER_LIST_SUCCESS = 'CONTRA_LEDGER_LIST_SUCCESS'
export const CONTRA_LEDGER_LIST_FAILURE = 'CONTRA_LEDGER_LIST_FAILURE'
// Listing serial number --------
export const SERIAL_NUMBER_REQUEST = 'SERIAL_NUMBER_REQUEST'
export const SERIAL_NUMBER_SUCCESS = 'SERIAL_NUMBER_SUCCESS'
export const SERIAL_NUMBER_FAILURE = 'SERIAL_NUMBER_FAILURE'
// Contra Voucher entry Create ---------
export const CONTRA_VOUCHER_ENTRY_CREATE_REQUEST = 'CONTRA_VOUCHER_ENTRY_CREATE_REQUEST'
export const CONTRA_VOUCHER_ENTRY_CREATE_SUCCESS = 'CONTRA_VOUCHER_ENTRY_CREATE_SUCCESS'
export const CONTRA_VOUCHER_ENTRY_CREATE_FAILURE = 'CONTRA_VOUCHER_ENTRY_CREATE_FAILURE'
// Contra Voucher entry List ---------
export const CONTRA_VOUCHER_ENTRY_LIST_REQUEST = 'CONTRA_VOUCHER_ENTRY_LIST_REQUEST'
export const CONTRA_VOUCHER_ENTRY_LIST_SUCCESS = 'CONTRA_VOUCHER_ENTRY_LIST_SUCCESS'
export const CONTRA_VOUCHER_ENTRY_LIST_FAILURE = 'CONTRA_VOUCHER_ENTRY_LIST_FAILURE'
// Contra Voucher entry List ---------
export const CONTRA_VOUCHER_ENTRY_SINGLE_REQUEST = 'CONTRA_VOUCHER_ENTRY_SINGLE_REQUEST'
export const CONTRA_VOUCHER_ENTRY_SINGLE_SUCCESS = 'CONTRA_VOUCHER_ENTRY_SINGLE_SUCCESS'
export const CONTRA_VOUCHER_ENTRY_SINGLE_FAILURE = 'CONTRA_VOUCHER_ENTRY_SINGLE_FAILURE'
// Contra Voucher entry List ---------
export const CONTRA_VOUCHER_ENTRY_UPDATE_REQUEST = 'CONTRA_VOUCHER_ENTRY_UPDATE_REQUEST'
export const CONTRA_VOUCHER_ENTRY_UPDATE_SUCCESS = 'CONTRA_VOUCHER_ENTRY_UPDATE_SUCCESS'
export const CONTRA_VOUCHER_ENTRY_UPDATE_FAILURE = 'CONTRA_VOUCHER_ENTRY_UPDATE_FAILURE'
// Contra Voucher entry List ---------
export const VOUCHER_ENTRY_DELETE_REQUEST = 'VOUCHER_ENTRY_DELETE_REQUEST'
export const VOUCHER_ENTRY_DELETE_SUCCESS = 'VOUCHER_ENTRY_DELETE_SUCCESS'
export const VOUCHER_ENTRY_DELETE_FAILURE = 'VOUCHER_ENTRY_DELETE_FAILURE'
// voucher entry voucher type default set --
export const VOUCHER_TYPE_SAVE = 'VOUCHER_TYPE_SAVE';
// ledger book ==========================
export const LEDGER_BOOK_REQUEST = 'LEDGER_BOOK_REQUEST'
export const LEDGER_BOOK_SUCCESS = 'LEDGER_BOOK_SUCCESS'
export const LEDGER_BOOK_FAILURE = 'LEDGER_BOOK_FAILURE'
// ledger posting ==========================
export const LEDGER_POSTING_LIST_REQUEST = 'LEDGER_POSTING_LIST_REQUEST'
export const LEDGER_POSTING_LIST_SUCCESS = 'LEDGER_POSTING_LIST_SUCCESS'
export const LEDGER_POSTING_LIST_FAILURE = 'LEDGER_POSTING_LIST_FAILURE'
// current period api ===================
export const CURRENT_PERIOD_REQUEST = 'CURRENT_PERIOD_REQUEST'
export const CURRENT_PERIOD_SUCCESS = 'CURRENT_PERIOD_SUCCESS'
export const CURRENT_PERIOD_FAILURE = 'CURRENT_PERIOD_FAILURE'
// CURRENT PERIOD UPDATE API ==========================
export const CURRENT_PERIOD_UPDATE_REQUEST = 'CURRENT_PERIOD_UPDATE_REQUEST';
export const CURRENT_PERIOD_UPDATE_SUCCESS = 'CURRENT_PERIOD_UPDATE_SUCCESS';
export const CURRENT_PERIOD_UPDATE_FAILURE = 'CURRENT_PERIOD_UPDATE_FAILURE';
// current period with redux ================================
export const CURRENT_DATE_SAVE = 'CURRENT_DATE_SAVE';
export const CURRENT_PERIOD_SAVE = 'CURRENT_PERIOD_SAVE';
// Stock summary -------------------
export const STOCK_SUMMARY_LIST_REQUEST = 'STOCK_SUMMARY_LIST_REQUEST'
export const STOCK_SUMMARY_LIST_SUCCESS = 'STOCK_SUMMARY_LIST_SUCCESS'
export const STOCK_SUMMARY_LIST_FAILURE = 'STOCK_SUMMARY_LIST_FAILURE'
// Stock summary -------------------
export const STOCK_SUMMARY_ITEM_VOUCHER_REQUEST = 'STOCK_SUMMARY_ITEM_VOUCHER_REQUEST'
export const STOCK_SUMMARY_ITEM_VOUCHER_SUCCESS = 'STOCK_SUMMARY_ITEM_VOUCHER_SUCCESS'
export const STOCK_SUMMARY_ITEM_VOUCHER_FAILURE = 'STOCK_SUMMARY_ITEM_VOUCHER_FAILURE'
// Stock summary godown filter -------------------
export const STOCK_SUMMARY_GODOWN_FILTER_LIST_REQUEST = 'STOCK_SUMMARY_GODOWN_FILTER_LIST_REQUEST'
export const STOCK_SUMMARY_GODOWN_FILTER_LIST_SUCCESS = 'STOCK_SUMMARY_GODOWN_FILTER_LIST_SUCCESS'
export const STOCK_SUMMARY_GODOWN_FILTER_LIST_FAILURE = 'STOCK_SUMMARY_GODOWN_FILTER_LIST_FAILURE'
// Max Entry of Date 
export const MAX_DATE_OF_ENTRY_REQUEST = 'MAX_DATE_OF_ENTRY_REQUEST'
export const MAX_DATE_OF_ENTRY_SUCCESS = 'MAX_DATE_OF_ENTRY_SUCCESS'
export const MAX_DATE_OF_ENTRY_FAILURE = 'MAX_DATE_OF_ENTRY_FAILURE'
// Filter data when one or more voucher type name exist 
export const FILTER_VOUCHER_TYPE_DATA = 'FILTER_VOUCHER_TYPE_DATA'
// API CONDITIONAL
export const FILTER_API_CALL = 'FILTER_API_CALL'
// Profit and loss 
export const PROFIT_AND_LOSS_REQUEST = 'PROFIT_AND_LOSS_REQUEST'
export const PROFIT_AND_LOSS_SUCCESS = 'PROFIT_AND_LOSS_SUCCESS'
export const PROFIT_AND_LOSS_FAILURE = 'PROFIT_AND_LOSS_FAILURE'
// Group Summary
export const GROUP_SUMMARY_REQUEST = 'GROUP_SUMMARY_REQUEST'
export const GROUP_SUMMARY_SUCCESS = 'GROUP_SUMMARY_SUCCESS'
export const GROUP_SUMMARY_FAILURE = 'GROUP_SUMMARY_FAILURE'
// Balance sheet
export const BALANCE_SHEET_REQUEST = 'BALANCE_SHEET_REQUEST'
export const BALANCE_SHEET_SUCCESS = 'BALANCE_SHEET_SUCCESS'
export const BALANCE_SHEET_FAILURE = 'BALANCE_SHEET_FAILURE'

export const RESET_DATA = 'RESET_DATA';


export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';



