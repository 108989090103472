import { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

export const useDelet = ({
    prepareFormData,
    submitForm,
    shortcuts = [],
    closeModal,
    state,
}) => {
    const [loadingDelete, setLoadingDelete] = useState(false)
    // Memoized submit handler to prevent re-creation on every render
    const delteItem = useCallback(
        async (e) => {
            e?.preventDefault();
            try {
                setLoadingDelete(true)
                const formData = prepareFormData();
                const response = await submitForm(formData);

                if (response?.status) {
                    toast.success(response?.message || 'Operation successful!', {
                        position: toast.POSITION.TOP_CENTER,
                    });

                } else {
                    toast.warn(response?.message || 'Operation failed!', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            } catch (error) {
                console.error(error);
                toast.error(error?.message || 'An error occurred!', {
                    position: toast.POSITION.TOP_CENTER,
                });

            } finally {
                closeModal();
                setLoadingDelete(false)
            }
        },
        [prepareFormData, submitForm, closeModal]
    );

    useEffect(() => {
        const handleKeyDown = (e) => {
            shortcuts.forEach((shortcut) => {
                if (
                    e.key.toLowerCase() === shortcut.key.toLowerCase() &&
                    e.ctrlKey === !!shortcut.ctrlKey &&
                    e.altKey === !!shortcut.altKey
                ) {
                    e.preventDefault();
                    if (state) {
                        shortcut.action();
                    }
                }
            });

            if (state) {
                if (e.key.toLowerCase() === 'y') {
                    e.preventDefault();
                    delteItem();
                } else if (e.key.toLowerCase() === 'n') {
                    e.preventDefault();
                    closeModal();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [shortcuts, state, delteItem, closeModal]);

    return { delteItem,loadingDelete };
};
