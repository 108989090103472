import {
    // SIGN IN TYPE
    SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
    SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
    GET_Company_LIST_REQUEST, GET_Company_LIST_SUCCESS, GET_Comapny_LIST_FAILURE,
    GET_Company_Alter_REQUEST, GET_Company_Alter_SUCCESS, GET_Comapny_Alter_FAILURE,
    LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE,
    // Group Action Type 
    GROUP_LIST_REQUEST, GROUP_LIST_SUCCESS, GROUP_LIST_FAILURE,
    GROUP_CREATE_REQUEST, GROUP_CREATE_SUCCESS, GROUP_CREATE_FAILURE,
    GROUP_UPDATE_REQUEST, GROUP_UPDATE_SUCCESS, GROUP_UPDATE_FAILURE,
    GROUP_DELETE_REQUEST, GROUP_DELETE_SUCCESS, GROUP_DELETE_FAILURE,
    GROUP_SINGLE_REQUEST, GROUP_SINGLE_SUCCESS, GROUP_SINGLE_FAILURE,
    GROUP_LIST_UNDER_REQUEST, GROUP_LIST_UNDER_SUCCESS, GROUP_LIST_UNDER_FAILURE,
    // Ledger Action Type 
    LEDGER_CREATE_REQUEST, LEDGER_CREATE_SUCCESS, LEDGER_CREATE_FAILURE,
    LEDGER_SINGLE_DATA_REQUEST, LEDGER_SINGLE_DATA_SUCCESS, LEDGER_SINGLE_DATA_FAILURE,
    LEDGER_UPDATE_REQUEST, LEDGER_UPDATE_SUCCESS, LEDGER_UPDATE_FAILURE,
    LEDGER_DELETE_REQUEST, LEDGER_DELETE_SUCCESS, LEDGER_DELETE_FAILURE,
    LEDGER_COMMON_LIST_REQUEST, LEDGER_COMMON_LIST_SUCCESS, LEDGER_COMMON_LIST_FAILURE,
    LEDGER_LIST_REQUEST, LEDGER_LIST_SUCCESS, LEDGER_LIST_FAILURE,
    // UNIT Action Type 
    UNIT_CREATE_REQUEST, UNIT_CREATE_SUCCESS, UNIT_CREATE_FAILURE,
    UNIT_LIST_REQUEST, UNIT_LIST_SUCCESS, UNIT_LIST_FAILURE,
    UNIT_DELETE_REQUEST, UNIT_DELETE_SUCCESS, UNIT_DELETE_FAILURE,
    UNIT_UPDATE_REQUEST, UNIT_UPDATE_SUCCESS, UNIT_UPDATE_FAILURE,
    UNIT_SINGLE_DATA_REQUEST, UNIT_SINGLE_DATA_SUCCESS, UNIT_SINGLE_DATA_FAILURE,
    // UQC CODE Action Type  
    UQC_CODE_LIST_REQUEST, UQC_CODE_LIST_SUCCESS, UQC_CODE_LIST_FAILURE,
    UQC_CODE_CREATE_REQUEST, UQC_CODE_CREATE_SUCCESS, UQC_CODE_CREATE_FAILURE,
    UQC_CODE_DELETE_REQUEST, UQC_CODE_DELETE_SUCCESS, UQC_CODE_DELETE_FAILURE,
    UQC_CODE_UPDATE_REQUEST, UQC_CODE_UPDATE_SUCCESS, UQC_CODE_UPDATE_FAILURE,
    UQC_CODE_SINGLE_DATA_REQUEST, UQC_CODE_SINGLE_DATA_SUCCESS, UQC_CODE_SINGLE_DATA_FAILURE,
    // STOCK CATEGORY Action Type 
    STOCK_CATEGORY_CREATE_REQUEST, STOCK_CATEGORY_CREATE_SUCCESS, STOCK_CATEGORY_CREATE_FAILURE,
    STOCK_CATEGORY_NAME_VALIDATE_REQUEST, STOCK_CATEGORY_NAME_VALIDATE_SUCCESS, STOCK_CATEGORY_NAME_VALIDATE_FAILURE,
    STOCK_CATEGORY_UPDATE_REQUEST, STOCK_CATEGORY_UPDATE_SUCCESS, STOCK_CATEGORY_UPDATE_FAILURE,
    STOCK_CATEGORY_LIST_REQUEST, STOCK_CATEGORY_LIST_SUCCESS, STOCK_CATEGORY_LIST_FAILURE,
    STOCK_CATEGORY_UNDER_LIST_REQUEST, STOCK_CATEGORY_UNDER_LIST_SUCCESS, STOCK_CATEGORY_UNDER_LIST_FAILURE,
    STOCK_CATEGORY_DELETE_REQUEST, STOCK_CATEGORY_DELETE_SUCCESS, STOCK_CATEGORY_DELETE_FAILURE,
    STOCK_CATEGORY_SINGLE_REQUEST, STOCK_CATEGORY_SINGLE_SUCCESS, STOCK_CATEGORY_SINGLE_FAILURE,
    // STOCK GROUP Action Type 
    STOCK_GROUP_CREATE_REQUEST, STOCK_GROUP_CREATE_SUCCESS, STOCK_GROUP_CREATE_FAILURE,
    STOCK_GROUP_UPDATE_REQUEST, STOCK_GROUP_UPDATE_SUCCESS, STOCK_GROUP_UPDATE_FAILURE,
    STOCK_GROUP_LIST_REQUEST, STOCK_GROUP_LIST_SUCCESS, STOCK_GROUP_LIST_FAILURE,
    STOCK_GROUP_UNDER_LIST_REQUEST, STOCK_GROUP_UNDER_LIST_SUCCESS, STOCK_GROUP_UNDER_LIST_FAILURE,
    STOCK_GROUP_DELETE_REQUEST, STOCK_GROUP_DELETE_SUCCESS, STOCK_GROUP_DELETE_FAILURE,
    STOCK_GROUP_SINGLE_DATA_REQUEST, STOCK_GROUP_SINGLE_DATA_SUCCESS, STOCK_GROUP_SINGLE_DATA_FAILURE,
    // STOCK COMPANY Action Type
    STOCK_COMPANY_CREATE_REQUEST, STOCK_COMPANY_CREATE_SUCCESS, STOCK_COMPANY_CREATE_FAILURE,
    STOCK_COMPANY_UPDATE_REQUEST, STOCK_COMPANY_UPDATE_SUCCESS, STOCK_COMPANY_UPDATE_FAILURE,
    STOCK_COMPANY_LIST_REQUEST, STOCK_COMPANY_LIST_SUCCESS, STOCK_COMPANY_LIST_FAILURE,
    STOCK_COMPANY_DELETE_REQUEST, STOCK_COMPANY_DELETE_SUCCESS, STOCK_COMPANY_DELETE_FAILURE,
    STOCK_COMPANY_SINGLE_DATA_REQUEST, STOCK_COMPANY_SINGLE_DATA_SUCCESS, STOCK_COMPANY_SINGLE_DATA_FAILURE,
    // STOCK ITEM Action Type 
    STOCK_ITEM_CREATE_REQUEST, STOCK_ITEM_CREATE_SUCCESS, STOCK_ITEM_CREATE_FAILURE,
    STOCK_ITEM_UPDATE_REQUEST, STOCK_ITEM_UPDATE_SUCCESS, STOCK_ITEM_UPDATE_FAILURE,
    STOCK_ITEM_LIST_REQUEST, STOCK_ITEM_LIST_SUCCESS, STOCK_ITEM_LIST_FAILURE,
    STOCK_ITEM_DELETE_REQUEST, STOCK_ITEM_DELETE_SUCCESS, STOCK_ITEM_DELETE_FAILURE,
    STOCK_ITEM_SINGLE_DATA_REQUEST, STOCK_ITEM_SINGLE_DATA_SUCCESS, STOCK_ITEM_SINGLE_DATA_FAILURE,
    STOCK_ITEM_LIST_TO_DROPDOWN_REQUEST, STOCK_ITEM_LIST_TO_DROPDOWN_SUCCESS, STOCK_ITEM_LIST_TO_DROPDOWN_FAILURE,
    // VOUCHER TYPE Action Type 
    VOUCHER_TYPE_CREATE_REQUEST, VOUCHER_TYPE_CREATE_SUCCESS, VOUCHER_TYPE_CREATE_FAILURE,
    VOUCHER_TYPE_UPDATE_REQUEST, VOUCHER_TYPE_UPDATE_SUCCESS, VOUCHER_TYPE_UPDATE_FAILURE,
    VOUCHER_TYPE_LIST_REQUEST, VOUCHER_TYPE_LIST_SUCCESS, VOUCHER_TYPE_LIST_FAILURE,
    VOUCHER_TYPE_DELETE_REQUEST, VOUCHER_TYPE_DELETE_SUCCESS, VOUCHER_TYPE_DELETE_FAILURE,
    VOUCHER_TYPE_SINGLE_DATA_REQUEST, VOUCHER_TYPE_SINGLE_DATA_SUCCESS, VOUCHER_TYPE_SINGLE_DATA_FAILURE,
    VOUCHER_TYPE_DEFAULT_LIST_REQUEST, VOUCHER_TYPE_DEFAULT_LIST_SUCCSESS, VOUCHER_TYPE_DEFAULT_LIST_FAILURE,
    VOUCHER_TYPE_NAME_CHECK_REQUEST, VOUCHER_TYPE_NAME_CHECK_SUCCESS, VOUCHER_TYPE_NAME_CHECK_FAILURE,
    // Number Series For Voucher Type Action Type 
    NUMBER_SERIES_CREATE_REQUEST, NUMBER_SERIES_CREATE_SUCCESS, NUMBER_SERIES_CREATE_FAILURE,
    NUMBER_SERIES_LIST_REQUEST, NUMBER_SERIES_LIST_SUCCESS, NUMBER_SERIES_LIST_FAILURE,
    NUMBER_SERIES_SINGLE_LIST_REQUEST, NUMBER_SERIES_SINGLE_LIST_SUCCESS, NUMBER_SERIES_SINGLE_LIST_FAILURE,
    NUMBER_SERIES_UPDATE_REQUEST, NUMBER_SERIES_UPDATE_SUCCESS, NUMBER_SERIES_UPDATE_FAILURE,
    // GODOWN Action Type 
    GODOWN_CREATE_REQUEST, GODOWN_CREATE_SUCCESS, GODOWN_CREATE_FAILURE,
    GODOWN_UPDATE_REQUEST, GODOWN_UPDATE_SUCCESS, GODOWN_UPDATE_FAILURE,
    GODOWN_LIST_REQUEST, GODOWN_LIST_SUCCESS, GODOWN_LIST_FAILURE,
    GODOWN_DELETE_REQUEST, GODOWN_DELETE_SUCCESS, GODOWN_DELETE_FAILURE,
    GODOWN_SINGLE_DATA_REQUEST, GODOWN_SINGLE_DATA_SUCCESS, GODOWN_SINGLE_DATA_FAILURE,
    GODOWN_PRIMARY_LIST_REQUEST, GODOWN_PRIMARY_LIST_SUCCESS, GODOWN_PRIMARY_LIST_FAILURE,
    //  VOUCHER ENTRY's Action Type 
    CONTRA_LEDGER_LIST_REQUEST, CONTRA_LEDGER_LIST_SUCCESS, CONTRA_LEDGER_LIST_FAILURE,
    CONTRA_VOUCHER_ENTRY_CREATE_REQUEST, CONTRA_VOUCHER_ENTRY_CREATE_SUCCESS, CONTRA_VOUCHER_ENTRY_CREATE_FAILURE,
    CONTRA_VOUCHER_ENTRY_LIST_REQUEST, CONTRA_VOUCHER_ENTRY_LIST_SUCCESS, CONTRA_VOUCHER_ENTRY_LIST_FAILURE,
    CONTRA_VOUCHER_ENTRY_SINGLE_REQUEST, CONTRA_VOUCHER_ENTRY_SINGLE_SUCCESS, CONTRA_VOUCHER_ENTRY_SINGLE_FAILURE,
    CONTRA_VOUCHER_ENTRY_UPDATE_REQUEST, CONTRA_VOUCHER_ENTRY_UPDATE_SUCCESS, CONTRA_VOUCHER_ENTRY_UPDATE_FAILURE,
    SERIAL_NUMBER_REQUEST, SERIAL_NUMBER_SUCCESS, SERIAL_NUMBER_FAILURE,
    VOUCHER_TYPE_SAVE,
    VOUCHER_ENTRY_DELETE_REQUEST, VOUCHER_ENTRY_DELETE_SUCCESS, VOUCHER_ENTRY_DELETE_FAILURE,
    // lEDGER BOOK LISTING Action Type 
    LEDGER_BOOK_REQUEST, LEDGER_BOOK_SUCCESS, LEDGER_BOOK_FAILURE,
    // LEDGER POSTING LIST Action Type
    LEDGER_POSTING_LIST_REQUEST, LEDGER_POSTING_LIST_SUCCESS, LEDGER_POSTING_LIST_FAILURE,
    // CURRENT PERIOD Type 
    CURRENT_DATE_SAVE, CURRENT_PERIOD_SAVE,
    // STOCK SUMMARY LIST -------------
    STOCK_SUMMARY_LIST_REQUEST, STOCK_SUMMARY_LIST_SUCCESS, STOCK_SUMMARY_LIST_FAILURE,
    STOCK_SUMMARY_GODOWN_FILTER_LIST_REQUEST, STOCK_SUMMARY_GODOWN_FILTER_LIST_SUCCESS, STOCK_SUMMARY_GODOWN_FILTER_LIST_FAILURE,
    STOCK_SUMMARY_ITEM_VOUCHER_REQUEST, STOCK_SUMMARY_ITEM_VOUCHER_SUCCESS, STOCK_SUMMARY_ITEM_VOUCHER_FAILURE,

    // Max Date of Entry -------------
    MAX_DATE_OF_ENTRY_REQUEST, MAX_DATE_OF_ENTRY_SUCCESS, MAX_DATE_OF_ENTRY_FAILURE,
    // Filter Voucher Type 
    FILTER_VOUCHER_TYPE_DATA,
    FILTER_API_CALL,
    PROFIT_AND_LOSS_REQUEST,
    PROFIT_AND_LOSS_SUCCESS,
    PROFIT_AND_LOSS_FAILURE,
    GROUP_SUMMARY_REQUEST,
    GROUP_SUMMARY_SUCCESS,
    GROUP_SUMMARY_FAILURE,
    RESET_DATA,
    // Balance Sheet
    BALANCE_SHEET_REQUEST, BALANCE_SHEET_SUCCESS, BALANCE_SHEET_FAILURE,
    OPEN_MODAL,
    CLOSE_MODAL,
    CLOSE_ALL_MODALS

} from './action-types';
import axiosInstance from '../../axios/axiosinstance';
// for user signIn
export function signIn(data) {
    return async (dispatch) => {
        dispatch({ type: SIGN_IN_REQUEST });
        try {
            var response = await axiosInstance.post(`login`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: SIGN_IN_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SIGN_IN_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SIGN_IN_FAILURE, payload: error });
            return Promise.reject(error)
        }
    };
}
// for user signUp
export function signUp(data) {
    return async (dispatch) => {
        dispatch({ type: SIGN_UP_REQUEST });
        try {
            var response = await axiosInstance.post(`company_registration`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: SIGN_UP_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SIGN_UP_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SIGN_UP_FAILURE, payload: error });
            return Promise.reject(error)
        }
    };
}
// logout 
export function logOut() {
    return async dispatch => {
        dispatch({ type: LOGOUT_REQUEST, payload: 'Logout_Request' })
        try {
            dispatch({ type: LOGOUT_SUCCESS, payload: 'Logout_Success' })
            dispatch({ type: RESET_DATA });
        }
        catch (e) {
            dispatch({ type: LOGOUT_FAILURE, payload: 'Logout_Failure' })
        }
    }
}
// for Get Company Alter
export function getCompanyAlter(data) {
    return async (dispatch) => {
        dispatch({ type: GET_Company_Alter_REQUEST });
        try {
            var response = await axiosInstance.post(`updateduserprofile`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GET_Company_Alter_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GET_Comapny_Alter_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GET_Comapny_Alter_FAILURE, payload: error });
            return Promise.reject(error);
        }
    };
}
// for Get Company List
export function getCompanyList() {
    return async (dispatch) => {
        dispatch({ type: GET_Company_LIST_REQUEST });
        try {
            var response = await axiosInstance.get(`company-list`);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GET_Company_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GET_Comapny_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GET_Comapny_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    };
}
// FOR GROUP LIST
export function groupList(data) {
    return async (dispatch) => {
        dispatch({ type: GROUP_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`group-list`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GROUP_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GROUP_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GROUP_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function groupListCreate(data) {
    return async (dispatch) => {
        dispatch({ type: GROUP_LIST_UNDER_REQUEST });
        try {
            var response = await axiosInstance.post(`all-group-list`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GROUP_LIST_UNDER_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GROUP_LIST_UNDER_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GROUP_LIST_UNDER_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function groupCreate(data) {
    return async (dispatch) => {
        dispatch({ type: GROUP_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`create_group`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GROUP_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GROUP_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GROUP_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function groupUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: GROUP_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update_group`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GROUP_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GROUP_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GROUP_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function groupDelete(data) {
    return async (dispatch) => {
        dispatch({ type: GROUP_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_group`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GROUP_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GROUP_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GROUP_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function groupSingle(id) {
    return async (dispatch) => {
        dispatch({ type: GROUP_SINGLE_REQUEST });
        try {
            var response = await axiosInstance.get(`single_group/${id}`);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GROUP_SINGLE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GROUP_SINGLE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GROUP_SINGLE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}

// for ledger create ==================================================================================================================================================
export function ledgerCreate(data) {
    return async (dispatch) => {
        dispatch({ type: LEDGER_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`ledger_creation`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: LEDGER_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: LEDGER_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: LEDGER_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}

// for ledger create ==================================================================================================================================================
export function ledgerSingleData(data) {
    return async (dispatch) => {
        dispatch({ type: LEDGER_SINGLE_DATA_REQUEST });
        try {
            var response = await axiosInstance.post(`singleLedger`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: LEDGER_SINGLE_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: LEDGER_SINGLE_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: LEDGER_SINGLE_DATA_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function ledgerUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: LEDGER_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update_ledger`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: LEDGER_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: LEDGER_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: LEDGER_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function ledgerDelete(data) {
    return async (dispatch) => {
        dispatch({ type: LEDGER_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_ledger`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: LEDGER_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: LEDGER_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: LEDGER_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function ledgerCommonList(data) {
    return async (dispatch) => {
        dispatch({ type: LEDGER_COMMON_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`common-ledger-List`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: LEDGER_COMMON_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: LEDGER_COMMON_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: LEDGER_COMMON_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function ledgerList(data) {
    return async (dispatch) => {
        dispatch({ type: LEDGER_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`ledgerList`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: LEDGER_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: LEDGER_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: LEDGER_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function unitsCreate(data) {
    return async (dispatch) => {
        dispatch({ type: UNIT_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`unit_create`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: UNIT_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UNIT_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: UNIT_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function unitsListing(data) {
    return async (dispatch) => {
        dispatch({ type: UNIT_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`unit_List`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                console.log("this is response", responseJson)
                dispatch({ type: UNIT_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UNIT_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            console.log('error odf unit', error)
            dispatch({ type: UNIT_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function unitsUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: UNIT_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update_unit`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: UNIT_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UNIT_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: UNIT_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function unitsDelete(data) {
    return async (dispatch) => {
        dispatch({ type: UNIT_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_units`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: UNIT_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UNIT_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: UNIT_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function unitsSingleData(data) {
    return async (dispatch) => {
        dispatch({ type: UNIT_SINGLE_DATA_REQUEST });
        try {
            var response = await axiosInstance.post(`single_unit_record`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: UNIT_SINGLE_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UNIT_SINGLE_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: UNIT_SINGLE_DATA_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function uqcListing(data) {
    return async (dispatch) => {
        dispatch({ type: UQC_CODE_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`uqc_List`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: UQC_CODE_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UQC_CODE_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            console.log(error)
            dispatch({ type: UQC_CODE_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function uqcCreate(data) {
    return async (dispatch) => {
        dispatch({ type: UQC_CODE_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`uqc_create`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                console.log(responseJson)
                dispatch({ type: UQC_CODE_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UQC_CODE_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            console.log(error)
            dispatch({ type: UQC_CODE_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function uqcDelete(data) {
    return async (dispatch) => {
        dispatch({ type: UQC_CODE_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_uqc`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: UQC_CODE_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UQC_CODE_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: UQC_CODE_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function uqcUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: UQC_CODE_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update_uqc`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: UQC_CODE_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UQC_CODE_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: UQC_CODE_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function uqcSingleData(data) {
    return async (dispatch) => {
        dispatch({ type: UQC_CODE_SINGLE_DATA_REQUEST });
        try {
            var response = await axiosInstance.post(`single_uqc_record`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: UQC_CODE_SINGLE_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UQC_CODE_SINGLE_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: UQC_CODE_SINGLE_DATA_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCategoryCreate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_CATEGORY_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`category_create`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_CATEGORY_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_CATEGORY_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_CATEGORY_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCategoryNameValidate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_CATEGORY_NAME_VALIDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`check_duplicate_category`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_CATEGORY_NAME_VALIDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_CATEGORY_NAME_VALIDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_CATEGORY_NAME_VALIDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCategoryUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_CATEGORY_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update_category`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_CATEGORY_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_CATEGORY_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_CATEGORY_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCategoryList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_CATEGORY_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`category_List`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_CATEGORY_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_CATEGORY_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_CATEGORY_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCategoryPrimaryList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_CATEGORY_UNDER_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`allCategory_List`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_CATEGORY_UNDER_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_CATEGORY_UNDER_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_CATEGORY_UNDER_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}

export function stockCategoryDelete(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_CATEGORY_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_category`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_CATEGORY_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_CATEGORY_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_CATEGORY_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCategorySingleData(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_CATEGORY_SINGLE_REQUEST });
        try {
            var response = await axiosInstance.post(`single_category_record`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_CATEGORY_SINGLE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_CATEGORY_SINGLE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_CATEGORY_SINGLE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockGroupCreate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_GROUP_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`create-stock-group`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_GROUP_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_GROUP_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_GROUP_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockGroupUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_GROUP_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update-stock-group`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_GROUP_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_GROUP_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_GROUP_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockGroupPrimaryList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_GROUP_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`primary-stock-group`, data);
            var responseJson = response.data;
            console.log("stock primary data----", responseJson)
            if (responseJson?.status) {
                dispatch({ type: STOCK_GROUP_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_GROUP_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_GROUP_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockGroupList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_GROUP_UNDER_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-group`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_GROUP_UNDER_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_GROUP_UNDER_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_GROUP_UNDER_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockGroupDelete(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_GROUP_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_stock_group`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_GROUP_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_GROUP_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_GROUP_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockGroupSingleData(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_GROUP_SINGLE_DATA_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-group-details`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_GROUP_SINGLE_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_GROUP_SINGLE_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_GROUP_SINGLE_DATA_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCompanyCreate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_COMPANY_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`create-stock-company`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_COMPANY_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_COMPANY_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_COMPANY_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCompanyUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_COMPANY_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update-stock-company`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_COMPANY_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_COMPANY_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_COMPANY_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCompanyList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_COMPANY_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-company`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_COMPANY_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_COMPANY_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_COMPANY_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCompanyDelete(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_COMPANY_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_stock_company`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_COMPANY_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_COMPANY_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_COMPANY_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockCompanySingleData(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_COMPANY_SINGLE_DATA_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-company-details`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_COMPANY_SINGLE_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_COMPANY_SINGLE_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_COMPANY_SINGLE_DATA_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}

export function stockItemCreate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_ITEM_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`create-stock-item`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_ITEM_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_ITEM_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_ITEM_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockItemUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_ITEM_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update-stock-item`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_ITEM_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_ITEM_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_ITEM_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockItemList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_ITEM_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-item`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_ITEM_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_ITEM_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_ITEM_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockItemListToDropdown(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_ITEM_LIST_TO_DROPDOWN_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-item-qty`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_ITEM_LIST_TO_DROPDOWN_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_ITEM_LIST_TO_DROPDOWN_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_ITEM_LIST_TO_DROPDOWN_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockItemDelete(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_ITEM_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_stock_item`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_ITEM_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_ITEM_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_ITEM_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockItemSingleData(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_ITEM_SINGLE_DATA_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-item-details`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_ITEM_SINGLE_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_ITEM_SINGLE_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_ITEM_SINGLE_DATA_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function voucherTypeCreate(data) {
    return async (dispatch) => {
        dispatch({ type: VOUCHER_TYPE_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`create-voucher-type`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: VOUCHER_TYPE_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: VOUCHER_TYPE_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: VOUCHER_TYPE_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function voucherTypeUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: VOUCHER_TYPE_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update-voucher-type`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: VOUCHER_TYPE_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: VOUCHER_TYPE_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: VOUCHER_TYPE_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function voucherTypeList(data) {
    return async (dispatch) => {
        dispatch({ type: VOUCHER_TYPE_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`voucher-type-list`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: VOUCHER_TYPE_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: VOUCHER_TYPE_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            console.log("voucher type list --", error)
            dispatch({ type: VOUCHER_TYPE_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function voucherTypeDefaultList(data) {
    return async (dispatch) => {
        dispatch({ type: VOUCHER_TYPE_DEFAULT_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`primary-voucher-type-list`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: VOUCHER_TYPE_DEFAULT_LIST_SUCCSESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: VOUCHER_TYPE_DEFAULT_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            console.log("voucher type list --", error)
            dispatch({ type: VOUCHER_TYPE_DEFAULT_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function voucherTypeDelete(data) {
    return async (dispatch) => {
        dispatch({ type: VOUCHER_TYPE_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_voucher_type`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: VOUCHER_TYPE_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: VOUCHER_TYPE_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: VOUCHER_TYPE_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function voucherTypeSingleData(data) {
    return async (dispatch) => {
        dispatch({ type: VOUCHER_TYPE_SINGLE_DATA_REQUEST });
        try {
            var response = await axiosInstance.post(`voucher-type-details`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: VOUCHER_TYPE_SINGLE_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: VOUCHER_TYPE_SINGLE_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: VOUCHER_TYPE_SINGLE_DATA_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// voucher type name check ----------------------------
export function voucherTypeNameCheck(data) {
    return async (dispatch) => {
        dispatch({ type: VOUCHER_TYPE_NAME_CHECK_REQUEST });
        try {
            var response = await axiosInstance.post(`check-voucher-type`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: VOUCHER_TYPE_NAME_CHECK_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: VOUCHER_TYPE_NAME_CHECK_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: VOUCHER_TYPE_NAME_CHECK_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// number series create =======================================
export function numberSeriesCreate(data) {
    return async (dispatch) => {
        dispatch({ type: NUMBER_SERIES_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`create-voucher-number-series`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: NUMBER_SERIES_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: NUMBER_SERIES_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: NUMBER_SERIES_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// voucher type number list ============================================
export function numberSeriesList(data) {
    return async (dispatch) => {
        dispatch({ type: NUMBER_SERIES_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`voucher-number-series-list`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: NUMBER_SERIES_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: NUMBER_SERIES_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: NUMBER_SERIES_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function numberSeriesSingleData(data) {
    return async (dispatch) => {
        dispatch({ type: NUMBER_SERIES_SINGLE_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`single_voucher_number_series`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: NUMBER_SERIES_SINGLE_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: NUMBER_SERIES_SINGLE_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: NUMBER_SERIES_SINGLE_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function numberSeriesUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: NUMBER_SERIES_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update_voucher_number_series`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: NUMBER_SERIES_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: NUMBER_SERIES_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: NUMBER_SERIES_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// voucher type number list ============================================
export function godownCreate(data) {
    return async (dispatch) => {
        dispatch({ type: GODOWN_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`godown_create`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GODOWN_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GODOWN_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GODOWN_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// voucher type number list ============================================
export function godownUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: GODOWN_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update_godown`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GODOWN_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GODOWN_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GODOWN_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// voucher type number list ============================================
export function godownList(data) {
    return async (dispatch) => {
        dispatch({ type: GODOWN_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`godown_List`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GODOWN_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GODOWN_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GODOWN_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// voucher type number list ============================================
export function godownDelete(data) {
    return async (dispatch) => {
        dispatch({ type: GODOWN_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_godown`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GODOWN_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GODOWN_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GODOWN_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// voucher type number list ============================================
export function godownSingleData(data) {
    return async (dispatch) => {
        dispatch({ type: GODOWN_SINGLE_DATA_REQUEST });
        try {
            var response = await axiosInstance.post(`single_godown_record`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GODOWN_SINGLE_DATA_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GODOWN_SINGLE_DATA_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GODOWN_SINGLE_DATA_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function godownPrimaryList(data) {
    return async (dispatch) => {
        dispatch({ type: GODOWN_PRIMARY_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`primary_godown_list`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: GODOWN_PRIMARY_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GODOWN_PRIMARY_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GODOWN_PRIMARY_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// CONTRA LEDGER LISTING IN VOUCHER ENTRY==================================
export function contraLedgerListing(data) {
    return async (dispatch) => {
        dispatch({ type: CONTRA_LEDGER_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`ledger_by_groups`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: CONTRA_LEDGER_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: CONTRA_LEDGER_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: CONTRA_LEDGER_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// SERIAL NUMBER IN VOUCHER ENTRY==================================
export function serialNumberList(data) {
    return async (dispatch) => {
        dispatch({ type: SERIAL_NUMBER_REQUEST });
        try {
            var response = await axiosInstance.post(`voucher-type-number-series`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: SERIAL_NUMBER_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SERIAL_NUMBER_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SERIAL_NUMBER_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// CONTRA VOUCHER ENTRY CREATE==================================
export function contraVoucherCreate(data) {
    return async (dispatch) => {
        dispatch({ type: CONTRA_VOUCHER_ENTRY_CREATE_REQUEST });
        try {
            var response = await axiosInstance.post(`create-voucher-entry`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: CONTRA_VOUCHER_ENTRY_CREATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: CONTRA_VOUCHER_ENTRY_CREATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: CONTRA_VOUCHER_ENTRY_CREATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function contraVoucherList(data) {
    return async (dispatch) => {
        dispatch({ type: CONTRA_VOUCHER_ENTRY_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`getVoucherEntry`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: CONTRA_VOUCHER_ENTRY_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: CONTRA_VOUCHER_ENTRY_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: CONTRA_VOUCHER_ENTRY_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function contraVoucherSingle(data) {
    return async (dispatch) => {
        dispatch({ type: CONTRA_VOUCHER_ENTRY_SINGLE_REQUEST });
        try {
            var response = await axiosInstance.post(`single-voucher-entries`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: CONTRA_VOUCHER_ENTRY_SINGLE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: CONTRA_VOUCHER_ENTRY_SINGLE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: CONTRA_VOUCHER_ENTRY_SINGLE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function contraVoucherUpdate(data) {
    return async (dispatch) => {
        dispatch({ type: CONTRA_VOUCHER_ENTRY_UPDATE_REQUEST });
        try {
            var response = await axiosInstance.post(`update-voucher`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: CONTRA_VOUCHER_ENTRY_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: CONTRA_VOUCHER_ENTRY_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: CONTRA_VOUCHER_ENTRY_UPDATE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function voucherEntryDelete(data) {
    return async (dispatch) => {
        dispatch({ type: VOUCHER_ENTRY_DELETE_REQUEST });
        try {
            var response = await axiosInstance.post(`delete_voucher_entry`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: VOUCHER_ENTRY_DELETE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: VOUCHER_ENTRY_DELETE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: VOUCHER_ENTRY_DELETE_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export const storeVoucherEntryData = (data) => {
    return {
        type: VOUCHER_TYPE_SAVE,
        payload: data,
    };
};
export function ledgerBookList(data) {
    return async (dispatch) => {
        dispatch({ type: LEDGER_BOOK_REQUEST });
        try {
            var response = await axiosInstance.post(`ledger_by_company`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: LEDGER_BOOK_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: LEDGER_BOOK_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: LEDGER_BOOK_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function ledgerPostingList(data) {
    return async (dispatch) => {
        dispatch({ type: LEDGER_POSTING_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`ledger_posting_details`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: LEDGER_POSTING_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: LEDGER_POSTING_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: LEDGER_POSTING_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// CURRENT PERIOD REDUX ====================
export const currentDateRedux = (data) => {
    return {
        type: CURRENT_DATE_SAVE,
        payload: data,
    };
};
// CURRENT PERIOD REDUX ====================
export const conditionalApi = (data) => {
    return {
        type: FILTER_API_CALL,
        payload: data,
    };
};

// CURRENT PERIOD REDUX====================
export const currentPeriodRedux = (data) => {
    return {
        type: CURRENT_PERIOD_SAVE,
        payload: data,
    };
};
//-----------------
export function stockSummaryList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_SUMMARY_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-summary`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_SUMMARY_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_SUMMARY_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_SUMMARY_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function stockSummaryItemVoucherList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_SUMMARY_ITEM_VOUCHER_REQUEST });
        try {
            var response = await axiosInstance.post(`stock-item-voucher`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_SUMMARY_ITEM_VOUCHER_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_SUMMARY_ITEM_VOUCHER_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_SUMMARY_ITEM_VOUCHER_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
//-----------------
export function stockSummaryGodownFilterList(data) {
    return async (dispatch) => {
        dispatch({ type: STOCK_SUMMARY_GODOWN_FILTER_LIST_REQUEST });
        try {
            var response = await axiosInstance.post(`filter-stock-item-bylocation`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: STOCK_SUMMARY_GODOWN_FILTER_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: STOCK_SUMMARY_GODOWN_FILTER_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: STOCK_SUMMARY_GODOWN_FILTER_LIST_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function maxDateOfEntry(data) {
    return async (dispatch) => {
        dispatch({ type: MAX_DATE_OF_ENTRY_REQUEST });
        try {
            var response = await axiosInstance.post(`last-voucher-entry`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: MAX_DATE_OF_ENTRY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: MAX_DATE_OF_ENTRY_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: MAX_DATE_OF_ENTRY_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// Filter voucher type data 
export const filterVoucherTypeData = (data) => {
    return {
        type: FILTER_VOUCHER_TYPE_DATA,
        payload: data,
    };
};

export function profitAndLoss(data) {
    return async (dispatch) => {
        dispatch({ type: PROFIT_AND_LOSS_REQUEST });
        try {
            var response = await axiosInstance.post(`profit-loss`, data);
            var responseJson = response.data;
            if (responseJson?.status) {
                dispatch({ type: PROFIT_AND_LOSS_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: PROFIT_AND_LOSS_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: PROFIT_AND_LOSS_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
export function groupSummary(data) {
    return async (dispatch) => {
        dispatch({ type: GROUP_SUMMARY_REQUEST });
        try {
            var response = await axiosInstance.post(`group-summary`, data);
            var responseJson = response;
            if (responseJson?.status) {
                dispatch({ type: GROUP_SUMMARY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GROUP_SUMMARY_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GROUP_SUMMARY_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}
// Balance sheet
export function balanceSheet(data) {
    return async (dispatch) => {
        dispatch({ type: BALANCE_SHEET_REQUEST });
        try {
            var response = await axiosInstance.post(`balance-sheet`, data);
            var responseJson = response;
            if (responseJson?.status) {
                dispatch({ type: BALANCE_SHEET_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: BALANCE_SHEET_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: BALANCE_SHEET_FAILURE, payload: error });
            return Promise.reject(error)
        }
    }
}


export function openModal(modalId) {
    return (dispatch) => {
        dispatch({ type: OPEN_MODAL, payload: modalId });
    }
};
export function closeModal(modalId) {
    return (dispatch) => {
        dispatch({ type: CLOSE_MODAL, payload: modalId });
    }
};
export function closeAllModals() {
    return (dispatch) => {
        dispatch({ type: CLOSE_ALL_MODALS });
    }
};

