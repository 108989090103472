import React, { useEffect, useState, useCallback, useMemo } from 'react';
import './balancesheet.css';
import TableLayoutProfitBalance from '../../../components/Table/TableLayoutProfitBalance';
import NavLayout from '../../../components/NavNameOfComponent/NavLayout';
import BalanceSheetFooter from '../../../components/Footer/BalanceSheetFooter';
import BalanceSheetHelp from '../../../components/help/BalanceSheet/BalanceSheetHelp';
import { useNavigate } from 'react-router-dom';
import { profitAndLoss } from '../../../redux/actions/action-creator';
import { currencyFormate, dateFormateSend, dateFormateShow } from '../../../components/Date_Formate_Utilitiy/Index';
import { useSelector, useDispatch } from 'react-redux';
import Period from '../../../components/help/stock_summary/stock_summary_component/Period';


function ProfitAndLossAC() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedItem1, setSelectedItem1] = useState()
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItems1, setSelectedItems1] = useState([]);
  const [data, setData] = useState([])
  const [rawdata, setRawData] = useState([])
  const [data1, setData1] = useState([])
  const [rawdata1, setRawData1] = useState([])
  const [show, setShow] = useState(false)
  const [configData, setConfigData] = useState(
    {
      show_per: '',
      exclude_account_with_zero_cls: 'Condensed',
      formate_of_report: '',
    }
  )

  const { userData } = useSelector(state => state.auth);
  const { company_session } = useSelector(state => state.common);
  const initialDate = useMemo(() => ({
    send_from_date: dateFormateSend(company_session?.start_financial_year),
    send_to_date: dateFormateSend(company_session?.current_date),
  }), [company_session]);

  const [date, setDate] = useState(initialDate);
  const [total, setTotal] = useState({
    total_left_trading: '',
    total_right_trading: '',
    total_left: '',
    total_right: ''
  })
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('fromDate', dateFormateSend(date.send_from_date));
        formData.append('toDate', dateFormateSend(date.send_to_date));
        formData.append('company_id', userData.id);
        const response = await dispatch(profitAndLoss(formData));
        if (response && response.status) {

          const totalAmountBeforeGrossLoss = sumBeforeGrossLoss(response?.data?.left_side_data);
          const total_l = sumAfterGrossLeft(response?.data?.left_side_data)
          const total_r = sumAfterGrossRight(response?.data?.right_side_data)

          const newObject = {
            group_name: "",
            total_amount: totalAmountBeforeGrossLoss
          };
          const grossLossIndex = response?.data?.left_side_data.findIndex(item => item.group_name === "Gross Loss b/f" || item.group_name === "Gross Profit c/o");

          if (grossLossIndex !== -1) {
            response?.data?.left_side_data.splice(response?.data?.left_side_data[grossLossIndex].group_name === 'Gross Profit c/o' ? grossLossIndex + 1 : grossLossIndex, 0, newObject);
          }

          const totalAmountBeforeGrossLoss1 = sumBeforeGrossLoss(response?.data?.right_side_data);

          const newObject1 = {
            group_name: "",
            total_amount: totalAmountBeforeGrossLoss1
          };
          const grossLossIndex1 = response?.data?.right_side_data.findIndex(item => item.group_name === "Gross Loss c/o" || item.group_name === "Gross Profit b/f");

          if (grossLossIndex1 !== -1) {
            response?.data?.right_side_data.splice(response?.data?.right_side_data[grossLossIndex1].group_name === 'Gross Loss c/o' ? grossLossIndex1 + 1 : grossLossIndex1, 0, newObject1);
          }
          setTotal({ ...total, total_left: total_l, total_right: total_r, total_left_trading: totalAmountBeforeGrossLoss, total_right_trading: totalAmountBeforeGrossLoss1 })
          setRawData(response?.data?.left_side_data);
          setRawData1(response?.data?.right_side_data);
        } else {
          setRawData(response?.data?.left_side_data);
          setRawData1(response?.data?.right_side_data);
        }
      } catch (error) {
        setRawData([]);
        setRawData1([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [date])

  const sumBeforeGrossLoss = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].group_name === "Gross Profit b/f" || data[i].group_name === "Gross Loss b/f") {
        break;
      }
      if (data[i].group_name === "Gross Loss c/o") {
        if (data[i].total_amount || data[i].amount) {
          sum += Math.abs(data[i].total_amount || data[i].amount);
        }
        break;
      }
      if (data[i].group_name === "Gross Profit c/o") {
        if (data[i].total_amount || data[i].amount) {
          sum += Math.abs(data[i].total_amount || data[i].amount);
        }
        break;
      }
      if (data[i].total_amount) {
        sum += Math.abs(data[i].total_amount);
      }
    }
    return sum;
  }


  const sumAfterGrossLeft = (data) => {
    let sum = 0;
    let grossLossIndex = data.findIndex(item => item.group_name === "Gross Loss b/f" || item.group_name === 'Gross Profit c/o');
    if (grossLossIndex !== -1) {
      for (let i = data[grossLossIndex].group_name === 'Gross Profit c/o' ? grossLossIndex + 1 : grossLossIndex; i < data.length; i++) {
        if (data[i].total_amount || data[i].amount) {
          sum += Math.abs(data[i].total_amount || data[i].amount);
        }
      }
    }
    return sum;
  }
  const sumAfterGrossRight = (data) => {
    let sum = 0;
    let grossLossIndex = data.findIndex(item => item.group_name === "Gross Loss c/o" || item.group_name === 'Gross Profit b/f');
    if (grossLossIndex !== -1) {
      for (let i = data[grossLossIndex].group_name === 'Gross Loss c/o' ? grossLossIndex + 1 : grossLossIndex; i < data.length; i++) {
        if (data[i].total_amount || data[i].amount) {
          sum += Math.abs(data[i].total_amount || data[i].amount);
        }
      }
    }
    return sum;
  }

  const filterDataCoonfigure = useCallback(() => {
    const filterEmptyLedgerInfo = (item) => {
      const hasEmptyPurchaseInfo = Array.isArray(item?.purchase_ledger_info) && item.purchase_ledger_info.length === 0;
      const hasEmptySaleInfo = Array.isArray(item?.sale_ledger_info) && item.sale_ledger_info.length === 0;
      return !(hasEmptyPurchaseInfo || hasEmptySaleInfo);
    };

    const newData = rawdata?.filter(filterEmptyLedgerInfo)?.map(item => {
      const godownData = item?.opening_stock || item?.ledger_info;
      if (Array.isArray(godownData)) {
        return configData?.formate_of_report === 'Condensed' ? [item] : [item, ...godownData];
      }
      return configData?.formate_of_report === 'Condensed' ? [item] : [item];
    }).flat();
    setData(newData)
    const newData1 = rawdata1?.filter(filterEmptyLedgerInfo)?.map(item => {
      const godownData = item?.closing_stock || item?.ledger_info;
      if (Array.isArray(godownData)) {
        return configData?.formate_of_report === 'Condensed' ? [item] : [item, ...godownData];
      }
      return configData?.formate_of_report === 'Condensed' ? [item] : [item];
    }).flat();
    setData1(newData1)
  }, [configData, rawdata, rawdata1])


  useEffect(() => {
    filterDataCoonfigure();
  }, [filterDataCoonfigure]);

  const detailFormate = useCallback((e) => {
    if ((e.altKey && e.key === 'F1') || (e.altKey && e.key === 'F5')) {
      e.preventDefault();
      setConfigData(prevState => ({
        ...prevState,
        formate_of_report: prevState.formate_of_report === 'Condensed' ? 'Detailed' : 'Condensed'
      }));
      if (rawdata?.length < selectedItem && !selectedItem1) {
        setSelectedItem(0)
      }
      if (rawdata1?.length < selectedItem1 && !selectedItem) {
        setSelectedItem1(0)
      }
    }

    // if (e.key === 'F12') {
    //   e.preventDefault();
    //   setshow_configure(true)
    // }
  }, [configData, selectedItem, selectedItem1])
  useEffect(() => {
    window.addEventListener('keydown', detailFormate);
    return () => {
      window.removeEventListener('keydown', detailFormate);
    };

  }, [detailFormate])

  const handleKeyDown = useCallback((e) => {
    const handleNavigation = (selectedIndex, setSelectedItem, data) => {
      if (show) {

      } else {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, data?.length - 1));
        }
        else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedList = data[selectedIndex];
          if (selectedList) {
            if (selectedList?.group_name === 'Opening Stock' || selectedList?.group_name === 'Closing Stock') {
              navigate(`../stocksummary`);
            } else if (selectedList?.item_name) {
              navigate(`../stock_item_voucher/${selectedList?.stock_item_id}`);
            } else if (selectedList?.group_name && selectedList?.group_name !== 'Net Profit' && selectedList?.group_name !== 'Net Loss') {
              navigate(`../group_summary/${selectedList?.id}`);
            } else if (selectedList?.ledger_name) {
              navigate(`../ledgervoucher/${selectedList?.id}`);
            }
          }
        }
      }
    };
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      setSelectedItem(null);
      setSelectedItem1(0);
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      setSelectedItem(0);
      setSelectedItem1(null);
    }

    if (selectedItem !== null && selectedItem !== undefined) {
      handleNavigation(selectedItem, setSelectedItem, data);
    }

    if (selectedItem1 !== null && selectedItem1 !== undefined) {
      handleNavigation(selectedItem1, setSelectedItem1, data1);
    }
  }, [selectedItem, selectedItem1, data, data1, show]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown])
  return (
    <div className='section'>
      <div className='detail'>
        {
          show ?
            <Period
              setShow={setShow}
              setDate={setDate}
              date={date}
              setLoading={setLoading}
            /> : null
        }
        <div className='container-fluid'>
          <NavLayout>
            Profit & Loss  <span>{`${dateFormateShow(date?.send_from_date) === 'NaN' ? null : dateFormateShow(date?.send_from_date)} To ${dateFormateShow(date?.send_to_date) === 'NaN' ? null : dateFormateShow(date?.send_to_date)}`}</span>
          </NavLayout>
        </div>
        <TableLayoutProfitBalance
          table_heading={'Particular'}
          table_heading1={'Particular'}
          total_left={total?.total_left}
          total_right={total?.total_right}
        >
          <div className='col-md-6'>
            {
              data?.map((item, index) => {
                return (
                  <div onClick={() => setSelectedItem(index)} key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                    <div className={`row pe-0 ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                      <div className={`col-8 text-start table_body_font ${item?.ledger_name || item?.item_name ? '' : 'ps-1'}`} style={{ fontWeight: item?.ledger_name || item?.item_name ? '' : 'bold' }}>
                        {item?.group_name}{item?.ledger_name}{item?.item_name}
                      </div>
                      <div className={`col-4 text-end table_body_font ${item?.group_name === "" ? 'border-danger border-top border-bottom' : ''}`} style={{ fontWeight: item?.ledger_name || item?.item_name ? '' : 'bold' ,borderColor:'red'}}>
                        {currencyFormate(Math.abs(item?.amount))}
                        {currencyFormate(Math.abs(item?.total_amount))}
                      </div>
                    </div>
                  </div>

                )
              })
            }
          </div>
          <div className='col-md-6'>
            {
              // loading ? <div>Loading...</div> :
              data1?.map((item, index) => {
                return (
                  <div onClick={() => setSelectedItem1(index)} key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                    <div className={`row pe-0 ${index === selectedItem1 ? selectedItems1.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                      <div className={`col-8 text-start table_body_font  ${item?.ledger_name || item?.item_name ? '' : 'ps-1'}`} style={{ fontWeight: item?.ledger_name || item?.item_name ? '' : 'bold', color: item?.group_name === 'Net Loss' ? 'red' : '' }}>
                        {item?.group_name}{item?.ledger_name}{item?.item_name}
                      </div>
                      <div className={`col-4 text-end table_body_font ${item?.group_name === "" ? ' border-danger border-top border-bottom' : ''}`} style={{ fontWeight: item?.ledger_name || item?.item_name ? '' : 'bold', color: item?.group_name === 'Net Loss' ? 'red' : '' }}>
                        {currencyFormate(Math.abs(item?.amount))}
                        {currencyFormate(Math.abs(item?.total_amount))}
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </TableLayoutProfitBalance>
        <BalanceSheetFooter />
      </div>
      <div className='help' style={{ position: 'relative' }}>
        {
          show ? null :
            <BalanceSheetHelp
              setShow={setShow}
            />
        }
      </div>
    </div>
  )
}

export default ProfitAndLossAC



