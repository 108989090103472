import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LINK_PATH } from '../../constant';
import { useDropdownKeyboardNavigation } from '../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../components/NavigationList/NavigationList';
import useKeyboardNavigation from '../../components/Custom_Hooks/useKeyboardNavigation';

const GateWayOfTally = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const keyMap = {
        'c': '/createmasters',
        'd': '/displaymorereports',
        'h': '/chartofaccountmasters',
        'v': '/voucherstransaction',
        'k': '/daybooktransaction',
        'n': '/bankingutilities',
        'b': '/balancesheetreports',
        'p': '/profitandlossac',
        's': '/stocksummary',
        'r': '/displaymorereports',
    };
    useKeyboardNavigation(keyMap);
    const data = [
        { to: '/createmasters', text: 'Create', category: 'Master' },
        { to: '/chartofaccountmasters', text: 'Chart of Accounts', category: 'Master' },

        { to: '/voucherstransaction', text: 'Vouchers', category: 'Transaction' },
        { to: '/daybooktransaction', text: 'Day Book', category: 'Transaction' },

        { to: '/bankingutilities', text: 'Banking', category: 'Utilities' },

        { to: `${LINK_PATH}/balancesheetreports`, text: 'Balance Sheet', category: 'Reports' },
        { to: '/profitandlossac', text: 'Profit & Loss A/c', category: 'Reports' },
        { to: '/stocksummary', text: 'Stock Summary', category: 'Reports' },
        { to: '/displaymorereports', text: 'Display More Reports', category: 'Reports' },
    ];

    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: data,
        // state: location.pathname==='/create-master-group'|| location.pathname==='/create-master-ledger'|| location?.pathname==='/create-master-invmas-stockgroup'?false:true,
        state: location.pathname === '/' ? true : false,
        onItemSelect: (item) => {
            // if (['/ledgerbook', '/current_date', '/period', '/create-master-group', '/create-master-ledger', '/create-master-invmas-stockgroup'].includes(location.pathname)) {
            //     return;
            // }
            if(location.pathname==='/'){
                navigate(item.to)
            }
        },

    });
    return (
        <NavigationList
            data={data}
            selectedItemIndex={selectedItemIndex}
            setSelectedItemIndex={setSelectedItemIndex}
            title='Gateway Of Tally'
            first_heading='Master'
        />
    )
}

export default React.memo(GateWayOfTally)









