import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { stockSummaryItemVoucherList } from '../../../../redux/actions/action-creator'
import TableLayoutItem from '../../../../components/Table/TableLayoutItem'
import NavLayout from '../../../../components/NavNameOfComponent/NavLayout'
import Period from '../../../../components/help/stock_summary/stock_summary_component/Period'
import ChangeView from '../../../../components/help/stock_summary/stockitemsummary/ChangeView'
import GodownFilter from '../../../../components/help/stock_summary/stockitemsummary/GodownFilter'
import StockItemVoucherFooter from '../../../../components/Footer/StockItemVoucherFooter'
import StockItemVoucherHelp from '../../../../components/help/stock_summary/StockItemVoucher/StockItemVoucherHelp'
import StockItemFilter from '../../../../components/help/stock_summary/StockItemVoucher/StockItemFilter'
import { currencyFormate, dateFormateShow, dateFormateSend } from '../../../../components/Date_Formate_Utilitiy/Index'

const StockItemVoucher = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { company_session } = useSelector(state => state.common);
    const { userData } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState(0)
    const [selectedItems, setSelectedItems] = useState([]);
    const [show, setShow] = useState(false)
    const [stockItem, setStockItem] = useState(false)
    const [changeView, setChangeView] = useState(false)
    const [godownWise, setGodownWise] = useState(false)
    const [data, setData] = useState([])

    const initialDate = useMemo(() => ({
        send_from_date: dateFormateSend(company_session?.start_financial_year),
        send_to_date: dateFormateSend(company_session?.current_date),
        location_id: '',
        filter_name: 'Item-Wise',
        name_nav: ``,
    }), [company_session]);

    const [date, setDate] = useState(initialDate);
    // Memoized function to avoid unnecessary re-renders
    const fetchData = useCallback(async () => {
        setLoading(true);
        const formdata = new FormData();
        formdata.append('company_id', userData?.id);
        formdata.append('fromDate', dateFormateSend(date?.send_from_date));
        formdata.append('toDate', dateFormateSend(date?.send_to_date));
        formdata.append('location_id', date?.location_id);
        formdata.append('item_id', id);

        try {
            const response = await dispatch(stockSummaryItemVoucherList(formdata));
            if (response.status) {
                toast.success(response?.message || 'Stock Summary Item !', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                });
                setData(response.data);
            } else {
                toast.warn(response?.message || 'Stock Summary Item !', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            toast.error('Failed to fetch Stock Summary Item!', {
                position: toast.POSITION.TOP_CENTER,
            });
        } finally {
            setLoading(false);
        }
    }, [date, id, dispatch, userData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    // const data = [
    //     { id: 1, particular: 'Opening Balance', inward: { qty: '', alt_unit: '', value: '' }, outwards: { qty: '', alt_unit: '', value: '', gross_value: '', comsumption: '', gross_profit: '', per: '' }, closing: { qty: '', alt_unit: '', value: '' } },
    //     { id: 2, particular: 'April', inward: { qty: '10,000.00 Nos', alt_unit: '10 Box', value: '1000000000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' } },
    //     { id: 3, particular: 'May', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' } },
    //     { id: 4, particular: 'June', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' } },
    //     { id: 5, particular: 'July', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '50 Nos', alt_unit: '5 Box', value: '50000' } },
    //     { id: 6, particular: 'August', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '50 Nos', alt_unit: '5 Box', value: '50000' } },
    //     { id: 7, particular: 'September', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '50 Nos', alt_unit: '5 Box', value: '50000' } },
    //     { id: 8, particular: 'October', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '50 Nos', alt_unit: '5 Box', value: '50000' } },
    //     { id: 9, particular: 'November', inward: { qty: '150 Nos', alt_unit: '15 Box', value: '150000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
    //     { id: 10, particular: 'December', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
    //     { id: 11, particular: 'January', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
    //     { id: 12, particular: 'February', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
    //     { id: 13, particular: 'March', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
    // ];

    // Handle keyboard navigation
    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedItem(prevState => Math.min(prevState + 1, data?.voucher_item_data?.length - 1));
        } else if (e.key === 'Enter') {
            e.preventDefault();
            const selectedList = data[selectedItem];
            if (selectedList) {
                // navigate(`/your/path/${selectedList.id}`);
            }
        }
    }, [data, selectedItem]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    let qtyNum = data?.opening_data?.quantity?.match(/\d+/g)
    let qtyUnitName = data?.opening_data?.quantity?.match(/[a-z]+/gi)
    let cumulativeQuntity = parseFloat(qtyNum) || 0;
    let cumulativeRate = parseFloat(data?.opening_data?.rate) || 0;
    let closing_rate
    // if (!cumulativeQuntity) {
    //     return null
    // }
    // if (!cumulativeRate) {
    //     return null
    // }
    let count = 1
    // if (loading) {
    //     return
    // }
    // let cumulativeValue = parseFloat(state?.opening_balance) || 0;
    return (
        <div className='section'>
            <div className='detail'>
                {
                    show ?
                        <Period
                            setShow={setShow}
                            setDate={setDate}
                            date={date}
                            setLoading={setLoading}
                        /> : null
                }
                {
                    stockItem ?
                        <StockItemFilter
                            setStockItem={setStockItem}
                            setDate={setDate}
                            date={date}
                            setLoading={setLoading}
                        /> : null
                }
                {
                    changeView ?
                        <ChangeView
                            setChangeView={setChangeView}
                            setDate={setDate}
                            date={date}
                        /> : null
                }
                {
                    godownWise ?
                        <GodownFilter
                            godownWise={godownWise}
                            date={date}
                            setGodownWise={setGodownWise}
                            setDate={setDate}
                        /> : null
                }
                <div className='container-fluid'>
                    <NavLayout>
                        Stock Item Vouchers : {data?.stock_item_name} : {date?.name_nav} <span>{`${dateFormateShow(date?.send_from_date) === 'NaN' ? null : dateFormateShow(date?.send_from_date)} To ${dateFormateShow(date?.send_to_date) === 'NaN' ? null : dateFormateShow(date?.send_to_date)}`}</span>
                    </NavLayout>
                </div>
                <TableLayoutItem iw_rate={closing_rate?.toFixed(2)}>
                    <div>
                        <div className={`row`} >
                            <div className={`col-5 border-end border-start border-dark text-start table_body_font`}>
                                <div className='row'>
                                    <div className='col-2 m-0 p-0 text-end '>{dateFormateShow(data?.opening_date)}</div>
                                    <div className='col-5 m-0 p-0 ps-1 text-start'> Opening Balance</div>
                                    <div className='col-3 m-0 p-0 text-end'></div>
                                    <div className='col-2 m-0 p-0 pe-1 text-end'></div>
                                </div>
                            </div>
                            <div className='col-7 m-0 p-0'>
                                <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                                    <div className='col-4 border-end border-dark m-0 p-0'>
                                        <div className='row m-0 p-0' style={{ width: '100%' }}>
                                            <div className='col-4 text-end p-0 m-0 table_body_font' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                {data?.opening_data?.quantity}
                                            </div>
                                            <div className='col-3 m-0 p-0 text-end table_body_font' style={{ overflow: 'hidden', }}>
                                                {currencyFormate(data?.opening_data?.rate)}
                                            </div>
                                            <div className='col-5 text-end p-0 m-0 pe-1 table_body_font' style={{ overflow: 'hidden', whiteSpace: 'nowrap', wordSpacing: -7 }}>
                                                {currencyFormate(data?.opening_data?.value)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 m-0 p-0'>
                                        <div className='row m-0 p-0' style={{ width: '100%' }}>
                                            <div className='col-4 text-end p-0 m-0 table_body_font' >
                                            </div>
                                            <div className='col-3 m-0 p-0 text-end table_body_font' >
                                            </div>
                                            <div className='col-5 text-end p-0 pe-1 m-0 table_body_font' >
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-4 border-end border-dark m-0 p-0'>
                                        <div className='row m-0 p-0' style={{ width: '100%' }}>
                                            <div className='col-4 text-end p-0 m-0 table_body_font'>
                                                {data?.opening_data?.quantity}
                                            </div>
                                            <div className='col-3 text-end m-0 p-0 table_body_font'>
                                                {currencyFormate(data?.opening_data?.rate)}
                                            </div>
                                            <div className='col-5 text-end p-0 pe-1 m-0 table_body_font'>
                                                {currencyFormate(data?.opening_data?.value)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        data?.voucher_item_data?.map((item, index) => {
                            let item_iw_qty = parseFloat(item?.inwardData?.quantity?.match(/\d+/g))
                            let item_iw_rate = parseFloat(item?.inwardData?.rate?.match(/\d+/g))
                            let item_Ow_qty = parseFloat(item?.outwardData?.quantity?.match(/\d+/g))
                            count += item?.inwardData.length === 0 ? 0 : 1
                            cumulativeQuntity += item_iw_qty ? item_iw_qty : 0
                            cumulativeRate += item_iw_rate ? item_iw_rate : 0
                            closing_rate = cumulativeRate / parseFloat(count);
                            cumulativeQuntity -= item_Ow_qty ? item_Ow_qty : 0;
                            return (
                                <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                                    <div className={`row ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                                        <div className={`col-5 border-end border-start border-dark text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                                            <div className='row'>
                                                <div className='col-2 m-0 p-0 text-end '>{dateFormateShow(item?.create_date)}</div>
                                                <div className='col-5 m-0 p-0 ps-1 text-start'> {item?.party_ledger_name}</div>
                                                <div className='col-3 m-0 p-0 text-end'>{item?.voucher_type_name}</div>
                                                <div className='col-2 m-0 p-0 pe-1 text-end'>{item?.serial_no}</div>
                                            </div>
                                        </div>
                                        <div className='col-7 m-0 p-0'>
                                            <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                                                <div className='col-4 border-end border-dark m-0 p-0'>
                                                    <div className='row m-0 p-0' style={{ width: '100%' }}>
                                                        <div className='col-4 text-end p-0 m-0 table_body_font' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                            {item?.inwardData?.quantity}
                                                        </div>
                                                        <div className='col-3 m-0 p-0 text-end table_body_font' style={{ overflow: 'hidden', }}>
                                                            {currencyFormate(item?.inwardData?.rate)}
                                                        </div>
                                                        <div className='col-5 text-end p-0 m-0 pe-1 table_body_font' style={{ overflow: 'hidden', whiteSpace: 'nowrap', wordSpacing: -7 }}>
                                                            {currencyFormate(item?.inwardData?.value)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-4 m-0 p-0'>
                                                    <div className='row m-0 p-0' style={{ width: '100%' }}>
                                                        <div className='col-4 text-end p-0 m-0 table_body_font' >
                                                            {item?.outwardData?.quantity}
                                                        </div>
                                                        <div className='col-3 m-0 p-0 text-end table_body_font' >
                                                            {currencyFormate(item?.outwardData?.rate)}
                                                        </div>
                                                        <div className='col-5 text-end p-0 pe-1 m-0 table_body_font' >
                                                            {currencyFormate(item?.outwardData?.value)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-4 border-end border-dark m-0 p-0'>
                                                    <div className='row m-0 p-0' style={{ width: '100%' }}>
                                                        <div className='col-4 text-end p-0 m-0 table_body_font'>
                                                            {/* {item?.ClosingData?.quantity} */}
                                                            {cumulativeQuntity} {qtyUnitName}
                                                        </div>
                                                        <div className='col-3 text-end m-0 p-0 table_body_font'>
                                                            {/* {currencyFormate(item?.ClosingData?.rate)} */}
                                                            {closing_rate?.toFixed(2)}
                                                        </div>
                                                        <div className='col-5 text-end p-0 pe-1 m-0 table_body_font'>
                                                            {/* {currencyFormate(item?.ClosingData?.value)} */}
                                                            {cumulativeQuntity * cumulativeRate?.toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </TableLayoutItem>
                <StockItemVoucherFooter />
            </div>
            <div className='help' style={{ position: 'relative' }}>
                <StockItemVoucherHelp
                    setShow={setShow}
                    setStockItem={setStockItem}
                    setChangeView={setChangeView}
                    setGodownWise={setGodownWise}

                />
            </div>
        </div>
    )
}

export default StockItemVoucher
