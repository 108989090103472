// import React, { useEffect, useState, useCallback } from 'react';
// import './balancesheet.css';
// import TableLayoutProfitBalance from '../../../components/Table/TableLayoutProfitBalance';
// import NavLayout from '../../../components/NavNameOfComponent/NavLayout';
// import BalanceSheetFooter from '../../../components/Footer/BalanceSheetFooter';
// import BalanceSheetHelp from '../../../components/help/BalanceSheet/BalanceSheetHelp';
// import { useNavigate } from 'react-router-dom';

// function BalanceSheet() {
//   const navigate = useNavigate();
//   const [selectedItem, setSelectedItem] = useState(0)
//   const [selectedItem1, setSelectedItem1] = useState()
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [selectedItems1, setSelectedItems1] = useState([]);
//   const data = [
//     { groupname: 'Current Liabilities', amount: '9,99,99,99,999.00 Cr' },
//     { groupname: 'Purchase Account', amount: '9,99,99,99,999.00 Dr' },
//     { groupname: 'Sales Account', amount: '9,99,99,99,999.00 Dr' },
//     { groupname: 'Suspense Account', amount: '9,99,99,99,999.00 Dr' },
//     { groupname: 'Capital Account', amount: '9,99,99,99,999.00 Dr' }
//   ]
//   const data1 = [
//     { groupname: 'Current Asset', amount: '9,99,99,99,999.00 Cr' },
//     { groupname: 'Bank Account', amount: '9,99,99,99,999.00 Dr' },
//     { groupname: 'Loan and Advance', amount: '9,99,99,99,999.00 Dr' },
//     { groupname: 'Stock in Hand', amount: '9,99,99,99,999.00 Dr' },
//   ]
//   const handleKeyDown = useCallback((e) => {
//     if (selectedItem !== null && selectedItem !== undefined) {
//       if (e.key === 'ArrowUp') {
//         e.preventDefault();
//         setSelectedItem(prevState => Math.max(prevState - 1, 0));
//       } else
//         if (e.key === 'ArrowDown') {
//           e.preventDefault();
//           setSelectedItem(prevState => Math.min(prevState + 1, data?.length - 1));
//         } else
//           if (e.key === 'ArrowRight') {
//             setSelectedItem()
//             setSelectedItem1(0)
//           } else if (e.key === 'ArrowLeft') {
//             setSelectedItem(0)
//             setSelectedItem1()
//           }

//       if (e.key === 'Enter') {
//         e.preventDefault();
//         const selectedList = data[selectedItem]
//         if (selectedList) {
//           navigate(`../group_summary`)
//         }
//       }
//     }
//     if (selectedItem1 !== null && selectedItem1 !== undefined) {
//       if (e.key === 'ArrowUp') {
//         e.preventDefault();
//         setSelectedItem1(prevState => Math.max(prevState - 1, 0));
//       } else
//         if (e.key === 'ArrowDown') {
//           e.preventDefault();
//           setSelectedItem1(prevState => Math.min(prevState + 1, data1?.length - 1));
//         } else
//           if (e.key === 'ArrowRight') {
//             setSelectedItem()
//             setSelectedItem1(0)
//           } else if (e.key === 'ArrowLeft') {
//             setSelectedItem(0)
//             setSelectedItem1()
//           }
//       if (e.key === 'Enter') {
//         e.preventDefault();
//         const selectedList = data1[selectedItem1]
//         if (selectedList) {
//           navigate(`../group_summary`)
//         }
//       }
//     }
//   }, [selectedItem, selectedItem1, data1, data])
//   useEffect(() => {
//     window.addEventListener('keydown', handleKeyDown);
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [handleKeyDown])
//   return (
//     <div className='section'>
//       <div className='detail'>
//         <div className='container-fluid'>
//           <NavLayout>
//             Balance Sheet  <span>1-Apr-2024 To 31-March-2024</span>
//           </NavLayout>
//         </div>
//         <TableLayoutProfitBalance
//           table_heading={'Liabilities'}
//           table_heading1={'Assets'}
//         >
//           <div className='col-md-6'>
//             {
//               // loading ? <div>Loading...</div> :
//               data?.map((item, index) => {
//                 return (
//                   <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
//                     <div className={`row pe-0 ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
//                       <div className={`col-8 text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
//                         {item.groupname}
//                       </div>
//                       <div className={`col-4 text-end table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
//                         {item.amount}
//                       </div>
//                     </div>
//                   </div>
//                 )
//               })
//             }
//           </div>
//           <div className='col-md-6'>
//             {
//               // loading ? <div>Loading...</div> :
//               data1?.map((item, index) => {
//                 return (
//                   <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
//                     <div className={`row pe-0 ${index === selectedItem1 ? selectedItems1.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
//                       <div className={`col-8 text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
//                         {item.groupname}
//                       </div>
//                       <div className={`col-4 text-end table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
//                         {item.amount}
//                       </div>
//                     </div>
//                   </div>
//                 )
//               })
//             }
//           </div>
//         </TableLayoutProfitBalance>
//         <BalanceSheetFooter />
//       </div>
//       <div className='help' style={{ position: 'relative' }}>
//         <BalanceSheetHelp />
//       </div>
//     </div>
//   )
// }

// export default BalanceSheet








import React, { useEffect, useState, useCallback, useMemo } from 'react';
import './balancesheet.css';
import TableLayoutProfitBalance from '../../../components/Table/TableLayoutProfitBalance';
import NavLayout from '../../../components/NavNameOfComponent/NavLayout';
import BalanceSheetFooter from '../../../components/Footer/BalanceSheetFooter';
import BalanceSheetHelp from '../../../components/help/BalanceSheet/BalanceSheetHelp';
import { useNavigate } from 'react-router-dom';
import { balanceSheet } from '../../../redux/actions/action-creator';
import { currencyFormate, dateFormateSend, dateFormateShow } from '../../../components/Date_Formate_Utilitiy/Index';
import { useSelector, useDispatch } from 'react-redux';
import Period from '../../../components/help/stock_summary/stock_summary_component/Period';
import Indicator from '../../../components/Indicator/Indicator';
import HomeLayout from '../../../components/home_page/HomeLayout';


function BalanceSheet() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedItem1, setSelectedItem1] = useState()
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItems1, setSelectedItems1] = useState([]);
  const [data, setData] = useState([])
  const [rawdata, setRawData] = useState([])
  const [data1, setData1] = useState([])
  const [rawdata1, setRawData1] = useState([])
  const [show, setShow] = useState(false)
  const [configData, setConfigData] = useState(
    {
      show_per: '',
      exclude_account_with_zero_cls: 'Condensed',
      formate_of_report: '',
    }
  )

  const { userData } = useSelector(state => state.auth);
  const { company_session } = useSelector(state => state.common);
  const initialDate = useMemo(() => ({
    send_from_date: dateFormateSend(company_session?.start_financial_year),
    send_to_date: dateFormateSend(company_session?.current_date),
  }), [company_session]);

  const [date, setDate] = useState(initialDate);
  const [total, setTotal] = useState({
    total_left: '',
    total_right: ''
  })
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('fromDate', dateFormateSend(date.send_from_date));
        formData.append('toDate', dateFormateSend(date.send_to_date));
        formData.append('company_id', userData.id);
        const response = await dispatch(balanceSheet(formData));
        if (response && response.status) {

          const totalAmountBeforeGrossLoss = sumBeforeGrossLoss(response?.data?.data?.left_side_data);
          const totalAmountBeforeGrossLoss1 = sumBeforeGrossLoss(response?.data?.data?.right_side_data);
          setTotal({ ...total, total_left: totalAmountBeforeGrossLoss, total_right: totalAmountBeforeGrossLoss1 })
          setRawData(response?.data?.data?.left_side_data);
          setRawData1(response?.data?.data?.right_side_data);
        } else {
          setRawData(response?.data?.data?.left_side_data);
          setRawData1(response?.data?.data?.right_side_data);
        }
      } catch (error) {
        setRawData([]);
        setRawData1([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [date])

  const sumBeforeGrossLoss = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].total_amount) {
        sum += data[i].total_amount
      }
    }
    return sum;
  }

  const filterDataCoonfigure = useCallback(() => {
    const newData = rawdata?.map(item => {
      const godownData = item?.ledger_info;
      if (Array.isArray(godownData)) {
        return configData?.formate_of_report === 'Condensed' ? [item] : [item, ...godownData];
      }
      return configData?.formate_of_report === 'Condensed' ? [item] : [item];
    }).flat();
    setData(newData)
    const newData1 = rawdata1?.map(item => {
      const godownData = item?.ledger_info;
      if (Array.isArray(godownData)) {
        return configData?.formate_of_report === 'Condensed' ? [item] : [item, ...godownData];
      }
      return configData?.formate_of_report === 'Condensed' ? [item] : [item];
    }).flat();
    setData1(newData1)
  }, [configData, rawdata, rawdata1])


  useEffect(() => {
    filterDataCoonfigure();
  }, [filterDataCoonfigure]);

  const detailFormate = useCallback((e) => {
    if ((e.altKey && e.key === 'F1') || (e.altKey && e.key === 'F5')) {
      e.preventDefault();
      setConfigData(prevState => ({
        ...prevState,
        formate_of_report: prevState.formate_of_report === 'Condensed' ? 'Detailed' : 'Condensed'
      }));
      if (rawdata?.length < selectedItem && !selectedItem1) {
        setSelectedItem(0)
      }
      if (rawdata1?.length < selectedItem1 && !selectedItem) {
        setSelectedItem1(0)
      }
    }

    // if (e.key === 'F12') {
    //   e.preventDefault();
    //   setshow_configure(true)
    // }
  }, [configData, selectedItem, selectedItem1])
  useEffect(() => {
    window.addEventListener('keydown', detailFormate);
    return () => {
      window.removeEventListener('keydown', detailFormate);
    };

  }, [detailFormate])

  const handleKeyDown = useCallback((e) => {
    const handleNavigation = (selectedIndex, setSelectedItem, data) => {
      if (show) {

      } else {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, data?.length - 1));
        }
        else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedList = data[selectedIndex];
          if (selectedList) {
            if (selectedList?.group_name === 'Opening Stock' || selectedList?.group_name === 'Closing Stock') {
              navigate(`../stocksummary`);
            } else if (selectedList?.item_name) {
              navigate(`../stock_item_voucher/${selectedList?.stock_item_id}`);
            } else if (selectedList?.group_name && selectedList?.group_name !== 'Net Profit' && selectedList?.group_name !== 'Net Loss') {
              navigate(`../group_summary/${selectedList?.id}`);
            } else if (selectedList?.ledger_name) {
              navigate(`../ledgervoucher/${selectedList?.id}`);
            }
          }
        }
      }
    };
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      setSelectedItem(null);
      setSelectedItem1(0);
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      setSelectedItem(0);
      setSelectedItem1(null);
    }

    if (selectedItem !== null && selectedItem !== undefined) {
      handleNavigation(selectedItem, setSelectedItem, data);
    }

    if (selectedItem1 !== null && selectedItem1 !== undefined) {
      handleNavigation(selectedItem1, setSelectedItem1, data1);
    }
  }, [selectedItem, selectedItem1, data, data1, show]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown])
  return (
    <HomeLayout>
      {
        show ?
          <Period
            setShow={setShow}
            setDate={setDate}
            date={date}
            setLoading={setLoading}
          /> : null
      }
      <div className='container-fluid'>
        <NavLayout>
          Balance Sheet  <span>{`${dateFormateShow(date?.send_from_date) === 'NaN' ? null : dateFormateShow(date?.send_from_date)} To ${dateFormateShow(date?.send_to_date) === 'NaN' ? null : dateFormateShow(date?.send_to_date)}`}</span>
        </NavLayout>
      </div>
      {
        loading ?
          <Indicator /> :

          <TableLayoutProfitBalance
            table_heading={'Liabilities'}
            table_heading1={'Assets'}
            total_left={total?.total_left}
            total_right={total?.total_right}
          >
            <div className='col-md-6'>
              {
                data?.map((item, index) => {
                  return (
                    <div onClick={() => setSelectedItem(index)} key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                      <div className={`row pe-0 ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                        <div className={`col-8 text-start table_body_font ${item?.name ? '' : 'ps-1'}`} style={{ fontWeight: item?.name ? '' : 'bold' }}>
                          {item?.group_name}{item?.name}
                        </div>
                        <div className={`col-4 text-end table_body_font ${item?.group_name === "" ? 'border-danger border-top border-bottom' : ''}`} style={{ fontWeight: item?.name ? '' : 'bold', paddingRight: item?.name ? '100px' : '' }}>
                          {item?.amount ? item?.amount > 0 ? '(-)' : '' : ''}  {currencyFormate(Math.abs(item?.amount))} {item?.amount ? item?.amount > 0 ? 'Dr' : 'Cr' : ''}
                          {item?.total_amount ? item?.total_amount > 0 ? '(-)' : '' : ''}  {currencyFormate(Math.abs(item?.total_amount))}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className='col-md-6'>
              {
                // loading ? <div>Loading...</div> :
                data1?.map((item, index) => {
                  return (
                    <div onClick={() => setSelectedItem1(index)} key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                      <div className={`row pe-0 ${index === selectedItem1 ? selectedItems1.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                        <div className={`col-8 text-start table_body_font  ${item?.name ? '' : 'ps-1'}`} style={{ fontWeight: item?.name ? '' : 'bold', color: item?.group_name === 'Net Loss' ? 'red' : '' }}>
                          {item?.group_name}{item?.name}
                        </div>
                        <div className={`col-4 text-end table_body_font ${item?.group_name === "" ? ' border-danger border-top border-bottom' : ''}`} style={{ fontWeight: item?.name ? '' : 'bold', paddingRight: item?.name ? '100px' : '' }}>
                          {item?.amount ? item?.amount < 0 ? '(-)' : '' : ''}   {currencyFormate(Math.abs(item?.amount))} {item?.amount ? item?.amount > 0 ? 'Dr' : 'Cr' : ''}
                          {item?.total_amount ? item?.total_amount < 0 ? '(-)' : '' : ''}  {currencyFormate(Math.abs(item?.total_amount))}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </TableLayoutProfitBalance>
      }
      <BalanceSheetFooter />
    </HomeLayout>
    // <div className='help' style={{ position: 'relative' }}>
    //   {
    //     show ? null :
    //       <BalanceSheetHelp
    //         setShow={setShow}
    //       />
    //   }
    // </div>

  )
}

export default BalanceSheet




