
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/js/bootstrap'
import React, { useEffect, useState, Suspense, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Index from './routes/Index';
import { StrictMode } from 'react';
import LoadingBar from 'react-top-loading-bar'

function App() {
  const [progress, setProgress] = useState(0)
  return (
    <StrictMode>
      <Provider store={store}>
          <BrowserRouter>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => { setProgress(0) }}
            />
            <Routes>
              <Route path={'*'} element={<Index setProgress={setProgress} />} />
            </Routes>
            {/* <Index /> */}
          </BrowserRouter>
      </Provider>
    </StrictMode>
  );
}

export default App;
