import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { groupListCreate, ledgerCreate, openModal, closeModal } from '../../../../../redux/actions/action-creator';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';
import Form from '../../../../../components/Form/Form';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import { useInputAction } from '../../../../../components/Custom_Hooks/useInputAction';
import { toast } from 'react-toastify';
import { statesList } from '../../../../../components/Date_Formate_Utilitiy/Index';
import useData from '../../../../../components/Custom_Hooks/useData';
import { useInputActionLedger } from '../../../../../components/Custom_Hooks/useInputActionLedger';


const typesOfLederList = [{ id: 1, name: 'Not Applicable' }, { id: 2, name: 'Invoice Rounding' }]
const yes_no_array =
  [
    { name: 'Yes' },
    { name: 'No' },
  ]
const rounding_method_array =
  [
    { name: 'Downward Rounding' },
    { name: 'Normal Rounding' },
    { name: 'Upward Rounding' },
  ]


const Ledger = ({ title, closeModal1 }) => {
  const dispatch = useDispatch();

  const { userData = null } = useSelector(state => state.auth);
  const { group_all_list = [] } = useSelector(state => state.common);
  const modal_state = useSelector(state => state.common.modal_state);
  const [nameInput, setNameInput] = useState(null)
  // dropdown state 
  const [showSetAlterRange, setShowSetAlterRange] = useState(false)
  const [showEnableCheqPrinting, setShowEnableCheqPrinting] = useState(false)
  const [showSetAlterCheqPrinting, setShowSetAlterCheqPrinting] = useState(false)
  const [showUseAsNational, setShowUseAsNational] = useState(false)
  const [showMaintainBalance, setShowMaintainBalance] = useState(false)
  const [showCheckForCredit, setShowCheckForCredit] = useState(false)
  const [showProvideBankDetail, setShowProvideBankDetail] = useState(false)
  const [showProvideContact, setShowProvideContact] = useState(false)
  const [showList, setShowList] = useState(false);
  const [showListState, setShowListState] = useState(false);


  const initialState = {
    company_id: userData?.id,
    account_no: '',
    ledger_name: '',
    alise_name: '',
    group_id: userData?.group_data[1]?.id,
    under_id: userData?.group_data[1]?.name,

    maintain_balance_bill_by_bill: 'No',//done
    default_credit_period: '',//done
    specify_credit_limit: '',//done
    inventory_values_are_affected: 'No',//done
    type_of_ledger: 'Not Applicable',//done
    rounding_method: '',//done
    rounding_limit: '',//done

    effective_date_for_reconciliation: '',//done
    set_od_limit: '',//done
    ac_holders_name: '',//done
    ac_no: '',//done
    ifs_code: '',//done
    swift_code: '',//done
    bank_name: 'Not Applicable',//done
    branch: '',//done
    set_alter_range_for_checque_books: 'No',//done
    enable_check_printing: 'No',//done
    set_alter_cheque_printing_configuration: 'No',//done
    use_as_notional_bank_for_post_dated_vouchers: 'No',//done

    behave_as_duties_and_taxes_ledger: '',//no
    type_of_duty_tax: '',//no
    tax_type: '',//no
    valuation_type: '',//done
    rate_per_unit: '',//done
    percentage_of_calculation: '',//done
    gst_applicability: 'Not Applicable',//done
    set_alter_gst_details: '',//no
    include_in_assessable_value_calculation: 'Not Applicable',//done
    hsn_sac_details: '',//done
    source_of_details: '',//done
    hsn_sac: '',//done
    description: '',//done
    gst_rate_details: '',//done
    taxability_type: '',//done
    nature_of_transaction: '',//dpne
    igst_rate: '',//done
    cgst_rate: '',//done
    sgst_utgst_rate: '',//done
    cess_valution_type: '',//done
    cess_rate: '',//done
    applicable_for_reverse_charge: '',//done
    eligible_for_input_tax_credit: '',//done
    type_of_supply: '',//done
    check_for_credit_days_duriing_voucher_entry: '',//done
    method_of_appropriation_to_allocate_additional_consts_in_purchas: 'Not Applicable',//done

    mailing_name: '',//done
    address: '',//done
    city: '',
    state: 'Madhya Pradesh',//done
    country: 'India',//done
    pincode: '',//done
    mobile_no: '',//done
    email: '',//done
    provide_contact_details: 'No',//done
    provide_bank_details: 'No',//done
    pan_it_no: '',//done
    aadhaar: '',//aadhar
    transport_detail: '',//done

    registration_type: '',//done
    assessee_of_other_territory: '',//done
    gst_in_uin: '',//done
    use_ledger_as_common_party: '',//done
    set_alter_additional_gst_details: '',//done
    ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: '',//done

    opening_balance: '',//done
    type: '',//done

    override_credit_limit_using_post_dated_transaction: '',
    appropriate_to: '',
    method_of_calculation: '',
    classification: '',

    group_slug: userData?.group_data[1]?.slug,
    nature_of_group: userData?.group_data[1]?.nature_of_group
  }
  // const [data, setData] = useState(initialState);
  const { data, setData } = useData(initialState);




  const inputRefs = {
    // account_no: useRef(null),
    ledger_name: useRef(null),
    alise_name: useRef(null),
    under_id: useRef(null),
    maintain_balance_bill_by_bill: useRef(null),
    default_credit_period: useRef(null),
    check_for_credit_days_duriing_voucher_entry: useRef(null),
    specify_credit_limit: useRef(null),

    type_of_ledger: useRef(null),
    rounding_method: useRef(null),
    rounding_limit: useRef(null),
    // inventory_values_are_affected: useRef(null),
    effective_date_for_reconciliation: useRef(null),
    set_od_limit: useRef(null),
    ac_holders_name: useRef(null),
    ac_no: useRef(null),
    ifs_code: useRef(null),
    swift_code: useRef(null),
    bank_name: useRef(null),
    branch: useRef(null),
    set_alter_range_for_checque_books: useRef(null),
    enable_check_printing: useRef(null),
    set_alter_cheque_printing_configuration: useRef(null),
    use_as_notional_bank_for_post_dated_vouchers: useRef(null),
    // behave_as_duties_and_taxes_ledger: useRef(null),
    // type_of_duty_tax: useRef(null),
    // tax_type: useRef(null),
    // valuation_type: useRef(null),
    // rate_per_unit: useRef(null),
    // percentage_of_calculation: useRef(null),
    // gst_applicability: useRef(null),
    // set_alter_gst_details: useRef(null),
    // include_in_assessable_value_calculation: useRef(null),
    // hsn_sac_details: useRef(null),
    // source_of_details: useRef(null),
    // hsn_sac: useRef(null),
    // description: useRef(null),
    // gst_rate_details: useRef(null),
    // taxability_type: useRef(null),
    // nature_of_transaction: useRef(null),
    // igst_rate: useRef(null),
    // cgst_rate: useRef(null),
    // sgst_utgst_rate: useRef(null),
    // cess_valution_type: useRef(null),
    // cess_rate: useRef(null),
    // applicable_for_reverse_charge: useRef(null),
    // eligible_for_input_tax_credit: useRef(null),
    // type_of_supply: useRef(null),
    // method_of_appropriation_to_allocate_additional_consts_in_purchas: useRef(null),

    mailing_name: useRef(null),
    address: useRef(null),
    city: useRef(null),
    state: useRef(null),
    country: useRef(null),
    pincode: useRef(null),
    email: useRef(null),
    mobile_no: useRef(null),
    provide_contact_details: useRef(null),
    provide_bank_details: useRef(null),
    aadhaar: useRef(null),
    transport_detail: useRef(null),
    pan_it_no: useRef(null),

    // registration_type: useRef(null),
    // assessee_of_other_territory: useRef(null),
    // gst_in_uin: useRef(null),
    // use_ledger_as_common_party: useRef(null),
    // set_alter_additional_gst_details: useRef(null),
    // ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: useRef(null),

    opening_balance: useRef(null),
    type: useRef(null),
  };


  // const nextMapping = {

  //   "capital-account": "maintain_balance_bill_by_bill",
  //   "reserves-surplus": "maintain_balance_bill_by_bill",
  //   "current-assets": "maintain_balance_bill_by_bill",
  //   "depositsasset": "maintain_balance_bill_by_bill",
  //   "current-liabilities": "maintain_balance_bill_by_bill",
  //   "direct-expenses": "maintain_balance_bill_by_bill",
  //   "loansliability": "maintain_balance_bill_by_bill",
  //   "investments": "maintain_balance_bill_by_bill",
  //   "provisions": "maintain_balance_bill_by_bill",
  //   "misc-expenses": "maintain_balance_bill_by_bill",
  //   "secured-loans": "maintain_balance_bill_by_bill",
  //   "unsecured-loans": "maintain_balance_bill_by_bill",
  //   "suspense-ac": "maintain_balance_bill_by_bill",
  //   "loans-advance-asset": "maintain_balance_bill_by_bill",
  //   "direct-incomes": "maintain_balance_bill_by_bill",
  //   "branchdivisions": "maintain_balance_bill_by_bill",
  //   "sundry-creditors": "maintain_balance_bill_by_bill",
  //   "sundry-debtors": "maintain_balance_bill_by_bill",
  //   "indirect-expenses": "maintain_balance_bill_by_bill",
  //   "indirect-incomes": "maintain_balance_bill_by_bill",
  //   "cashinhand": "opening_balance",
  //   "stockinhand": "opening_balance",
  //   "purchase-accounts": "opening_balance",
  //   "sales-accounts": "opening_balance",
  //   "fixed-assets": "mailing_name",
  //   "bank-od-ac": "effective_date_for_reconciliation",
  //   "bank-account": "effective_date_for_reconciliation"

  // };

  // function getNextMapping(key) {
  //   switch (key) {
  //     case "capital-account":
  //     case "reserves-surplus":
  //     case "current-assets":
  //     case "depositsasset":
  //     case "current-liabilities":
  //     case "direct-expenses":
  //     case "loansliability":
  //     case "investments":
  //     case "provisions":
  //     case "misc-expenses":
  //     case "secured-loans":
  //     case "unsecured-loans":
  //     case "suspense-ac":
  //     case "loans-advance-asset":
  //     case "direct-incomes":
  //     case "branchdivisions":
  //     case "sundry-creditors":
  //     case "sundry-debtors":
  //     case "indirect-expenses":
  //     case "indirect-incomes":
  //       return "maintain_balance_bill_by_bill";

  //     case "cashinhand":
  //     case "stockinhand":
  //     case "purchase-accounts":
  //     case "sales-accounts":
  //       return "opening_balance";

  //     case "fixed-assets":
  //       return "mailing_name";

  //     case "bank-od-ac":
  //     case "bank-account":
  //       return "effective_date_for_reconciliation";

  //     default:
  //       return null; // Or any other default value you need
  //   }
  // }

  // const fieldConfig = {
  //   ledger_name: { name: "ledger_name", next: "alise_name" },
  //   alise_name: { name: "alise_name", next: "under_id", prev: "ledger_name" },
  //   under_id: {
  //     name: "under_id",
  //     next: ['capital-account', 'reserves-surplus', 'current-assets', 'depositsasset', 'current-liabilities', 'direct-expenses', 'loansliability', 'investments', 'provisions', 'misc-expenses', 'secured-loans', 'unsecured-loans', 'suspense-ac', 'loans-advance-asset', 'direct-incomes', 'branchdivisions', 'sundry-creditors', 'sundry-debtors', 'indirect-expenses', 'indirect-incomes'].includes(data?.group_slug) ?
  //       "maintain_balance_bill_by_bill" :
  //       ['cashinhand', 'stockinhand', 'purchase-accounts', 'sales-accounts'].includes(data?.group_slug) ?
  //         "opening_balance" :
  //         ['fixed-assets'].includes(data?.group_slug) ?
  //           "mailing_name" :
  //           "effective_date_for_reconciliation",

  //     next: getNextMapping(data?.group_slug),
  //     prev: "alise_name"
  //   },

  //   maintain_balance_bill_by_bill: {
  //     name: "maintain_balance_bill_by_bill",
  //     next: data?.maintain_balance_bill_by_bill === 'Yes' ? "default_credit_period" :
  //       ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug) ? "specify_credit_limit" :
  //         ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses',].includes(data?.group_slug) ? "type_of_ledger" :
  //           ['reserves-surplus', 'provisions', 'suspense-ac'].includes(data?.group_slug) ? "opening_balance" : "mailing_name",
  //     prev: "under_id"
  //   },
  //   default_credit_period: {
  //     name: "default_credit_period",
  //     next: ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug) ? "check_for_credit_days_duriing_voucher_entry" :
  //       ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses',].includes(data?.group_slug) ? "type_of_ledger" :
  //         ['reserves-surplus', 'provisions', 'suspense-ac'].includes(data?.group_slug) ? "opening_balance" : "mailing_name",
  //     prev: "maintain_balance_bill_by_bill"
  //   },
  //   check_for_credit_days_duriing_voucher_entry: {
  //     name: "check_for_credit_days_duriing_voucher_entry",
  //     next: ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug) ? "specify_credit_limit" : "check_for_credit_days_duriing_voucher_entry",
  //     prev: "default_credit_period"
  //   },
  //   specify_credit_limit: {
  //     name: "specify_credit_limit",
  //     next: "mailing_name",
  //     prev: data?.maintain_balance_bill_by_bill === 'No' ? 'maintain_balance_bill_by_bill' : "check_for_credit_days_duriing_voucher_entry"
  //   },
  //   type_of_ledger: {
  //     name: "type_of_ledger",
  //     next: data?.type_of_ledger === 'Not Applicable' ? "opening_balance" : "rounding_method",
  //     prev: data?.maintain_balance_bill_by_bill === 'No' ? "maintain_balance_bill_by_bill" : 'default_credit_period'
  //   },
  //   rounding_method: { name: "rounding_method", prev: "type_of_ledger" },
  //   rounding_limit: { name: "rounding_limit", prev: "rounding_method" },

  //   effective_date_for_reconciliation: {
  //     name: "effective_date_for_reconciliation",
  //     next: data?.group_slug === 'bank-od-ac' ? 'set_od_limit' : "ac_holders_name",
  //     prev: "under_id"
  //   },
  //   set_od_limit: { name: "set_od_limit", next: "ac_holders_name", prev: "effective_date_for_reconciliation" },
  //   ac_holders_name: { name: "ac_holders_name", next: 'ac_no', prev: data?.group_slug === 'bank-od-ac' ? "set_od_limit" : "effective_date_for_reconciliation" },
  //   ac_no: { name: "ac_no", next: "ifs_code", prev: "ac_holders_name" },
  //   ifs_code: { name: "ifs_code", next: "swift_code", prev: "ac_no" },
  //   swift_code: { name: "swift_code", next: "bank_name", prev: "ifs_code" },
  //   bank_name: { name: "bank_name", next: "branch", prev: "swift_code" },
  //   branch: { name: "branch", next: "set_alter_range_for_checque_books", prev: "bank_name" },
  //   set_alter_range_for_checque_books: { name: "set_alter_range_for_checque_books", next: "enable_check_printing", prev: "branch" },
  //   enable_check_printing: { name: "enable_check_printing", next: data?.enable_check_printing === 'Yes' ? "set_alter_cheque_printing_configuration" : "use_as_notional_bank_for_post_dated_vouchers", prev: "set_alter_range_for_checque_books" },
  //   set_alter_cheque_printing_configuration: { name: "set_alter_cheque_printing_configuration", next: "use_as_notional_bank_for_post_dated_vouchers", prev: "enable_check_printing" },
  //   use_as_notional_bank_for_post_dated_vouchers: { name: "use_as_notional_bank_for_post_dated_vouchers", next: "mailing_name", prev: data?.enable_check_printing === 'No' ? "enable_check_printing" : "set_alter_cheque_printing_configuration" },



  //   type_of_duty_tax: { name: "type_of_duty_tax", prev: "used_for_calculation" },
  //   tax_type: { name: "tax_type", prev: "used_for_calculation" },
  //   valuation_type: { name: "valuation_type", prev: "used_for_calculation" },
  //   rate_per_unit: { name: "rate_per_unit", prev: "used_for_calculation" },
  //   percentage_of_calculation: { name: "percentage_of_calculation", prev: "used_for_calculation" },
  //   // { name: "behave_as_duties_and_taxes_ledger", prev: "used_for_calculation" },


  //   gst_applicability: { name: "gst_applicability", prev: "used_for_calculation" },
  //   set_alter_gst_details: { name: "set_alter_gst_details", prev: "used_for_calculation" },
  //   include_in_assessable_value_calculation: { name: "include_in_assessable_value_calculation", prev: "used_for_calculation" },
  //   hsn_sac_details: { name: "hsn_sac_details", prev: "used_for_calculation" },
  //   source_of_details: { name: "source_of_details", prev: "used_for_calculation" },
  //   hsn_sac: { name: "hsn_sac", prev: "used_for_calculation" },
  //   description: { name: "description", prev: "used_for_calculation" },
  //   gst_rate_details: { name: "gst_rate_details", prev: "used_for_calculation" },
  //   taxability_type: { name: "taxability_type", prev: "used_for_calculation" },
  //   nature_of_transaction: { name: "nature_of_transaction", prev: "used_for_calculation" },
  //   igst_rate: { name: "igst_rate", prev: "used_for_calculation" },
  //   cgst_rate: { name: "cgst_rate", prev: "used_for_calculation" },
  //   sgst_utgst_rate: { name: "sgst_utgst_rate", prev: "used_for_calculation" },
  //   cess_valution_type: { name: "cess_valution_type", prev: "used_for_calculation" },
  //   cess_rate: { name: "cess_rate", prev: "used_for_calculation" },
  //   applicable_for_reverse_charge: { name: "applicable_for_reverse_charge", prev: "used_for_calculation" },
  //   eligible_for_input_tax_credit: { name: "eligible_for_input_tax_credit", prev: "used_for_calculation" },
  //   type_of_supply: { name: "type_of_supply", prev: "used_for_calculation" },

  //   mailing_name: { name: "mailing_name", next: "address", prev: "used_for_calculation" },
  //   address: { name: "address", next: "city", prev: "mailing_name" },
  //   city: { name: "city", next: "state", prev: "address" },
  //   state: { name: "state", next: "country", prev: "city" },
  //   country: { name: "country", next: "pincode", prev: "state" },
  //   pincode: { name: "pincode", next: "mobile_no", prev: "country" },
  //   email: { name: "email", next: "mobile_no", prev: "pincode" },
  //   mobile_no: { name: "mobile_no", next: "provide_contact_details", prev: "email" },
  //   provide_contact_details: { name: "provide_contact_details", next: "provide_bank_details", prev: "mobile_no" },
  //   provide_bank_details: { name: "provide_bank_details", next: "aadhaar", prev: "provide_contact_details" },
  //   aadhaar: { name: "aadhaar", next: "transport_detail", prev: "provide_bank_details" },
  //   transport_detail: { name: "transport_detail", next: "pan_it_no", prev: "aadhaar" },
  //   pan_it_no: { name: "pan_it_no", next: "opening_balance", prev: "transport_detail" },

  //   registration_type: { name: "registration_type", next: "", prev: "used_for_calculation" },
  //   assessee_of_other_territory: { name: "assessee_of_other_territory", next: "", prev: "used_for_calculation" },
  //   gst_in_uin: { name: "gst_in_uin", next: "", prev: "used_for_calculation" },
  //   use_ledger_as_common_party: { name: "use_ledger_as_common_party", next: "", prev: "used_for_calculation" },
  //   set_alter_additional_gst_details: { name: "set_alter_additional_gst_details", next: "", prev: "used_for_calculation" },
  //   ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: { name: "ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation", prev: "used_for_calculation" },

  //   opening_balance: {
  //     name: "opening_balance",
  //     next: data?.opening_balance ? "type" : null,
  //     prev:
  //       ['capital-account', 'current-assets', 'fixed-assets', 'depositsasset', 'current-liabilities', 'bank-account', 'bank-od-ac', 'branchdivisions', 'secured-loans', 'unsecured-loans', 'sundry-creditors', 'sundry-debtors', 'investments', 'loans-advance-asset', 'loansliability'].includes(data?.group_slug) ?
  //         "pan_it_no" :
  //         ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses'].includes(data?.group_slug) ?
  //           data?.type_of_ledger === 'Not Applicable' ? "type_of_ledger" : "rounding_limit" : ""
  //   },
  //   type: { name: "type", prev: "opening_balance" }
  // }

  const inputFields = {
    show: [
      // { label: 'Account No', name: 'account_no', type: 'text', isRequired: false, },
      { label: 'Name', name: 'ledger_name', type: 'text', isRequired: true, },
      { label: '(alise)', name: 'alise_name', type: 'text', isRequired: false, },
      { label: 'Under', name: 'under_id', type: 'text', isRequired: true, },
    ],
    condition1: [
      // ['capital-account', 'reserves-surplus', 'current-assets', 'depositsasset', 'current-liabilities', 'direct-expenses', 'loansliability', 'investments', 'provisions', 'misc-expenses', 'secured-loans', 'unsecured-loans', 'suspense-ac', 'loans-advance-asset', 'direct-incomes'].includes(data?.group_slug)
      {
        label: 'Maintain balance bill-by-bill',
        name: 'maintain_balance_bill_by_bill',
        type: 'text',
        isRequired: false,
        // isDrop: true,
        state: modal_state?.maintain_balance_bill_by_bill,
      },
      ...(data?.maintain_balance_bill_by_bill === 'Yes'
        ? [
          {
            label: 'Default Credit Period',
            name: 'default_credit_period',
            type: 'text',
            isRequired: false,
            isDrop: false,
          },
        ]
        : []),
    ],
    condition2: [
      // ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug)
      {
        label: 'Maintain balance bill-by-bill',
        name: 'maintain_balance_bill_by_bill',
        type: 'text',
        isRequired: false,
        isDrop: true,
        state: modal_state?.maintain_balance_bill_by_bill,
      },
      ...(data?.maintain_balance_bill_by_bill === 'Yes'
        ? [
          {
            label: 'Default Credit Period',
            name: 'default_credit_period',
            type: 'text',
            isRequired: false,
            isDrop: false,
          },
          {
            label: 'Check for Credit Days during Voucher Entry',
            name: 'check_for_credit_days_duriing_voucher_entry',
            type: 'text',
            isRequired: false,
            isDrop: true,
            state: modal_state?.check_for_credit_days_duriing_voucher_entry,

          },
        ]
        : []),
      {
        label: 'Specify Credit Limit',
        name: 'specify_credit_limit',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
    ],
    condition3: [
      // ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes'].includes(data?.group_slug)
      {
        label: 'Maintain balance bill-by-bill',
        name: 'maintain_balance_bill_by_bill',
        type: 'text',
        isRequired: false,
        // isDrop: true,
        state: modal_state?.maintain_balance_bill_by_bill,
      },
      ...(data?.maintain_balance_bill_by_bill === 'Yes'
        ? [
          {
            label: 'Default Credit Period',
            name: 'default_credit_period',
            type: 'text',
            isRequired: false,
            isDrop: false,
          },
        ]
        : []),
      {
        label: 'Type of Ledger',
        name: 'type_of_ledger',
        type: 'text',
        isRequired: false,
        isDrop: true,
      },
      ...(data?.type_of_ledger === 'Invoice Rounding'
        ? [
          {
            label: 'Rounding Method',
            name: 'rounding_method',
            type: 'text',
            isRequired: false,
            isDrop: true,
          },
          ...(data?.rounding_method === 'Downward Rounding' ||
            data?.rounding_method === 'Normal Rounding' ||
            data?.rounding_method === 'Upward Rounding'
            ? [
              {
                label: 'Rounding Limit',
                name: 'rounding_limit',
                type: 'text',
                isRequired: false,
                isDrop: false,
              },
            ]
            : []),
        ]
        : []),
    ],
    condition4: [
      // ['bank-account', 'bank-od-ac'].includes(data?.group_slug)
      {

        label: 'Effective Date for Reconciliation',
        name: 'effective_date_for_reconciliation',
        type: 'text',
        isRequired: false,
      },
      ...(data?.group_slug === 'bank-od-ac' ? [{
        label: 'Set OD Limit',
        name: 'set_od_limit',
        type: 'text',
        isRequired: false,
      }] : []),
      {
        label: 'A/c Holders Name',
        name: 'ac_holders_name',
        type: 'text',
        isRequired: false,
        isDrop: false
      },
      {
        label: 'A/c No.',
        name: 'ac_no',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
      {
        label: 'IFSC Code',
        name: 'ifs_code',
        type: 'text',
        isRequired: true,
        isDrop: false,
      },
      {
        label: 'SWIFT Code',
        name: 'swift_code',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
      {
        label: 'Bank Name',
        name: 'bank_name',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
      {
        label: 'branch',
        name: 'branch',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
      {
        label: 'Set/Alter range for Cheque Books',
        name: 'set_alter_range_for_checque_books',
        type: 'text',
        isRequired: false,
        isDrop: true,
        state: modal_state?.set_alter_range_for_checque_books,
      },
      {
        label: 'Enable Cheque Printing',
        name: 'enable_check_printing',
        type: 'text',
        isRequired: false,
        isDrop: true,
        state: modal_state?.enable_check_printing,
      },
      ...(data?.enable_check_printing === 'Yes'
        ? [
          {
            label: 'Set/Alter Cheque Printing configuration',
            name: 'set_alter_cheque_printing_configuration',
            type: 'text',
            isRequired: false,
            isDrop: true,
            state: modal_state?.set_alter_cheque_printing_configuration,
          },
        ]
        : []),
      {
        label: 'Use as National Bank for Post-Dated Vouchers',
        name: 'use_as_notional_bank_for_post_dated_vouchers',
        type: 'text',
        isRequired: false,
        isDrop: true,
        state: modal_state?.use_as_notional_bank_for_post_dated_vouchers,
      },
    ],



    dutie: [
      ...(
        data?.group_slug === 'duties-taxes'
          ? [
            {
              label: "Type of Duty/Tax",
              name: "type_of_duty_tax",
              type: "text",
              isRequired: false,
              isDrop: true,
              dropdownList: ["GST", "Others"],
            },
            ...(data?.type_of_duty_tax === "GST"
              ? [
                {
                  label: "Tax Type",
                  name: "tax_type",
                  type: "text",
                  isRequired: false,
                  isDrop: true,
                  dropdownList: ["IGST", "CGST", "SGST/UTGST", "Cess"],
                },
              ]
              : []),
            ...(data?.tax_type === "Cess"
              ? [
                {
                  label: "Valuation Type",
                  name: "valuation_type",
                  type: "text",
                  isRequired: false,
                  isDrop: true,
                  dropdownList: ["Any", "Based On Quantity", "Based On Value"],
                },
              ]
              : []),
            ...(data?.valuation_type === "Based On Quantity"
              ? [
                {
                  label: "Rate per Unit",
                  name: "rate_per_unit",
                  type: "text",
                  isRequired: false,
                },
              ]
              : [
                {
                  label: "Percentage of Calculation",
                  name: "percentage_of_calculation",
                  type: "number",
                  isRequired: false,
                },
              ]),
            {
              label: "Rounding Method",
              name: "rounding_method",
              type: "text",
              isRequired: false,
              isDrop: true,
              dropdownList: [
                "Not Applicable",
                "Downward Rounding",
                "Normal Rounding",
                "Upward Rounding",
              ],
              showCondition: data?.percentage_of_calculation || data?.rate_per_unit > 0,
            },
            {
              label: "Rounding Limit",
              name: "rounding_limit",
              type: "text",
              isRequired: false,
              showCondition:
                data?.rounding_method === "Downward Rounding" ||
                data?.rounding_method === "Normal Rounding" ||
                data?.rounding_method === "Upward Rounding",
            },
          ]
          : []),],
    // mailing details ----
    mailing: [
      // ['capital-account', 'current-assets', 'fixed-assets', 'depositsasset', 'current-liabilities', 'bank-account', 'bank-od-ac', 'branchdivisions', 'secured-loans', 'unsecured-loans', 'sundry-creditors', 'sundry-debtors', 'investments', 'loans-advance-asset', 'loansliability'].includes(data?.group_slug)
      {
        label: "Name",
        name: "mailing_name",
        type: "text",
        isRequired: true,
        editable: true,
      },
      {
        label: "Address",
        name: "address",
        type: "text",
        isRequired: false,
      },
      {
        label: "City",
        name: "city",
        type: "text",
        isRequired: false,
      },

      {
        label: "State",
        name: "state",
        type: "text",
        isRequired: false,
        isDrop: true,
      },
      {
        label: "Country",
        name: "country",
        type: "text",
        isRequired: false,
        isDrop: false,
      },

      {
        label: "Pincode",
        name: "pincode",
        type: "text",
        isRequired: false,
      },
      {
        label: "Email",
        name: "email",
        type: "text",
        isRequired: false,
      },
      {
        label: "Mobile No",
        name: "mobile_no",
        type: "text",
        isRequired: false,
      },
      {
        label: "Provide Contact Details",
        name: "provide_contact_details",
        type: "text",
        isRequired: false,
        isDrop: true,
        state: modal_state?.provide_contact_details,
      },
      {
        label: "Provide Bank Details",
        name: "provide_bank_details",
        type: "text",
        isRequired: false,
        isDrop: true,
        state: modal_state?.provide_bank_details,
      },
      {
        label: "Aadhaar No.",
        name: "aadhaar",
        type: "text",
        isRequired: false,
        isDrop: false,
      },
      {
        label: "Transport Detail",
        name: "transport_detail",
        type: "text",
        isRequired: false,
        isDrop: false,
      },

      {
        label: "PAN/IT No.",
        name: "pan_it_no",
        type: "text",
        isRequired: false,
        isDrop: false,
      },
    ],


    opening: [{ label: "Opening Balance", name: "opening_balance", type: "text", value: data?.opening_balance, ref: inputRefs?.opening_balance, isRequired: false, isDrop: false, },
    ]
  }

  useEffect(() => {
    const formdata = new FormData();
    formdata.append('company_id', userData?.id)
    dispatch(groupListCreate(formdata))
  }, []);

  const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

  const validations = useMemo(() => ({
    ledger_name: (value) => value.trim() !== "",
    alise_name: (value) => value.trim() !== "",
    mailing_name: (value) => value.trim() !== "",
    address: (value) => value.trim() !== "",
    city: (value) => value.trim() !== "",
    under_id: (value) => group_all_list.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    maintain_balance_bill_by_bill: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    check_for_credit_days_duriing_voucher_entry: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    specify_credit_limit: (value) => (/^\d+$/).test(value),
    type_of_ledger: (value) => typesOfLederList.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    rounding_method: (value) => rounding_method_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    rounding_limit: (value) => (/^\d+$/).test(value),
    set_alter_range_for_checque_books: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    enable_check_printing: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    set_alter_cheque_printing_configuration: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    use_as_notional_bank_for_post_dated_vouchers: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    state: (value) => statesList.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    pincode: (value) => (/^\d{1,6}$/).test(value),
    mobile_no: (value) => (/^\d{1,10}$/).test(value),
    provide_contact_details: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    provide_bank_details: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    opening_balance: (value) => (/^\d+$/).test(value),
  }), [data]);



  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    acc[name] = {
      onFocus: () => {
        const ref = inputRefs[name];
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        // const visibilityMap = {
        //   under_id: () => setShowList(true),
        //   maintain_balance_bill_by_bill: () => setShowMaintainBalance(true),
        //   check_for_credit_days_duriing_voucher_entry: () => setShowCheckForCredit(true),
        //   set_alter_range_for_checque_books: () => setShowSetAlterRange(true),
        //   enable_check_printing: () => setShowEnableCheqPrinting(true),
        //   set_alter_cheque_printing_configuration: () => setShowSetAlterCheqPrinting(true),
        //   use_as_notional_bank_for_post_dated_vouchers: () => setShowUseAsNational(true),
        //   state: () => setShowListState(true),
        //   provide_contact_details: () => setShowProvideContact(true),
        //   provide_bank_details: () => setShowProvideBankDetail(true),
        // };
        // if (visibilityMap[name]) visibilityMap[name]();
        if ([
          'under_id',
          'maintain_balance_bill_by_bill',
          'check_for_credit_days_duriing_voucher_entry',
          'set_alter_range_for_checque_books',
          'enable_check_printing',
          'set_alter_cheque_printing_configuration',
          'use_as_notional_bank_for_post_dated_vouchers',
          'state',
          'provide_contact_details',
          'provide_bank_details'].includes(name)) {
          if (name) {
            dispatch(openModal(name));
          }
        }
      },
      onBlur: (value) => {
        if (name === 'ledger_name') {
          if (!value.trim()) {
            inputRefs[name]?.current?.focus();
          }
        }
        // const hideMap = {
        //   under_id: () => setShowList(false),
        //   maintain_balance_bill_by_bill: () => setShowMaintainBalance(false),
        //   check_for_credit_days_duriing_voucher_entry: () => setShowCheckForCredit(false),
        //   set_alter_range_for_checque_books: () => setShowSetAlterRange(false),
        //   enable_check_printing: () => setShowEnableCheqPrinting(false),
        //   set_alter_cheque_printing_configuration: () => setShowSetAlterCheqPrinting(false),
        //   use_as_notional_bank_for_post_dated_vouchers: () => setShowUseAsNational(false),
        //   state: () => setShowListState(false),
        //   provide_contact_details: () => setShowProvideContact(false),
        //   provide_bank_details: () => setShowProvideBankDetail(false),
        // };
        // if (hideMap[name]) hideMap[name]();
        if ([
          'under_id',
          'maintain_balance_bill_by_bill',
          'check_for_credit_days_duriing_voucher_entry',
          'set_alter_range_for_checque_books',
          'enable_check_printing',
          'set_alter_cheque_printing_configuration',
          'use_as_notional_bank_for_post_dated_vouchers',
          'state',
          'provide_contact_details',
          'provide_bank_details'].includes(name)) {
          if (name) {
            dispatch(closeModal(name));
          }
        }
      },
    };
    return acc;

  }, {})

  const check_dropdown = modal_state?.under_id || modal_state?.maintain_balance_bill_by_bill || modal_state?.check_for_credit_days_duriing_voucher_entry || modal_state?.set_alter_range_for_checque_books || modal_state?.enable_check_printing || modal_state?.set_alter_cheque_printing_configuration || modal_state?.use_as_notional_bank_for_post_dated_vouchers || modal_state?.state || modal_state?.provide_bank_details || modal_state?.provide_contact_details
  const { handleChange, handleFocus, handleBlur, handleKeyDown, setSubmitConfirm, submitConfirm } = useInputActionLedger({
    data,
    setData,
    validations,
    refs,
    check_dropdown,
    inputRefs
  });

  const validateForm = () => {

    const requiredFields = [
      { field: 'ledger_name', message: 'Required ledger Name Field' },
      { field: 'under_id', message: 'Required Under Field' },
      { field: 'group_id', message: 'Required under' },
      { field: 'company_id', message: 'Required company id' },
    ]
    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        inputRefs[field]?.current?.focus();
        return false;
      }
    }
    return true;
  };
  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setData((prev) => {
      const updatedState = { ...initialState };
      updatedState['under_id'] = prev.under_id;
      updatedState['group_id'] = prev.group_id;
      updatedState['group_slug'] = prev.group_slug;
      updatedState['nature_of_group'] = prev.nature_of_group;
      return updatedState;
    });
  };

  const { handleSubmit, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(ledgerCreate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref: inputRefs?.ledger_name,
    closeModal,
    setSubmitConfirm,
    submitConfirm
  });



  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };

  useClickOutside(nameInput, handleClickOutside, handleClickInside);

  const dropdownConfig = {
    under_id: { title: 'List of Group', input_id: 'group_id', data: group_all_list },
    state: { title: 'List of State', data: statesList },
  };

  const renderInputFields = useCallback((condition) =>
    inputFields[condition]?.map(({ label, name, type, isRequired, isDrop, state, setState }, index) => (
      <InputField
        key={index}
        lable={label}
        name={name}
        type={type}
        value={data[name]}
        isRequired={isRequired}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        ref={inputRefs[name]}
        show_drop={state}
        setDrop_add_show={setState}
        setData={setData}
        data={data}
        isDrop={isDrop}
        refs={refs}
      />
    )), [data, handleChange, handleBlur, handleFocus, inputRefs])

  const conditionMappings = {
    condition1: [
      'capital-account', 'reserves-surplus', 'current-assets', 'depositsasset', 'current-liabilities',
       'loansliability', 'investments', 'provisions',
      'secured-loans', 'unsecured-loans', 'suspense-ac', 'loans-advance-asset',
    
    ],
    condition2: ['branchdivisions', 'sundry-creditors', 'sundry-debtors'],
    condition3: ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses'],
    condition4: ['bank-account', 'bank-od-ac']
  };
  const renderCondition = (group_slug) => {
    for (let condition in conditionMappings) {
      if (conditionMappings[condition].includes(group_slug)) {
        return renderInputFields(condition);
      }
    }
    return null;
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title={title || 'Ledger Creation'}
      loading={loading}
      col='col-12'
      Footer={MasterCreationFooter}
    >
      <div className='row p-0  m-0 mb-1'>
        <div className=' col-md-6 m-0 p-0 ps-2' style={{ height: '18vh' }}>
          {renderInputFields('show')}
          {/* {
            showList && (
              <DropdownLayout_1
                title='List of Group'
                state={showList}
                setState={setShowList}
                data={data}
                setData={setData}
                input_value={data?.under_id}
                input_key='under_id'
                input_id='group_id'
                rawData={group_all_list}
                ref={inputRefs.under_id}
                refs={refs}
              />)} */}
          {Object.entries(dropdownConfig).map(([key, config]) => (
            modal_state?.[key] && (
              <DropdownLayout_1
                key={key}
                title={config.title}
                state={modal_state[key]}
                data={data}
                setData={setData}
                input_value={data[key]}
                input_key={key}
                input_id={config?.input_id}
                rawData={config.data}
                ref={inputRefs[key]}
                inputRefs={inputRefs}
                setSubmitConfirm={setSubmitConfirm}
              />
            )
          ))}
        </div>
        <div className='border_2_1px_top'></div>

        <div className='col-md-12 m-0 p-0 ps-2' style={{ height: '60vh' }}>
          <div className='row'>
            <div className=' col-lg-6 pt-1 border_2_1px_right' style={{ height: '60vh' }}>
              {/* {['capital-account', 'reserves-surplus', 'current-assets', 'depositsasset', 'current-liabilities', 'direct-expenses', 'loansliability', 'investments', 'provisions', 'misc-expenses', 'secured-loans', 'unsecured-loans', 'suspense-ac', 'loans-advance-asset', 'direct-incomes', 'indirect-expenses', 'indirect-incomes'].includes(data?.group_slug) ?
                renderInputFields('condition1') : null
              }
              {
                ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug) ?
                  renderInputFields('condition2') : null
              }
              {
                ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses'].includes(data?.group_slug) ?
                  renderInputFields('condition3')
                  : null
              }
              {
                ['bank-account', 'bank-od-ac'].includes(data?.group_slug) ?
                  renderInputFields('condition4')
                  : null
              } */}
              {
                renderCondition(data?.group_slug)
              }
            </div>
            <div className='col-md-6'>
              <p className='text-start p-0 m-0 pt-1' style={{ fontWeight: 'bold' }}> <u>Mailing Details</u></p>
              {
                ['capital-account', 'current-assets', 'fixed-assets', 'depositsasset', 'current-liabilities', 'bank-account', 'bank-od-ac', 'branchdivisions', 'secured-loans', 'unsecured-loans', 'sundry-creditors', 'sundry-debtors', 'investments', 'loans-advance-asset', 'loansliability'].includes(data?.group_slug) ?
                  renderInputFields('mailing')
                  : null
              }
            </div>
          </div>
        </div>
        <div className='row border_2_1px_top m-0 p-0 ps-2 pt-1 justify-content-center'>
          <div className='col-lg-4 p-0 m-0'>
            {
              inputFields?.opening?.slice(0, 1)?.map(({ label, name, type, value, ref, isRequired }, index) => {
                return (
                  <InputField
                    key={index}
                    lable={label}
                    type={type}
                    name={name}
                    value={value}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    isRequired={isRequired}
                    ref={ref}
                  />
                )
              })
            }
          </div>
          <div className='col-lg-1 text-start  p-0 m-0 ps-1'>
            {
              data?.opening_balance ?
                <input
                  style={{ fontSize: 14, width: 30 }}
                  autoCapitalize='true'
                  type='text'
                  autoComplete="off"
                  name='type'
                  id='type'
                  value={data?.type || ""}
                  onKeyUp={handleChange}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  ref={inputRefs?.type}
                /> : null
            }

          </div>

        </div>
      </div>
    </Form>
  )
}
export default Ledger;
