import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LINK_PATH } from '../../../../constant';
import { useDropdownKeyboardNavigation } from '../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../../../components/NavigationList/NavigationList';
import useKeyboardNavigation from '../../../../components/Custom_Hooks/useKeyboardNavigation';

const StatementofInventory = () => {
   
    const navigate = useNavigate();
    const location = useLocation();

    const keyMap = {
        'i': '',
        'g': '',
        's': '',
        'c': '',
        't': '',
        'p': '',
    };

    useKeyboardNavigation(keyMap);

    const data = [
        { to: '', text: 'Stock Query' },
        { to: '', text: 'Movement Analysis' },
        { to: '', text: 'Ageing Analysis' },
        { to: '', text: 'Reorder Status' },
        { to: '', text: 'COst Estimation' },
        { to: '', text: 'SaLes Order Outstandings' },
        { to: '', text: 'Purchase Order Outstandings ' },
        { to: '', text: 'Sale Bills Pending' },
        { to: '', text: 'PUrchase Bills Pending' },
        { to: '', text: 'Quit' }
    ];
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: data,
        state: true,
        onItemSelect: (item) => {
            if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
                return;
            }
            navigate(item.to)
        },
    });

    return (
        <NavigationList
            data={data}
            selectedItemIndex={selectedItemIndex}
            setSelectedItemIndex={setSelectedItemIndex}
            title='Statements of Inventory'
            first_heading='STOCK'
        />
    )
}

export default React.memo(StatementofInventory);
