import React from 'react'
import FooterLayout from '../FooterLayout'

const MasterCreationFooter=(props)=> {
    const fields = [
        { id: "1", name: "Back", shortcutKey: "Esc", type: 'button', line: '', isDisabled: false },
        { id: "2", name: "", shortcutKey: "", type: 'button', line: '', isDisabled: false },
        { id: "3", name: "", shortcutKey: "", type: 'button', line: '', isDisabled: false },
        { id: "4", name: "Accept", shortcutKey: "A", type: 'submit', line: 'double', isDisabled: false, },
        { id: "5", name: "", shortcutKey: "", type: 'button', line: '', isDisabled: false },
        { id: "6", name: "", shortcutKey: "", type: 'button', line: '', isDisabled: false },
        { id: "7", name: "", shortcutKey: "", type: 'button', line: '', isDisabled: false },
        { id: "8", name: "", shortcutKey: "", type: 'button', line: '', isDisabled: false },
        { id: "9", name: "", shortcutKey: "", type: 'button', line: '', isDisabled: false },
        { id: "10", name: "", shortcutKey: "", type: 'button', line: '', isDisabled: false },
    ]
    return (
        <div className='tomasterfooter'>
            <div className='footer'>
                {
                    fields.map((item, index) => {
                        return (
                            <FooterLayout key={item.id} fields={item} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default MasterCreationFooter
