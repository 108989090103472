import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unitsUpdate, unitsSingleData, unitsListing, uqcCreate, uqcListing } from '../../../../../redux/actions/action-creator';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import Form from '../../../../../components/Form/Form';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { useInputAction } from '../../../../../components/Custom_Hooks/useInputAction';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
const type_of_unit_array = [{ id: 1, name: 'Simple' }, { id: 2, name: 'Compound' }]

const Unit = ({ data_to_update, title, closeModal }) => {
  const dispatch = useDispatch();

  const [showList, setShowList] = useState(false);
  const [showListType, setShowListType] = useState(false);
  const [showListFirst, setShowListFirst] = useState(false);
  const [showListSecound, setShowListSecound] = useState(false);
  const [nameInput, setNameInput] = useState(null)
  const [load, setLoad] = useState(false)

  const { unit_data = null } = useSelector(state => state.common);
  const { uqc_code_response = null } = useSelector(state => state.common);
  const { userData = null } = useSelector(state => state.auth);

  const initialState = {
    type: 'Simple',
    symbol: '',
    formal_name: '',
    unit_quantity_code: 'Not Applicable',
    uqc_id: 'Not Applicable',
    no_decimal_places: '0',
    first_unit: '',
    first_unit_id: '',
    conversion: '',
    second_unit: '',
    secound_unit_id: '',
    uqc_code_create_name: '',
    company_id: userData?.id,
    id: data_to_update?.id
  }
  const [data, setData] = useState(initialState);

  const inputRefs = {
    type: useRef(null),
    symbol: useRef(null),
    formal_name: useRef(null),
    unit_quantity_code: useRef(null),
    no_decimal_places: useRef(null),
    first_unit_id: useRef(null),
    conversion: useRef(null),
    secound_unit_id: useRef(null),
  };
  const first_ref = data?.type === 'Simple' ? inputRefs?.symbol : inputRefs?.first_unit_id

  function getCurrentInputRef(name) {
    return inputRefs[name] || null;
  }

  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(uqcListing(formData))
    dispatch(unitsListing(formData))
  }, [])

  useEffect(() => {
    setLoad(true)
    const formData = new FormData();
    formData.append('id', data_to_update?.id)
    dispatch(unitsSingleData(formData)).then((response) => {
      if (response.status) {
        console.log("this is response :----",response)
        setData({
          type: response.data?.type,
          symbol: response.data?.symbol,
          formal_name: response.data?.formal_name,
          unit_quantity_code: response.data?.uqc_name,
          uqc_id: response.data?.uqc_id,
          number_of_decimal_places: response.data?.no_decimal_places,
        })
        setLoad(false)
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.Responsemessage || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }, [])

  const fieldConfig = [
    { name: "type", next: data?.type === 'Simple' ? "symbol" : "first_unit_id" },
    { name: "symbol", next: "formal_name", prev: "type" },
    { name: "formal_name", next: "unit_quantity_code", prev: "symbol" },
    { name: "unit_quantity_code", next: 'no_decimal_places', prev: 'formal_name' },
    { name: "no_decimal_places", prev: "unit_quantity_code" },
    { name: "first_unit_id", next: "conversion", prev: "type" },
    { name: "conversion", next: "secound_unit_id", prev: "first_unit_id" },
    { name: "secound_unit_id", prev: "conversion" },
  ];

  const inputFields = [
    { label: 'Type', name: 'type', type: 'text', isRequired: true, },
    { label: 'Symbol', name: 'symbol', type: 'text', isRequired: true, },
    { label: 'Formal Name', name: 'formal_name', type: 'text', isRequired: true, },
    { label: 'Unit Quantity Code (UQC)', name: 'unit_quantity_code', type: 'text', isRequired: false },
    { label: 'Number of Decimal Places', name: 'no_decimal_places', type: 'text', isRequired: false, },
    { label: 'First Unit', name: 'first_unit_id', type: 'text', isRequired: true },
    { label: 'Conversion', name: 'conversion', type: 'text', isRequired: true },
    { label: 'Secound Unit', name: 'secound_unit_id', type: 'text', isRequired: true },
  ];


  const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase();
  const validations = {
    symbol: (value) => value.trim() !== "",
    conversion: (value) => value.trim() !== "",
    no_decimal_places: (value) => value.trim() !== "",
    formal_name: (value) => value.trim() !== "",
    unit_quantity_code: (value) => uqc_code_response?.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    type: (value) => type_of_unit_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    first_unit_id: (value) => unit_data?.some(item => cleanString(item?.symbol)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    secound_unit_id: (value) => unit_data?.some(item => cleanString(item?.symbol)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
  };

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    const nextField = fieldConfig.find(f => f.name === name)?.next;
    const prevField = fieldConfig.find(f => f.name === name)?.prev;
    acc[name] = {
      next: nextField ? inputRefs[nextField] : null,
      prev: prevField ? inputRefs[prevField] : null,
      curr: getCurrentInputRef(name),
      onFocus: () => {
        const ref = getCurrentInputRef(name);
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if (name === 'type') {
          setShowListType(true)
        }
        if (name === 'unit_quantity_code') {
          setShowList(true)
        }

        if (name === 'first_unit_id') {
          setShowListFirst(true)
        }
        if (name === 'secound_unit_id') {
          setShowListSecound(true)
        }
      },
      onBlur: (value) => {
        if (name === 'name') {
          if (!value.trim()) {
            getCurrentInputRef(name)?.current?.focus();
          }
        }
        if (name === 'type') {
          setShowListType(false)
        }
        if (name === 'unit_quantity_code') {
          setShowList(false)
        }

        if (name === 'first_unit_id') {
          setShowListFirst(false)
        }
        if (name === 'secound_unit_id') {
          setShowListSecound(false)
        }
      },
    };
    return acc;
  }, {});


  const validateForm = () => {
    let requiredFields;
    if (data?.type === 'Simple') {
      requiredFields = [
        { field: 'type', message: 'Required Name Field' },
        { field: 'symbol', message: 'Required Under Field' },
        { field: 'formal_name', message: 'Required Nature of Group' },
        { field: "unit_quantity_code", message: 'Required Uqc Field' },
        { field: "no_decimal_places", message: 'Required No Decimal Place Field' },
      ];
    } else {
      requiredFields = [
        { field: 'type', message: 'Required Name Field' },
        { field: 'first_unit', message: 'Required Group Behave Like Sub Group' },
        { field: 'first_unit_id', message: 'Required Nett Debit Field' },
        { field: 'second_unit', message: 'Required Used For Calculation' },
        { field: 'secound_unit_id', message: 'Required Method to Allocate' },
        { field: 'conversion', message: 'Required Conversion Field' },
      ];
    }

    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        getCurrentInputRef(field)?.current?.focus();
        return false;
      }
    }
    return true;
  };


  const resetForm = () => {
    setData(initialState)
  };


  const { handleSubmit, setSubmitConfirm, submitConfirm, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(unitsUpdate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref,
    closeModal
  });

  const check_dropdown = showList || showListType || showListFirst || showListSecound
  // || showNatureList || showYesNoList || showUsedForList || showNetDebitList

  const { handleChange, handleFocus, handleBlur, handleKeyDown } = useInputAction({
    data,
    setData,
    validations,
    refs,
    setSubmitConfirm,
    check_dropdown,
  });

  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };
  useClickOutside(nameInput, handleClickOutside, handleClickInside);
  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title={title || 'Unit Creation'}
      loading={loading || load}
      col='col-6'
      Footer={MasterCreationFooter}
    >
      <div className='row p-0 ps-2 m-0 mb-1'>
        {inputFields.slice(0, 1).map(({ label, name, type, isRequired }, index) => (
          <InputField
            key={index}
            lable={label}
            type={type}
            name={name}
            value={data[name]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={inputRefs[name]}
          />
        ))}
        {
          showListType || showList || showListFirst || showListSecound ?
            <DropdownLayout_1
              title='List of Unit Type'
              state={showListType || showList || showListFirst || showListSecound}
              setState={showListType ? setShowListType : showList ? setShowList : showListFirst ? setShowListFirst : setShowListSecound}
              data={data}
              setData={setData}
              input_value={showListType ? data?.type : showList ? data?.unit_quantity_code : showListFirst ? data?.first_unit_id : data?.secound_unit_id}
              input_key={showListType ? 'type' : showList ? 'unit_quantity_code' : showListFirst ? 'first_unit_id' : 'secound_unit_id'}
              input_id={showList ? 'uqc_id' : showListFirst ? 'first_unit_id' : 'secound_unit_id'}
              rawData={showListType ? type_of_unit_array : showList ? uqc_code_response : showListFirst ? unit_data : unit_data}
              ref={showListType ? inputRefs?.type : showList ? inputRefs?.unit_quantity_code : showListFirst ? inputRefs?.first_unit_id : inputRefs?.secound_unit_id}
              refs={refs}
              setSubmitConfirm={setSubmitConfirm}
            /> : null}
        {
          data?.type === 'Simple' ?
            <>
              {inputFields.slice(1, 5).map(({ label, name, type, isRequired }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  ref={inputRefs[name]}
                />
              ))}
            </> :
            <>
              <div className='col-12 d-flex align-items-center justify-content-center flex-column'>
                <p className='m-0 mt-1 p-0'><strong><u>Units with multiplier Factors</u></strong></p>
                <p className='m-0 p-0'>(example: kgs of 1000 gms)</p>
              </div>
              {inputFields.slice(5).map(({ label, name, type, isRequired }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  ref={inputRefs[name]}
                />
              ))}
            </>
        }


      </div>
    </Form>
  )
}

export default Unit
