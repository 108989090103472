import React from 'react'

function NavLayout({children}) {
    return (
        <div className='row table_date_header' style={{height:19.5}} >
            <div className='col-12 d-flex justify-content-between table_header_font'style={{height:19.5}}>
                {children}
            </div>
        </div>
    )
}

export default NavLayout
