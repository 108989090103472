import { stockCompanySingleData, stockCompanyUpdate } from '../../../../../redux/actions/action-creator';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import Form from '../../../../../components/Form/Form';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { useInputAction } from '../../../../../components/Custom_Hooks/useInputAction';
import InputField from '../../../../../components/Input/InputField';

function StockCompany({ data_to_update, title, closeModal }) {
    const dispatch = useDispatch();
    const [load, setLoad] = useState(false)
    const [nameInput, setNameInput] = useState(null)

    const { userData = null } = useSelector(state => state.auth);

    const initialState = {
        name: '',
        alias_name: '',
        company_id: userData?.id,
        id: data_to_update?.id
    }
    const [data, setData] = useState(initialState);

    useEffect(() => {
        setLoad(true)
        const formData = new FormData();
        formData.append('id', data_to_update?.id)
        dispatch(stockCompanySingleData(formData)).then((response) => {
            if (response.status) {
                setData({
                    name: response.data?.name,
                    alias_name: response.data?.alias_name,
                    company_id: userData?.id,
                    id: data_to_update?.id
                })
                setLoad(false)
            }
            else {
                toast.warn(response?.message || 'Fail !', {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).catch(error => {
            console.log(error)
            toast.error(error?.Responsemessage || ' Failed!', {
                position: toast.POSITION.TOP_CENTER
            });
        })
    }, [])

    const inputRefs = {
        name: useRef(null),
        alias_name: useRef(null),
    };
    const first_ref = inputRefs?.name

    function getCurrentInputRef(name) {
        return inputRefs[name] || null;
    }
    const fieldConfig = [
        { name: "name", next: "alias_name" },
        { name: "alias_name", prev: "name" },
    ];
    const inputFields = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            isRequired: true,
        },
        {
            label: '(alise)',
            name: 'alias_name',
            type: 'text',
            isRequired: false,
        },
    ];

    const validations = {
        name: (value) => value.trim() !== "",
        alias_name: (value) => value.trim() !== "",
    };

    const refs = Object.keys(inputRefs).reduce((acc, name) => {
        const nextField = fieldConfig.find(f => f.name === name)?.next;
        const prevField = fieldConfig.find(f => f.name === name)?.prev;
        acc[name] = {
            next: nextField ? inputRefs[nextField] : null,
            prev: prevField ? inputRefs[prevField] : null,
            curr: getCurrentInputRef(name),
            onFocus: () => {
                const ref = getCurrentInputRef(name);
                if (ref) {
                    setNameInput(ref)
                    ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
                };
            },
            onBlur: (value) => {
                if (name === 'name') {
                    if (!value.trim()) {
                        getCurrentInputRef(name)?.current?.focus();
                    }
                }
            },
        };
        return acc;
    }, {});


    const validateForm = () => {
        const requiredFields = [
            { field: 'name', message: 'Required Name Field' },
        ]

        for (let { field, message } of requiredFields) {
            if (!data?.[field]) {
                toast.error(message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setSubmitConfirm('');
                getCurrentInputRef(field)?.current?.focus();
                return false;
            }
        }
        return true;
    };

    // Function to reset form fields-----------------------------------------
    const resetForm = () => {
        setData(initialState)
    };

    const { handleSubmit, setSubmitConfirm, submitConfirm, loading, handleFocusReset } = useFormHandler({
        validateForm,
        prepareFormData: () => prepareFormData(data),
        submitForm: (data) => dispatch(stockCompanyUpdate(data)),
        shortcuts: [
            {
                key: 'a',
                ctrlKey: true,
                action: () => handleSubmit(new Event('submit')),
            },
        ],
        resetForm,
        first_ref,
        closeModal
    });

    const { handleChange, handleFocus, handleBlur, handleKeyDown } = useInputAction({
        data,
        setData,
        validations,
        refs,
        setSubmitConfirm,
        check_dropdown: false,
    });

    const handleClickOutside = (event) => {
        if (nameInput.current) {
            event.preventDefault();
            nameInput.current.focus();
        }
    };

    const handleClickInside = (event) => {
        let clickedElement = event.target;
        if (clickedElement?.tagName &&
            (clickedElement?.tagName.toLowerCase() === 'input' ||
                clickedElement?.tagName.toLowerCase() === 'textarea')) {
            clickedElement.focus();
        }
    };

    useClickOutside(nameInput, handleClickOutside, handleClickInside);


    return (
        <Form
            handleSubmit={handleSubmit}
            handleKeyDown={handleKeyDown}
            submitConfirm={submitConfirm}
            handleFocusReset={handleFocusReset}
            title={title || 'Stock Company Alteration'}
            loading={loading||load}
            col='col-5'
            Footer={MasterCreationFooter}
        >
            <div className='row p-0 ps-2 m-0 mb-1'>
                {inputFields?.map(({ label, name, type, value, ref, isRequired }, index) => (
                    <InputField
                        key={index}
                        lable={label}
                        type={type}
                        name={name}
                        value={data[name]}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleFocus={handleFocus}
                        isRequired={isRequired}
                        ref={inputRefs[name]}
                    />
                ))}
            </div>
        </Form>
    )
}

export default StockCompany;
