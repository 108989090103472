import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ledgerBookList } from "../../../../../redux/actions/action-creator";
import MasterCreationFooter from "../../../../../components/Footer/Master_Footer/MasterCreationFooter";
import Form from "../../../../../components/Form/Form";
import { useDropdownFilter } from "../../../../../components/Custom_Hooks/useDropdownFilter";
import { useDropdownScroll } from "../../../../../components/Custom_Hooks/useDropdownScroll";
import { useDropdownKeyboardNavigation } from "../../../../../components/Custom_Hooks/useDropdownKeyboardNavigation";

function LedgerBook(props) {

    const dropdownRef = useRef(null)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [load,setLoad]= useState(false)

    const [all_data, setall_data] = useState([])
    const [input_value, setInputValue] = useState({ input_name: '' })
    const input_ref = useRef(null)

    const { userData = null } = useSelector(state => state.auth);

    // Triger function whenever the change input value 
    const handlechange = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...input_value, [name]: value })
    }


    // Api call to get data of ledger book
    useEffect(() => {
        setLoad(true)
        const data = new FormData();
        data.append('company_id', userData?.id)
        dispatch(ledgerBookList(data)).then((response) => {
            if (response.status) {
                setall_data(response.data)
                setLoad(false)
            }
        })
    }, [])


    const { filteredData } = useDropdownFilter({
        dataList: all_data,
        searchInput: input_value?.input_name,
        inputRef: input_ref,
    });
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: filteredData,
        state: true,
        onItemSelect: (item) => {
            navigate(`../ledgervoucher/${item?.id}`)
        },
        input_value: input_value?.input_name
    });
    useDropdownScroll({ dropdownRef, selectedItemIndex });

    return (
        <Form
            title={'Select Ledger'}
            loading={load}
            col='col-12'
            Footer={MasterCreationFooter}
        >
            <div className='row mt-0'>
                <div className="col-12">
                    <input
                        autoFocus
                        autoComplete="off"
                        className='voucher_entry_account_input'
                        type="text"
                        id='input_name'
                        name='input_name'
                        style={{ margin: 0, padding: 0, paddingLeft: 5 }}
                        onKeyUp={handlechange}
                        onChange={handlechange}
                        ref={input_ref}
                        value={input_value?.input_name || ""} />
                </div>
            </div>
            <div className='row border'>
                <div className='row table_date_header' style={{ height: 19.5 }} >
                    <div className='col-12 table_header_font' >
                        <div className="row ps-1">
                            <div className="col-1 text-end" style={{ width: '5%' }}>A/c</div>
                            <div className="col-3 text-start" style={{ width: '33%' }}>Ledger Name</div>
                            <div className="col-2 text-start" style={{ width: '15%' }}>Group</div>
                            <div className="col-2 text-start" style={{ width: '15%' }}>City</div>
                            <div className="col-1 text-start" style={{ width: '8%' }}>Mobile No</div>
                            <div className="col-2 text-start" style={{ width: '11%' }}>Aadhaar No</div>
                            <div className="col-1 text-end" style={{ width: '13%' }}>Closing Bal.</div>
                        </div>
                    </div>
                </div>
                <div className='row' >
                    <div className="col-12 m-0 p-0" style={{ height: '80vh' }}>
                        <div className="row">
                            {filteredData?.length > 0 ? (
                                filteredData?.map((key, number) => {
                                    const name = key?.ledger_name
                                    const id = key?.id
                                    const index = name?.toLowerCase()?.indexOf(input_value?.input_name?.toLowerCase());
                                    return (
                                        <div
                                            className={`col-12 table_header_font dropdown_link ${number === selectedItemIndex ? 'selected' : ''}`}
                                            key={number}
                                            style={{ height: 19 }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedItemIndex(number)
                                                if (number === selectedItemIndex) {
                                                }
                                            }} >
                                            <div className="row m-0 p-0 ps-1">
                                                <div className="col-1 text-end" style={{ width: '5%' }}>55559</div>
                                                <div className="col-3 text-start" style={{ width: '33%' }}>
                                                    {index !== -1 ? (
                                                        < >
                                                            {name?.substring(0, index)}
                                                            <span style={{ color: 'red' }}>{name?.substring(index, index + input_value?.input_name?.length)}</span>
                                                            {name?.substring(index + input_value?.input_name?.length)}
                                                        </>
                                                    ) : (
                                                        name
                                                    )}
                                                </div>
                                                <div className="col-2 text-start" style={{ width: '15%' }}>Purchase ledger Account</div>
                                                <div className="col-2 text-start" style={{ width: '15%' }}>Bhopal MP Nagar</div>
                                                <div className="col-1 text-start" style={{ width: '8%' }}>9111188400</div>
                                                <div className="col-2 text-start" style={{ width: '11%' }}>5555 7777 8888</div>
                                                <div className="col-1 text-end" style={{ width: '13%' }}>10,00,00,000.00 Dr</div>
                                            </div>
                                        </div>

                                    );
                                })
                            ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
}
export default LedgerBook;