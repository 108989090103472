
import { groupListCreate, groupSingle, groupUpdate } from '../../../../../redux/actions/action-creator';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import Form from '../../../../../components/Form/Form';
import { yes_no_array, nature_of_group_arrya, applicable_not_applicable_array } from '../../../../../components/Dummi_Data';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { useInputAction } from '../../../../../components/Custom_Hooks/useInputAction';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';


const GroupAlter = ({data_to_update,title,closeModal}) => {
  const dispatch = useDispatch();

  const [showList, setShowList] = useState(false)
  const [nameInput, setNameInput] = useState(null)
  const [showNatureList, setShowNatureList] = useState(false)
  const [showYesNoList, setShowYesNoList] = useState(false)
  const [showNetDebitList, setShowNetDebitList] = useState(false)
  const [showUsedForList, setShowUsedForList] = useState(false)
  const [showMethodForList, setShowMethodForList] = useState(false)
  const [load, setLoad] = useState(false)

  // get redux state -----------------------------------------------------
  const group_all_list = useSelector(state => state.common.group_all_list);
  const { userData = null } = useSelector(state => state.auth);
  const str = '\u2666';
  const initialState = {
    company_id: userData?.id,
    name: '',
    alise_name: '',
    under_id: 'Capital Account',
    under: '5',
    nature_of_group: 'Liabilities',
    group_behaves_like_sub_ledger: 'No',
    nett_debit_credit_balance_for_reporting: 'No',
    used_for_calculation: 'No',
    method_to_allocate_when_used_in_purchase_invoice: `${str} Not Applicable`,
    id: data_to_update?.id
    // status:'Yes'
  }
  const [data, setData] = useState(initialState);
  const inputRefs = {
    name: useRef(null),
    alise_name: useRef(null),
    under_id: useRef(null),
    nature_of_group: useRef(null),
    group_behaves_like_sub_ledger: useRef(null),
    nett_debit_credit_balance_for_reporting: useRef(null),
    used_for_calculation: useRef(null),
    method_to_allocate_when_used_in_purchase_invoice: useRef(null),
    // status: useRef(null),
  };
  const first_ref = inputRefs?.name

  function getCurrentInputRef(name) {
    return inputRefs[name] || null;
  }


  const fieldConfig = [
    { name: "name", next: "alise_name" },
    { name: "alise_name", next: "under_id", prev: "name" },
    { name: "under_id", next: "group_behaves_like_sub_ledger", prev: "alise_name" },
    { name: "group_behaves_like_sub_ledger", next: data.under_id === 'Primary' ? 'nature_of_group' : "nett_debit_credit_balance_for_reporting", prev: data.under_id === 'Primary' ? 'nature_of_group' : "under_id" },
    { name: "nature_of_group", next: "nett_debit_credit_balance_for_reporting", prev: "under_id" },
    { name: "nett_debit_credit_balance_for_reporting", next: "used_for_calculation", prev: "group_behaves_like_sub_ledger" },
    { name: "used_for_calculation", next: "method_to_allocate_when_used_in_purchase_invoice", prev: "nett_debit_credit_balance_for_reporting" },
    { name: "method_to_allocate_when_used_in_purchase_invoice", prev: "used_for_calculation" },
    // { name: "status", prev: "method_to_allocate_when_used_in_purchase_invoice" },
  ];
  const inputFields = [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      value: data?.name,
      ref: inputRefs?.name,
      isRequired: true,
    },
    {
      label: '(alise)',
      name: 'alise_name',
      type: 'text',
      value: data?.alise_name,
      ref: inputRefs?.alise_name,
      isRequired: false,
    },
    {
      label: 'Under',
      name: 'under_id',
      type: 'text',
      value: data?.under_id,
      ref: inputRefs?.under_id,
      isRequired: true,
    },
    // {
    //   label: 'Nature Of Group',
    //   name: 'nature_of_group',
    //   type: 'text',
    //   value: data?.nature_of_group,
    //   ref: inputRefs?.nature_of_group,
    //   isRequired: true,
    // },

    // Dropdown Fields
    {
      label: 'Group behaves like a sub-ledger',
      name: 'group_behaves_like_sub_ledger',
      type: 'text',
      value: data?.group_behaves_like_sub_ledger,
      ref: inputRefs?.group_behaves_like_sub_ledger,
      isRequired: true,
      isDrop: true,
      state: showYesNoList,
      setState: setShowYesNoList,
    },
    ...(data?.under_id === 'Primary' ? [{
      label: 'Nature Of Group',
      name: 'nature_of_group',
      type: 'text',
      value: data?.nature_of_group,
      ref: inputRefs?.nature_of_group,
      isRequired: true,
    }] : []),
    {
      label: 'Nett Debit/Credit Balance for Reporting',
      name: 'nett_debit_credit_balance_for_reporting',
      type: 'text',
      value: data?.nett_debit_credit_balance_for_reporting,
      ref: inputRefs?.nett_debit_credit_balance_for_reporting,
      isRequired: true,
      isDrop: true,
      state: showNetDebitList,
      setState: setShowNetDebitList,
    },
    {
      label: 'Used for calculation (for example: taxes, discounts)',
      name: 'used_for_calculation',
      type: 'text',
      value: data?.used_for_calculation,
      ref: inputRefs?.used_for_calculation,
      isRequired: true,
      isDrop: true,
      state: showUsedForList,
      setState: setShowUsedForList,
    },
    {
      label: 'Method to allocate when used in purchase Invoice',
      name: 'method_to_allocate_when_used_in_purchase_invoice',
      type: 'text',
      value: data?.method_to_allocate_when_used_in_purchase_invoice,
      ref: inputRefs?.method_to_allocate_when_used_in_purchase_invoice,
      isRequired: true,
      isDrop: true,
      state: showMethodForList,
      setState: setShowMethodForList,
    },
  ];

  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(groupListCreate(formData)).then((response) => {
      if (response.status) {
      }
    })
  }, [])

  useEffect(() => {
    setLoad(true)
    dispatch(groupSingle(data_to_update?.id)).then((response) => {
      if (response.status) {
        // const formData = new FormData();
        // formData.append('company_id', userData?.id)
        // dispatch(groupListCreate(formData))
        console.log("this is :---",response)
        setData({
          name: response.data.name,
          alise_name: response.data.alise_name,
          under_id: response.data.under_name,
          under: response.data.under,
          nature_of_group: response.data.nature_of_group,
          group_behaves_like_sub_ledger: response.data.group_behaves_like_sub_ledger,
          nett_debit_credit_balance_for_reporting: response.data.nett_debit_credit_balance_for_reporting,
          used_for_calculation: response.data.used_for_calculation,
          method_to_allocate_when_used_in_purchase_invoice: response.data.method_to_allocate_when_used_in_purchase_invoice,
          company_id: userData?.id,
          id: data_to_update?.id
        })
        setLoad(false)

      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      toast.error(error?.message || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }, [])


  const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  const validations = {
    name: (value) => value.trim() !== "",
    alise_name: (value) => value.trim() !== "",
    under_id: (value) => group_all_list.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    nature_of_group: (value) => nature_of_group_arrya.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    group_behaves_like_sub_ledger: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    nett_debit_credit_balance_for_reporting: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    used_for_calculation: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    method_to_allocate_when_used_in_purchase_invoice: (value) => applicable_not_applicable_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
  };

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    const nextField = fieldConfig.find(f => f.name === name)?.next;
    const prevField = fieldConfig.find(f => f.name === name)?.prev;
    acc[name] = {
      next: nextField ? inputRefs[nextField] : null,
      prev: prevField ? inputRefs[prevField] : null,
      curr: getCurrentInputRef(name),
      onFocus: () => {
        const ref = getCurrentInputRef(name);
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if (name === 'under_id') {
          setShowList(true)
        }
        if (name === 'nature_of_group') {
          setShowNatureList(true)
          setShowYesNoList(false)
          setShowNetDebitList(false)
          setShowUsedForList(false)
          setShowMethodForList(false)
        }

        if (name === 'group_behaves_like_sub_ledger') {
          setShowYesNoList(true)
        }
        if (name === 'nett_debit_credit_balance_for_reporting') {
          setShowNetDebitList(true)
        }
        if (name === 'used_for_calculation') {
          setShowUsedForList(true)
        }
        if (name === 'method_to_allocate_when_used_in_purchase_invoice') {
          setShowMethodForList(true)
        }
      },
      onBlur: (value) => {
        if (name === 'name') {
          if (!value.trim()) {
            getCurrentInputRef(name)?.current?.focus();
          }
        }
        if (name === 'under_id') {
          setShowList(false)
        }

        if (name === 'nature_of_group') {
          setShowNatureList(false)
        }
        if (name === 'group_behaves_like_sub_ledger') {
          setShowYesNoList(false)
          // normalizedValue(name, value)
        }
        if (name === 'nett_debit_credit_balance_for_reporting') {
          setShowNetDebitList(false)
          // normalizedValue(name, value)
        }
        if (name === 'used_for_calculation') {
          setShowUsedForList(false)
          // normalizedValue(name, value)
        }
        if (name === 'method_to_allocate_when_used_in_purchase_invoice') {
          setShowMethodForList(false)
        }
      },
    };
    return acc;
  }, {});

  const normalizedValue = (name, value) => {
    const normalizedValue = value.toLowerCase().trim();
    const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
    const validPatternNo = /^(n|o|no)?$/;
    if (normalizedValue === '') {
      setData({ ...data, [name]: 'No' })
    } else if (validPatternNo.test(normalizedValue)) {
      setData({ ...data, [name]: 'No' })
    } else if (validPatternYes.test(normalizedValue)) {
      setData({ ...data, [name]: 'Yes' })
    }
  }
  const validateForm = () => {
    const requiredFields = [
      { field: 'name', message: 'Required Name Field' },
      { field: 'under_id', message: 'Required Under Field' },
      { field: 'nature_of_group', message: 'Required Nature of Group' },
      { field: 'group_behaves_like_sub_ledger', message: 'Required Group Behave Like Sub Group' },
      { field: 'nett_debit_credit_balance_for_reporting', message: 'Required Nett Debit Field' },
      { field: 'used_for_calculation', message: 'Required Used For Calculation' },
      { field: 'method_to_allocate_when_used_in_purchase_invoice', message: 'Required Metod to Allocate' },
      // { field: 'status', message: 'Required Metod to Allocate' },
    ]

    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        getCurrentInputRef(field)?.current?.focus();
        return false;
      }
    }
    return true;
  };

  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setData(initialState)
  };

  const { handleSubmit, setSubmitConfirm, submitConfirm, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(groupUpdate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref,
    closeModal
  });

  const check_dropdown = showList || showNatureList || showYesNoList || showUsedForList || showNetDebitList

  const { handleChange, handleFocus, handleBlur, handleKeyDown } = useInputAction({
    data,
    setData,
    validations,
    refs,
    setSubmitConfirm,
    check_dropdown,
  });




  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };

  useClickOutside(nameInput, handleClickOutside, handleClickInside);
  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title='Group Alteration'
      loading={load||loading}
      col='col-7'
      Footer={MasterCreationFooter}

    >
      <div className='row p-0 ps-2 m-0 mb-1'>
        {inputFields.slice(0, 3).map(({ label, name, type, value, ref, isRequired }) => (
          <InputField
            lable={label}
            type={type}
            name={name}
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={ref}
          />
        ))}
        {
          showList ?
            <DropdownLayout_1
              title='List of Group'
              state={showList}
              setState={setShowList}
              data={data}
              setData={setData}
              input_value={data?.under_id}
              input_key='under_id'
              input_id='under'
              rawData={group_all_list}
              ref={inputRefs.under_id}
              refs={refs}
            /> : null}
      </div>
      <div className='border'></div>
      <div className='row m-0 p-0 ps-2 mt-1'>

        {inputFields.slice(3).map(({ label, name, type, value, ref, isRequired, state, setState, isDrop }) => (
          <InputField
            lable={label}
            type={type}
            name={name}
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={ref}
            refs={refs}
            show_drop={state}
            setDrop_add_show={setState}
            setData={setData}
            data={data}
            isDrop={isDrop}
          />
        ))}
        {
          showNatureList ?

            <DropdownLayout_1
              title='List of Group Nature'
              state={showNatureList}
              setState={setShowNatureList}
              data={data}
              setData={setData}
              input_value={data?.nature_of_group}
              input_key='nature_of_group'
              rawData={nature_of_group_arrya}
              ref={inputRefs.nature_of_group}
              refs={refs}
            /> :
            null
        }
      </div>
    </Form>
  )
}
export default React.memo(GroupAlter);





























