import React from "react";

// Date Formate Change To Send The server
function dateFormateSend(datestring) {
    const date_send = new Date(datestring);
    const day = date_send.getDate();
    const month = date_send.getMonth() + 1;
    const year = date_send.getFullYear();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
}
// Date Formate Change To Show The UserInterface
function dateFormateShow(datestring) {
    const date_show = new Date(datestring);
    const day = date_show.getDate();
    const month = date_show.toLocaleString('default', { month: 'short' });
    const year = date_show.getFullYear();
    const result = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`
    return result === 'NaN-Invalid Date-NaN' ? null : result;
}
// Get Day Accourding to Date
function dateDayShow(date_formated_show) {
    const date_show = new Date(date_formated_show);
    const options = { weekday: 'long' };
    return date_show.toLocaleDateString('en-US', options);
}

function getDateInFormate(date) {
    const monthNames = {
        J: 1, Ja: 1, Jan: 1, Janu: 1, Janua: 1, Januar: 1, January: 1,
        F: 2, Fe: 2, Feb: 2, Febr: 2, Febru: 2, Februa: 2, Februar: 2, February: 2,
        M: 3, Ma: 3, Mar: 3, Marc: 3, March: 3,
        A: 4, Ap: 4, Apr: 4, Apri: 4, April: 4,
        May: 5,
        Ju: 6, Jun: 6, June: 6, Jul: 7, July: 7,
        Au: 8, Aug: 8, Augu: 8, Augus: 8, August: 8,
        S: 9, Se: 9, Sep: 9, Sept: 9, Septe: 9, Septem: 9, Septemb: 9, Septembe: 9, September: 9,
        O: 10, Oc: 10, Oct: 10, Octo: 10, Octob: 10, Octobe: 10, October: 10,
        N: 11, No: 11, Nov: 11, Nove: 11, Novem: 11, Novemb: 11, Novembe: 11, November: 11,
        D: 12, De: 12, Dec: 12, Dece: 12, Decem: 12, Decemb: 12, Decembe: 12, December: 12
    };

    const match = date?.match(/^(\d{1,2})[.\-\/,\'\:\]\[\{\}\=\+\_\)\(\*\&\^\%\$\#\@\!\|\";` ]?(J[a-z]*|F[a-z]*|M[a-z]*|A[a-z]*|S[a-z]*|O[a-z]*|N[a-z]*|D[a-z]*|\d{1,2})?[.\-\/,\'\:\]\[\{\}\=\+\_\)\(\*\&\^\%\$\#\@\!\|\";` ]?(\d{2,4})?$/i);

    if (!match) return false;

    let [, day, monthTextOrNumber, yearFrom] = match;
    let day_ = parseInt(day, 10);
    let month_;
    let year_;

    if (monthTextOrNumber && isNaN(monthTextOrNumber)) {
        const normalizedMonth = monthTextOrNumber.charAt(0).toUpperCase() + monthTextOrNumber.slice(1).toLowerCase();
        month_ = monthNames[normalizedMonth] || null;
    } else {
        month_ = parseInt(monthTextOrNumber, 10);
    }

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    if (yearFrom) {
        year_ = parseInt(yearFrom, 10);
        if (year_ < 100) {
            year_ += 2000;
        } else if (year_ < 1000 || year_ > 9999) {
            return false;
        }
    } else {
        if (month_ < 4) {
            year_ = currentMonth >= 4 ? currentYear + 1 : currentYear;
        } else {
            year_ = currentMonth >= 4 ? currentYear : currentYear - 1;
        }
    }

    if (!month_ || month_ < 1 || month_ > 12) return false;

    const daysInMonth = new Date(year_, month_, 0).getDate();
    if (day_ < 1 || day_ > daysInMonth) return false;

    return formatToStandardDate(day_, month_, year_);
}

function formatToStandardDate(day, month, year) {
    const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNamesShort[month - 1];
    if (day < 10) {
        day = `0${day}`
    }

    return `${day}-${monthName}-${year}`;
}



const datePayment_func = (date_get, voucher_date, date_payment_input_ref, company_session) => {
    let date_payment_to;
    const match = date_get?.match(/^(\d+)[.\-\/](\d+)[.-\/]?(\d*)$/);
    if (match) {
        const [, day, month, year_from] = match;
        const year = new Date(company_session?.start_financial_year).getFullYear();
        const financialYearStart = year;
        const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
        if (year_from !== '') {
            const parsedMonth = parseInt(month, 10);
            if (parsedMonth >= 1 && parsedMonth <= 12) {
                let parsedYear;
                if (year_from.length === 2) {
                    const currentYear = new Date().getFullYear();
                    const currentCentury = Math.floor(currentYear / 100) * 100;
                    parsedYear = parseInt(year_from, 10) + currentCentury;
                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                    const parsedDay = parseInt(day, 10);
                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                        const monthNames = [
                            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                        ];
                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                        date_payment_to = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                        const new_date_check = new Date(date_payment_to)
                        const current_date_to_validate = new Date(voucher_date);
                        if (current_date_to_validate < new_date_check) {
                            date_payment_input_ref.current.focus();
                        } else {
                            return date_payment_to = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                        }
                    } else {
                        alert('Invalid Day')
                    }
                } else if (year_from.length === 4) {
                    parsedYear = parseInt(year_from, 10);
                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                    const parsedDay = parseInt(day, 10);
                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                        const monthNames = [
                            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                        ];
                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                        date_payment_to = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                        const new_date_check = new Date(date_payment_to)
                        const current_date_to_validate = new Date(voucher_date);
                        if (current_date_to_validate < new_date_check) {
                            date_payment_input_ref.current.focus();
                        } else {
                            return date_payment_to = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                        }
                    } else {
                        alert('Invalid Day')
                    }
                }
            } else {
                alert('Invalid Month')
            }
        } else {
            const parsedMonth = parseInt(month, 10);
            if (parsedMonth >= 1 && parsedMonth <= 12) {
                const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                const parsedDay = parseInt(day, 10);
                if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                    const monthNames = [
                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                    ];
                    const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                    date_payment_to = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                    const new_date_check = new Date(date_payment_to)
                    const current_date_to_validate = new Date(voucher_date);
                    if (current_date_to_validate < new_date_check) {
                        date_payment_input_ref.current.focus();
                    } else {
                        return date_payment_to = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                    }
                } else {
                    alert('Invalid Day')
                }
            } else {
                alert('Invalid Month')
            }
        }
    } else {
        alert("Invalid date")
    }
}


function getVoucherDate(value, company_session) {
    const match = value?.match(/^(\d+)[.\-\,\'\;\`\/](\d+)[.\-\,\'\;\`\/]?(\d*)$/);
    if (match) {
        const [, day, month, year_from] = match;
        const year = new Date(company_session?.start_financial_year).getFullYear();
        const financialYearStart = year;
        const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
        if (year_from !== '') {
            const parsedMonth = parseInt(month, 10);
            if (parsedMonth >= 1 && parsedMonth <= 12) {
                let parsedYear;
                if (year_from.length === 2) {
                    const currentYear = new Date().getFullYear();
                    const currentCentury = Math.floor(currentYear / 100) * 100;
                    parsedYear = parseInt(year_from, 10) + currentCentury;
                    if (parsedYear === financialYearStart) {
                        if (parsedMonth > 3) {
                            const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                            const parsedDay = parseInt(day, 10);
                            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                const day_name = new Date(formattedDateString);
                                const option = { weekday: 'long' }
                                const day_show = day_name.toLocaleString('en-US', option)
                                // setdate({ ...date, date_get: formattedDateString })
                                return { formattedDateString, day_show }
                            } else {
                                alert('Invalid Day')
                            }
                        }
                    } else if (parsedYear === financialYearEnd) {
                        if (parsedMonth <= 3) {
                            const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                            const parsedDay = parseInt(day, 10);
                            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                const day_name = new Date(formattedDateString);
                                const option = { weekday: 'long' }
                                const day_show = day_name.toLocaleString('en-US', option)
                                // setdate({ ...date, date_get: formattedDateString })
                                return { formattedDateString, day_show }
                            } else {
                                alert('Invalid Day')
                            }
                        }
                    } else {
                        alert('Select in Range')
                    }
                } else if (year_from.length === 4) {
                    parsedYear = parseInt(year_from, 10);
                    if (parsedYear === financialYearStart) {
                        if (parsedMonth > 3) {
                            const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                            const parsedDay = parseInt(day, 10);
                            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                const day_name = new Date(formattedDateString);
                                const option = { weekday: 'long' }
                                const day_show = day_name.toLocaleString('en-US', option)
                                // setdate({ ...date, date_get: formattedDateString })
                                return { formattedDateString, day_show }
                            } else {
                                alert('Invalid Day')
                            }
                        }
                    } else if (parsedYear === financialYearEnd) {
                        if (parsedMonth <= 3) {
                            const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                            const parsedDay = parseInt(day, 10);
                            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                const day_name = new Date(formattedDateString);
                                const option = { weekday: 'long' }
                                const day_show = day_name.toLocaleString('en-US', option)
                                // setdate({ ...date, date_get: formattedDateString })
                                return { formattedDateString, day_show }
                            } else {
                                alert('Invalid Day')
                            }
                        }
                    } else {
                        alert('Select in Range')
                    }
                }
            } else {
                alert('Invalid MOnth')
            }

        } else {
            const parsedMonth = parseInt(month, 10);
            if (parsedMonth >= 1 && parsedMonth <= 12) {
                const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                const parsedDay = parseInt(day, 10);
                if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                    const monthNames = [
                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                    ];
                    const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                    const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                    const day_name = new Date(formattedDateString);
                    const option = { weekday: 'long' }
                    const day_show = day_name.toLocaleString('en-US', option)
                    // setdate({ ...date, date_get: formattedDateString })
                    return { formattedDateString, day_show }
                } else {
                    alert('Invalid Day')
                }
            } else {
                alert('Invalid Month')
            }
        }
    }
    else {
        alert("Invalid date")
    }

}


function addDecimalTwoPoint(value) {
    parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}
function currencyFormate(value) {
    if (value === '' || value === 0 || value === '0.00' || isNaN(value)) {
        return null;
    }
    const result = parseFloat(value).toFixed(2).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
    return result

}
function removeCurrencyComma(value) {
    if (typeof value === 'string') {
        return value?.replace(/\,/g, '');
    }
}

const formatInputToCapitalization = (input) => {
    if (!input) return "";
    const words = input.split(/\s+/);
    const formattedWords = words.map((word) => {
        if (!word) return "";
        const firstChar = word.charAt(0).toUpperCase();
        const remainingChars = word.slice(1);
        return firstChar + remainingChars;
    });
    return formattedWords.join(" ");
}

const countriesList = [
    { code: 'AF', name: 'Afghanistan' },
    { code: 'AL', name: 'Albania' },
    { code: 'DZ', name: 'Algeria' },
    { code: 'AS', name: 'American Samoa' },
    { code: 'AD', name: 'Andorra' },
    { code: 'AO', name: 'Angola' },
    { code: 'AI', name: 'Anguilla' },
    { code: 'AQ', name: 'Antarctica' },
    { code: 'AG', name: 'Antigua and Barbuda' },
    { code: 'AR', name: 'Argentina' },
    { code: 'AM', name: 'Armenia' },
    { code: 'AW', name: 'Aruba' },
    { code: 'AU', name: 'Australia' },
    { code: 'AT', name: 'Austria' },
    { code: 'AZ', name: 'Azerbaijan' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'BH', name: 'Bahrain' },
    { code: 'BD', name: 'Bangladesh' },
    { code: 'BB', name: 'Barbados' },
    { code: 'BY', name: 'Belarus' },
    { code: 'BE', name: 'Belgium' },
    { code: 'BZ', name: 'Belize' },
    { code: 'BJ', name: 'Benin' },
    { code: 'BM', name: 'Bermuda' },
    { code: 'BT', name: 'Bhutan' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'BA', name: 'Bosnia and Herzegovina' },
    { code: 'BW', name: 'Botswana' },
    { code: 'BV', name: 'Bouvet Island' },
    { code: 'BR', name: 'Brazil' },
    { code: 'IO', name: 'British Indian Ocean Territory' },
    { code: 'BN', name: 'Brunei Darussalam' },
    { code: 'BG', name: 'Bulgaria' },
    { code: 'BF', name: 'Burkina Faso' },
    { code: 'BI', name: 'Burundi' },
    { code: 'KH', name: 'Cambodia' },
    { code: 'CM', name: 'Cameroon' },
    { code: 'CA', name: 'Canada' },
    { code: 'CV', name: 'Cape Verde' },
    { code: 'KY', name: 'Cayman Islands' },
    { code: 'CF', name: 'Central African Republic' },
    { code: 'TD', name: 'Chad' },
    { code: 'CL', name: 'Chile' },
    { code: 'CN', name: 'China' },
    { code: 'CX', name: 'Christmas Island' },
    { code: 'CC', name: 'Cocos (Keeling) Islands' },
    { code: 'CO', name: 'Colombia' },
    { code: 'KM', name: 'Comoros' },
    { code: 'CG', name: 'Congo' },
    { code: 'CD', name: 'Congo, The Democratic Republic of the' },
    { code: 'CK', name: 'Cook Islands' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'CI', name: "Cote D'Ivoire" },
    { code: 'HR', name: 'Croatia' },
    { code: 'CU', name: 'Cuba' },
    { code: 'CY', name: 'Cyprus' },
    { code: 'CZ', name: 'Czech Republic' },
    { code: 'DK', name: 'Denmark' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'DM', name: 'Dominica' },
    { code: 'DO', name: 'Dominican Republic' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'EG', name: 'Egypt' },
    { code: 'SV', name: 'El Salvador' },
    { code: 'GQ', name: 'Equatorial Guinea' },
    { code: 'ER', name: 'Eritrea' },
    { code: 'EE', name: 'Estonia' },
    { code: 'ET', name: 'Ethiopia' },
    { code: 'FK', name: 'Falkland Islands (Malvinas)' },
    { code: 'FO', name: 'Faroe Islands' },
    { code: 'FJ', name: 'Fiji' },
    { code: 'FI', name: 'Finland' },
    { code: 'FR', name: 'France' },
    { code: 'GF', name: 'French Guiana' },
    { code: 'PF', name: 'French Polynesia' },
    { code: 'TF', name: 'French Southern Territories' },
    { code: 'GA', name: 'Gabon' },
    { code: 'GM', name: 'Gambia' },
    { code: 'GE', name: 'Georgia' },
    { code: 'DE', name: 'Germany' },
    { code: 'GH', name: 'Ghana' },
    { code: 'GI', name: 'Gibraltar' },
    { code: 'GR', name: 'Greece' },
    { code: 'GL', name: 'Greenland' },
    { code: 'GD', name: 'Grenada' },
    { code: 'GP', name: 'Guadeloupe' },
    { code: 'GU', name: 'Guam' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'GN', name: 'Guinea' },
    { code: 'GW', name: 'Guinea-Bissau' },
    { code: 'GY', name: 'Guyana' },
    { code: 'HT', name: 'Haiti' },
    { code: 'HM', name: 'Heard Island and Mcdonald Islands' },
    { code: 'VA', name: 'Holy See (Vatican City State)' },
    { code: 'HN', name: 'Honduras' },
    { code: 'HK', name: 'Hong Kong' },
    { code: 'HU', name: 'Hungary' },
    { code: 'IS', name: 'Iceland' },
    { code: 'IN', name: 'India' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'IR', name: 'Iran, Islamic Republic Of' },
    { code: 'IQ', name: 'Iraq' },
    { code: 'IE', name: 'Ireland' },
    { code: 'IL', name: 'Israel' },
    { code: 'IT', name: 'Italy' },
    { code: 'JM', name: 'Jamaica' },
    { code: 'JP', name: 'Japan' },
    { code: 'JO', name: 'Jordan' },
    { code: 'KZ', name: 'Kazakhstan' },
    { code: 'KE', name: 'Kenya' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'KP', name: "Korea, Democratic People's Republic of" },
    { code: 'KR', name: 'Korea, Republic of' },
    { code: 'KW', name: 'Kuwait' },
    { code: 'KG', name: 'Kyrgyzstan' },
    { code: 'LA', name: "Lao People's Democratic Republic" },
    { code: 'LV', name: 'Latvia' },
    { code: 'LB', name: 'Lebanon' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'LR', name: 'Liberia' },
    { code: 'LY', name: 'Libyan Arab Jamahiriya' },
    { code: 'LI', name: 'Liechtenstein' },
    { code: 'LT', name: 'Lithuania' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'MO', name: 'Macao' },
    { code: 'MK', name: 'Macedonia, The Former Yugoslav Republic of' },
    { code: 'MG', name: 'Madagascar' },
    { code: 'MW', name: 'Malawi' },
    { code: 'MY', name: 'Malaysia' },
    { code: 'MV', name: 'Maldives' },
    { code: 'ML', name: 'Mali' },
    { code: 'MT', name: 'Malta' },
    { code: 'MH', name: 'Marshall Islands' },
    { code: 'MQ', name: 'Martinique' },
    { code: 'MR', name: 'Mauritania' },
    { code: 'MU', name: 'Mauritius' },
    { code: 'YT', name: 'Mayotte' },
    { code: 'MX', name: 'Mexico' },
    { code: 'FM', name: 'Micronesia, Federated States of' },
    { code: 'MD', name: 'Moldova, Republic of' },
    { code: 'MC', name: 'Monaco' },
    { code: 'MN', name: 'Mongolia' },
    { code: 'MS', name: 'Montserrat' },
    { code: 'MA', name: 'Morocco' },
    { code: 'MZ', name: 'Mozambique' },
    { code: 'MM', name: 'Myanmar' },
    { code: 'NA', name: 'Namibia' },
    { code: 'NR', name: 'Nauru' },
    { code: 'NP', name: 'Nepal' },
    { code: 'NL', name: 'Netherlands' },
    { code: 'AN', name: 'Netherlands Antilles' },
    { code: 'NC', name: 'New Caledonia' },
    { code: 'NZ', name: 'New Zealand' },
    { code: 'NI', name: 'Nicaragua' },
    { code: 'NE', name: 'Niger' },
    { code: 'NG', name: 'Nigeria' },
    { code: 'NU', name: 'Niue' },
    { code: 'NF', name: 'Norfolk Island' },
    { code: 'MP', name: 'Northern Mariana Islands' },
    { code: 'NO', name: 'Norway' },
    { code: 'OM', name: 'Oman' },
    { code: 'PK', name: 'Pakistan' },
    { code: 'PW', name: 'Palau' },
    { code: 'PS', name: 'Palestinian Territory, Occupied' },
    { code: 'PA', name: 'Panama' },
    { code: 'PG', name: 'Papua New Guinea' },
    { code: 'PY', name: 'Paraguay' },
    { code: 'PE', name: 'Peru' },
    { code: 'PH', name: 'Philippines' },
    { code: 'PN', name: 'Pitcairn' },
    { code: 'PL', name: 'Poland' },
    { code: 'PT', name: 'Portugal' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'QA', name: 'Qatar' },
    { code: 'RE', name: 'Reunion' },
    { code: 'RO', name: 'Romania' },
    { code: 'RU', name: 'Russian Federation' },
    { code: 'RW', name: 'Rwanda' },
    { code: 'SH', name: 'Saint Helena' },
    { code: 'KN', name: 'Saint Kitts and Nevis' },
    { code: 'LC', name: 'Saint Lucia' },
    { code: 'PM', name: 'Saint Pierre and Miquelon' },
    { code: 'VC', name: 'Saint Vincent and the Grenadines' },
    { code: 'WS', name: 'Samoa' },
    { code: 'SM', name: 'San Marino' },
    { code: 'ST', name: 'Sao Tome and Principe' },
    { code: 'SA', name: 'Saudi Arabia' },
    { code: 'SN', name: 'Senegal' },
    { code: 'CS', name: 'Serbia and Montenegro' },
    { code: 'SC', name: 'Seychelles' },
    { code: 'SL', name: 'Sierra Leone' },
    { code: 'SG', name: 'Singapore' },
    { code: 'SK', name: 'Slovakia' },
    { code: 'SI', name: 'Slovenia' },
    { code: 'SB', name: 'Solomon Islands' },
    { code: 'SO', name: 'Somalia' },
    { code: 'ZA', name: 'South Africa' },
    { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { code: 'ES', name: 'Spain' },
    { code: 'LK', name: 'Sri Lanka' },
    { code: 'SD', name: 'Sudan' },
    { code: 'SR', name: 'Suriname' },
    { code: 'SJ', name: 'Svalbard and Jan Mayen' },
    { code: 'SZ', name: 'Swaziland' },
    { code: 'SE', name: 'Sweden' },
    { code: 'CH', name: 'Switzerland' },
    { code: 'SY', name: 'Syrian Arab Republic' },
    { code: 'TW', name: 'Taiwan, Province of China' },
    { code: 'TJ', name: 'Tajikistan' },
    { code: 'TZ', name: 'Tanzania, United Republic of' },
    { code: 'TH', name: 'Thailand' },
    { code: 'TL', name: 'Timor-Leste' },
    { code: 'TG', name: 'Togo' },
    { code: 'TK', name: 'Tokelau' },
    { code: 'TO', name: 'Tonga' },
    { code: 'TT', name: 'Trinidad and Tobago' },
    { code: 'TN', name: 'Tunisia' },
    { code: 'TR', name: 'Turkey' },
    { code: 'TM', name: 'Turkmenistan' },
    { code: 'TC', name: 'Turks and Caicos Islands' },
    { code: 'TV', name: 'Tuvalu' },
    { code: 'UG', name: 'Uganda' },
    { code: 'UA', name: 'Ukraine' },
    { code: 'AE', name: 'United Arab Emirates' },
    { code: 'GB', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'UM', name: 'United States Minor Outlying Islands' },
    { code: 'UY', name: 'Uruguay' },
    { code: 'UZ', name: 'Uzbekistan' },
    { code: 'VU', name: 'Vanuatu' },
    { code: 'VE', name: 'Venezuela' },
    { code: 'VN', name: 'Viet Nam' },
    { code: 'VG', name: 'Virgin Islands, British' },
    { code: 'VI', name: 'Virgin Islands, U.S.' },
    { code: 'WF', name: 'Wallis and Futuna' },
    { code: 'EH', name: 'Western Sahara' },
    { code: 'YE', name: 'Yemen' },
    { code: 'ZM', name: 'Zambia' },
    { code: 'ZW', name: 'Zimbabwe' }
];
const statesList = [
    { code: 'AN', name: 'Andaman and Nicobar Islands' },
    { code: 'AP', name: 'Andhra Pradesh' },
    { code: 'AR', name: 'Arunachal Pradesh' },
    { code: 'AS', name: 'Assam' },
    { code: 'BR', name: 'Bihar' },
    { code: 'CH', name: 'Chandigarh' },
    { code: 'CT', name: 'Chhattisgarh' },
    { code: 'DN', name: 'Dadra and Nagar Haveli and Daman and Diu' },
    { code: 'DL', name: 'Delhi' },
    { code: 'GA', name: 'Goa' },
    { code: 'GJ', name: 'Gujarat' },
    { code: 'HR', name: 'Haryana' },
    { code: 'HP', name: 'Himachal Pradesh' },
    { code: 'JK', name: 'Jammu and Kashmir' },
    { code: 'JH', name: 'Jharkhand' },
    { code: 'KA', name: 'Karnataka' },
    { code: 'KL', name: 'Kerala' },
    { code: 'LA', name: 'Ladakh' },
    { code: 'LD', name: 'Lakshadweep' },
    { code: 'MP', name: 'Madhya Pradesh' },
    { code: 'MH', name: 'Maharashtra' },
    { code: 'MN', name: 'Manipur' },
    { code: 'ML', name: 'Meghalaya' },
    { code: 'MZ', name: 'Mizoram' },
    { code: 'NL', name: 'Nagaland' },
    { code: 'OR', name: 'Odisha' },
    { code: 'PY', name: 'Puducherry' },
    { code: 'PB', name: 'Punjab' },
    { code: 'RJ', name: 'Rajasthan' },
    { code: 'SK', name: 'Sikkim' },
    { code: 'TN', name: 'Tamil Nadu' },
    { code: 'TS', name: 'Telangana' },
    { code: 'TR', name: 'Tripura' },
    { code: 'UP', name: 'Uttar Pradesh' },
    { code: 'UK', name: 'Uttarakhand' },
    { code: 'WB', name: 'West Bengal' }
];



export { dateFormateSend, dateFormateShow, dateDayShow, getVoucherDate, datePayment_func, addDecimalTwoPoint, countriesList, statesList, currencyFormate, removeCurrencyComma, getDateInFormate, formatInputToCapitalization }