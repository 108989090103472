import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { Eye, EyeSlash } from 'react-bootstrap-icons'

const TextArea = forwardRef(({ type, lable, name, value, handleChange, handleFocus, handleBlur }, ref) => {
    const maxHeight = 65;
    const adjustHeight = () => {
        const textArea = ref.current;
        if (textArea) {
            textArea.style.height = 'auto';
            textArea.style.height = `${Math.min(textArea.scrollHeight, maxHeight)}px`;
        }
    };

    // const handleScroll = (e) => {
    //     const textArea = ref.current;
    //     if (textArea) {
    //         const cursorPosition = textArea.selectionStart;
    //         const maxScroll = textArea.scrollHeight - textArea.clientHeight;
    //         if (cursorPosition === value.length) {
    //             textArea.scrollTop = maxScroll;
    //         }
    //     }
    // };
    useEffect(()=>{
        adjustHeight();
    },[value])
    // const behaviour_input = useCallback(() => {
    //     adjustHeight();
    //     // handleScroll();
    // }, [value])

    // useEffect(() => {
    //     behaviour_input();
    // }, [behaviour_input])

    return (
        <div className='row m-0 p-0'>
            <div className='col-5 m-0 p-0 text-start'>
                <label>{lable}</label>
            </div>
            <div className=' m-0 p-0' style={{width:'2%'}}>
                <label> :</label>
            </div>
            <div className='col-6 m-0 p-0 text-start' style={{ position: 'relative' }}>
                <textarea
                    style={{ fontSize: 13, width: '100%', height: 19.5, resize: 'none', overflowY: 'auto' }}
                    type={type}
                    autoFocus={name === 'company_name'}
                    name={name}
                    id={name}
                    // className='lab-right'
                    value={value || ""}
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    // onInput={adjustHeight}
                    // onScroll={handleScroll}
                    ref={ref}
                />
            </div>
        </div>
    )
})

export default React.memo(TextArea)
