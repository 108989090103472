import { useState, useEffect } from 'react';

export const useDropdownFilterForDynamicInput = ({
    dataList,
    searchInput,
    inputRef,
    defaultItem = { id: '1', name: 'Not Applicable' },
}) => {
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        // if (dataList.length > 0 && dataList[0]?.name !== defaultItem.name) {
        //     dataList.unshift(defaultItem); 
        // }
        const cleanedSearchInput = searchInput?.toLowerCase().replace(/\s+/g, '') || '';

        
        const lowercaseSearchInput = searchInput?.toLowerCase() || '';
      
        const filteredSuggestions = dataList.filter((item) =>
           (item?.name||item?.ledger_name||item?.symbol)?.toLowerCase()?.replace(/\s+/g, '').includes(cleanedSearchInput)
        );

        const shouldUseFilteredData =
            searchInput !== '' &&
            inputRef?.selectionStart > 0;
        setFilteredData(shouldUseFilteredData ? filteredSuggestions : dataList);
    }, [dataList, searchInput, inputRef?.selectionStart]);

    return { filteredData };
};
