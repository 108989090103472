import React from 'react';
import InputField from '../Input/InputField';

const ModalForm = ({ inputFields, inputRefs, data,setData, handleChange, handleBlur, handleFocus, closeModal }) => {
    return (
        <div className='form'>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    width: 'auto',
                    height: 'auto',
                    borderWidth: 1,
                    borderColor: 'black',
                    borderStyle: 'solid',
                    background: '#fff',
                    zIndex: 1000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <div className='container'>
                    <div className='row p-3'>
                        <p>Behaviour</p>
                        {inputFields?.map(({ label, name, type, isRequired, isDrop, state }, index) => (
                            <InputField
                                key={index}
                                lable={label}
                                type={type}
                                name={name}
                                value={data[name]}
                                ref={inputRefs[name]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleFocus={handleFocus}
                                isRequired={isRequired}
                                isDrop={isDrop}
                                show_drop={state}
                                // setDrop_add_show={setState}
                                setData={setData}
                                data={data}
                            />
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default React.memo(ModalForm)
