import React from 'react'

const Help = ({ children }) => {
    return (
        <div className='help border_1_1px_left backgroud_color_1 position-relative'>
            {children}
        </div>
    )
}

export default React.memo(Help);

