import React from 'react';
import { currencyFormate } from '../Date_Formate_Utilitiy/Index';

function TableLayoutProfitBalance({ children, table_heading, table_heading1, total_left, total_right }) {
    return (
        <div className='container-fluid' id='reletive' style={{ height: '90vh' }}>
            <div className='row border-bottom border-dark' style={{ height: 25 }}>
                <div className='col-md-6 border-end border-dark'>
                    <div className='row'>
                        <div className='col-8 text-start'>{table_heading}</div>
                        {/* <div className='col-4 text-end'></div> */}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-8 text-start'>{table_heading1}</div>
                        {/* <div className='col-4 text-end'></div> */}
                    </div>
                </div>
            </div>
            <div className={` row  border-bottom border-dark postion-relative`} style={{ height: '79vh' }} >
                <div className='col-md-6 border-end border-dark'>
                    <div className='row'>
                        <div className='col-8'></div>
                        <div className='col-4'></div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-8'></div>
                        <div className='col-4'></div>
                    </div>
                </div>
                <div className='row m-0 p-0' style={{ position: 'absolute', width: '100%' }}>
                    {children}
                </div>
                {/* <div className='row m-0 p-0 border-dark border-top border-bottom' style={{ position: 'absolute', top: '50%', width: '100%', height: 19.5 }}>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-8'></div>
                            <div className='col-4 text-end table_body_font'>{total_left_trading}</div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-8'></div>
                            <div className='col-4 text-end table_body_font '>{total_right_trading}</div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 p-0' style={{ position: 'absolute', top: '55%', width: '100%', height: 19.5 }}>
                 {children}
                </div> */}
                
            </div>
            <div className={`row  border-0`}>
                <div className='col-md-6 border-end border-dark'>
                    <div className='row'>
                        <div className='col-8 text-start'>Total</div>
                        <div className='col-4 text-end table_body_font'>{total_left>0?'(-)':''}{currencyFormate(Math.abs(total_left))}</div>
                    </div>
                </div>
                <div className='col-md-6 border-end border-dark'>
                    <div className='row'>
                        <div className='col-8 text-start'>Total</div>
                        <div className='col-4 text-end table_body_font'>{total_left<0?'(-)':''}{currencyFormate(Math.abs(total_right))}</div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TableLayoutProfitBalance
