import { useEffect } from 'react';

export const useDropdownScroll = ({ dropdownRef, selectedItemIndex, itemHeight = 20 }) => {
    useEffect(() => {
        if (dropdownRef?.current) {
            const dropdownElement = dropdownRef.current;
            const selectedElement = dropdownElement.querySelector('.selected');

            if (selectedElement) {
                const dropdownHeight = dropdownElement.offsetHeight;
                const dropdownScrollTop = dropdownElement.scrollTop;

                const elementTop = selectedElement.offsetTop;
                const elementBottom = elementTop + selectedElement.offsetHeight;

                // Scroll down if selected item is below the visible area
                if (elementBottom > dropdownScrollTop + dropdownHeight) {
                    dropdownElement.scrollTop = elementBottom - dropdownHeight;
                }

                // Scroll up if selected item is above the visible area
                if (elementTop < dropdownScrollTop) {
                    dropdownElement.scrollTop = elementTop;
                }
            }




            // if (selectedElement) {
            //     const boxHeight = dropdownRef.current.offsetHeight;
            //     const elementBottom = selectedElement.offsetTop - 13;
            //     const elementBottom1 = selectedElement.offsetTop + selectedElement.offsetHeight;
            //     if (elementBottom > boxHeight) {
            //         dropdownRef.current.scrollTop = elementBottom - boxHeight;
            //     } else {
            //         if (elementBottom1 > boxHeight) {
            //             dropdownRef.current.scrollTop = elementBottom - boxHeight;
            //         }
            //     }
            // }



        //     const handleWheel = (event) => {
        //         if (isScrolling) return;
        //         isScrolling = true;
        //         setTimeout(() => {
        //             const box = document.getElementById('box'); // Replace 'yourBoxId' with the ID of your box
        //             const boxHeight = box.clientHeight;
        //             if (event.deltaY > 0 && selectedIndex < all_data.length) {
        //                 setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, all_data.length - 1));
        //             } else if (event.deltaY < 0 && selectedIndex > 0) {
        //                 setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        //             }
        //             isScrolling = false;
        //         }, scrollSpeed);
        //     };
        //     window.addEventListener('wheel', handleWheel);
        //     return () => {
        //         window.removeEventListener('wheel', handleWheel);
        //     };
        }
    }, [dropdownRef, selectedItemIndex, itemHeight]);
};
