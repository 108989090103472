import React from 'react';
import { Link } from 'react-router-dom';
import './navigationList.css';

const NavigationList = ({ first_heading,secound_heading,third_heading, title, data, selectedItemIndex, setSelectedItemIndex }) => {
    const formatText = (text, index) => {
        const formattedText = {
            'Create': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Chart of Accounts': <>{text.substring(0, 1)}<span className='shortcut_color_and_upper'>{text.substring(1, 2)}</span>{text.substring(2)}</>,
            'Vouchers': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Day Book': <>{text.substring(0, text?.length - 1)}<span className='shortcut_color_and_upper'>{text.substring(text?.length - 1)}</span></>,
            'Banking': <>{text.substring(0, 2)}<span className='shortcut_color_and_upper'>{text.substring(2, 3)}</span>{text.substring(3)}</>,
            'Balance Sheet': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Profit & Loss A/c': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Stock Summary': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Display More Reports': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Group': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Ledger': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Vouchers Type': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Stock Group': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Stock Category': <>{text.substring(0, 6)}<span className='shortcut_color_and_upper'>{text.substring(6, 7)}</span>{text.substring(7)}</>,
            'Stock Company': <>{text.substring(0, text.length - 1)}<span className='shortcut_color_and_upper'>{text.substring(text.length - 1)}</span></>,
            'Stock Item': <>{text.substring(0, 6)}<span className='shortcut_color_and_upper'>{text.substring(6, 7)}</span>{text.substring(7)}</>,
            'Unit': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Godown': <>{text.substring(0, 3)}<span className='shortcut_color_and_upper'>{text.substring(3, 4)}</span>{text.substring(4)}</>,
            'PAN Details': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            
            'Cheque Printing': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Cheque Register': <>{text.substring(0, 7)}<span className='shortcut_color_and_upper'>{text.substring(7, 8)}</span>{text.substring(8)}</>,
            'Post-dated Summary': <>{text.substring(0, 3)}<span className='shortcut_color_and_upper'>{text.substring(3, 4)}</span>{text.substring(4)}</>,
            'Deposit Slip': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Payment Advice': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Bank Reconciliation': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            
            
            'Trial Balance': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Day Book': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Cash Flow': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Funds Flow': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Account Books': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Statements of Accounts': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Inventory Books': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Statements of Inventory': <>{text.substring(0, 4)}<span className='shortcut_color_and_upper'>{text.substring(4, 5)}</span>{text.substring(5)}</>,
            'GST Reports': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Exception Reports': <>{text.substring(0, 1)}<span className='shortcut_color_and_upper'>{text.substring(1, 2)}</span>{text.substring(2)}</>,
            'Analysis & Verification': <>{text.substring(0, 11)}<span className='shortcut_color_and_upper'>{text.substring(11, 12)}</span>{text.substring(12)}</>,
            
            
            'Cash/Bank Book(s)': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Ledger': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Group Summary': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Group Vouchers': <>{text.substring(0, 6)}<span className='shortcut_color_and_upper'>{text.substring(6, 7)}</span>{text.substring(7)}</>,
            'Contra Register': <>{text.substring(0, 3)}<span className='shortcut_color_and_upper'>{text.substring(3, 4)}</span>{text.substring(4)}</>,
            'Payment Register': <>{text.substring(0, 2)}<span className='shortcut_color_and_upper'>{text.substring(2, 3)}</span>{text.substring(3)}</>,
            'Receipt Register': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Sales Register': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Purchase Register': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Journal Register': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Debit Note Register': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Credit Note Register': <>{text.substring(0, 2)}<span className='shortcut_color_and_upper'>{text.substring(2, 3)}</span>{text.substring(3)}</>,
            'Voucher Clarification': <>{text.substring(0, 2)}<span className='shortcut_color_and_upper'>{text.substring(2, 3)}</span>{text.substring(3)}</>,
            
            'OutStandings': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Tally Audit': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Statistics': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Quit': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            
            
            'Stock Item': <>{text.substring(0, 6)}<span className='shortcut_color_and_upper'>{text.substring(6, 7)}</span>{text.substring(7)}</>,
            'Godowns': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Stock Group Summary': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Stock Category Summary': <>{text.substring(0, 3)}<span className='shortcut_color_and_upper'>{text.substring(3, 4)}</span>{text.substring(4)}</>,
            'Sales Order Book': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Purchase Order Book': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Delivery Note Register': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Receipt Note Register': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Stock Transfer Journal Register': <>{text.substring(0, 1)}<span className='shortcut_color_and_upper'>{text.substring(1, 2)}</span>{text.substring(2)}</>,
            'Physical Stock Register': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            
            
            'Data Analysis': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
            'Voucher Verification': <><span className='shortcut_color_and_upper'>{text.substring(0, 1)}</span>{text.substring(1)}</>,
        };
        return formattedText[text] || text;
    };
    return (
        <div className='card-container backgroud_color_1 border_1_1px'>
            <div className='backgroud_color_2 white'>
                <p>{title}</p>
            </div>
            <div className='pt-2'>
                <ul className='list-item'>
                    <h6 className='list-heading mb-0'>{first_heading}</h6>
                    {data.map((link, index) => (
                        <div key={index}>
                            {
                                link.text === 'Vouchers' ?
                                    <h6 className='list-heading mb-0 mt-3'>TRANSACTION</h6> : null
                            }
                            {
                                link.text === 'Banking' ?
                                    <h6 className='list-heading mb-0 mt-3'>UTILITIES</h6> : null
                            }
                            {
                                link.text === 'Balance Sheet' ?
                                    <h6 className='list-heading mb-0 mt-3'>REPORTS</h6> : null
                            }


                            {
                                link.text === 'Stock Group' ?
                                    <h6 className='list-heading mb-0 mt-3'>Inventory Masters</h6> : null
                            }
                            {
                                link.text === 'PAN Details' ?
                                    <h6 className='list-heading mb-0 mt-3'>Statutory Details</h6> : null
                            }
                            {
                                link.text === 'Deposit Slip' ?
                                    <h6 className='list-heading mb-0 mt-3'>STATEMENTS</h6> : null
                            }
                            
                            {
                                link.text === 'Inventory Books' ?
                                    <h6 className='list-heading mb-0 mt-3'>{secound_heading}</h6> : null
                            }
                            {
                                link.text === 'Exception Reports' ?
                                    <h6 className='list-heading mb-0 mt-3'>{third_heading}</h6> : null
                            }
                           
                           
                            {
                                link.text === 'Contra Register' ?
                                    <h6 className='list-heading mb-0 mt-3'>{secound_heading}</h6> : null
                            }
                            {
                                link.text === 'Sales Order Book' ?
                                    <h6 className='list-heading mb-0 mt-3'>{secound_heading}</h6> : null
                            }
                            <li className={index === selectedItemIndex ? 'selected' : ''}>
                                <Link to={index === selectedItemIndex ? link.to : ''} onClick={() => setSelectedItemIndex(index)}>
                                    {formatText(link.text, index)}
                                </Link>
                            </li>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default React.memo(NavigationList);
