import React from 'react'
import Help from '../help/Help'
import { useLocation } from 'react-router-dom'
import BalanceSheetHelp from '../help/BalanceSheet/BalanceSheetHelp';

const HomeLayout = ({ children }) => {
    const location = useLocation();
    return (
        <div className='section' >
            <div className='detail'>
                {children}
            </div>
            <Help>
                {
                    location.pathname === '/' ?
                        <div style={{ position: 'absolute', bottom: 5, width: '100%' }}>
                            <div className='help_button_card  ms-1' >
                                <button className={`button_padding`}
                                    style={{ background: 'white', paddingTop: 3, paddingBottom: 3, marginBottom: 2 }}
                                >
                                    Version: 1.0.0
                                </button>
                            </div>
                        </div> :
                        location.pathname === '/balancesheetreports' ?
                            <BalanceSheetHelp /> : null
                }
            </Help>
        </div>
    )
}

export default React.memo(HomeLayout)
