import React, { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import DropdownForDynamicInput from '../../../../../components/Dropdown/DropdownForDynamicInput';
import DropdownYesNo from '../../../../../components/Dropdown/DropdownYesNo';
import { yes_no_array } from '../../../../../components/Dummi_Data';

const ItemWithGodown = forwardRef((props, ref) => {
    const { godown_data = null } = useSelector(state => state.common);
    const modal_state = useSelector(state => state.common.modal_state);
    const { handleChange, handleDynamicBlur, handleDynamicFocus, data_item, handleKeyDownItem, inputRefs, setData, data } = props
    const unit_per = [{ id: 1, name: data?.unit_name }, { id: 2, name: data?.alt_unit_name }]
    const [total,setTotal] = useState({
        quantity:0,
        amount:0
    })
    useEffect(()=>{
        setTotal({
            quantity:data_item.reduce((sum,item)=>sum+parseFloat(item.quantity),0),
            amount:data_item.reduce((sum,item)=>sum+parseFloat(item.value),0)
        })
    },[data_item])

    return (
        <div className='form'>
            {data_item.map((item, index) => (
                modal_state?.[`godown_name${index}`] && (
                    <DropdownForDynamicInput
                        key={`godown_name${index}`}
                        title={'Godown List'}
                        state={modal_state[`godown_name${index}`]}
                        data={data}
                        data_item={data_item}
                        index={index}
                        setData={setData}
                        input_value={data_item[index]['godown_name']}
                        input_key={'godown_name'}
                        rawData={godown_data}
                        ref={inputRefs.current[`godown_name${index}`]}
                    />
                )
            ))}
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    width: data?.quantity ? '60%' : '30%',
                    height: '60vh',
                    borderWidth: 1,
                    borderColor: 'black',
                    borderStyle: 'solid',
                    background: '#fff',
                    zIndex: 1000,
                    overflow: 'hidden'
                }}>
                <div className='container-fluid p-0 m-0'>
                    <div className='row m-0 p-0'>
                        <div className='col'>
                            <b>Allocations of :{data?.name}</b>
                        </div>
                    </div>
                    <div className='row m-0 p-0'>
                        <div className='col-12 m-0 p-0'>
                            <p className='m-0 p-0'>For :{`${data?.quantity} ${data?.unit_name || ''} ${data?.value}`}</p>
                            <div className='row m-0 p-0 border-bottom border-top'>
                                <div className={`col-4 text-start ${data?.quantity ? 'col-4' : 'col-8'}`}>Godown</div>
                                {
                                    data?.quantity !== '' ?
                                        <>
                                            <div className='col-2 text-end'>Quantity</div>
                                            <div className='col-2 text-end'>Rate</div>
                                            <div className='col-2 text-start'>Per</div>
                                        </> :
                                        null
                                    //  <div className='col-6'></div>
                                }

                                <div className={`col-2 text-end ${data?.quantity ? 'col-2' : 'col-4'}`}>Amount</div>
                            </div>
                        </div>
                    </div>
                    <div className='row p-0 m-0 ps-1 pe-1 '>
                        {data_item.map((item, index) => (
                            <div key={index} className='col-12 p-0 mb-2' style={{ height: 19 }}>
                                <div className='row p-0'>
                                    <div className={`col-4 mb-1 ${data?.quantity ? 'col-4' : 'col-8'}`}>
                                        <input
                                            key={index}
                                            style={{ fontSize: 13, width: '100%', height: 19, }}
                                            type={'text'}
                                            value={data?.data_item[index]['godown_name']}
                                            onChange={(e) => handleChange(e, index, 'godown_name', e.target.value)}
                                            onKeyUp={(e) => handleChange(e, index, 'godown_name', e.target.value)}
                                            onKeyDown={(e) => handleKeyDownItem(e, index, 'godown_name')}
                                            onFocus={(e) => handleDynamicFocus(e, index, 'godown_name')}
                                            onBlur={(e) => handleDynamicBlur(e, index, 'godown_name')}
                                            ref={(el) => {
                                                inputRefs.current[`godown_name${index}`] = el;
                                            }}
                                        />
                                    </div>
                                    {
                                        data?.quantity !== '' ?
                                            <>
                                                <div className='col-2'>
                                                    <input
                                                        type={'text'}
                                                        style={{ width: '100%', height: 19,textAlign:'end' }}
                                                        value={data?.data_item[index]['quantity']}
                                                        onChange={(e) => handleChange(e, index, 'quantity', e.target.value)}
                                                        onKeyUp={(e) => handleChange(e, index, 'quantity', e.target.value)}
                                                        onKeyDown={(e) => handleKeyDownItem(e, index, 'quantity')}
                                                        onFocus={(e) => handleDynamicFocus(e, index, 'quantity')}
                                                        onBlur={(e) => handleDynamicBlur(e, index, 'quantity')}
                                                        ref={(el) => {
                                                            inputRefs.current[`quantity${index}`] = el;
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-2'>
                                                    <input
                                                        type={'text'}
                                                        style={{ width: '100%', height: 19,textAlign:'end' }}
                                                        value={data?.data_item[index]['rate']}
                                                        onChange={(e) => handleChange(e, index, 'rate', e.target.value)}
                                                        onKeyUp={(e) => handleChange(e, index, 'rate', e.target.value)}
                                                        onKeyDown={(e) => handleKeyDownItem(e, index, 'rate')}
                                                        onFocus={(e) => handleDynamicFocus(e, index, 'rate')}
                                                        onBlur={(e) => handleDynamicBlur(e, index, 'rate')}
                                                        ref={(el) => {
                                                            inputRefs.current[`rate${index}`] = el;
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-2 position-relative'>
                                                    <input
                                                        type={'text'}
                                                        style={{ width: '100%', height: 19,textAlign:'start' }}
                                                        value={data?.data_item[index]['per']}
                                                        onChange={(e) => handleChange(e, index, 'per', e.target.value)}
                                                        onKeyUp={(e) => handleChange(e, index, 'per', e.target.value)}
                                                        onKeyDown={(e) => handleKeyDownItem(e, index, 'per')}
                                                        onFocus={(e) => handleDynamicFocus(e, index, 'per')}
                                                        onBlur={(e) => handleDynamicBlur(e, index, 'per')}
                                                        ref={(el) => {
                                                            inputRefs.current[`per${index}`] = el;
                                                        }}
                                                    />
                                                    {
                                                        modal_state[`per${index}`] && data?.alt_unit_name !== 'Not Applicable' && (
                                                            <DropdownYesNo
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '-90%',
                                                                    top:'50%',
                                                                    transform: 'translateY(-50%)',
                                                                    zIndex: 1000,
                                                                    width: '100%'
                                                                }}
                                                                state={modal_state[`per${index}`]}
                                                                data={data}
                                                                index={index}
                                                                setData={setData}
                                                                input_value={data?.data_item[index]['per']}
                                                                input_key={'per'}
                                                                rawData={unit_per}
                                                                ref={inputRefs.current[`per${index}`]}
                                                                refs={inputRefs}
                                                            // next={next}
                                                            />)
                                                    }
                                                </div>
                                            </> :
                                            null
                                        // <div className='col-6'></div>
                                    }
                                    <div className={`col-2 ${data?.quantity ? 'col-2' : 'col-4'}`}>
                                        <input
                                            type={'text'}
                                            style={{ width: '100%', height: 19,textAlign:'end' }}
                                            value={data?.data_item[index]['value']}
                                            onChange={(e) => handleChange(e, index, 'value', e.target.value)}
                                            onKeyUp={(e) => handleChange(e, index, 'value', e.target.value)}
                                            onKeyDown={(e) => handleKeyDownItem(e, index, 'value')}
                                            onFocus={(e) => handleDynamicFocus(e, index, 'value')}
                                            onBlur={(e) => handleDynamicBlur(e, index, 'value')}
                                            ref={(el) => {
                                                inputRefs.current[`value${index}`] = el;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='row m-0 p-0' style={{
                        borderTop: '1px solid black',
                        borderBottom: '1px solid black',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%'
                    }}>
                        <div className='col-4'>
                            <b>Total</b>
                        </div>
                        <div className='col-2'>
                            <b>{total.quantity==='NaN'||total.quantity===0?'':total.quantity}</b>
                        </div>
                        <div className='col-2'></div>
                        <div className='col-2'></div>
                        <div className='col-2'>
                            <b>{total.amount==='NaN'?'':total.amount}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default ItemWithGodown;
