import React, { useEffect, useState, useCallback } from 'react';
import './section.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Banking from '../../pages/gatewayoftally/utilities/Banking';
import GateWayOfTally from '../../pages/gatewayoftally/GateWayOfTally';
import DisplayMoreReports from '../../pages/gatewayoftally/Reports/DisplayMoreReports';
import ChequePrinting from '../../pages/gatewayoftally/utilities/banking/cheque/ChequePrinting';
import PostDatedSummary from '../../pages/gatewayoftally/utilities/banking/cheque/PostDatedSummary';
import DepositSlip from '../../pages/gatewayoftally/utilities/banking/statement/DepositSlip';
import PaymentAdvice from '../../pages/gatewayoftally/utilities/banking/statement/PaymentAdvice';
import BankReconciliation from '../../pages/gatewayoftally/utilities/banking/statement/BankReconciliation';
import Period_Home from './Period_Home';
import Current_Date from './Current_Date';
import AccountBook from '../../pages/gatewayoftally/Reports/displaymore/AccountBook';
import StatementofAccount from '../../pages/gatewayoftally/Reports/displaymore/StatementofAccount';
import InventoryBook from '../../pages/gatewayoftally/Reports/displaymore/InventoryBook';
import StatementofInventory from '../../pages/gatewayoftally/Reports/displaymore/StatementofInventory';
import GstReport from '../../pages/gatewayoftally/Reports/displaymore/GstReport';
import ExceptionReports from '../../pages/gatewayoftally/Reports/displaymore/ExceptionReports';
import AnalysisVerification from '../../pages/gatewayoftally/Reports/displaymore/AnalysisVerification';
import LedgerBook from '../../pages/gatewayoftally/Reports/displaymore/account_book/LedgerBook';
import { maxDateOfEntry } from '../../redux/actions/action-creator';
import { dateDayShow, dateFormateShow } from '../Date_Formate_Utilitiy/Index';
import CreateMasters from '../../pages/gatewayoftally/master/create/CreateMasters';
import ChartOfAccount from '../../pages/gatewayoftally/master/chartOfAccount/ChartOfAccount';
import Help from '../help/Help';
import Group from '../../pages/gatewayoftally/master/create/accounting_masters/Group';
import HomeLayout from './HomeLayout';
import Ledger from '../../pages/gatewayoftally/master/create/accounting_masters/Ledger';
import StockGroup from '../../pages/gatewayoftally/master/create/Inventory_masters/StockGroup';
import StockCatagory from '../../pages/gatewayoftally/master/create/Inventory_masters/StockCatagory';
import StockCompany from '../../pages/gatewayoftally/master/create/Inventory_masters/StockCompany';
import GoDown from '../../pages/gatewayoftally/master/create/Inventory_masters/GoDown';
import Unit from '../../pages/gatewayoftally/master/create/Inventory_masters/Unit';
import StockItem from '../../pages/gatewayoftally/master/create/Inventory_masters/StockItem';

const Section = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigation = useNavigate();

    const { company_session } = useSelector(state => state.common);
    const { userData } = useSelector(state => state.auth);

    const [max_date, setMax_date] = useState('')
    const [loading, setloading] = useState(false)
    const [chequePrinting, setChequePrinting] = useState(false);
    const [postDatedSummary, setPostDatedSummary] = useState(false);
    const [depositSlip, setDepositSlip] = useState(false);
    const [paymentAdvice, setPaymentAdvice] = useState(false);
    const [bankReconciliation, setBankReconciliation] = useState(false);


    const navigate = useNavigate();

    useEffect(() => {

        const formData = new FormData();
        formData.append('company_id', userData?.id)
        dispatch(maxDateOfEntry(formData)).then((response) => {

            if (response.status) {
                setMax_date(response?.data)
                setloading(false)
            } else {
                setMax_date('')
                setloading(false)
            }
        })

    }, [])

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'F2') {
                e.preventDefault();
                navigation('/current_date')
            }
            if (e.ctrlKey && e.key === 'F2') {
                e.preventDefault();
                navigation('/period')
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [])

    const renderDetails = useCallback(() => {
        switch (location.pathname) {
            case '/bankingutilities':
                return <Banking />;
            case '/displaymorereports':
                return <DisplayMoreReports />;
            case '/accountbook':
            case '/ledgerbook':
                return <AccountBook />;
            case '/statementofaccount':
                return <StatementofAccount />;
            case '/inventorybook':
                return <InventoryBook />;
            case '/statementofinventory':
                return <StatementofInventory />;
            case '/gstreport':
                return <GstReport />;
            case '/exceptionreports':
                return <ExceptionReports />;
            case '/analysisverification':
                return <AnalysisVerification />;
            case '/createmasters':
            case '/create-master-group':
                return <CreateMasters />;
            case '/chartofaccountmasters':
                return <ChartOfAccount />;
            default:
                return <GateWayOfTally />;
        }
    }, [location.pathname]);

    return (
        <HomeLayout>
            {location.pathname === '/create-master-group' ? <Group /> : null}
            {location.pathname === '/create-master-invmas-stockitem' ? <StockItem /> : null}
            {location.pathname === '/create-master-invmas-unit' ? <Unit /> : null}
            {location.pathname === '/create-master-invmas-godown' ? <GoDown /> : null}
            {location.pathname === '/create-master-invmas-stockcompany' ? <StockCompany /> : null}
            {location.pathname === '/create-master-invmas-stockcategory' ? <StockCatagory /> : null}
            {location.pathname === '/create-master-invmas-stockgroup' ? <StockGroup /> : null}
            {location.pathname === '/create-master-ledger' ? <Ledger
            /> : null}
            {location.pathname === '/ledgerbook' ? <LedgerBook /> : null}
            {location.pathname === '/current_date' ? <Current_Date /> : null}
            {location.pathname === '/period' ? <Period_Home /> : null}
            {chequePrinting ? <ChequePrinting /> : null}
            {postDatedSummary ? <PostDatedSummary /> : null}
            {depositSlip ? <DepositSlip /> : null}
            {paymentAdvice ? <PaymentAdvice /> : null}
            {bankReconciliation ? <BankReconciliation /> : null}

            <div className='detail-item' id='section'>
                <div className='detail-1 border_2_1px_right'>
                    <div className=' pt-3 pb-3'>
                        <h5 className='m-0 p-0'>{userData?.company_name}</h5>
                        <p className='m-0 p-0 font_size'>{userData?.address}</p>
                        <p className='m-0 p-0 font_size'>{userData?.mobile == '' ? null : `Mobile No.: ${userData?.mobile}`}</p>
                    </div>
                    <div className='row m-0 p-0 border_1_2px_top  border_1_1px_bottom'>
                        <div className='col-xl btn home-btn border-0 ' style={{ pointerEvents: 'none' }} >
                            <p className='text-info text-center font_size m-0 mb-1'>MAX. DATE OF ENTRY</p>
                            <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}> {max_date ? dateDayShow(max_date) : ''}{max_date ? ', ' : ''}{max_date ? dateFormateShow(max_date) : ''} </p>
                        </div>
                        <div className='col-xl btn home-btn border-0' onClick={() => { return (navigation('/period')) }}>
                            <p className='text-info text-center font_size m-0 mb-1'><u>F2</u>: CURRENT PERIOD</p>
                            <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}>{company_session.financial_year}</p>
                        </div>
                        <div className='col-xl btn home-btn border-0' onClick={() => { return (navigation('/current_date')) }}>
                            <p className='text-info text-center font_size m-0 mb-1'> F2: CURRENT Date</p>
                            <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}>{company_session.current_date}</p>
                        </div>
                    </div>
                </div>
                <div className='detail-2' id='detail-2'>
                    {renderDetails()}
                </div>
            </div>
        </HomeLayout>
    )
}
export default React.memo(Section);
