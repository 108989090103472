import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDropdownKeyboardNavigation } from '../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../../components/NavigationList/NavigationList';

function Banking(props) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
      return;
    }
    const keyMap = {
      'c': '',
      'd': '',
      'h': '',
      'v': '',
      'k': '',
      'n': '',
      'b': '',
      'p': '',
      's': '',
      'r': '',
    };

    const handleKeyDown = (event) => {
      const key = event.key.toLowerCase();
      const route = keyMap[key];
      if (route) {
        if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
          return;
        }
        navigate(route);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [location.pathname, navigate]);

  const data = [
    { to: '', text: 'Cheque Printing', category: 'Cheque' },
    { to: '', text: 'Cheque Register', category: 'Cheque' },

    { to: '', text: 'Post-dated Summary', category: 'Cheque' },
    { to: '', text: 'Deposit Slip', category: 'Statements' },
    { to: '', text: 'Payment Advice', category: 'Statements' },
    { to: ``, text: 'Bank Reconciliation', category: 'Statements' },
  ];
  const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
    items: data,
    state: true,
    onItemSelect: (item) => {
      if (['/ledgerbook', '/current_date', '/period'].includes(location.pathname)) {
        return;
      }
      navigate(item.to)
    },

  });
  return (
    <NavigationList
      data={data}
      selectedItemIndex={selectedItemIndex}
      setSelectedItemIndex={setSelectedItemIndex}
      title='Banking'
      first_heading='CHEQUE'
      secound_heading='STATEMENTS'
    />
  )
}

export default React.memo(Banking);
