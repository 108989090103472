import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDropdownKeyboardNavigation } from '../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import NavigationList from '../../../../components/NavigationList/NavigationList';
import useKeyboardNavigation from '../../../../components/Custom_Hooks/useKeyboardNavigation';

const ChartOfAccount = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const keyMap = {
    'g': '/chart-of-account-accounting-master-group',
    'l': '/chart-of-account-accounting-master-ledger',
    'v': '/chart-of-account-accounting-master-voucherstype',
    's': '/chart-of-account-invmas-stockgroup',
    'c': '/chart-of-account-invmas-stockcategory',
    'y': '/chart-of-account-invmas-stockcompany',
    'i': '/chart-of-account-invmas-stockitem',
    'u': '/chart-of-account-invmas-unit',
    'o': '/chart-of-account-invmas-godown',
  };

  useKeyboardNavigation(keyMap);

  const data = [
    // Accounting Master
    { category: "Accounting Master", to: "/chart-of-account-accounting-master-group", text: "Group" },
    { category: "Accounting Master", to: "/chart-of-account-accounting-master-ledger", text: "Ledger" },
    { category: "Accounting Master", to: "/chart-of-account-accounting-master-voucherstype", text: "Vouchers Type" },
    // Inventory Master
    { category: "Inventory Master", to: "/chart-of-account-invmas-stockgroup", text: "Stock Group" },
    { category: "Inventory Master", to: "/chart-of-account-invmas-stockcategory", text: "Stock Category" },
    { category: "Inventory Master", to: "/chart-of-account-invmas-stockcompany", text: "Stock Company" },
    { category: "Inventory Master", to: "/chart-of-account-invmas-stockitem", text: "Stock Item" },
    { category: "Inventory Master", to: "/chart-of-account-invmas-unit", text: "Unit" },
    { category: "Inventory Master", to: "/chart-of-account-invmas-godown", text: "Godown" }
  ];
  const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
    items: data,
    // state: true,
    state: location?.pathname === '/chartofaccountmasters' ? true : false,
    onItemSelect: (item) => {
      // if (['/ledgerbook', '/current_date', '/period','/create-master-ledger'].includes(location.pathname)) {
      //   return;
      // }
      if (location?.pathname === '/chartofaccountmasters') {
        navigate(item.to)
      }
    },

  });
  return (
    <NavigationList
      data={data}
      selectedItemIndex={selectedItemIndex}
      setSelectedItemIndex={setSelectedItemIndex}
      title='Chart Of Account'
      first_heading='Accounting Master'
      secound_heading='Inventory Masters'

    />
  )
}

export default React.memo(ChartOfAccount);
