import React from 'react'

const Indicator = () => {
    return (
        <div className='container'>
            <div className='row justify-content-center align-items-center' id='row'>
                <div className="spinner-border text-success" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Indicator)
