import '../App.css';
import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AuthRoute from './AuthRoute';
import AppRoute from './AppRoute';
import { LINK_PATH } from '../constant';
import { closeAllModals, closeModal } from '../redux/actions/action-creator';

const Index = ({ setProgress }) => {
  const { id } = useParams()
  const dispatch = useDispatch();
  const { userData = null } = useSelector(state => state.auth);
  const modal_state = useSelector(state => state.common.modal_state);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      // List of shortcuts to disable
      if (event.ctrlKey && (event.key === 't' || event.key === 'T')) {
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'r') {
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'n') {
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'p') {
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'w') {
        event.preventDefault();
      }
      // else if (event.ctrlKey && event.key === 'a') {
      //   event.preventDefault();
      // }
      //  else if (event.ctrlKey && event.key === 'c') {
      //   event.preventDefault();
      // } 
      else if (event.ctrlKey && (event.key === '=' || event.key === '+')) {
        // event.preventDefault();
      } else if (event.ctrlKey && (event.key === '_' || event.key === '-')) {
        // event.preventDefault();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        // if (location.pathname !== `/`) {
        //   navigate(-1);
        // }

        if (Object?.values(modal_state)?.includes(true)) {
          dispatch(closeAllModals());
        }
        else
          if (location.pathname !== '/') {
            navigate(-1);
          }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate, location.pathname, modal_state]);

  useEffect(() => {
    const changeTitleName = () => {
      const path = location.pathname;
      if (path === '/') {
        document.title = 'eBalance | Home';
      } else if (path === '/createmasters') {
        document.title = 'Create Master';
      } else if (path === '/create-master-group') {
        document.title = 'Group Creation';
      }
      else if (path === '/create-master-ledger') {
        document.title = 'Ledger Creation';
      }
      else if (path === '/chart-of-account-accounting-master-group') {
        document.title = 'List of Group(s)';
      }
      else if (path === `/alter_accounting_groupupdate/${id}`) {
        document.title = 'Group Alteration';
      }
     
     
      else if (path === `/create-master-invmas-stockcompany`) {
        document.title = 'Stock Company Creation';
      }
      else if (path === `/create-master-invmas-stockcategory`) {
        document.title = 'Stock Category Creation';
      }
      else if (path === `/create-master-invmas-stockgroup`) {
        document.title = 'Stock Group Creation';
      }
      else if (path === `/chart-of-account-invmas-stockgroup`) {
        document.title = 'List of Stock Group';
      }
      else if (path === `/chartofaccountmasters`) {
        document.title = 'Master List';
      }
      else if (path === `/chart-of-account-accounting-master-ledger`) {
        document.title = 'List of Ledger(s)';
      }
      else {
        document.title = 'Accounting';
      }
    };
    changeTitleName();
    return () => {
    };
  }, [location]);

  return (
    <div className="App">
      <Routes>
        {
          userData?.id
            ?
            <Route path={`${LINK_PATH}/*`} element={<AppRoute setProgress={setProgress} />} />
            :
            <Route path={`${LINK_PATH}/*`} element={<AuthRoute setProgress={setProgress} />} />
        }
      </Routes>
    </div>
  )
}
export default Index;
