import React from 'react';
import { Link } from 'react-router-dom';

const CreateConfirm = ({ message, handleSubmit, handleFocusReset }) => {
    return (
        <div
            style={{
                position: 'absolute',
                bottom: 24,
                right: 1,
                width: '10%',
                height: '100px',
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
                background: '#fff',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <p className='modal_message'>
                            {message} ?
                        </p>
                    </div>
                    <div className='col-12'>
                        <div className='row justify-content-center'>
                            <div className='col-3 m-0 p-0'>
                                {/* <Link className="modal_link" onClick={() => { return (handleSubmit(new Event('submit'))) }} >Yes</Link> */}
                                <Link style={{ textDecoration: 'none', color: '#000' }} className="modal_link" onClick={() => { return (handleSubmit(new Event('submit'))) }} ><span style={{ color: 'red' }}>Y</span>es </Link>
                            </div>
                            <div className='col-2'>or</div>
                            <div className='col-3'>
                                <Link style={{ textDecoration: 'none', color: '#000' }} className='modal_link' onClick={() => { return (handleFocusReset()) }}><span style={{ color: 'red' }}>N</span>o</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(CreateConfirm)
