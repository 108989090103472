
export const yes_no_array =
    [
        { name: 'Yes' },
        { name: 'No' },
    ]
export const nature_of_group_arrya =
    [
        { name: 'Assets' },
        { name: 'Income' },
        { name: 'Expenses' },
        { name: 'Liabilities' }
    ]

export const applicable_not_applicable_array =
    [
        { name: 'Not Applicable' },
        { name: 'Appropriate by Qty' },
        { name: 'Appropriate by Value' },
    ]
export const availlable_array =
    [
        { name: 'Available' },
        { name: 'Not Available' },
    ]



// const is_gst_applicable_array =
//   [
//     { name: 'Applicable' },
//     { name: 'Not Applicable' },
//   ]
// const yes_no_array =
//   [
//     { name: 'Yes' },
//     { name: 'No' },
//   ]
// const taxability_array =
//   [
//     { name: 'Exempt' },
//     { name: 'Nill Rated' },
//     { name: 'None-Gst' },
//     { name: 'Taxable' },
//   ]
// const hsn_detail_gst_rate_array =
//   [
//     { name: 'As per Company/Stock Group' },
//     { name: 'Specify Details Here' },
//     { name: 'Specify in Voucher' },
//   ]
// const type_of_supply_array =
//   [
//     { name: 'Capital Goods' },
//     { name: 'Goods' },
//     { name: 'Services' },
//   ]
// const costing_method_array =
//   [
//     { name: 'At Zero Cost' },
//     { name: 'Avg Cost' },
//     { name: 'FIFO' },
//     { name: 'FIFO Perpetual' },
//     { name: 'Last Purchase Cost' },
//     { name: 'LIFO Annual' },
//     { name: 'LIFO Perpetual' },
//     { name: 'Monthly Avg Cost' },
//     { name: 'STD Cost' },
//   ]
// const market_valuation_array =
//   [
//     { name: 'At Zero Price' },
//     { name: 'Avg Price' },
//     { name: 'Last Sale Price' },
//     { name: 'STD Price' },
//   ]
// const cess_valuation_array =
//   [
//     { name: 'Not Applicable' },
//     { name: 'Based on Quantity' },
//     { name: 'Based on Value' },
//     { name: 'Based on Qty & Value' },
//   ]