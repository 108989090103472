import React from "react";
import MasterCreationFooter from "../Footer/Master_Footer/MasterCreationFooter";
import NavLayout from "../NavNameOfComponent/NavLayout";
import CreateConfirm from "../Modal/CreateConfirm";
import Indicator from "../Indicator/Indicator";

const Form = ({ children, col, handleSubmit, handleKeyDown, submitConfirm, handleFocusReset, title, loading, Footer }) => {
    if (loading) {
        return <Indicator />
    }
    return (
        <div className='form'>
            <div className='cross_button' onClick={() => { }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <NavLayout>
                <b className='ps-2'>{title} </b>
            </NavLayout>
            {
                submitConfirm === 'Accept' ?
                    <CreateConfirm
                        message={submitConfirm}
                        handleSubmit={handleSubmit}
                        handleFocusReset={handleFocusReset}
                    /> : null
            }
            <form
                onSubmit={handleSubmit}
                onKeyDown={handleKeyDown}
                autoComplete='off'
                className=""
                style={{ pointerEvents: submitConfirm ? 'none' : '' }}
            >
                <div className="row m-0 p-0 position-relative">
                    <div className={`${col} p-0 border_2_1px_right border_2_1px_bottom`} style={{ background: '#fff' }}>
                        <div className='container-fluid p-0 pt-1'>
                            {children}
                        </div>
                    </div>
                </div>
                <Footer />
            </form>
        </div>
    )
}
export default React.memo(Form);


