import React from 'react'
import FooterLayout from '../FooterLayout'

function MasterListFooter(props) {
    const fields = [
        { id: "1", name: "Back", shortcutKey: "Esc",line:'',type:'button', isDisabled: false },
        { id: "2", name: "Alter", shortcutKey: "Enter",line:'single',type:'button', isDisabled: false },
        { id: "3", name: "View", shortcutKey: "Enter",line:'',type:'button', isDisabled: true },
        { id: "4", name: "", shortcutKey: "",line:'',type:'button', isDisabled: true,  },
        { id: "5", name: "Create Master", shortcutKey: "C",line:'double',type:'button', isDisabled: false },
        { id: "6", name: "", shortcutKey: "",line:'',type:'button', isDisabled: true },
        { id: "7", name: "Delete", shortcutKey: "D",line:'single',type:'button', isDisabled: false },
        { id: "8", name: "", shortcutKey: "",line:'',type:'button', isDisabled: true },
        { id: "9", name: "Remove Line", shortcutKey: "R",line:'double',type:'button', isDisabled: true },
        { id: "10", name: "Restore Line", shortcutKey: "U",line:'double',type:'button', isDisabled: true },
    ]
    return (
        <div className='tomasterfooter'>
            <div className='footer'>
                {
                    fields.map((item, index) => {
                        return (
                            <FooterLayout key={item.id} fields={item} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default MasterListFooter
